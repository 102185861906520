import React from 'react'

function Header({text}) {
  return (
    <p className='text-white fw-bold' style={{
        backgroundColor: "#5B5B5B",
        fontSize: "17px",
        padding: "0.7em",
        margin: "0"
    }}>{text}</p>
  )
}

export default Header