import { useEffect, useState } from "react";
import { asyncWrap } from "../utils/utils";
import axios from "axios";
import { Modal } from "react-bootstrap";
import CloseModalTab from "../component/Nav/CloseModalTab";
const AboutUs = () => {
  const [aboutUs, setAboutUs] = useState();

  const getCmsValues = async () => {
    const [error, result] = await asyncWrap(axios.get("/cmsValues"));
    if (!result) {
      console.log(error);
      return;
    }
    setAboutUs(result.data.data.AboutUs);
  };

  useEffect(() => {
    getCmsValues();
  }, []);

  return (
    <Modal fullscreen={true} show={true}>
      <Modal.Body
        style={{ margin: "0px 10px" }}
        className="mb-20 p-0 text-left"
      >
        <CloseModalTab />
        {aboutUs && (
          <div dangerouslySetInnerHTML={{ __html: aboutUs.Descriptions }}></div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default AboutUs;
