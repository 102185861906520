import { Col, Image, Modal, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { AiFillCheckCircle } from "react-icons/ai";
import { Input, Label, Spinner, Button, FormGroup, Form } from "reactstrap";
import Swal from "sweetalert2";
import moment from "moment";
import { asyncWrap } from "../../utils/utils";
import axios from "axios";
import OtpInput from "react-otp-input";
import { BsFillCameraFill } from "react-icons/bs";
import { useAuth } from "../../context/auth-context";
import { imageResize } from "../../utils/imageResize";

const AadharCardModal = ({ show, setShow }) => {
  const [loading, setLoading] = useState(false);
  const [aadharCardNumber, setAadharCardNumber] = useState("");
  const [showOtp, setShowOtp] = useState(false);
  const [referenceid, setReferenceId] = useState();
  const [otpaadhar, setOtpAadhar] = useState();
  const [showImageUpload, setShowImageUpload] = useState(false);
  const [aadharCardUrl, setAadharCardUrl] = useState("");
  const [disableUploadbtn, setDisableUploadbtn] = useState(false);
  const [aadharVerify, setAadharVerify] = useState(false);
  const [firstAadharVerify, setFirstAadharVerify] = useState(false);
  const [kycVerify, setKycVerify] = useState(false);
  const [kycAutomaticVerify, setKycAutomaticVerify] = useState(false);
  const [dob, setDob] = useState();
  const [address, setAddress] = useState();
  const [fullName, setFullName] = useState();
  const [refIdData, setRefIdData] = useState(false);
  const history = useHistory();
  const maxSize = 10 * 1024 * 1024;

  const { user } = useAuth();

  const handleChange = otp => {
    setOtpAadhar(otp);
  };

  useEffect(() => {
    getUserProfile();
  }, []);

  const getUserProfile = async () => {
    const [error, result] = await asyncWrap(axios.get("/userDetails"));
    if (error?.response?.status === 401) {
      Swal.fire("Please Logout and login again");
      return;
    }
    if (!result) {
      console.log(error);
      return;
    } else {
      if (result.data.data?.IsAadharVerified) {
        setKycVerify(true);
      } else {
        const [configError, configResult] = await asyncWrap(
          axios.get("config")
        );
        if (configError) {
          console.log(configError);
        } else {
          if (
            configResult.data.data.filter(
              item => item.FlagName === "KYC Automatic verification"
            )[0].Value === "true"
          ) {
            setKycAutomaticVerify(true);
          } else {
            setShowImageUpload(true);
          }
        }
      }
      setAadharCardNumber(result.data.data?.Aadharcardnumber);
      setAadharCardUrl(result.data.data?.Aadharcardfrontimage);
      setRefIdData(result.data.data?.CompanyPhoto)
    }
  };

  const handleAadhaarCard = async () => {
    setLoading(true);
    if (aadharCardNumber === "") {
      Swal.fire("Please enter aadhaar card no");
    } else if (aadharCardNumber.length !== 12) {
      Swal.fire("Please enter valid aadhaar card no and click get code first");
    } else {
      const [error, result] = await asyncWrap(
        axios.post("/verifyKyc/send-aadhar-otp", {
          addhar: aadharCardNumber,
        })
      );

      if (!result) {
        console.log(error);
        Swal.fire(error?.response?.data?.data?.message);
      } else {
        setReferenceId(result.data.data.ref_id);
        setShowOtp(true);
        Swal.fire(result.data.data.message);
      }
    }
    setLoading(false);
  };

  const handleOtpAadharCardVerify = async () => {
    setLoading(true);
    if (aadharCardNumber === "") {
      Swal.fire("Please enter aadhaar card no");
    } else if (aadharCardNumber.length !== 12) {
      Swal.fire("Please enter valid aadhaar card no and click get code first");
    } else if (otpaadhar === "") {
      Swal.fire("Please enter otp.");
    } else {
      const [error, result] = await asyncWrap(
        axios.post("/verifyKyc/verify-aadhar-otp", {
          ref_id: referenceid,
          otp: otpaadhar,
        })
      );
      if (!result) {
        Swal.fire(error.response.data.data.message);
      } else {
        console.log(result.data?.data)
        setShowOtp(false);
        setAadharVerify(true);
        setFirstAadharVerify(true);
        setShowImageUpload(true);
        setAddress(result.data?.data?.address);
        setDob(result.data?.data?.dob);
        setFullName(result.data?.data?.name);
        handleAadharCardSubmit(result.data?.data);
      }
    }
    setLoading(false);
  };

  const handleUploadAadharCardImage = async e => {
    const file = e.target.files[0];
    if (file && file.size >= maxSize) {
      Swal.fire("File size exceeds the limit (10MB)");
      return;
    }
    setDisableUploadbtn(true);
    const formData = new FormData();
    const newFile = await imageResize(e.target.files[0]);
    formData.append("file", newFile);
    const [error, result] = await asyncWrap(axios.post("/upload", formData));
    if (error) {
      Swal.fire(error.response.data.message);
      return;
    }
    const {
      data: { fileUrl },
    } = result;
    setAadharCardUrl(fileUrl);
    setShowImageUpload(true);
    setDisableUploadbtn(false);
  };

  const handleAadharCardSubmit = async (aadharCardDetails) => {
    if (aadharCardNumber === "" || aadharCardNumber.length !== 12) {
      Swal.fire("Please enter aadhaar card no");
      return;
    }
    const [error, result] = await asyncWrap(
      axios.post("/editProfile", {
        userType: user?.profile?.roleName || user?.roleName,
        kycTypeId: "Aadhar Card",
        aadharNumber: aadharCardNumber,
        userId: user?.profile?.userId || user.userId,
        kycNumber: aadharCardNumber,
        aadharFront: aadharCardUrl,
        addressLine1: aadharCardDetails?.address,
        dob: moment(aadharCardDetails?.dob, "DD-MM-YYYY").format("YYYY-MM-DD"),
        name: aadharCardDetails?.name,
        kycPhotoPath: aadharCardUrl,
        isaadharverify: 1,
        instagramId: JSON.stringify(aadharCardDetails),
        companyPhoto: refIdData ? refIdData + "," + aadharCardDetails?.ref_id : (aadharCardDetails?.ref_id).toString()
      })
    );
    if (!result) {
      Swal.fire(error.response.data.data.message);
      history.replace("/home");
    } else if (result.data?.success) {
      history.replace("/home");
    } else {
      Swal.fire(result?.data?.message).then(() => {
        window.location.reload();
      });
    }
  };

  const handleNextClick = async () => {
    if (aadharCardNumber === "" || aadharCardNumber.length !== 12) {
      Swal.fire("Please Enter AadharCard Number Correctly");
      return;
    }
    const [error, result] = await asyncWrap(
      axios.post("/editProfile", {
        userType: user?.profile?.roleName || user?.roleName,
        kycTypeId: "Aadhar Card",
        aadharNumber: aadharCardNumber,
        userId: user?.profile?.userId || user.userId,
        kycNumber: aadharCardNumber,
        kycPhotoPath: aadharCardUrl,
        aadharFront: aadharCardUrl,
      })
    );
    if (!result) {
      Swal.fire(error.response.data.data.message);
      history.replace("/home");
    } else if (result.data?.success) {
      history.replace("/home");
    } else {
      Swal.fire(result?.data?.message).then(() => {
        window.location.reload();
      });
    }
  };

  return (
    <>
      <Modal fullscreen={true} show={show}>
        <Modal.Body
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            marginTop: "50px",
          }}
        >
          <Row style={{ width: "100%" }}>
            <Col
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <Form className="w-100">
                <FormGroup floating className="p-0">
                  <Input
                    readOnly={kycVerify || firstAadharVerify}
                    style={{ border: "1px solid #B0B0B0", width: "100%" }}
                    required
                    onChange={e => setAadharCardNumber(e.target.value)}
                    value={aadharCardNumber}
                    placeholder="Enter Aadhar Card Number"
                    type="number"
                  />
                  <Label>Aadhar Card</Label>
                </FormGroup>
              </Form>
              <div
                style={{
                  width: "15%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "baseline",
                }}
              >
                <AiFillCheckCircle
                  style={{
                    height: "auto",
                    width: "60%",
                    marginTop: "12px",
                    color: `${aadharVerify || kycVerify ? "green" : "black"}`,
                  }}
                />
              </div>
            </Col>
          </Row>
          {showOtp ? (
            <Row>
              <Col>
                <Label style={{ width: "100%" }}>
                  Otp
                  <OtpInput
                    value={otpaadhar}
                    onChange={handleChange}
                    numInputs={6}
                    isInputNum={true}
                    inputStyle={{
                      width: "30px",
                      textAlign: "center",
                      background: "white",
                      margin: "7px",
                      borderBottom: "1px solid #7B7B7B",
                      borderTop: "0px",
                      borderRight: "0px",
                      borderLeft: "0px",
                      padding: "6px",
                      fontSize: "15px",
                      color: "#434343",
                      fontWeight: "600",
                    }}
                  />
                </Label>
              </Col>
            </Row>
          ) : null}
          {showImageUpload && !kycVerify ? (
            <Row className="w-100">
              <Col>
                <Label style={{ width: "100%" }}>
                  Aadhar Card Image
                  <div
                    style={{
                      background: "#E5ECFD",
                      height: "150px",
                      borderRadius: "10px",
                    }}
                    className="mt-2 mb-3"
                  >
                    <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                      <BsFillCameraFill
                        style={{
                          color: "#3A69DD",
                          width: "40px",
                          height: "40px",
                        }}
                      />
                    </div>
                  </div>
                  <Input
                    hidden
                    required
                    max="1048576"
                    accept=".jpeg,.jpg,.png"
                    id="aadharCardImage"
                    onChange={e => handleUploadAadharCardImage(e)}
                    placeholder="Enter Aadhar Card Number"
                    style={{ width: "100%" }}
                    type="file"
                  />
                </Label>
              </Col>
            </Row>
          ) : null}
          {aadharCardUrl ? (
            <Row>
              <Col>
                <Image src={aadharCardUrl} width="100%" height="200px" />
              </Col>
            </Row>
          ) : null}
          {!kycVerify ? (
            <Row
              style={{
                // background: "#FAFAFA",
                padding: "10px",
                margin: "10px 0px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {showOtp ? (
                <div>
                  <Col>
                    <p
                      onClick={handleAadhaarCard}
                      style={{
                        color: "#3A69DD",
                        fontSize: "15px",
                        fontWeight: "bolder",
                        textAlign: "center",
                      }}
                    >
                      Resend Otp
                    </p>
                  </Col>
                  <Col className="p-0" style={{ color: "#CC7E02" }}>
                    <Button
                      style={{
                        width: "max-content",
                        background: "#3A69DD",
                        color: "white",
                        border: "none",
                        padding: "0.6rem 2rem",
                        marginTop: "0.5rem",
                        fontWeight: "600",
                        borderRadius: "20px",
                      }}
                      onClick={handleOtpAadharCardVerify}
                      disabled={loading}
                    >
                      {loading ? (
                        <Spinner
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        "Verify Otp"
                      )}
                    </Button>
                  </Col>
                </div>
              ) : showImageUpload ? (
                <Col className="p-0" style={{ color: "#CC7E02" }}>
                  <Button
                    disabled={disableUploadbtn}
                    style={{
                      width: "max-content",
                      background: `${disableUploadbtn ? "#ccc" : "#3A69DD"}`,
                      color: "white",
                      border: "none",
                      padding: "0.6rem 2rem",
                      marginTop: "0.5rem",
                      fontWeight: "600",
                      borderRadius: "20px",
                    }}
                    onClick={handleNextClick}
                  >
                    {disableUploadbtn ? (
                      <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                      "Submit"
                    )}
                  </Button>
                </Col>
              ) : (
                <Col className="p-0" style={{ color: "#CC7E02" }}>
                  <Button
                    disabled={aadharCardNumber.length !== 12 || loading}
                    style={
                      aadharCardNumber.length !== 12
                        ? {
                            width: "max-content",
                            background: "gray",
                            border: "none",
                            padding: "0.6rem 2rem",
                            marginTop: "0.5rem",
                            borderRadius: "20px",
                          }
                        : {
                            width: "max-content",
                            background: "#3A69DD",
                            color: "white",
                            border: "none",
                            padding: "0.6rem 2rem",
                            marginTop: "0.5rem",
                            borderRadius: "20px",
                            fontWeight: "600",
                          }
                    }
                    onClick={handleAadhaarCard}
                  >
                    {loading ? (
                      <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                      "Send Otp"
                    )}
                  </Button>
                </Col>
              )}
            </Row>
          ) : (
            ""
          )}

          {loading ? (
            <Row
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                color: "gray",
              }}
            >
              <p className="text-center">
                Please wait, We are fetching your details. This may take 2-3
                minutes
              </p>
            </Row>
          ) : (
            ""
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AadharCardModal;
