import React, { useEffect, useState } from 'react'
import Header from './Header'
import SalesHeader from './SalesHeader'
import SalesInfoHeader from '../../component/SalesInfoHeader/SalesInfoHeader'
import CategoryWiseSales from '../CategoryWiseSales/CategoryWiseSales'
import { asyncWrap } from '../../utils/utils'
import axios from 'axios'

function SalesInfo() {
  const [activeCategoryTab, setActiveCategoryTab] = useState(1)
  const [fyData, setFyData] = useState([])
  const [overallGrowth, setOverallGrowth] = useState(0)

  const categories = [
    {
        id: 1,
        icon: "/assets/images/salesInfo/payment.svg",
        width: "25px"
    },
    {
        id: 2,
        icon: "/assets/images/salesInfo/payment.svg",
        width: "25px"
    },
    {
        id: 3,
        icon: "/assets/images/salesInfo/payment.svg",
        width: "25px"
    },
    {
        id: 4,
        icon: "/assets/images/salesInfo/payment.svg",
        width: "25px"
    },
    {
        id: 5,
        icon: "/assets/images/salesInfo/payment.svg",
        width: "25px"
    }
  ]

  const getFyData = async () => {
    const [error, result] = await asyncWrap(axios.get("/fy-data"))

    if(!result) {
      console.log("Error in getting financial year data")
      console.log(error)
      return;
    }

    if(result?.data?.data) {
      setFyData(result.data.data)
      let growth = (result.data.data[0].CurrentFYTotalValue - result.data.data[0].PreviousFYTotalValue)

      // calculating the growth percentage if the PreviousFYTotalValue is not zero
      if(result.data.data[0].CurrentFYTotalValue > 0) {
        growth = (growth/result.data.data[0].CurrentFYTotalValue) * 100
      } else {
        growth = 0;
      }
      
      setOverallGrowth(Math.ceil(growth ? growth : 0))
    }
  }

  useEffect(() => {
    getFyData()
  }, [])


  return (
    <div>
        <Header text={"Sales"} />
        <SalesHeader 
        heading1="Sales"
        heading2="Last Year Sales"
        heading3="Overall Growth"
        value1={`₹ ${fyData[0]?.CurrentFYTotalValue ? fyData[0]?.CurrentFYTotalValue : 0}`}
        value2={`₹ ${fyData[0]?.PreviousFYTotalValue ? fyData[0]?.PreviousFYTotalValue : 0}`}
        value3={`${overallGrowth} %`}
        tooltipText="Total sales in the current Financial Year"
        />
        <Header text="Category Wise Sales" />
        <div className='mt-3'>
        <SalesInfoHeader
        data={categories}
        active={activeCategoryTab}
        setActive={setActiveCategoryTab}
        />
        </div>

        {activeCategoryTab === 1 && <CategoryWiseSales />}
        {activeCategoryTab === 2 && <CategoryWiseSales />}
        {activeCategoryTab === 3 && <CategoryWiseSales />}
        {activeCategoryTab === 4 && <CategoryWiseSales />}
        {activeCategoryTab === 5 && <CategoryWiseSales />}
    </div>
  )
}

export default SalesInfo