import moment from "moment";
import { Modal } from "react-bootstrap";
import { useHistory } from "react-router";

const SuccessModal = ({ show, setShow, point }) => {
  const history = useHistory();

  return (
    <Modal fullscreen={true} show={show}>
      <Modal.Body className="mt-4 d-flex flex-column align-items-center">
        <div
          style={{ color: "#3A69DD", fontSize: "28px" }}
          className="fw-bold my-2"
        >
          {point || 0} points
        </div>
        <div
          style={{ color: "#434343", fontSize: "30px" }}
          className="fw-bold my-4"
        >
          ✅ Success
        </div>
        <div
          style={{ color: "#434343", fontSize: "18px" }}
          className="my-1 text-center"
        >
          Your payment requested has been accepted. Thank you!
        </div>
        <div style={{ color: "#7B7B7B", fontSize: "18px" }} className="my-1">
          {moment().format("MMMM Do YYYY, h:mm:ss a")}
        </div>
        <div
          style={{ color: "#3A69DD", fontSize: "20px" }}
          className="fw-bold my-4"
          onClick={() => history.push("/points-info")}
        >
          Transactions
        </div>
        {/* <button
          style={{ background: "#3A69DD", color: "white" }}
          className="btn btn-primary fw-bold rounded-5 px-4"
        >
          Share
        </button> */}
      </Modal.Body>
    </Modal>
  );
};

export default SuccessModal;
