import React, {useState} from 'react'
import Button from './Button'
import Select from "react-select"
import Header from '../SalesInfo/Header';
import AgreementCard from './AgreementCard';
import SummaryModal from './SummaryModal';

const options = [
    { value: 'q1', label: 'Q1' },
    { value: 'q2', label: 'Q2' },
    { value: 'q3', label: 'Q3' },
    { value: 'q4', label: 'Q4' },
  ];

function ProductAgreement() {
    const [selectedOption, setSelectedOption] = useState(options[0]);
    const [showSummaryModal, setShowSummaryModal] = useState(false)
  

    const handleSummaryModal = () => {
        setShowSummaryModal(prev => !prev)
    }


    const summaryColumn = [
      {
        id: 0,
        name: "Group Name",
      },
      {
        id: 1,
        name: "Target Amt"
      },
      {
        id: 2,
        name: "Sales Amt"
      },
    ]

    const summaryData = [
      {
        "Group Name": "Wiring Devices",
        "Target Amt": "₹ 85,00,000",
        "Sales Amt": "₹ 85,00,000",
      },
      {
        "Group Name": "Wiring Devices",
        "Target Amt": "₹ 85,00,000",
        "Sales Amt": "₹ 85,00,000",
      },
      {
        "Group Name": "Wiring Devices",
        "Target Amt": "₹ 85,00,000",
        "Sales Amt": "₹ 85,00,000",
      },
    ]
    
  return (
    <div>
          {/* summary modal */}
          <SummaryModal
            show={showSummaryModal}
            onHide={() => setShowSummaryModal(false)}
            column={summaryColumn}
            data={summaryData}
            />

        <div className='d-flex gap-2 mx-3 my-3 justify-content-between' style={{
            fontSize: "17px"
        }}>
            <Button text="Accept" />
            <Button text="Summary" onClick={handleSummaryModal} />
            <Select
            value={selectedOption}
            onChange={setSelectedOption}
            options={options}
            closeMenuOnSelect
            className='flex-grow-1'
            styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderWidth: "1.5px",
                  borderColor: state.isFocused ? 'grey' : 'black',
                  
                }),
                  valueContainer: (baseStyles, state) => ({
                    ...baseStyles,
                    padding: "0px",
                  })
              }}
             />
        </div>

        <Header text="Wiring Devices" />
        <AgreementCard heading="JANUARY" />
        <AgreementCard heading="FEBRUARY" />
        <AgreementCard heading="MARCH" />
        <AgreementCard heading="APRIL" />
        <AgreementCard heading="Q1" />
        <AgreementCard heading="YEARLY" />
    </div>
  )
}

export default ProductAgreement