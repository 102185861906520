import axios from "axios";
import { asyncWrap } from "./utils";
import Swal from "sweetalert2";

export const getMokKeys = async () => {
  const [err, result] = await asyncWrap(axios.get("/get-mok-keys"));
  if (!result) {
    console.log(err.response.data.message);
    return null;
  }
  return result.data;
};
