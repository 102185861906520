const BankIcon = () => (
  <div className="flex flex-col items-center justify-center p-[10px]">
    <svg
      width="30"
      height="30"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.4762 17.1431H4.7619V30.4764H10.4762V17.1431Z"
        fill="#3A69DD"
      />
      <path
        d="M22.8572 17.1431H17.1429V30.4764H22.8572V17.1431Z"
        fill="#3A69DD"
      />
      <path
        d="M39.0476 34.2856H0.952393V39.9999H39.0476V34.2856Z"
        fill="#3A69DD"
      />
      <path
        d="M35.2381 17.1431H29.5238V30.4764H35.2381V17.1431Z"
        fill="#3A69DD"
      />
      <path
        d="M20 0L0.952393 9.52381V13.3333H39.0476V9.52381L20 0Z"
        fill="#3A69DD"
      />
    </svg>
  </div>
);

export default BankIcon;
