const MarathiIcon = ({ color }) => (
  <div className="flex flex-col items-center justify-center p-[10px]">
    <svg
      width="37"
      height="38"
      viewBox="0 0 37 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.82 6.696V0.424H36.94V6.696H31.34V38H23.612V23.384H14.764V30.216H8.1L6.084 25.736C5.00133 23.272 4.46 21.5547 4.46 20.584C4.46 18.9787 5.3 17.952 6.98 17.504V6.696H0.82ZM23.612 6.696H14.764V17.112H23.612V6.696Z"
        fill={color}
      />
    </svg>
  </div>
);

export default MarathiIcon;
