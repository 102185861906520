import axios from "axios";
import { useEffect, useState } from "react";
import { Col, Row, Image } from "react-bootstrap";
import { asyncWrap } from "../utils/utils";

import tick from "/assets/images/games/history/tick.svg";
import lock from "/assets/images/lock-card.png";

import { useTranslation } from "react-i18next";
import StickerDetail from "../component/RewardsAndCashback/StickerDetail";

function Stickers() {
  // const history = useHistory();
  const [stikersData, setStickersData] = useState([]);
  const [showStickerDetail, setShowStickerDetail] = useState(false);
  const [stickerDetailForModal, setStickerDetailForModal] = useState();
  const { t } = useTranslation();
  useEffect(() => {
    getStikers();
  }, []);

  const getStikers = async () => {
    //eslint-disable-next-line
    const [error, result] = await asyncWrap(axios.get("/stickers"));

    if (!result) {
      console.log(`didn't got data from api`, error);
      return;
    }
    setStickersData(result.data.data);
  };

  return (
    <div>
      <StickerDetail
        setShow={setShowStickerDetail}
        show={showStickerDetail}
        data={stickerDetailForModal}
      />
      <div>
        <p style={{ margin: 0 }}>{t("UNO_TAG_stickers_are_here")}</p>
        <p style={{ margin: 0 }}>
          {t("get_a_sticker_every_time_you_use_unotag")}
        </p>
      </div>
      <div className="mainPage" style={{ marginTop: "55px" }}>
        <Row>
          {stikersData.map((data, index) => (
            <Col xs={4} key={index}>
              <div
                style={{
                  position: "relative",
                }}
                onClick={() => {
                  setStickerDetailForModal(data);
                  setShowStickerDetail(true);
                }}
              >
                <Image src={data.StickerImage} alt="" height="50" />
                <p>{data.StickerName}</p>
                <Image
                  src={data?.FlagStatus === "True" ? tick : lock}
                  style={{
                    width: 25,
                    position: "absolute",
                    height: 25,
                    right: 5,
                    top: 5,
                  }}
                  alt=""
                />
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
}

export default Stickers;
