import React from 'react'
import Chart from "react-apexcharts";

function SalesChart({chartData, total}) {
  console.log(chartData.series)
  return (
    <div>
        <div
          style={{ height: "280px" }}
          className={`my-2 d-flex justify-content-center ${chartData.series?.length === 0 ? "align-items-center": ""}`}
        >
          {chartData.series?.length > 0 ? <Chart
            series={chartData.series}
            options={chartData.options}
            type="donut"
            height={280}
          /> : (
            <p>
              No data available
            </p>
          )}
        </div>
        <div className="d-flex justify-content-between mx-4 mb-3">
          <button
            type="button"
            style={{
              color: "white",
              backgroundColor: "#129842",
              outline: "none",
              border: "none",
              minWidth: "6em"
            }}
          >
            Total
          </button>

          <button
            type="button"
            style={{
              color: "white",
              backgroundColor: "#129842",
              outline: "none",
              border: "none",
              minWidth: "6em"
            }}
          >
            &#8377; {total?.toLocaleString()}
          </button>
        </div>
      </div>
  )
}

export default SalesChart