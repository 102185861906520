import { Modal, Image } from "react-bootstrap";
import close from "/assets/images/popclose.png";

const JourneyDetails = ({ show, setShow, data, points }) => {
  return (
    <Modal
      fullscreen={false}
      backdrop="static"
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={() => setShow(false)}
    >
      <Modal.Body>
        <span className="modal-close">
          <Image src={close} alt="" onClick={() => setShow(false)} />
        </span>
        <div style={{ width: "250px", borderRadius: "10px" }}>
          <p style={{ fontSize: "20px", color: "#000000" }}>You will earn</p>
          <p style={{ fontSize: "20px", color: "#000000" }}>{points} Points</p>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default JourneyDetails;
