import { FormGroup, Col, Label, Input, FormFeedback } from "reactstrap";
import { Field } from "formik";

//eslint-disable-next-line
export default ({ label, name, type, touched, errors, ...restOfProps }) => (
  <Col xs="12" md="9" className="p-0">
    <FormGroup floating row>
      <Field
        style={{
          textAlign: "start",
          border: "1px solid #B0B0B0",
        }}
        type={type}
        id={name}
        placeholder={name}
        name={name}
        as={Input}
        {...restOfProps}
        invalid={!!(touched && errors)}
      />
      <Label htmlFor={name}>{label}</Label>
      {touched && errors && <FormFeedback>{errors}</FormFeedback>}
    </FormGroup>
  </Col>
);
