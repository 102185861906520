import { Slider } from "antd";
import VideoPlayer from "../../component/Videoplayer";
import { useState } from "react";
import { Button, Image, Modal } from "react-bootstrap";
import close from "/assets/images/popclose.png";
import popupbg from "/assets/images/popupbg.png";
import { useHistory } from "react-router";

const LudoGameDetail = ({ show, setShow, data, playerid }) => {
  const history = useHistory();
  const [showVideoPlayer, setVideoPlayer] = useState(false);

  const sliders = [
    { title: "ULIP" },
    { title: "Smart Wealth" },
    { title: "Traditional" },
    { title: "Annuity" },
    { title: "YONO Premium" },
  ];
  const [values, setValues] = useState(
    new Array(sliders.length).fill(100 / sliders.length)
  );

  const [newIncentive, setNewIncentive] = useState(10000);

  const handleChange = (index, value) => {
    let maxValue = 100;
    const remaining = maxValue - parseInt(value, 10);
    let incetive = Math.floor(Math.random() * 10000) + 10000;
    setNewIncentive(incetive);
    setValues((vs) =>
      vs.map((v, i) => {
        if (i === index) return parseInt(value, 10);
        const oldRemaining = maxValue - parseInt(vs[index], 10);
        if (oldRemaining) return (remaining * v) / oldRemaining;
        return remaining / (sliders.length - 1);
      })
    );
  };

  const modalData = () => {
    if (playerid === 1) {
      return (
        <Modal
          fullscreen={false}
          backdrop="static"
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={show}
          onHide={() => setShow(false)}
        >
          <Modal.Header>Benchmark Premium</Modal.Header>
          <Modal.Body
            style={{
              backgroundImage: `url(${popupbg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              overflowY: "hidden",
              width: "70vw",
              overflowX: "hidden",
            }}
            className="m-0 p-3 text-left"
          >
            <div>
              <div>Role of the user = BDE</div>
              <div>Benchmark Premium target = 30,00,000</div>
              <div>
                you have earned 25,00,000 Benchmark premium and have to collect
                another 5,00,000 to complete target.
              </div>
              <div className="m-2">
                {sliders.map((item, index) => (
                  <div>
                    <div>{item.title}</div>
                    <Slider
                      key={index}
                      index={index}
                      value={values[index]}
                      title={item.title}
                      onChange={(e) => handleChange(index, e)}
                    />
                  </div>
                ))}
              </div>
              <div>your current incentive = 10,000</div>
              <div>your possiable incentive with new data = {newIncentive}</div>
            </div>
            <span className="modal-close">
              <Image src={close} alt="" onClick={() => setShow(false)} />
            </span>
            <div>
              <Button
                style={{ margin: "15px" }}
                onClick={() => {
                  setVideoPlayer(true);
                }}
                variant="outline-secondary"
              >
                Go to Game Section
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      );
    } else if (playerid === 2) {
      return (
        <Modal
          fullscreen={false}
          backdrop="static"
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={show}
          onHide={() => setShow(false)}
        >
          <Modal.Header>Total Rated NBP Budget = 7415268</Modal.Header>
          <Modal.Body
            style={{
              backgroundImage: `url(${popupbg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              overflowY: "hidden",
              width: "70vw",
              overflowX: "hidden",
            }}
            className="m-0 p-3 text-left"
          >
            <div>
              <div>you have completed 46.6% of your target of NBP.</div>
              <div>complete NBP by 3972465 to complete your target.</div>
              <div>yout current incentive = 0</div>
              <div onClick={() => history.push("/rank")}>
                Your current Rank = 7
              </div>
              {/* <div>{(7415268 / 56) * 26}</div> */}
            </div>
            <span className="modal-close">
              <Image src={close} alt="" onClick={() => setShow(false)} />
            </span>
            <div>
              <Button
                style={{ margin: "15px" }}
                onClick={() => {
                  setVideoPlayer(true);
                }}
                variant="outline-secondary"
              >
                Go to Game Section
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      );
    } else if (playerid === 3) {
      return (
        <Modal
          fullscreen={false}
          backdrop="static"
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={show}
          onHide={() => setShow(false)}
        >
          <Modal.Header>Individual Rated Budget</Modal.Header>
          {/* <Modal fullscreen={false} centered show={show} onHide={() => setShow(false)} className="center-modal"> */}
          <Modal.Body
            style={{
              backgroundImage: `url(${popupbg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              overflowY: "hidden",
              width: "70vw",
              overflowX: "hidden",
            }}
            className="m-0 p-3 text-left"
          >
            <div>
              <div>Your individual Rated NBP is 667314.</div>
              <div>
                you have completed 75% of your target.
                <br />
                Earn another 166829 individual Rated Budget and get a chance to
                earn higher incentive.
              </div>
            </div>
            <span className="modal-close">
              <Image src={close} alt="" onClick={() => setShow(false)} />
            </span>
            <div>
              <Button
                style={{ margin: "15px" }}
                onClick={() => {
                  setVideoPlayer(true);
                }}
                variant="outline-secondary"
              >
                Go to Game Section
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      );
    } else if (playerid === 4) {
      return (
        <Modal
          fullscreen={false}
          backdrop="static"
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={show}
          onHide={() => setShow(false)}
        >
          <Modal.Header>Persistency</Modal.Header>
          {/* <Modal fullscreen={false} centered show={show} onHide={() => setShow(false)} className="center-modal"> */}
          <Modal.Body
            style={{
              backgroundImage: `url(${popupbg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              overflowY: "hidden",
              width: "70vw",
              overflowX: "hidden",
            }}
            className="m-0 p-3 text-left"
          >
            <div>
              <div>your persistency is 95.6%</div>
              <div>This qualifies you for level 3</div>
              {/* <div>{(7415268 / 56) * 26}</div> */}
            </div>
            <span className="modal-close">
              <Image src={close} alt="" onClick={() => setShow(false)} />
            </span>
            <div>
              <Button
                style={{ margin: "15px" }}
                onClick={() => {
                  setVideoPlayer(true);
                }}
                variant="outline-secondary"
              >
                Go to Game Section
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      );
    }
  };

  return (
    <>
      {showVideoPlayer && (
        <VideoPlayer
          playerid={playerid}
          show={showVideoPlayer}
          setShow={setVideoPlayer}
          data={data}
        />
      )}
      {modalData()}
    </>
  );
};

export default LudoGameDetail;
