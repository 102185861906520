import { useEffect, useState } from "react";
import { Card } from "antd";
import { useLocation, useParams } from "react-router-dom";
import RetailerForm from "./RetailerForm";
import * as Yup from "yup";
import { asyncWrap } from "../utils/utils";
import axios from "axios";
import moment from "moment";
import { message } from "antd";
import Swal from "sweetalert2";

//eslint-disable-next-line
export default props => {
  const { userId } = useParams();
  const [user, setUser] = useState({});
  const [registerFields, setRegisterFields] = useState([]);
  const [state, setStates] = useState([]);
  const [intialfileUrl, setIntialfileUrl] = useState();
  const location = useLocation();

  const [cmsValues, setCmsValues] = useState();

  const getCmsValues = async () => {
    const [error, result] = await asyncWrap(axios.get("/cmsValues"));
    if (!result) {
      console.log(error);
      return;
    }
    setCmsValues(result.data.data);
  };

  useEffect(() => {
    getCmsValues();
  }, []);

  const fetchRegisterFields = async () => {
    const [error, result] = await asyncWrap(
      axios({
        method: "GET",
        url: "cmsValues",
        params: {
          cmsType: "RegistrationFields",
        },
      })
    );

    if (!result) {
      console.log(error);
      message.error({
        content: "Failed to fetch fields data!",
        style: { marginTop: "5vh" },
      });
    } else {
      setRegisterFields(
        result.data.data.RegistrationFields.Descriptions.Retailer
      );
    }
  };

  const isVisibleField = (val) => {
    return registerFields.filter(item => val === item.ControlName)[0].isVisible;
  };
  const isRequiredField = (val) => {
    return registerFields.filter(item => val === item.ControlName)[0]
      .isRequired;
  };

  const fetchStateData = async () => {
    const [error, result] = await asyncWrap(axios.get("masterData/regions"));

    if (error) {
      console.log(error);
      return;
    }

    setStates(
      result.data.data.states.map((item) => ({
        ...item,
        label: item.stateName,
        value: item.stateId,
      }))
    );
  };

  const handleSelectState = () => {
    const selectdist = state.filter(value => {
      return (
        value.stateName.toLocaleLowerCase() ===
        location.state?.userdata?.userdata?.split_address.state.toLocaleLowerCase()
      );
    });
    return selectdist
      ? {
          value: selectdist[0]?.stateId || "",
          label: selectdist[0]?.stateName || "",
        }
      : [];
  };

  // const handleSelectDistrict = () => {
  //   const selectdistrictdata = district.filter(value => {
  //     return (
  //       value.districtName.toLocaleLowerCase() ===
  //       location.state?.userdata?.userdata?.split_address.dist.toLocaleLowerCase()
  //     );
  //   });
  //   return selectdistrictdata
  //     ? {
  //         value: selectdistrictdata[0]?.districtId || "",
  //         label: selectdistrictdata[0]?.districtName || "",
  //       }
  //     : [];
  // };

  function dataURItoBlob(dataURI) {
    var byteString = atob(dataURI.split(",")[1]);

    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  }

  const handleImageData = async () => {
    const formData = new FormData();
    formData.append(
      "file",
      dataURItoBlob(location.state?.userdata?.userdata?.photo_link)
    );
    // console.log(dataURItoBlob(location.state?.userdata.photo_link))
    const [error, result] = await asyncWrap(axios.post("/upload", formData));
    if (error) {
      Swal.fire(error.response.data.message);
      return;
    }

    const {
      data: { fileUrl },
    } = result;
    setIntialfileUrl(fileUrl);
  };

  useEffect(() => {
    if (location.state?.userdata) {
      handleImageData();
    }
    //eslint-disable-next-line
  }, []);

  const initialValues = {
    userId: userId || 0,
    userName: "",
    roleId: "6",
    RSM: "",
    ASM: "",
    zone: "",
    shopName: "",
    ownerName: location.state?.userdata?.userdata?.name || "",
    ownerPhone: location.state?.userdata?.mobileNumber || "",
    ownerDob:
      moment(location.state?.userdata?.userdata?.dob, "DD-MM-YYYY").format(
        "YYYY-MM-DD"
      ) || "",
    ownerWhatsApp: "",
    ownerAnniversary: "",
    language: "",
    outletEmail: "",
    outletLandlineNumber: "",
    outletType: "",
    address: location.state?.userdata?.userdata?.address || "",
    state: handleSelectState() || "",
    city: "",
    pinCode: location.state?.userdata?.userdata?.split_address.pincode || "",
    beatsName: "",
    counterBoy: [{ countername: "", contactnumber: "" }],
    gstIn: "",
    aadharOwner: "",
    docType: "1",
    docFile: "",
    kycPhotoPreview: "",
    accHolderName: "",
    bankAccNumber: "",
    confirmBankAccNumber: "",
    bankName: "",
    ifscCode: "",
    canceledChequePic: "",
    paytmNumber: "",
    orgEstDate: "",
    imageShop: "",
    imageShopPreview: "",
    ownerPhotoPreview: intialfileUrl || "",
    ownerPhoto: intialfileUrl || "",
    questAnswer: "",
    userUniqueId: "",
    docFileImg: "",
    addharPhotoImg: "",
    cancelledChequeImg: "",
    AlterNetNumber: "",
    PanNumber: "",
    Salutation: "",
    SecondaryMobile: "",
    PrimaryMobile: "",
    FacebookId: "",
    InstagramId: "",
    LinkedInProfile: "",
    TwittterHandle: "",
    FirstName: "",
    MiddleName: "",
    LastName: "",
    UpiId: "",
    kycNumber: location.state?.userdata?.aadharNumber || "",
    kycPhotoPath: "",
    kycTypeId: 2,
    aadharBackPreview: "",
    PancardFrontImage: "",
    PanCardFrontPreview: "",
    PancardBackImage: "",
    PanCardBackPreview: "",
  };

  const fetchEditData = async (id) => {
    const [error, result] = await asyncWrap(
      axios.get(`user/list/?userId=${id}`)
    );

    if (error) {
      Swal.fire("Failed to get user data!");
      return;
    }
    const { data } = result.data;
    setUser({ ...data, roleId: "6" });
  };

  useEffect(() => {
    if (userId) {
      fetchEditData(userId);
    }
    fetchStateData();
    fetchRegisterFields();
  }, [userId]);

  const getData = () => {
    if (userId) {
      const data = {
        ...user,
        counterBoy: user.CounterBoys
          ? JSON.parse(user.CounterBoys)
          : initialValues.counterBoy,
        shopName: user.FirmName || "",
        ownerPhone: user.mobileNumber || "",
        zone: user.Zone || "",
        ownerDob: moment.utc(user.dateOfBirth).format("YYYY-MM-DD"),
        ownerWhatsApp: user.whatsAppMobileNumber,
        ownerAnniversary: moment.utc(user.aniversary).format("YYYY-MM-DD"),
        language: user.Language || "",
        outletEmail: user.Email || "",
        outletLandlineNumber: user.LandlineNumber || "",
        outletType: user.OutletType || "",
        state: { label: user.StateName, value: user.StateId },
        district: { label: user.districtName, value: user.districtId },
        gstIn: user.GST || "",
        ownerName: user.fullName || "",
        aadharOwner: user.Aadharcardnumber || "",
        kycPhotoPreview: user.kycPhotoPath || "",
        addharPhotoImg: user.kycPhotoPath || "",
        accHolderName: user.NameofAccountHolder || "",
        bankAccNumber: user.AccountNumber || "",
        confirmBankAccNumber: user.AccountNumber || "",
        bankName: user.BankName || "",
        ifscCode: user.IfscNumber || "",
        canceledChequePic: user.CancelledCheaquePhoto || "",
        cancelledChequeImg: user.CancelledCheaquePhoto || "",
        paytmNumber: user.PaytmNumber || "",
        imageShop: user.CompanyPhoto || "",
        imageShopPreview: user.CompanyPhoto || "",
        ownerPhoto: user.profilePhotoPath || "",
        ownerPhotoPreview: user.profilePhotoPath || "",
        questAnswer: user.FirmSellAnswer || "",
        docFileImg: user.FirmUploadDocumentPhotoPath || "",
        docFile: user.FirmUploadDocumentPhotoPath || "",
        docType:
          user.FirmUploadDocumentTypeId === 0
            ? 1
            : user.FirmUploadDocumentTypeId,
        address: user.Address2 || "",
        address2: user.Address3 || "",
        kycTypeId: user.KycTypeId || 1,
        aadharPhoto: user.Aadharcardfrontimage,
        // key up
        aadharFrontPhoto: user.Aadharcardfrontimage,
        aadharPhotoBack: user.Aadharcardbackimage,
        // key up
        aadharBackPreview: user.Aadharcardbackimage,
        PancardFrontImage: user.PancardFrontimage,
        // key up
        PanCardFrontPreview: user.PancardFrontimage,
        PancardBackImage: user.PancardbackImage,
        //key up
        PanCardBackPreview: user.PancardbackImage,
        PanNumber: user.PancardNumber,
        //UPI ID NEED TO ADD

        // Continue working here editing user
      };
      return data;
    } else {
      return initialValues;
    }
  };

  const onSubmit = async values => {
    const counterBoy = values.counterBoy;

    const beneficiaryDetail = {
      name: values.accHolderName,
      bankName: values.bankName,
      accountNumber: values.bankAccNumber,
      ifscNumber: values.ifscCode,
    };
    var valuesToSend = {
      ownerAnniversary: handleDateError(values.ownerAnniversary),
      ownerDob: handleDateError(values.ownerDob),
      mobileNumber: String(values.ownerPhone),
      state: values.state.stateName,
      district: values.district.value,
      dob: values.ownerDob,
      anniversary: values.ownerAnniversary,
      paytmDetails: values.paytmNumber,
      name: values.ownerName,
      city: values.city,
      addressLine1: values.address,
      profilePic: values.ownerPhoto,
      kycNumber: values.kycNumber,
      kycPhotoPath: values.kycPhotoPath,
      language: values.language,
      whatappNumber: values.ownerWhatsApp,
      pinCode: values.pinCode,
      email: values.outletEmail,
      kycTypeId: values.kycTypeId,
      userType: "retailer",
      phoneNumber: String(values.ownerPhone),
      alternateNumber: String(values.AlterNetNumber),
      panNumber: values.PanNumber,
      gstNumber: values.gstIn,
      certificateNo: values.ce,
      pancardBack: values.PancardBackImage,
      pancardFront: values.PancardFrontImage,
      salutation: values.Salutation,
      primaryMobile: values.PrimaryMobile,
      secondaryMobile: values.SecondaryMobile,
      countryId: 1,
      landlineNumber: values.outletLandlineNumber,
      facebookId: values.FacebookId,
      instagramId: values.InstagramId,
      linkedInProfile: values.LinkedInProfile,
      twitterHandle: values.TwittterHandle,
      firstName: values.FirstName,
      lastName: values.LastName,
      aadharBack: values.aadharPhotoBack,
      aadharFront: values.aadharFrontPhoto,
      aadharNumber: values.aadharOwner,
      middleName: values.MiddleName,
      RSM: values.RSM,
      ASM: values.ASM,
      zone: values.zone,
      beatId: values?.beatsName?.BeatId || "",
      counterBoy: values.counter,
      outletType: values.outletType,
      establishment: values.orgEstDate,
      firmSellAnswer: values.questAnswer,
      upiId: values.UpiId,
      firmDocPhotoPath: values.imageShop,
      cancelledChequePhoto: values.canceledChequePic,
      firmName: values.shopName,
    };

    if (counterBoy[0].contactnumber.length) {
      valuesToSend = { ...valuesToSend, counterBoy: counterBoy };
    }

    if (isRequiredField("State") && !valuesToSend.state) {
      Swal.fire("State is a required field!");
      return;
    }
    if (isRequiredField("District") && !valuesToSend.district) {
      Swal.fire("District is a required field!");
      return;
    }
    // if (isRequiredField("Beat ControlName of User") && !valuesToSend.beatId) {
    //   Swal.fire("Beat name is a required field!");
    //   return;
    // }

    const [error, result] = await asyncWrap(
      axios({
        method: "POST",
        url: "editProfile",
        data: beneficiaryDetail.accountNumber
          ? { ...valuesToSend, beneficiaryDetail }
          : { ...valuesToSend },
      })
    );

    if (!error) {
      Swal.fire(result?.data?.message);
      if (!userId) {
        props.history.push("/login");
      }
      props.history.push(`/profile`);
    } else {
      Swal.fire("something Went Wrong!");
    }
  };

  const valSchema = Yup.object().shape({
    bankAccNumber: Yup.string().matches(
      /^[0-9]{8,16}$/,
      "Not a valid account number!"
    ),
    ifscCode: Yup.string().matches(
      /[A-Za-z]{4}0[A-Z0-9]{6}$/,
      "Not a valid IFSC code!"
    ),
    paytmNumber: Yup.string().matches(
      /^[6-9]\d{9}$/,
      "Not a valid Paytm number!"
    ),
  });

  const handleDateError = value => {
    if (value === "dd-mm-yyy" || value === "Invalid date") {
      return "";
    }
    return value;
  };

  return (
    <>
      <Card
        style={{
          marginTop: "0px",
          marginBottom: "50px",
          textAlign: "start",
        }}
        // title={userId ? "Edit Retailer User" : "Add Retailer User"}
      >
        <div className="d-flex flex-column align-items-center justify-content-center">
          <img
            style={{
              height: "60px",
            }}
            key={cmsValues?.Logo.CMSType}
            src={cmsValues?.Logo.URL}
            alt=""
          />
          {userId ? (
            <div
              style={{
                color: "#434343",
                fontWeight: "bold",
                fontSize: "20px",
              }}
              className="my-4"
            >
              Edit User
            </div>
          ) : (
            <div
              style={{
                color: "#434343",
                fontWeight: "bold",
                fontSize: "20px",
              }}
              className="my-4"
            >
              Complete Registration
            </div>
          )}
        </div>
        <RetailerForm
          isRequiredField={isRequiredField}
          isVisibleField={isVisibleField}
          registerFields={registerFields}
          initialValues={getData()}
          valSchema={valSchema}
          onSubmit={onSubmit}
          enableReinitialize={true}
          BeatId={user.BeatId}
        />
      </Card>
    </>
  );
};
