import { NotificationButton } from "@mok.one/sdk/react";
import { useAuth } from "../context/auth-context";
import { useMokKeysStore } from "../store";

const Notification = () => {
  const mokKeys = useMokKeysStore((state) => state.mokKeys);
  const { user } = useAuth();

  return (
    <>
      {mokKeys && (
        <NotificationButton
          writeKey={mokKeys?.writeKey}
          readKey={mokKeys?.readKey}
          id={user?.profile?.userId || user.userId}
          fullScreen={true}
          disableBellClick={true}
          textStyles={{ fontSize: "16px" }}
          titleStyles={{ fontSize: "16px" }}
          // isDev={true}
        />
      )}
    </>
  );
};

export default Notification;
