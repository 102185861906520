import { useState } from "react";
import { Modal, Table, Button } from "react-bootstrap";
import LeaderboardAfterQuiz from "./LeaderBoardAfterQuiz";

const LeaderBoard = (props) => {
  const [showLeaderBoardAfterQuiz, setShowLeaderBoardAfterQuiz] =
    useState(false);

  const {
    show,
    setShow,
    currentQuestion,
    setCurrentQuestion,
    questions,
    leaderBoardData,
  } = props;

  const skip = () => {
    if (questions.length === currentQuestion + 1) {
      setShowLeaderBoardAfterQuiz(true);
      setCurrentQuestion(0);
      setShow(false);
    } else {
      setShow(false);
      setCurrentQuestion(currentQuestion + 1);
    }
  };

  return (
    <div>
      <LeaderboardAfterQuiz
        show={showLeaderBoardAfterQuiz}
        setShow={setShowLeaderBoardAfterQuiz}
      />
      <Modal fullscreen={true} show={show} onHide={() => setShow(false)}>
        <Modal.Body style={{ background: "#8D69F9" }}>
          <div
            style={{
              marginTop: "50px",
              display: "flex",
              justifyContent: "center",
              color: "#fff",
            }}
          >
            <h3>Leaderboard</h3>
          </div>
          <div>
            <Table responsive="sm" style={{ color: "#fff", marginTop: "30px" }}>
              <thead>
                <tr>
                  <th>Rank</th>
                  <th>Name</th>
                  <th>Points</th>
                </tr>
              </thead>
              <tbody>
                {leaderBoardData &&
                  leaderBoardData.map((item, key) => (
                    <>
                      <tr
                        key={key}
                        style={{ background: "#D288FF", borderRadius: "15px" }}
                      >
                        <td>{item.Rank}</td>
                        <td>{item.FullName}</td>
                        <td>{item.Points}</td>
                      </tr>
                    </>
                  ))}
              </tbody>
            </Table>
          </div>
          <div>
            <Button
              onClick={() => skip()}
              style={{
                width: "80px",
                height: "40px",
                float: "right",
                background: "#fff",
                color: "#8D69F9",
                borderRadius: "20px",
                marginTop: "20px",
              }}
            >
              Next
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default LeaderBoard;
