import React, { useEffect, useState } from 'react'
import Header from '../SalesInfo/Header'
import SalesTable from '../CategoryWiseSales/SalesTable'
import { asyncWrap } from '../../utils/utils'
import axios from 'axios'
import SchemeTable from './SchemeTable'
import SpecialTargetModal from './SpecialTargetModal'
import ModalItem from './ModalItem'
import moment from 'moment'

const specialTargetColumn = [
  {
    id: 1,
    name: "Target Name",
    dataIndex: "TargetName",
    key: "SpecialTargetID"
  },
  {
    id: 2,
    name: "Target Type",
    dataIndex: "TargetType",
    key: "SpecialTargetID"
  },
  {
    id: 3,
    name: "Total Qty",
    dataIndex: "TotalQuantity",
    key: "SpecialTargetID"
  },
  {
    id: 4,
    name: "Total Value",
    dataIndex: "TotalValue",
    key: "SpecialTargetID"
  }
]

const quantitySchemeColumn = [
  {
    id: 1,
    name: "Scheme Name"
  },
  {
    id: 2,
    name: "Net Quantity"
  },
  {
    id: 3,
    name: "Current Slab"
  },
  {
    id: 4,
    name: "Next Slab"
  },
]

function SchemeInfo() {
  const [specialTarget, setSpecialTarget] = useState([])
  const [specialTargetLoading, setSpecialTargetLoading] = useState(true)
  const[specialTargetModalData, setSpecialTargetModalData] = useState(null)
  const [userAchievementData, setUserAchievementData] = useState(null)
  const[showSpecialTargetModal, setShowSpecialTargetModal] = useState(false)

  const handleSpecialTargetModal = async (data) => {

    setSpecialTargetModalData(data)

    // getting the user achievement
    const [error, result] = await asyncWrap(axios.post("special-target/detail/invoice-total", {
      StartDate: moment(data?.StartDate).format("YYYY-MM-DD"),
      EndDate: moment(data?.EndDate).format("YYYY-MM-DD"),
      SkuType: data?.SkuType
    }))

    if(!result) {
      console.log("Error in getting user achievement")
      console.log(error)
      return;
    }

    setUserAchievementData(result?.data?.data[0])
    setShowSpecialTargetModal(true)
  }

  const getSpecialTarget = async () => {
    setSpecialTarget(true)
    const [error, result] = await asyncWrap(axios.get("special-target"))

    if(!result) {
      console.log("Error in getting special target")
      console.log(error)
      setSpecialTargetLoading(false)
      return;
    }

    setSpecialTarget(result?.data?.data)
    setSpecialTargetLoading(false)
  }

  useEffect(() => {
    getSpecialTarget()
  }, [])

  return (
    <>
      <Header text="Special Target" />
      <div className='mb-2'>
        <SchemeTable 
        column={specialTargetColumn} 
        data={specialTarget}
        loading={specialTargetLoading}
        onClick={handleSpecialTargetModal}
        pageSize={4}
        setLoading={setSpecialTargetLoading}
        />
      </div>
      <Header text="Quantity Scheme" />
      <div className='mb-2' >
        <SalesTable column={quantitySchemeColumn} />
      </div>
      <Header text="Group Scheme" />
      <div className='mb-2' >
        <SalesTable column={quantitySchemeColumn} />
      </div>
      <Header text="Value Scheme" />
      <div className='mb-2' >
        <SalesTable column={quantitySchemeColumn} />
      </div>

      {/* modals */}
      <SpecialTargetModal 
      show={showSpecialTargetModal}
      onHide={() => setShowSpecialTargetModal(false)}
      SkuType={specialTargetModalData?.SkuType}
      showAchievement={true}
      item1={<ModalItem heading={"Target Name"} text={specialTargetModalData?.TargetName} />}
      item2={<ModalItem heading={"Target Type"} text={specialTargetModalData?.TargetType} />}
      item3={<ModalItem heading={"Start Date"} text={moment(specialTargetModalData?.StartDate).format("DD-MM-YYYY")} />}
      item4={<ModalItem heading={"End Date"} text={moment(specialTargetModalData?.EndDate).format("DD-MM-YYYY")} />}
      item5={<ModalItem heading={"Total Value"} text={specialTargetModalData?.TotalValue} />}
      item6={<ModalItem heading={"Total Quantity"} text={specialTargetModalData?.TotalQuantity} />}
      item7={<ModalItem heading={"Sku Type"} text={specialTargetModalData?.SkuType} />}
      item8={<ModalItem heading={"Total Value"} text={userAchievementData?.TotalValue ?? 0} />}
      item9={<ModalItem heading={"Total Quantity"} text={userAchievementData?.TotalQty ?? 0} />}
      />
    </>
  )
}

export default SchemeInfo