import { Card, Image, Modal } from "react-bootstrap";
import fanzone from "/assets/images/PredictionImage/fanzone.svg";
import trophy1 from "/assets/images/PredictionImage/trophy1.svg";
import trophy2 from "/assets/images/PredictionImage/trophy2.svg";
import trophy3 from "/assets/images/PredictionImage/trophy3.svg";
import star from "/assets/images/PredictionImage/starfan.svg";

const FanZonePrediction = ({ show, setShow }) => {
  return (
    <>
      <Modal
        fullscreen={true}
        show={show}
        style={{ backgroundColor: "white !important" }}
        onHide={() => setShow(false)}
      >
        <Modal.Body className="d-flex flex-column mt-3">
          <Image src={fanzone} width="100%" />

          <div
            className="mt-3"
            style={{
              width: "100%",
            }}
          >
            <div
              style={{
                fontSize: "20px",
                color: "#0F519F",
                fontWeight: "600",
                textAlign: "center",
              }}
            >
              FAN <span style={{ color: "#FF800B" }}>ZONE</span>
            </div>
          </div>
          <div
            className="mt-3"
            style={{
              alignItems: "end",
              paddingLeft: "12px",
              paddingRight: "12px",
              width: "100%",
            }}
          >
            <div
              style={{
                fontSize: "16px",
                color: "#0F519F",
                fontWeight: "600",
              }}
            >
              Leaderboard
            </div>
          </div>
          <div className="mt-4">
            <Card
              style={{
                background: "rgba(255, 199, 39, 0.4)",
                color: "black",
                borderRadius: "11px",
                width: "100%",
                boxShadow: "0px 0px 4px 0px rgba(255, 199, 39, 0.66)",
                border: "0px",
              }}
            >
              <Card.Body>
                <div className=" d-flex flex-row justify-content-between align-items-center">
                  <div>
                    <Image
                      style={{
                        background: "white",
                        width: "50px",
                        height: "50px",
                      }}
                      roundedCircle
                      alt=""
                    />
                  </div>
                  <div className="flex-fill" style={{ paddingLeft: "10px" }}>
                    <span style={{ fontSize: "16px", fontWeight: "500" }}>
                      Chennai
                    </span>
                    <br></br>
                    <span style={{ fontSize: "12px", fontWeight: "400" }}>
                      1,22,333 fans
                    </span>
                  </div>
                  <div>
                    <Image src={trophy2} width="" />
                  </div>
                </div>
              </Card.Body>
            </Card>
            <Card
              className="mt-3"
              style={{
                background: "rgba(193, 193, 193, 0.4)",
                color: "black",
                borderRadius: "11px",
                width: "100%",
                boxShadow: "0px 0px 4px 0px rgba(193, 193, 193, 0.66)",
                border: "0px",
              }}
            >
              <Card.Body>
                <div className=" d-flex flex-row justify-content-between align-items-center">
                  <div>
                    <Image
                      style={{
                        background: "white",
                        width: "50px",
                        height: "50px",
                      }}
                      roundedCircle
                      alt=""
                    />
                  </div>
                  <div className="flex-fill" style={{ paddingLeft: "10px" }}>
                    <span style={{ fontSize: "16px", fontWeight: "500" }}>
                      Bangalore
                    </span>
                    <br></br>
                    <span style={{ fontSize: "12px", fontWeight: "400" }}>
                      1,22,333 fans
                    </span>
                  </div>
                  <div>
                    <Image src={trophy3} width="" />
                  </div>
                </div>
              </Card.Body>
            </Card>
            <Card
              className="mt-3"
              style={{
                background: "rgba(199, 160, 59, 0.4)",
                color: "black",
                borderRadius: "11px",
                width: "100%",
                boxShadow: "0px 0px 4px 0px rgba(199, 160, 59, 0.66)",
                border: "0px",
              }}
            >
              <Card.Body>
                <div className=" d-flex flex-row justify-content-between align-items-center">
                  <div>
                    <Image
                      style={{
                        background: "white",
                        width: "50px",
                        height: "50px",
                      }}
                      roundedCircle
                      alt=""
                    />
                  </div>
                  <div className="flex-fill" style={{ paddingLeft: "10px" }}>
                    <span style={{ fontSize: "16px", fontWeight: "500" }}>
                      Chennai
                    </span>
                    <br></br>
                    <span style={{ fontSize: "12px", fontWeight: "400" }}>
                      1,22,333 fans
                    </span>
                  </div>
                  <div>
                    <Image src={trophy1} width="" />
                  </div>
                </div>
              </Card.Body>
            </Card>
            <Card
              className="mt-3"
              style={{
                background: "rgba(255, 255, 255, 0.9)",
                color: "black",
                borderRadius: "11px",
                width: "100%",
                boxShadow: "0px 0px 10px 3px rgba(0, 0, 0, 0.15)",
                border: "0px",
              }}
            >
              <Card.Body>
                <div className=" d-flex flex-row justify-content-between align-items-center">
                  <div>
                    <Image
                      style={{
                        background: "white",
                        width: "50px",
                        height: "50px",
                      }}
                      roundedCircle
                      alt=""
                    />
                  </div>
                  <div className="flex-fill" style={{ paddingLeft: "10px" }}>
                    <span style={{ fontSize: "16px", fontWeight: "500" }}>
                      Chennai
                    </span>
                    <br></br>
                    <span style={{ fontSize: "12px", fontWeight: "400" }}>
                      1,22,333 fans
                    </span>
                  </div>
                  <div style={{ position: "relative" }}>
                    <Image src={star} width="" />
                    <div
                      style={{
                        position: "absolute",
                        top: "30%",
                        left: "35%",
                        fontSize: "16px",
                        fontWeight: "500",
                        color: "white",
                      }}
                    >
                      #4
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default FanZonePrediction;
