const MalyalamIcon = ({ color }) => (
  <div className="flex flex-col items-center justify-center p-[10px]">
    <svg
      width="40"
      height="33"
      viewBox="0 0 40 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.176 0.659997C22.648 0.659997 25.616 1.03333 28.08 1.78C30.5813 2.52667 32.6533 3.55333 34.296 4.86C35.4907 5.83067 36.4613 6.95067 37.208 8.22C37.9547 9.452 38.496 10.908 38.832 12.588C39.2053 14.268 39.392 16.2467 39.392 18.524V32.804H0.136V18.412C0.136 16.2467 0.304 14.3427 0.64 12.7C1.01333 11.02 1.55467 9.54533 2.264 8.276C3.01067 6.96933 3.96267 5.84933 5.12 4.916C6.72533 3.572 8.62933 2.52667 10.832 1.78C13.072 1.03333 15.8533 0.659997 19.176 0.659997ZM15.032 6.596C13.912 6.596 12.904 6.89467 12.008 7.492C11.1493 8.08933 10.4213 8.91066 9.824 9.956C9.33867 10.8147 8.96533 11.86 8.704 13.092C8.48 14.2867 8.368 15.7613 8.368 17.516V28.996L6.352 28.884C8.592 27.0173 10.6267 25.0947 12.456 23.116C14.2853 21.1373 15.7413 19.1213 16.824 17.068C17.9067 15.0147 18.448 12.9427 18.448 10.852C18.448 10.068 18.3547 9.35867 18.168 8.724C17.9813 8.08933 17.6267 7.58533 17.104 7.212C16.6187 6.80133 15.928 6.596 15.032 6.596ZM26.568 12.084C26.568 13.876 26.1947 15.6307 25.448 17.348C24.7387 19.0653 23.7307 20.764 22.424 22.444C21.1547 24.124 19.6613 25.8227 17.944 27.54L16.488 27.204C17.4213 27.092 18.3733 27.0173 19.344 26.98C20.352 26.9427 21.0987 26.924 21.584 26.924H31.16V18.58C31.16 16.1907 30.936 14.2307 30.488 12.7C30.04 11.132 29.312 9.844 28.304 8.836C27.3333 7.79067 26.008 6.91333 24.328 6.204L24.552 5.98C25 6.57733 25.448 7.38 25.896 8.388C26.344 9.35867 26.568 10.5907 26.568 12.084Z"
        fill={color}
      />
    </svg>
  </div>
);

export default MalyalamIcon;
