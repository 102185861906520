import React, { useState } from "react";
import { Card, Image, ProgressBar } from "react-bootstrap";
import geo from "/assets/images/Quiz/geo.svg";
import humanities from "/assets/images/Quiz/humanities.svg";
import { useTranslation } from "react-i18next";

const Activity = () => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState("running");
  return (
    <>
          <div className="m-2 d-flex justify-content-around">
            {selected === "running" ? (
              <p
                className="primary"
                style={{
                  color: "#8D69F9",
                  fontSize: "16px",
                  fontWeight: "500",
                }}
              >
                <u onClick={() => setSelected("running")}>{t('running')}</u>
              </p>
            ) : (
              <p
                className="primary"
                style={{
                  color: "#000000",
                  fontSize: "16px",
                  fontWeight: "500",
                }}
              >
                <u onClick={() => setSelected("running")}>{t('running')}</u>
              </p>
            )}

            {selected === "completed" ? (
              <p
                style={{
                  color: "#8D69F9",
                  fontSize: "16px",
                  fontWeight: "500",
                }}
              >
                <u onClick={() => setSelected("completed")}>{t('completed')}</u>
              </p>
            ) : (
              <p
                style={{
                  color: "#000000",
                  fontSize: "16px",
                  fontWeight: "500",
                }}
              >
                <u onClick={() => setSelected("completed")}>{t('completed')}</u>
              </p>
            )}

            {selected === "unattempted" ? (
              <p
                className="primary"
                style={{
                  color: "#8D69F9",
                  fontSize: "16px",
                  fontWeight: "500",
                }}
              >
                <u onClick={() => setSelected("unattempted")}>{t('unattemted')}</u>
              </p>
            ) : (
              <p
                className="primary"
                style={{
                  color: "#000000",
                  fontSize: "16px",
                  fontWeight: "500",
                }}
              >
              <u onClick={() => setSelected("unattempted")}>{ t('unattemted')}</u>
              </p>
            )}
          </div>
          <div className="d-flex justify-content-around">
            <Card
              style={{
                width: "40vw",
                background: "#FEA965",
                marginTop: "50px",
                borderRadius: "10px",
              }}
            >
              <div
                style={{
                  width: "85px",
                  height: "85px",
                  backgroundColor: "#FFCA9F",
                  marginTop: "-20px",
                  zIndex: "1",
                  marginLeft: "39px",
                  borderRadius: "15px",
                  boxDhadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  boxShadow: "rgba(0, 0, 0, 0.25)",
                }}
              >
                <Image
                  className="img-fluid for-light"
                  src={geo}
                  style={{
                    width: "64px",
                    height: "64px",
                    position: "absolute",
                    top: "-10px",
                    left: "30%",
                  }}
                />
              </div>
              <Card.Text
                style={{
                  marginTop: "12px",
                  textAlign: "center",
                  color: "#fff",
                  fontWeight: "500",
                  fontSize: "16px",
                }}
              >
                Quiz Topic
              </Card.Text>
              <Card.Text
                style={{
                  marginTop: "-7px",
                  textAlign: "center",
                  color: "#fff",
                  fontWeight: "500",
                  fontSize: "12px",
                }}
              >
                15 Questions
              </Card.Text>
              <div
                style={{
                  width: "120px",
                  borderRadius: "8px",
                  justifyContent: "center",
                  position: "relative",
                  left: "20px",
                  bottom: "5px",
                  marginBottom: "7px",
                  marginTop: "10px",
                  background: "rgba(211, 175, 255, 1)",
                }}
              >
                <ProgressBar now={50} label={"7 questions left"} />
              </div>
            </Card>
            <Card
              style={{
                width: "40vw",
                background: "#52E9DB",
                marginTop: "50px",
                borderRadius: "10px",
              }}
            >
              <div
                style={{
                  width: "85px",
                  height: "85px",
                  backgroundColor: "#CCFFFA",
                  marginTop: "-20px",
                  zIndex: "1",
                  marginLeft: "39px",
                  borderRadius: "15px",
                  boxDhadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  boxShadow: "rgba(0, 0, 0, 0.25)",
                }}
              >
                <Image
                  className="img-fluid for-light"
                  src={humanities}
                  style={{
                    width: "64px",
                    height: "64px",
                    position: "absolute",
                    top: "-10px",
                    left: "30%",
                  }}
                />
              </div>
              <Card.Text
                style={{
                  marginTop: "12px",
                  textAlign: "center",
                  color: "#fff",
                  fontWeight: "500",
                  fontSize: "16px",
                }}
              >
                Quiz Topic
              </Card.Text>
              <Card.Text
                style={{
                  marginTop: "-7px",
                  textAlign: "center",
                  color: "#fff",
                  fontWeight: "500",
                  fontSize: "12px",
                }}
              >
                15 Questions
              </Card.Text>
              <div
                style={{
                  width: "120px",
                  borderRadius: "8px",
                  justifyContent: "center",
                  position: "relative",
                  left: "20px",
                  bottom: "5px",
                  marginBottom: "7px",
                  marginTop: "10px",
                  background: "rgba(211, 175, 255, 1)",
                }}
              >
                <ProgressBar now={50} label={"7 questions left"} />
              </div>
            </Card>
          </div>
          <div className="d-flex justify-content-around">
            <Card
              style={{
                width: "40vw",
                background: "#6088FD",
                marginTop: "50px",
                borderRadius: "10px",
              }}
            >
              <div
                style={{
                  width: "85px",
                  height: "85px",
                  backgroundColor: "#8BD5FF",
                  marginTop: "-20px",
                  zIndex: "1",
                  marginLeft: "39px",
                  borderRadius: "15px",
                  boxDhadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  boxShadow: "rgba(0, 0, 0, 0.25)",
                }}
              >
                <Image
                  className="img-fluid for-light"
                  src={humanities}
                  style={{
                    width: "64px",
                    height: "64px",
                    position: "absolute",
                    top: "-10px",
                    left: "30%",
                  }}
                />
              </div>
              <Card.Text
                style={{
                  marginTop: "12px",
                  textAlign: "center",
                  color: "#fff",
                  fontWeight: "500",
                  fontSize: "16px",
                }}
              >
                Quiz Topic
              </Card.Text>
              <Card.Text
                style={{
                  marginTop: "-7px",
                  textAlign: "center",
                  color: "#fff",
                  fontWeight: "500",
                  fontSize: "12px",
                }}
              >
                15 Questions
              </Card.Text>
              <div
                style={{
                  width: "120px",
                  borderRadius: "8px",
                  justifyContent: "center",
                  position: "relative",
                  left: "20px",
                  bottom: "5px",
                  marginBottom: "7px",
                  marginTop: "10px",
                  background: "rgba(211, 175, 255, 1)",
                }}
              >
                <ProgressBar now={50} label={"7 questions left"} />
              </div>
            </Card>
            <Card
              style={{
                width: "40vw",
                background: "#D288FF",
                marginTop: "50px",
                borderRadius: "10px",
              }}
            >
              <div
                style={{
                  width: "85px",
                  height: "85px",
                  backgroundColor: "#E4C4FC",
                  marginTop: "-20px",
                  zIndex: "1",
                  marginLeft: "39px",
                  borderRadius: "15px",
                  boxDhadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  boxShadow: "rgba(0, 0, 0, 0.25)",
                }}
              >
                <Image
                  className="img-fluid for-light"
                  src={geo}
                  style={{
                    width: "64px",
                    height: "64px",
                    position: "absolute",
                    top: "-10px",
                    left: "30%",
                  }}
                />
              </div>
              <Card.Text
                style={{
                  marginTop: "12px",
                  textAlign: "center",
                  color: "#fff",
                  fontWeight: "500",
                  fontSize: "16px",
                }}
              >
                Quiz Topic
              </Card.Text>
              <Card.Text
                style={{
                  marginTop: "-7px",
                  textAlign: "center",
                  color: "#fff",
                  fontWeight: "500",
                  fontSize: "12px",
                }}
              >
                15 Questions
              </Card.Text>
              <div
                style={{
                  width: "120px",
                  borderRadius: "8px",
                  justifyContent: "center",
                  position: "relative",
                  left: "20px",
                  bottom: "5px",
                  marginBottom: "7px",
                  marginTop: "10px",
                  background: "rgba(211, 175, 255, 1)",
                }}
              >
                <ProgressBar now={50} label={"7 questions left"} />
              </div>
            </Card>
          </div>
    </>
  );
};

export default Activity;