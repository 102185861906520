import { Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import { asyncWrap } from "../../utils/utils";
import axios from "axios";
import CloseModalTab from "../../component/Nav/CloseModalTab";
import { useAuth } from "../../context/auth-context";

const Schema = () => {
  // eslint-disable-next-line
  const [show, setShow] = useState(true);
  const { user } = useAuth();
  const [cmsValues, setCmsValues] = useState();

  useEffect(() => {
    getCmsValues();
    // eslint-disable-next-line
  }, []);

  const getCmsValues = async () => {
    const [error, result] = await asyncWrap(axios.get("/cmsValues"));
    if (!result) {
      console.log(error);
      return;
    }
    // setCmsValues(result.data.data?.SchemeList);
    setCmsValues(
      result.data.data?.SchemeList?.filter(
        (item) =>
          item.RoleName === (user?.profile?.roleName || user?.roleName) ||
          item.RoleName === null
      )
    );
  };

  return (
    <>
      <Modal fullscreen={true} show={show}>
        <Modal.Body>
          <div className="d-flex flex-column">
            <CloseModalTab />
            {cmsValues &&
              cmsValues.map((item, index) => (
                <div
                  key={index}
                  onClick={() => {
                    const newWindow = window.open(
                      item?.WebLink || item?.PDFPath
                    );
                    if (newWindow) newWindow.opener = null;
                  }}
                  style={{ borderRadius: "20px" }}
                  className="d-flex flex-column border mt-3"
                >
                  <div
                    style={{
                      height: "150px",
                      background: "#E5ECFD",
                      borderRadius: "15px",
                    }}
                    className="d-flex justify-content-center"
                  >
                    <img
                      alt="schemesimg"
                      style={{
                        maxWidth: "100%",
                        height: "100%",
                      }}
                      src={item.BannerImage}
                    />
                  </div>
                </div>
              ))}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Schema;
