import React from 'react'

function PaymentOutstandingCard() {
  return (
    <div className='rounded mx-3 d-flex mb-3 shadow-sm' style={{
        backgroundColor: "#EFEFF0",
        padding: "10px 15px",
        gap: "0.8em"
    }}>
        {/* left border */}
        <div style={{width: "0.4em",}} className='left-border bg-dark'></div>

        <div className='flex-grow-1'>
        <div className='d-flex justify-content-between'>
            <div>
                <div>
                <p className='m-0 text-start'>Division</p>
                <p className='m-0 text-start'>Mcb & Dbs</p>
                </div>
                
                <div style={{marginTop: "1.59em"}}>
                <p className='m-0 text-start'>Invoice Amount</p>
                <p className='m-0 text-start'>₹ 50,555</p>
                </div>
            </div>
            <div>
                <p className='m-0 text-start'>Invoice No</p>
                <p className='m-0 text-start'>I/VJ/19-20/39323</p>
                <p className='m-0 text-start'>(31-12-2019)</p>
                <p style={{color: "#ff2f30"}} className='m-0 text-start'>Due Days</p>
                <p className='m-0 text-start fs-1'>111</p>
            </div>
        </div>
        <div className='d-flex justify-content-between'>
            <p className='m-0 text-start'>Outstanding <br /> Amount</p>
            <div className='text-start ms-4'>
                <p className='m-0'><span className='text-decoration-line-through'>₹ 34,970</span> <span>(₹ 34,183) <span>(Discount: 2.25%)</span></span></p>
                <p className='m-0'>Savable Amount: <span style={{color: "#129842", fontSize: "16px"}}>₹ 787</span></p>
            </div>
        </div>
        </div>
    </div>
  )
}

export default PaymentOutstandingCard