import React, { useState } from "react";
import { Button, Modal, Row, Col } from "react-bootstrap";
import { GiGraduateCap } from "react-icons/gi";
import StartGame from "./StartGame";
import { GrFormClose } from "react-icons/gr";
import { useTranslation } from "react-i18next";

const MathGame = ({ show, setShow }) => {
  const { t } = useTranslation();
  const [showStartGame, setShowStartGame] = useState(false);
  return (
    <>
      <StartGame show={showStartGame} setShow={setShowStartGame} />
      <Modal
        fullscreen={true}
        show={show}
        onHide={() => setShow(false)}
        style={{ backgroundColor: "#282c34" }}
      >
        <Modal.Body style={{ textAlign: "center", backgroundColor: "#282c34" }}>
          <>
            <Row className="p-10" style={{ width: "100%" }}>
              <Col className="text-end" xs={{ span: 1, offset: 11 }}>
                <GrFormClose
                  onClick={() => setShow(false)}
                  style={{
                    marginRight: "10px",
                    fontSize: "25px",
                    backgroundColor: "white",
                    borderRadius: "50%",
                  }}
                />
              </Col>
            </Row>
            <div className="App-header">
              <div>
                <div className="App-brandname">
                  <GiGraduateCap style={{ fontSize: "100px" }} />
                  <br />
                  <h3>{ t('do_you_know')}</h3>
                  <h1>{ t('math')}</h1>
                </div>
                {/* <Form.Control type='text' /> */}
                <p>{ t('press_to_start_the_game')}</p>
                <Button
                  onClick={() => setShowStartGame(true)}
                  style={{
                    padding: "10px",
                    fontSize: "28px",
                    fontWeight: "600",
                    backgroundColor: "deepskyblue",
                  }}
                >
                  {t('start')}
                </Button>
              </div>
            </div>
          </>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default MathGame;
