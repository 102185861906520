import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Card, Table, Tabs, Pagination } from 'antd'
import { usePagination } from '../hooks';

const ListOrders = (props) => {
    const { status, userId } = props;

    const { data, gotoPage, currentPage, loading, total, fetchData } =
        usePagination(`/order?userId=${userId}&status=${status}`);

    const columns = [
        {
            title: 'Date',
            dataIndex: 'OrderCreatedDate',
            key: 'OrderCreatedDate'
        },
        {
            title: 'OrderID',
            dataIndex: 'OrderID',
            key:'OrderID'
        },
        {
            title: 'Amount',
            dataIndex: 'OrderAmount',
            key: 'OrderAmount'
        }
    ]

    return (
        <>
            <Table
                loading={loading}
                size="small"
                bordered
                columns={columns}
                pagination={false}
                dataSource={data}
            />
            <nav
                className="mt-4"
                style={{ display: 'flex', justifyContent: 'flex-end' }}
            >
                <Pagination
                    showQuickJumper
                    current={currentPage}
                    defaultPageSize={10}
                    total={total}
                    onChange={page => gotoPage(page)}
                    showSizeChanger={false}
                />
            </nav>
        </>
    )
}

const ViewOrders = (props) => {

    const location = useLocation();
    const [activated, setActive] = useState(0);

    return (
        <Card title="View orders">
            <Tabs activeKey = {activated.toString()}
                onTabClick={(activeKey) => {
                    setActive(parseInt(activeKey));
                }}>
                <Tabs.TabPane tab="Pending" key="0">
                    <ListOrders status={0} userId={location.state.userId} />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Approved" key="1">
                    <ListOrders status={1} userId={location.state.userId} />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Rejected" key="2">
                    <ListOrders status={2} userId={location.state.userId} />
                </Tabs.TabPane>
            </Tabs>

        </Card>
    )
}

export default ViewOrders;