import { Card, List, Pagination, Skeleton } from "antd";
import moment from "moment";
import { Modal } from "react-bootstrap";

const Transaction = ({
  show,
  setShow,
  data,
  currentPage,
  total,
  gotoPage,
  loading,
}) => {
  return (
    <Modal fullscreen={true} show={show} onHide={() => setShow(false)}>
      <Modal.Body className="mb-20 p-0 text-left">
        <Skeleton active loading={loading}>
          <Card>
            <List
              dataSource={data}
              renderItem={(item, key) => (
                <List.Item key={key}>
                  <List.Item.Meta
                    title={moment(item.BIDDateTime).format("DD-MM-YYYY, LT")}
                    description={item.MobileNumber}
                  />
                  <div style={{ color: "green" }}>{item.BIDValue}</div>
                </List.Item>
              )}
            />

            <nav
              className="mt-5"
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Pagination
                showQuickJumper
                current={currentPage}
                defaultPageSize={10}
                total={total}
                showSizeChanger={false}
                onChange={(page) => gotoPage(page)}
              />
            </nav>
          </Card>
        </Skeleton>
      </Modal.Body>
    </Modal>
  );
};

export default Transaction;
