import { Modal } from "react-bootstrap";
import { Slider } from "antd";
import { useState } from "react";

const VideoPlayerDetails = ({
  show,
  setShow,
  handlePlayPause,
  playerid,
  time,
}) => {
  const [sliderValue, setSliderValue] = useState(8);
  const moreIncentive = () => {
    setShow(false);
    handlePlayPause();
  };

  const videoModalData = () => {
    console.log(playerid, time);
    if (playerid === 1) {
      if (time === 8) {
        let range = { 0: "0", 8: "8", 12: "12", 17: ">16" };
        return (
          <Modal fullscreen={true} show={show} onHide={() => setShow(false)}>
            {/* <Modal.Header
              style={{
                border: 0,
                display: "flex",
                flexDirection: "row-reverse",
                marginBottom: 20,
              }}
            > */}
              {/* <SecoundaryHeader
                text="Benchmark Premium"
                sideHide="false"
                onBack={() => {
                  setShow(false);
                }}
              /> */}
            {/* </Modal.Header> */}
            <Modal.Body
              className="p-0 text-center"
              style={{
                marginTop: "10vh",
                marginLeft: "20px",
                marginRight: "20px",
              }}
            >
              <h3 style={{ marginBottom: "20px" }}>You are in Level 1</h3>
              <h5 style={{ marginBottom: "30px" }}>
                Benchmark premium = 35,00,000 i.e 112% <br />
              </h5>
              <h4>Rate of Activity</h4>
              <Slider
                marks={range}
                step={null}
                min={0}
                max={17}
                defaultValue={8}
                onChange={(value) => setSliderValue(value)}
              />
              <h4>
                Incentive =
                {sliderValue === 8
                  ? 500
                  : sliderValue === 12
                  ? 1000
                  : sliderValue === 17
                  ? 1500
                  : null}
              </h4>
              <h5 onClick={() => moreIncentive()}>See More Incentives</h5>
            </Modal.Body>
          </Modal>
        );
      } else if (time === 17) {
        let range = { 0: "0", 8: "8", 12: "12", 17: ">16" };
        return (
          <Modal fullscreen={true} show={show} onHide={() => setShow(false)}>
            {/* <Modal.Header
              style={{
                border: 0,
                display: "flex",
                flexDirection: "row-reverse",
                marginBottom: 20,
              }}
            > */}
              {/* <SecoundaryHeader
                text="Benchmark Premium"
                sideHide="false"
                onBack={() => {
                  setShow(false);
                }}
              /> */}
            {/* </Modal.Header> */}
            <Modal.Body
              className="p-0 text-center"
              style={{
                marginTop: "10vh",
                marginLeft: "20px",
                marginRight: "20px",
              }}
            >
              <h3 style={{ marginBottom: "20px" }}>You are in Level 2</h3>
              <h5 style={{ marginBottom: "30px" }}>
                Benchmark premium = 65,00,000 i.e 208% <br />
              </h5>
              <h4>Rate of Activity</h4>
              <Slider
                marks={range}
                step={null}
                min={0}
                max={17}
                defaultValue={8}
                onChange={(value) => setSliderValue(value)}
              />
              <h4>
                Incentive =
                {sliderValue === 8
                  ? 750
                  : sliderValue === 12
                  ? 2500
                  : sliderValue === 17
                  ? 2250
                  : null}
              </h4>
              <h5 onClick={() => moreIncentive()}>See More Incentives</h5>
            </Modal.Body>
          </Modal>
        );
      } else if (time === 26) {
        let range = { 0: "0", 8: "8", 12: "12", 17: ">16" };
        return (
          <Modal fullscreen={true} show={show} onHide={() => setShow(false)}>
            {/* <Modal.Header
              style={{
                border: 0,
                display: "flex",
                flexDirection: "row-reverse",
                marginBottom: 20,
              }}
            > */}
              {/* <SecoundaryHeader
                text="Benchmark Premium"
                sideHide="false"
                onBack={() => {
                  setShow(false);
                }}
              /> */}
            {/* </Modal.Header> */}
            <Modal.Body
              className="p-0 text-center"
              style={{
                marginTop: "10vh",
                marginLeft: "20px",
                marginRight: "20px",
              }}
            >
              <h3 style={{ marginBottom: "20px" }}>You are in Level 3</h3>
              <h5 style={{ marginBottom: "30px" }}>
                Benchmark premium = 95,00,000 i.e 305% <br />
              </h5>
              <h4>Rate of Activity</h4>
              <Slider
                marks={range}
                step={null}
                min={0}
                max={17}
                defaultValue={8}
                onChange={(value) => setSliderValue(value)}
              />
              <h4>
                Incentive =
                {sliderValue === 8
                  ? 1000
                  : sliderValue === 12
                  ? 2000
                  : sliderValue === 17
                  ? 3000
                  : null}
              </h4>
              <h5 onClick={() => moreIncentive()}>See More Incentives</h5>
            </Modal.Body>
          </Modal>
        );
      }
    } else if (playerid === 3) {
      if (time === 3) {
        return (
          <Modal fullscreen={true} show={show} onHide={() => setShow(false)}>
            {/* <Modal.Header
              style={{
                border: 0,
                display: "flex",
                flexDirection: "row-reverse",
                marginBottom: 20,
              }}
            > */}
              {/* <SecoundaryHeader
                text="Benchmark Premium"
                sideHide="false"
                onBack={() => {
                  setShow(false);
                }}
              /> */}
            {/* </Modal.Header> */}
            <Modal.Body
              className="p-0 text-center"
              style={{
                marginTop: "10vh",
                marginLeft: "20px",
                marginRight: "20px",
              }}
            >
              <h3 style={{ marginBottom: "20px" }}>
                You are in Level 1 of your individual rated Budget.
              </h3>
              <h3 style={{ marginBottom: "20px" }}>
                You are at 88% and explore higher incentives by continuing the
                game.
              </h3>
              <h3 style={{ marginBottom: "20px" }}>Your Incentive = 9,000</h3>
              <h3 style={{ marginBottom: "20px" }}>
                If your reach level 2 ie 95% of your target
              </h3>
              <h3 style={{ marginBottom: "20px" }}>Your incentive = 10,000</h3>
              <h5 onClick={() => moreIncentive()}>See More Incentives</h5>
            </Modal.Body>
          </Modal>
        );
      } else if (time === 6) {
        return (
          <Modal fullscreen={true} show={show} onHide={() => setShow(false)}>
            {/* <Modal.Header
              style={{
                border: 0,
                display: "flex",
                flexDirection: "row-reverse",
                marginBottom: 20,
              }}
            > */}
              {/* <SecoundaryHeader
                text="Benchmark Premium"
                sideHide="false"
                onBack={() => {
                  setShow(false);
                }}
              /> */}
            {/* </Modal.Header> */}
            <Modal.Body
              className="p-0 text-center"
              style={{
                marginTop: "10vh",
                marginLeft: "20px",
                marginRight: "20px",
              }}
            >
              <h3 style={{ marginBottom: "20px" }}>
                You are in Level 2 of your individual rated Budget.
              </h3>
              <h3 style={{ marginBottom: "20px" }}>
                You are at 95% and explore higher incentives by continuing the
                game.
              </h3>
              <h3 style={{ marginBottom: "20px" }}>Your Incentive = 10,000</h3>
              <h3 style={{ marginBottom: "20px" }}>
                If your reach level 3 ie 105% of your target
              </h3>
              <h3 style={{ marginBottom: "20px" }}>Your incentive = 11,000</h3>
              <h5 onClick={() => moreIncentive()}>See More Incentives</h5>
            </Modal.Body>
          </Modal>
        );
      } else if (time === 9) {
        return (
          <Modal fullscreen={true} show={show} onHide={() => setShow(false)}>
            {/* <Modal.Header
              style={{
                border: 0,
                display: "flex",
                flexDirection: "row-reverse",
                marginBottom: 20,
              }}
            > */}
              {/* <SecoundaryHeader
                text="Benchmark Premium"
                sideHide="false"
                onBack={() => {
                  setShow(false);
                }}
              /> */}
            {/* </Modal.Header> */}
            <Modal.Body
              className="p-0 text-center"
              style={{
                marginTop: "10vh",
                marginLeft: "20px",
                marginRight: "20px",
              }}
            >
              <h3 style={{ marginBottom: "20px" }}>
                You are in Level 3 of your individual rated Budget.
              </h3>
              <h3 style={{ marginBottom: "20px" }}>
                You are at 105% and explore higher incentives by continuing the
                game.
              </h3>
              <h3 style={{ marginBottom: "20px" }}>Your Incentive = 11,000</h3>
              <h3 style={{ marginBottom: "20px" }}>
                If your reach level 4 ie 115% of your target
              </h3>
              <h3 style={{ marginBottom: "20px" }}>Your incentive = 12,000</h3>
              <h5 onClick={() => moreIncentive()}>See More Incentives</h5>
            </Modal.Body>
          </Modal>
        );
      } else if (time === 12) {
        return (
          <Modal fullscreen={true} show={show} onHide={() => setShow(false)}>
            {/* <Modal.Header
              style={{
                border: 0,
                display: "flex",
                flexDirection: "row-reverse",
                marginBottom: 20,
              }}
            > */}
              {/* <SecoundaryHeader
                text="Benchmark Premium"
                sideHide="false"
                onBack={() => {
                  setShow(false);
                }}
              /> */}
            {/* </Modal.Header> */}
            <Modal.Body
              className="p-0 text-center"
              style={{
                marginTop: "10vh",
                marginLeft: "20px",
                marginRight: "20px",
              }}
            >
              <h3 style={{ marginBottom: "20px" }}>
                You are in Level 4 of your individual rated Budget.
              </h3>
              <h3 style={{ marginBottom: "20px" }}>
                You are at 115% and explore higher incentives by continuing the
                game.
              </h3>
              <h3 style={{ marginBottom: "20px" }}>Your Incentive = 12,000</h3>
              <h3 style={{ marginBottom: "20px" }}>
                If your reach level 5 ie 125% of your target
              </h3>
              <h3 style={{ marginBottom: "20px" }}>Your incentive = 13,000</h3>
              <h5 onClick={() => moreIncentive()}>See More Incentives</h5>
            </Modal.Body>
          </Modal>
        );
      } else if (time === 15) {
        return (
          <Modal fullscreen={true} show={show} onHide={() => setShow(false)}>
            {/* <Modal.Header
              style={{
                border: 0,
                display: "flex",
                flexDirection: "row-reverse",
                marginBottom: 20,
              }}
            > */}
              {/* <SecoundaryHeader
                text="Benchmark Premium"
                sideHide="false"
                onBack={() => {
                  setShow(false);
                }}
              /> */}
            {/* </Modal.Header> */}
            <Modal.Body
              className="p-0 text-center"
              style={{
                marginTop: "10vh",
                marginLeft: "20px",
                marginRight: "20px",
              }}
            >
              <h3 style={{ marginBottom: "20px" }}>
                You have crossed Level 1 and qualify for 7500 Incentive.
              </h3>
              <h5 onClick={() => moreIncentive()}>See More Incentives</h5>
            </Modal.Body>
          </Modal>
        );
      }
    } else if (playerid === 4) {
      if (time === 9) {
        return (
          <Modal fullscreen={true} show={show} onHide={() => setShow(false)}>
            {/* <Modal.Header
              style={{
                border: 0,
                display: "flex",
                flexDirection: "row-reverse",
                marginBottom: 20,
              }}
            > */}
              {/* <SecoundaryHeader
                text="Benchmark Premium"
                sideHide="false"
                onBack={() => {
                  setShow(false);
                }}
              /> */}
            {/* </Modal.Header> */}
            <Modal.Body
              className="p-0 text-center"
              style={{
                marginTop: "10vh",
                marginLeft: "20px",
                marginRight: "20px",
              }}
            >
              <h3 style={{ marginBottom: "20px" }}>
                You have crossed Level 1 and qualify for 7500 Incentive.
              </h3>
              <h5 onClick={() => moreIncentive()}>See More Incentives</h5>
            </Modal.Body>
          </Modal>
        );
      } else if (time === 16) {
        return (
          <Modal fullscreen={true} show={show} onHide={() => setShow(false)}>
            {/* <Modal.Header
              style={{
                border: 0,
                display: "flex",
                flexDirection: "row-reverse",
                marginBottom: 20,
              }}
            > */}
              {/* <SecoundaryHeader
                text="Benchmark Premium"
                sideHide="false"
                onBack={() => {
                  setShow(false);
                }}
              /> */}
            {/* </Modal.Header> */}
            <Modal.Body
              className="p-0 text-center"
              style={{
                marginTop: "10vh",
                marginLeft: "20px",
                marginRight: "20px",
              }}
            >
              <h3 style={{ marginBottom: "20px" }}>
                You have crossed Level 2 and qualify for 10000 Incentive.
              </h3>

              <h5 onClick={() => moreIncentive()}>See More Incentives</h5>
            </Modal.Body>
          </Modal>
        );
      } else if (time === 21) {
        return (
          <Modal fullscreen={true} show={show} onHide={() => setShow(false)}>
            {/* <Modal.Header
              style={{
                border: 0,
                display: "flex",
                flexDirection: "row-reverse",
                marginBottom: 20,
              }}
            > */}
              {/* <SecoundaryHeader
                text="Benchmark Premium"
                sideHide="false"
                onBack={() => {
                  setShow(false);
                }}
              /> */}
            {/* </Modal.Header> */}
            <Modal.Body
              className="p-0 text-center"
              style={{
                marginTop: "10vh",
                marginLeft: "20px",
                marginRight: "20px",
              }}
            >
              <h3 style={{ marginBottom: "20px" }}>
                You have earned incentive of 15000 INR
              </h3>
              <h3 style={{ marginBottom: "20px" }}>
                Congrats on maintaining high persistency!
              </h3>

              <h5 onClick={() => moreIncentive()}>See More Incentives</h5>
            </Modal.Body>
          </Modal>
        );
      }
    }
  };

  return <>{videoModalData()}</>;
};

export default VideoPlayerDetails;
