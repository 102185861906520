const BanglaIcon = ({ color }) => (
  <div className="flex flex-col items-center justify-center p-[10px]">
    <svg
      width="51"
      height="36"
      viewBox="0 0 51 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M50.052 0.667998V6.828H31.796V10.188L31.908 12.484V35.5H24.516C23.1347 33.484 21.4547 31.7107 19.476 30.18C17.5347 28.6493 15.3693 27.38 12.98 26.372C10.5907 25.3267 7.996 24.58 5.196 24.132L2.396 17.524C5.83067 15.62 9.58267 13.7907 13.652 12.036C17.7587 10.244 21.7907 8.82533 25.748 7.78L24.684 9.404V6.828H0.94V0.667998H50.052ZM24.516 13.156L25.692 14.388C24.0493 14.8733 22.4627 15.396 20.932 15.956C19.4387 16.516 17.9453 17.132 16.452 17.804C14.9587 18.4387 13.4093 19.148 11.804 19.932C13.3347 20.2307 14.9213 20.7533 16.564 21.5C18.244 22.2093 19.868 23.1427 21.436 24.3C23.0413 25.4573 24.4787 26.8013 25.748 28.332L24.74 28.948C24.7027 28.276 24.6467 27.5293 24.572 26.708C24.5347 25.8867 24.516 25.0093 24.516 24.076V13.156ZM47.7 21.052C47.7 22.284 47.4387 23.46 46.916 24.58C46.4307 25.7 45.572 26.6147 44.34 27.324C43.108 28.0333 41.3907 28.388 39.188 28.388C38.404 28.388 37.676 28.3693 37.004 28.332C36.3693 28.2573 35.772 28.164 35.212 28.052L35.436 21.892C35.6227 21.9293 35.8467 21.9667 36.108 22.004C36.4067 22.004 36.78 22.004 37.228 22.004C38.572 22.004 39.4493 21.78 39.86 21.332C40.2707 20.8467 40.476 20.268 40.476 19.596C40.476 18.3267 39.6733 17.2067 38.068 16.236C36.4627 15.2653 34.1107 14.5187 31.012 13.996V7.892C34.0733 8.30267 36.8547 9.08667 39.356 10.244C41.8947 11.364 43.9107 12.8387 45.404 14.668C46.9347 16.46 47.7 18.588 47.7 21.052Z"
        fill={color}
      />
    </svg>
  </div>
);

export default BanglaIcon;
