const JapaneseIcon = ({ color }) => (
  <div className="flex flex-col items-center justify-center p-[10px]">
    <svg
      width="47"
      height="50"
      viewBox="0 0 47 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.776 0.475999L15.496 0.251998C15.552 1.26 15.44 2.548 15.328 3.668C14.712 9.212 13.704 17.444 13.704 24.78C13.704 33.012 15.328 41.3 17.008 46.34L23.448 44.436C21.6 38.78 19.976 32.508 19.976 23.66C19.976 16.716 20.928 8.652 21.992 3.444C22.216 2.548 22.44 1.54 22.776 0.475999ZM12.752 6.636C9.336 6.636 4.464 6.188 2.448 5.908L2.616 12.348C5.248 12.46 9.616 12.74 12.584 12.74C21.04 12.74 32.8 11.9 40.472 10.5L40.416 4.06C31.848 6.132 23.392 6.636 12.752 6.636ZM36.944 14.868L30.056 13.188C30 14.42 29.608 16.828 29.216 18.004C26.976 25.732 23.168 30.716 19.08 34.468C15.664 37.66 11.912 39.508 9.28 39.508C7.432 39.508 6.704 38.556 6.704 36.708C6.704 32.956 10.68 27.58 16.28 24.948C19.024 23.716 23.056 22.26 28.152 22.26C35.712 22.26 39.8 26.292 39.8 31.052C39.8 36.596 35.376 42.028 24.568 43.204L28.32 49.14C42.04 47.012 46.744 39.34 46.744 31.388C46.744 22.652 39.016 16.604 28.544 16.604C24.344 16.604 19.752 17.444 16.056 18.788C7.152 22.036 0.0960003 30.268 0.0960003 38.164C0.0960003 43.876 3.512 46.34 7.6 46.34C12.472 46.34 17.344 43.876 20.984 41.188C26.304 37.268 32.072 29.708 35.264 19.628C35.656 18.452 36.496 15.876 36.944 14.868Z"
        fill={color}
      />
    </svg>
  </div>
);

export default JapaneseIcon;
