import React from 'react'

function ModalItem({heading, text}) {
  return (
    <div style={{
        lineHeight: 1,
    }}>
        <h6>{heading}</h6>
        <p>{text}</p>
    </div>
  )
}

export default ModalItem