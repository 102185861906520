import React, { useState } from 'react';
import { Card, Row } from 'react-bootstrap';
import { BiFileFind } from "react-icons/bi";
import SearchBar from '../component/SearchBar';
import PaymentAdjustmentModal from '../component/PaymentAdjustmentModal/PaymentAdjustmentModal';
import CustomDatePicker from '../component/CustomDatePicker';

function PaymentReceipt() {
  const [showModal, setShowModal] = useState(false);
  const [adjustmentData, setAdjustmentData] = useState([]);
  // Example data array
  const paymentData = [
    {
      date: '05-03-2020',
      division: 'Wire & Cable',
      status: 'Approved',
      paymentType: 'Nach',
      amount: '₹ 99,750',
      viewAdjustment: <BiFileFind color='red' fontSize={16} />,
      adjustmentDetails: [
        {
          invDetails: 'INV/VJ/17-18/S0167 (03-03-2018)',
          adjustedAmount: '₹ 26,210'
        },
        {
          invDetails: 'INV/VJ/17-18/S0322 (05-03-2018)',
          adjustedAmount: '₹ 62,137'
        },
      ],
    },
  ];

  const handleViewAdjustmentClick = (data) => {
    setAdjustmentData(data); // Set the data for the modal
    setShowModal(true);
  };

  const handleSearch = (searchValue) => {
    // Search logic here
    console.log('Search value:', searchValue);
  };

  return (
    <div className="container mt-3">
      {/* Search Bar */}
      <SearchBar placeholder="Division, Amount, Status..." onSearch={handleSearch} />

      {/* Date Pickers */}
      <div style={{ width: '90vw' }}>
        <Row className="mb-3">
          <CustomDatePicker label="From Date" controlId="fromDate" />
          <CustomDatePicker label="To Date" controlId="toDate" />
        </Row>
      </div>

      {/* Payment Receipt Entries */}
      {paymentData.map((payment, index) => (
        <Card key={index} className="mb-3" style={{ width: '90vw', display: 'flex', flexDirection: 'row', backgroundColor: '#E9EAEB' }}>
          <div style={{ backgroundColor: 'black', width: '5px', margin: '8px 2px 18px 12px' }}></div> {/* Vertical bar */}
          <div style={{ display: 'flex', width: '100%', padding: '0.8rem' }}>
            <div style={{ flex: 1, textAlign: 'left' }}>
              <div><strong>Date:</strong><br />{payment.date}</div>
              <div><strong>Status:</strong><br />{payment.status}</div>
              <div><strong>Payment Type:</strong><br />{payment.paymentType}</div>
            </div>
            <div style={{ flex: 1, textAlign: 'left' }}>
              <div><strong>Division:</strong><br />{payment.division}</div>
              <div onClick={() => handleViewAdjustmentClick(payment.adjustmentDetails)}><strong>View Adjustments:</strong><br />{payment.viewAdjustment}</div>
              <div><strong>Amount:</strong><br />{payment.amount}</div>
            </div>
          </div>
        </Card>
      ))}
      <PaymentAdjustmentModal
        show={showModal}
        onHide={() => setShowModal(false)}
        adjustmentData={adjustmentData}
      />
    </div>
  );
}

export default PaymentReceipt;
