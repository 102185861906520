// import SecoundaryHeader from "component/Nav/SecoundaryHeader";
import React from "react";
import { Modal, Table } from "react-bootstrap";
import { Card, CardBody, CardHeader, CardText } from "reactstrap";

const LuckyDrawDetail = ({ show, setShow, data }) => {
  return (
    <Modal fullscreen={true} show={show} onHide={() => setShow(false)}>
      <Modal.Body className="mt-20 p-0 text-center">
        <Card style={{height:"90vh"}}>
          <CardHeader>CArd NAme</CardHeader>
          <CardBody>
            <div className="d-flex justify-content-around flex-wrap">
              <CardText>Start Date: 21/04/2022</CardText>
              <CardText>End Date: 22/05/2033</CardText>
            </div>
            <CardText>Your Number: 344567</CardText>
            <CardText>Detail winner</CardText>
            <Table bordered hover>
              <thead>
                <tr>
                  <th>No.</th>
                  <th>Winner Name</th>
                  <th>Number</th>
                  <th>Points</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Mark</td>
                  <td>345678</td>
                  <td>100</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Mark</td>
                  <td>345678</td>
                  <td>100</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>Mark</td>
                  <td>345678</td>
                  <td>100</td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>Mark</td>
                  <td>345678</td>
                  <td>100</td>
                </tr>
                <tr>
                  <td>5</td>
                  <td>Mark</td>
                  <td>345678</td>
                  <td>100</td>
                </tr>
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </Modal.Body>
    </Modal>
  );
};

export default LuckyDrawDetail;
