import React, { useState } from 'react';
import { Card, Button } from 'react-bootstrap';
import PaymentBtn from '../component/PaymentInfo/PaymentBtn';

const ComboItem = ({ item }) => {
  const [quantity, setQuantity] = useState(0);

  const originalPrice = parseInt(item.price.replace(/[^0-9]/g, '')); 
  const totalPrice = `₹ ${originalPrice * quantity}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  const incrementQuantity = () => {
    setQuantity(prev => prev + 1);
  };

  const decrementQuantity = () => {
    setQuantity(prev => (prev > 0 ? prev - 1 : 0));
  };
  return (
    <>
      <hr style={{ margin: 0, opacity: 1 }} />
      <div className={`d-flex justify-content-between align-items-center`} style={{ padding: '10px' }}>
        <div>
          <div className="font-weight-bold">{item.name}</div>
          <div>{item.price}</div>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {quantity > 0 && (
            <div style={{ marginRight: '2.4em', whiteSpace: 'nowrap' }}>{totalPrice}</div>
          )}
          {quantity === 0 ? (
            <PaymentBtn text="Add" style={{ width: '20vw', padding: '3px 1px', backgroundColor: '#fff', color: 'red', border: '0.05em groove #e7e1eb', borderRadius: 0 }} onClick={incrementQuantity} />
          ) : (
            <div style={{ display: 'flex', alignItems: 'center', border: '1px solid #e7e1eb', padding: '0 5px', maxHeight: '2.5em', width: '20vw' }}>
              <PaymentBtn
                text="-"
                style={{ backgroundColor: 'transparent', color: 'black', border: 'none', fontSize: '2em', fontWeight: '800', padding: '0 3px' }}
                onClick={decrementQuantity}
              />
              <div style={{ color: 'red' }}>{String(quantity).padStart(2, '0')}</div>
              <PaymentBtn
                text="+"
                style={{ backgroundColor: 'transparent', color: 'red', border: 'none', fontSize: '2em', fontWeight: '800', padding: '0 3px' }}
                onClick={incrementQuantity}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const ModularSection = ({ title, items }) => {
  return (
    <div>
      <Card style={{ border: 'none', backgroundColor: 'white' }}>
        <Card.Header className="d-flex justify-content-between align-items-center" style={{ border: 'none', backgroundColor: 'white', maxHeight: '40px', padding: '1em 2em 0.5em 2em' }}>
          <Card.Title style={{ fontSize: '1.1em', marginTop: '1em' }}>{title}</Card.Title>
          <PaymentBtn text="Compare" style={{ width: '20vw', padding: '3px 1px', fontSize: '0.8em', backgroundColor: '#e9eaeb', color: '#000', border: 0 }} />
        </Card.Header>
        <Card.Body className='py-2'>
          {items.map((item, index) => (
            <ComboItem key={index} item={item} />
          ))}
        </Card.Body>
      </Card>
    </div>
  );
};

const ComboScheme = () => {
  const modularData = [
    {
      title: 'Air Modular',
      items: [
        { name: 'Combo 019', price: '₹ 2,11,100' },
        { name: 'Combo 019', price: '₹ 2,11,100' },
        { name: 'Combo 019', price: '₹ 2,11,100' },
        { name: 'Combo 019', price: '₹ 2,11,100' },
        { name: 'Combo 019', price: '₹ 2,11,100' },
        // ... other combos
      ],
    },
    {
      title: 'Curve Modular',
      items: [
        { name: 'Combo 019', price: '₹ 2,11,100' },
        { name: 'Combo 019', price: '₹ 2,11,100' },
        { name: 'Combo 019', price: '₹ 2,11,100' },
        { name: 'Combo 019', price: '₹ 2,11,100' },
        { name: 'Combo 019', price: '₹ 2,11,100' },
        { name: 'Combo 019', price: '₹ 2,11,100' },
        { name: 'Combo 019', price: '₹ 2,11,100' },
        // ... other combos
      ],
    },
    // ... modular data
  ];
  return (
    <div style={{ fontSize: '0.9em' }}>
      {modularData.map((modular, index, array) => (
        <div key={index} className='m-0'> {/* Added a div with a key for each section */}
          <hr style={{ height: '4px', backgroundColor: '#000000', margin: '0', opacity: 1 }} />
          <ModularSection title={modular.title} items={modular.items} />

        </div>
      ))}
      <div style={{ position: 'fixed', bottom: '4em', left: '40vw' }} >
        <PaymentBtn text="Category" style={{ width: '20vw', padding: '3px 1px', fontSize: '0.9em', backgroundColor: '#e9eaeb', color: '#000', border: 0 }} />
      </div>
      <div className="d-flex gap-1 mx-1 my-1" style={{ position: 'fixed', bottom: '0' }}>
        <PaymentBtn text="View Info" style={{ border: 'none', borderRadius: '4px', width: '49vw' }} />
        <PaymentBtn text="View Order" style={{ border: 'none', borderRadius: '4px', width: '47vw' }} />
      </div>
    </div>
  );
};

export default ComboScheme;