import React, { useState } from 'react'
import FinancialYearPicker from '../component/FinancialYearPicker/FinancialYearPicker'
import Chart from "react-apexcharts"
import SalesTable from '../component/CategoryWiseSales/SalesTable'

const reportColumn = [
  {
    id: 0,
    name: "Division"
  },
  {
    id: 1,
    name: "Amount"
  },
  {
    id: 2,
    name: "Percentage"
  },
]

function DivisionWiseSales() {
    let d = new Date()
    let endYear = d.getFullYear()
    let currentMonth = d.getMonth()
  
    // in case of financial - if currentMonth is after march - endYear will be endYear+1
  
    if(currentMonth > 2) {
      endYear = endYear + 1
    }
  
    const [selectedYear, setSelectedYear] = useState(`${endYear-1}-${endYear}`)
    const [startYear, setStartYear] = useState(2018)
    const [selectedMonth, setSelectedMonth] = useState("default")
    const [selectedQuarter, setSelectedQuarter] = useState("default")

    const [tableHeading, setTableHeading] = useState(`Yearly Report - ${endYear-1}-${endYear}`)
    const [chartData, setChartData] = useState({
          
      series: [{
        data: [21, 22, 10, 27.2, 16]
      }],
      options: {
        chart: {
          height: 350,
          toolbar: {show: false},
          type: 'bar',
          events: {
            click: function(chart, w, e) {
              // console.log(chart, w, e)
            }
          }
        },
        // colors: colors,
        plotOptions: {
          bar: {
            columnWidth: '45%',
            distributed: true,
          }
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          show: false
        },
        xaxis: {
          categories: [
            'John',
            'Smith',
            'Jake',
            'Amber',
            'Peter'
          ],
          labels: {
            style: {
              // colors: colors,
              fontSize: '12px'
            }
          }
        }
      },
    })

    const [reportData, setReportData] = useState([
      {
        division: "Wd",
        amount: "₹ 3,41,100",
        percentage: "57.07 %"
      },
      {
        division: "Wd",
        amount: "₹ 3,41,100",
        percentage: "57.07 %"
      },
      {
        division: "Wd",
        amount: "₹ 3,41,100",
        percentage: "57.07 %"
      },
    ])

    const handleHeading = (type, val) => {
      if(type === "year") {
        setTableHeading(`Yearly Report ${val}`)
      }else if(type === "month") {
        setTableHeading(`Monthly Report - ${val.toUpperCase()}`)
      }else if(type === "quarter") {
        setTableHeading(`Quarterly Report - ${val.toUpperCase()}`)
      }
    }

  return (
    <div>
    <FinancialYearPicker 
      selectedYear={selectedYear} 
      setSelectedYear={setSelectedYear}
      startYear={startYear}
      endYear={endYear}
      setSelectedMonth={setSelectedMonth}
      setSelectedQuarter={setSelectedQuarter}
      selectedMonth={selectedMonth}
      selectedQuarter={selectedQuarter}
      handleHeading={handleHeading}
      />

      <Chart options={chartData.options} series={chartData.series} type="bar" height={350} />

      <div style={{marginTop: "-3em"}} className='d-flex justify-content-between mx-2'>
        <p>Total Sales: 72.05</p>
        <p>* values are in Lacs</p>
      </div>
      <p style={{fontSize: "17px"}}>
        {tableHeading}
      </p>
      <SalesTable
      column={reportColumn}
      data={reportData}
      />
    </div>
  )
}

export default DivisionWiseSales