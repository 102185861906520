import React, { useState, useEffect } from 'react'
import styled from 'styled-components';
import { useHistory } from 'react-router'

import { Button } from 'reactstrap';
import { Image, Nav } from 'react-bootstrap';

const Intro = () => {
    const history = useHistory();
    const [active, setActive] = useState(0);

    useEffect(() => {
        setTimeout(() => {
            setActive(1);
        }, 2000);
    }, [])

    const next = () => {
        if (active <= 3) {
            setActive(active + 1);
        } else {
            history.push('/login');
        }
    }

    return (
        <div>
            <Nav className="navbar fixed-top navbar-light" style={{ background: "#fffef2", textAlign: "right", height: 55 }} >
                <div className="text-right" style={{
                    position: "absolute",
                    right: 25
                }}  >
                    <span onClick={() => history.push("/login")}>Skip</span>
                </div>
            </Nav >            <div style={{ background: "#fffef2", height: "100%" }} className="text-center">
                {active === 0 &&
                    <div style={{ marginTop: "35vh" }}>
                        <Image src={require("/assets/images/Name.png").default} alt="" />
                    </div>
                }
                {
                    active === 1 &&
                    <div>
                        <Image src={require("/assets/images/intro/in1.png").default} alt="" style={{
                            position: "relative",
                            "top": 80
                        }} />
                        <div style={{ background: "white" }}>
                            <div className="text-center" style={{ paddingTop: 90 }}>
                                <hr className={active === 1 ? "active block-design" : "unactive block-design"} />
                                <hr className={active === 2 ? "active block-design" : "unactive block-design"} />
                                <hr className={active === 3 ? "active block-design" : "unactive block-design"} />
                                <hr className={active === 4 ? "active block-design" : "unactive block-design"} />
                            </div>

                            <p className="text-center text-bold fs-25">
                                Complete Goals & Mission
                            </p>

                            <p className="text-center text-grey p-3 mb-3 mt-3">
                                Lorem ipsum dolor sit amet,
                                consetetur sadipscing elitr, sed.
                            </p>
                        </div>
                    </div>
                }

                {
                    active === 2 &&
                    <div>
                        <Image src={require("/assets/images/intro/in2.png").default} alt="" style={{
                            position: "relative",
                            "top": 80
                        }} />
                        <div style={{ background: "white" }}>
                            <div className="text-center" style={{ paddingTop: 90 }}>
                                <hr className={active === 1 ? "active block-design" : "unactive block-design"} />
                                <hr className={active === 2 ? "active block-design" : "unactive block-design"} />
                                <hr className={active === 3 ? "active block-design" : "unactive block-design"} />
                                <hr className={active === 4 ? "active block-design" : "unactive block-design"} />
                            </div>

                            <p className="text-center text-bold fs-25">
                                Complete Goals & Mission
                            </p>

                            <p className="text-center text-grey p-3 mb-3 mt-3">
                                Lorem ipsum dolor sit amet,
                                consetetur sadipscing elitr, sed.
                            </p>
                        </div>
                    </div>
                }

                {
                    active === 3 &&
                    <div>
                        <Image src={require("/assets/images/intro/in3.png").default} alt="" style={{
                            position: "relative",
                            "top": 80
                        }} />
                        <div style={{ background: "white" }}>
                            <div className="text-center" style={{ paddingTop: 90 }}>
                                <hr className="unactive block-design" />
                                <hr className="unactive block-design" />
                                <hr className="active block-design" />
                                <hr className="unactive block-design" />
                            </div>

                            <p className="text-center text-bold fs-25">
                                Complete Goals & Mission
                            </p>

                            <p className="text-center text-grey p-3 mb-3 mt-3">
                                Lorem ipsum dolor sit amet,
                                consetetur sadipscing elitr, sed.
                            </p>
                        </div>
                    </div>
                }

                {
                    active === 4 &&
                    <div>
                        <Image src={require("/assets/images/intro/in4.png").default} alt="" style={{
                            position: "relative",
                            "top": 80
                        }} />
                        <div style={{ background: "white" }}>
                            <div className="text-center" style={{ paddingTop: 90 }}>
                                <hr className="unactive block-design" />
                                <hr className="unactive block-design" />
                                <hr className="unactive block-design" />
                                <hr className="unactive block-design" />
                            </div>

                            <p className="text-center text-bold fs-25">
                                Complete Goals & Mission
                            </p>

                            <p className="text-center text-grey p-3 mb-3 mt-3">
                                Lorem ipsum dolor sit amet,
                                consetetur sadipscing elitr, sed.
                            </p>
                        </div>
                    </div>
                }
                <div style={{ background: 'white' }}>
                    {active > 0 && <div className="mt-3">
                        <StyledSubmitBtn onClick={next}>{active === 4 ? "Get Started" : "Next"}</StyledSubmitBtn>
                    </div>
                    }
                </div>


            </div>
        </div>
    )
}

const StyledSubmitBtn = styled(Button)`
    background-color: #DC7832;
    border: 0;
    border-radius: 500px;
    padding: 8px 35px 8px 35px;
`

export default Intro;
