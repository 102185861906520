import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import { asyncWrap } from "../../utils/utils";

function HitNumber({ show, setShow, data }) {
  const [top, settop] = useState("50%");
  const [left, setleft] = useState("50%");
  const [count, setcount] = useState(0);
  const [started, setStarted] = useState(false);
  useEffect(() => {
    if (started) {
      const interval = setInterval(() => {
        random();
      }, 1000);

      return () => clearInterval(interval);
    }
  });

  function random() {
    settop(`${Math.floor(Math.random() * (85 - 1 + 1)) + 1}%`);
    setleft(`${Math.floor(Math.random() * (85 - 1 + 1)) + 1}%`);
  }

  const postTapGameData = async () => {
    const config = {
      method: "post",
      url: "users-tapping",
      data: {
        tappingId: data.TappingID,
        tappingPoints: count,
        gratificationRuleId: 0,
        pointTypeId: data.PointTypeID,
      },
    };

    const [error, result] = await asyncWrap(axios(config));

    if (!result) {
      console.log(error);
      return;
    }

    Swal.fire(`You have gained ${count} points`);
  };

  return (
    <>
      <Modal
        fullscreen={true}
        show={show}
        style={{ backgroundColor: "white !important" }}
        onHide={() => setShow(false)}
      >
        {/* <Modal.Header
          style={{
            border: 0,
            display: "flex",
            flexDirection: "row-reverse",
          }}
        > */}
          {/* <SecoundaryHeader
            text="Tap Game"
            sideHide="false"
            onBack={() => {
              console.log("about us back clicked");
              setShow(false);
            }}
          /> */}
        {/* </Modal.Header> */}
        <Modal.Body
          style={{
            marginTop: "-15px",
            background: "#fffef2",
            boxShadow: "#00000005",
            borderRadius: " 20px 20px 0px 0px",
            textAlign: "center",
          }}
        >
          <div className="App">
            <h3 className="mt-5">Point Earn : {count}</h3>
            <div
              style={{
                position: "relative",
                width: "90vw",
                height: "74vh",
                border: "1px solid black",
                display: "inline-flex",
                justifyContent: "center",
              }}
            >
              <img
                onClick={() => {
                  if (started) {
                    setcount(count + 1);
                    random();
                  }
                }}
                src={data.TappingImage}
                alt="HTML5"
                style={{
                  width: 50,
                  height: 50,
                  position: "absolute",
                  top: top,
                  left: left,
                  right: "10px",
                  bottom: "10px",
                }}
              />
            </div>
            <div style={{ marginTop: "10px" }}>
              {!started ? (
                <Button
                  onClick={() => {
                    setStarted(true);
                  }}
                >
                  START
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    setStarted(false);
                    postTapGameData();
                  }}
                >
                  STOP
                </Button>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default HitNumber;
