import axios from "axios";
import { useEffect, useState } from "react";
import { Card, Col, Modal, Row } from "react-bootstrap";
import { asyncWrap } from "../../utils/utils";
import Puzzle from "./Puzzle";

const SelectPuzzle = ({ show, setShow }) => {
  const [puzzle, setPuzzle] = useState([]);
  const [showPuzzle, setShowPuzzle] = useState(false);
  const [data, setData] = useState([]);

  const color = ["#FEA965", "#CC4056", "#A13964", "#4E866A"];
  const getPuzzleData = async (title) => {
    const [error, result] = await asyncWrap(axios.get(`/users-puzzles`));
    if (!result) {
      console.log(error);
      return;
    }

    let data = result.data.data.map((item, i) =>
      i % 3 === 0
        ? { ...item, Color: color[i % 4] }
        : { ...item, Color: color[i % 4] }
    );
    setPuzzle(data);
  };

  useEffect(() => {
    if (show || showPuzzle) {
      getPuzzleData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, showPuzzle]);

  return (
    <>
      <Puzzle show={showPuzzle} setShow={setShowPuzzle} data={data} />
      <Modal fullscreen={true} show={show} onHide={() => setShow(false)}>
        <Modal.Body className="mb-20 p-0 text-center">
          <Row className="p-4">
            {puzzle &&
              puzzle.map((item) => (
                <Col
                  xs={22}
                  style={{
                    marginTop: "40px",
                  }}
                >
                  <Card
                    onClick={() => {
                      setData(item);
                      setShowPuzzle(true);
                    }}
                    style={{
                      backgroundColor: item.Color,
                      height: "160px",
                      boxShadow: "0px 4px 20px rgba(254, 169, 101, 0.5)",
                      borderRadius: "15px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        width: "90px",
                        height: "90px",
                        backgroundColor: "#FFCA9F",
                        borderRadius: "15px",
                        boxDhadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Card.Img
                        src={item.PuzzleImage}
                        style={{ width: "64px", height: "64px" }}
                      />
                    </div>
                    <Card.Text
                      style={{
                        marginTop: "5px",
                        textAlign: "center",
                        color: "#fff",
                        fontWeight: "500",
                        fontSize: "16px",
                      }}
                    >
                      {item.PuzzleName}
                    </Card.Text>
                  </Card>
                </Col>
              ))}
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SelectPuzzle;
