import { Button, Modal } from "react-bootstrap";
import { useHistory } from "react-router";

const MissingKyc = ({ show, setShow }) => {
  const history = useHistory();

  return (
    <Modal fullscreen={true} show={show}>
      <Modal.Body className="mt-4 d-flex flex-column align-items-center">
        <div
          style={{ color: "#AE1631", fontSize: "28px" }}
          className="fw-bold my-2"
        >
          OOPS! Missing KYC
        </div>
        <div
          style={{ color: "#434343", fontSize: "16px" }}
          className="mx-3 text-center my-3"
        >
          You haven’t completed your KYC yet. It’s mendetory to complete your
          KYC to start redeem your rewards.
        </div>
        <Button
          style={{
            width: "max-content",
            background: "#3A69DD",
            color: "white",
            border: "none",
            padding: "0.6rem 2rem",
            marginTop: "0.5rem",
            fontWeight: "600",
            borderRadius: "20px",
            alignSelf: "center",
          }}
          onClick={() => {
            history.push("/kycverify");
          }}
        >
          Complete Your Kyc
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default MissingKyc;
