import { useState } from "react";
import { Modal, Image, Row, Col, Button } from "react-bootstrap";
import person from "/assets/images/person.svg";
import ReactAnime from "react-animejs";
import { AiFillRightCircle, AiFillLeftCircle } from "react-icons/ai";
import JourneyDetails from "./JourneyDetails";
import medal from "/assets/images/medal.png";

import Background from "/assets/images/Journey/Background02.svg";
import { useHistory } from "react-router";

const { Anime } = ReactAnime;
const Journey = ({ show, setShow, data, userLevel }) => {
  const [showJourneyDetails, setShowJourneyDetails] = useState(false);
  const [showAchivement, setShowAchievement] = useState(false);

  const [points, setPoints] = useState();
  const history = useHistory();

  const showPoints = (level) => {
    setPoints(null);
    let points = data.filter((item) => item.PointType === level)[0]?.Points;
    setPoints(points);
    setShowJourneyDetails(true);
  };

  return (
    <>
      {data && (
        <JourneyDetails
          show={showJourneyDetails}
          setShow={setShowJourneyDetails}
          data={data}
          points={points}
        />
      )}
      <Modal fullscreen={true} show={show} onHide={() => setShow(false)}>
        <Modal.Body className="pb-20 p-0 text-left">
          <div
            style={{
              backgroundImage: `url(${Background})`,
              backgroundSize: "cover",
              backgroundPosition: "top",
              overflowX: "hidden",
              height: "2500px",
            }}
          >
            <Row className="pt10">
              <Col className="text-left" xs={2}>
                <img
                  onClick={() => setShow(false)}
                  src={require("/assets/images/topbar/Arrows@2x.png").default}
                  alt="act"
                  style={{ width: "30px", marginRight: " 10px" }}
                />
              </Col>
              <Col
                className="text-center nav-title pt-1"
                style={{ color: "white" }}
              >
                {data?.title}
                <p style={{ color: "white", fontSize: 10, margin: 0 }}>
                  Updated on
                </p>
                <p style={{ color: "#DC7832", fontSize: 10, margin: 0 }}>
                  {data?.days} Days Left
                </p>
              </Col>
              <Col className="text-right" xs={2}>
                <span></span>
              </Col>
            </Row>

            <h1 className="mt-5 text-center">Track Your Progress</h1>

            {showAchivement ? (
              <div
                className="float-right"
                style={{
                  position: "absolute",
                  right: 0,
                  top: "50px",
                  width: "200px",
                }}
              >
                <p className="text-center mt-5">
                  <div
                    className="rounded"
                    style={{
                      background:
                        "linear-gradient(145deg,#000000, #484848 100%)",
                    }}
                  >
                    <AiFillRightCircle
                      onClick={() => setShowAchievement(false)}
                      style={{
                        color: "#DC7832",
                        position: "relative",
                        display: "flex",
                        right: "0.5rem",
                        top: "5rem",
                        fontSize: "23px",
                      }}
                    />
                    <p style={{ color: "#FFFEF2", fontSize: 13 }}>
                      Your Achievements
                    </p>
                    <Row className="text-white">
                      <Col>
                        <p className="text-white m-0">
                          3/4
                          {/* {data?.badgeEarned}/{data?.badgeTotal} */}
                        </p>
                      </Col>
                      <Col>
                        <p className="text-white m-0">
                          60/100
                          {/* {data?.scoreEarned}/{data?.socreTotal} */}
                        </p>
                      </Col>
                    </Row>
                    <Row className="text-white">
                      <Col>
                        <p style={{ fontSize: "12px" }}>Bagde</p>
                      </Col>
                      <Col>
                        <p style={{ fontSize: "12px" }}>Score</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p className="text-white m-0">
                          1/3
                          {/* {data?.badgeEarned}/{data?.totalCertificate} */}
                        </p>
                      </Col>
                      <Col>
                        {/* <p className="text-white m-0">{gameRanking}</p> */}
                      </Col>
                    </Row>
                    <Row className="text-white">
                      <Col>
                        <p style={{ fontSize: "12px" }}>Certificates</p>
                      </Col>
                      <Col onClick={() => history.push("/rank")}>
                        <p style={{ fontSize: "12px" }}>Rank</p>
                      </Col>
                    </Row>
                  </div>
                </p>
              </div>
            ) : (
              <div
                style={{
                  top: "100px",
                  width: "80px",
                  height: "60px",
                  position: "absolute",
                  right: "0",
                  background: "#FAD6A5 0% 0% no-repeat padding-box",
                  borderRadius: "10px",
                  border: "1px solid #FFFFFF",
                }}
              >
                <div className="rounded">
                  <AiFillLeftCircle
                    onClick={() => {
                      setShowAchievement(true);
                    }}
                    style={{
                      color: "#DC7832",
                      justifyContent: "center",
                      position: "absolute",
                      top: "20px",
                      alignItems: "center",
                      fontSize: "20px",
                    }}
                  />
                  <Image
                    src={medal}
                    style={{
                      width: "50px",
                      marginLeft: "20px",
                      marginTop: "5px",
                    }}
                  />
                </div>
              </div>
            )}
            <Anime
              // setMeta={setMeta}
              animeConfig={{
                duration: 3000,
                easing: "linear",
              }}
              initial={[
                {
                  targets: "#img",
                  translateX:
                    userLevel === "1"
                      ? 150
                      : userLevel === "2"
                      ? 100
                      : userLevel === "3"
                      ? 110
                      : userLevel === "4"
                      ? 156
                      : userLevel === "5"
                      ? 160
                      : userLevel === "6"
                      ? 160
                      : userLevel === "7"
                      ? 160
                      : "",
                  translateY:
                    userLevel === "1"
                      ? 150
                      : userLevel === "2"
                      ? 500
                      : userLevel === "3"
                      ? 820
                      : userLevel === "4"
                      ? 1175
                      : userLevel === "5"
                      ? 1520
                      : userLevel === "6"
                      ? 1840
                      : userLevel === "7"
                      ? 2160
                      : "",
                  easing: "linear",
                  duration:
                    userLevel === "1"
                      ? 1000
                      : userLevel === "2"
                      ? 2000
                      : userLevel === "3"
                      ? 3000
                      : userLevel === "4"
                      ? 4000
                      : userLevel === "5"
                      ? 5000
                      : userLevel === "6"
                      ? 6000
                      : userLevel === "7"
                      ? 6000
                      : "",
                },
              ]}
            >
              <Image src={person} alt="" id="img" style={{ width: 80 }} />
            </Anime>
            <p className="text-center mt-5" style={{ height: "120px" }}>
              {showAchivement && (
                <Button
                  className="fs-10"
                  style={{
                    background: "linear-gradient(150deg,#C7ADA2, #AD8676 100%)",
                    marginRight: 90,
                    position: "relative",
                  }}
                  onClick={() => {
                    showPoints("L1 Points");
                  }}
                >
                  {userLevel >= "Level 1" ? (
                    <Image
                      src={require("/assets/images/check.png").default}
                      alt=""
                      style={{
                        fontSize: 20,
                        color: "green",
                        position: "absolute",
                        top: -7,
                        left: 26,
                      }}
                    />
                  ) : (
                    <Image
                      style={{ width: 8 }}
                      src={require("/assets/images/lock.png").default}
                      alt=""
                    />
                  )}
                  Bronze
                </Button>
              )}
            </p>
            <p className="text-center mt-5" style={{ height: "50px" }}>
              {showAchivement && (
                <Button
                  className="fs-10"
                  style={{
                    background: "linear-gradient(150deg,#F1F1F1, #9E9999 100%)",
                    marginLeft: 20,
                    position: "relative",
                  }}
                  onClick={() => {
                    showPoints("L2 Points");
                  }}
                >
                  <span
                    style={{
                      padding: "0px 5px",
                      background: "#484848",
                      border: "1px solid white",
                      position: "absolute",
                      top: -10,
                      left: 22,
                      borderRadius: 30,
                    }}
                  >
                    {userLevel >= "Level 2" ? (
                      <Image
                        src={require("/assets/images/check.png").default}
                        alt=""
                        style={{
                          // fontSize: 20,
                          color: "green",
                          position: "absolute",
                          // top: -7,
                          left: 1,
                        }}
                      />
                    ) : (
                      <Image
                        style={{ width: 8 }}
                        src={require("/assets/images/lock.png").default}
                        alt=""
                      />
                    )}
                  </span>
                  Silver
                </Button>
              )}
            </p>
            <p className="text-center mt-5 pt-5" style={{ height: "150px" }}>
              {showAchivement && (
                <Button
                  className="fs-10"
                  style={{
                    background: "linear-gradient(150deg,#FED526, #F9A02C 100%)",
                    marginRight: 40,
                    position: "relative",
                  }}
                  onClick={() => {
                    showPoints("L3 Points");
                  }}
                >
                  <span
                    style={{
                      padding: "0px 5px",
                      background: "#E29925",
                      border: "1px solid white",
                      position: "absolute",
                      top: -10,
                      left: 17,
                      borderRadius: 30,
                    }}
                  >
                    {userLevel >= "Level 3" ? (
                      <Image
                        src={require("/assets/images/check.png").default}
                        alt=""
                        style={{
                          // fontSize: 20,
                          color: "green",
                          position: "absolute",
                          // top: -7,
                          left: 1,
                        }}
                      />
                    ) : (
                      <Image
                        style={{ width: 8 }}
                        src={require("/assets/images/lock.png").default}
                        alt=""
                      />
                    )}
                  </span>
                  Gold
                </Button>
              )}
            </p>
            <p className="text-center mt-5 pt-5" style={{ height: "140px" }}>
              {showAchivement && (
                <Button
                  className="fs-10"
                  style={{
                    background: "linear-gradient(150deg,#26B4FE, #2CF2F9 100%)",
                    marginRight: 70,
                    marginTop: 95,
                    position: "relative",
                  }}
                  onClick={() => {
                    showPoints("L4 Points");
                  }}
                >
                  <span
                    style={{
                      padding: "0px 5px",
                      background: "#1999C8",
                      border: "1px solid white",
                      position: "absolute",
                      top: -10,
                      left: 30,
                      borderRadius: 30,
                    }}
                  >
                    {userLevel >= "Level 4" ? (
                      <Image
                        src={require("/assets/images/check.png").default}
                        alt=""
                        style={{
                          // fontSize: 20,
                          color: "green",
                          position: "absolute",
                          // top: -7,
                          left: 1,
                        }}
                      />
                    ) : (
                      <Image
                        style={{ width: 8 }}
                        src={require("/assets/images/lock.png").default}
                        alt=""
                      />
                    )}
                  </span>
                  Diamond
                </Button>
              )}
            </p>
            <p
              className="text-center mt-5 pt-5 mb-0 pb-5"
              style={{ height: "100px" }}
            >
              {showAchivement && (
                <Button
                  className="fs-10"
                  style={{
                    background: "linear-gradient(150deg,#26FE7C, #07C157 100%)",
                    marginLeft: 80,
                    marginTop: 125,
                    position: "relative",
                  }}
                  onClick={() => {
                    showPoints("L5 Points");
                  }}
                >
                  <span
                    style={{
                      padding: "0px 5px",
                      background: "#049E45",
                      border: "1px solid white",
                      position: "absolute",
                      top: -10,
                      left: 32,
                      borderRadius: 30,
                    }}
                  >
                    {userLevel >= "Level 5" ? (
                      <Image
                        src={require("/assets/images/check.png").default}
                        alt=""
                        style={{
                          // fontSize: 20,
                          color: "green",
                          position: "absolute",
                          // top: -7,
                          left: 1,
                        }}
                      />
                    ) : (
                      <Image
                        style={{ width: 8 }}
                        src={require("/assets/images/lock.png").default}
                        alt=""
                      />
                    )}
                  </span>
                  Signature
                </Button>
              )}
            </p>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Journey;
