const PunjabiIcon = ({ color }) => (
  <div className="flex flex-col items-center justify-center p-[10px]">
    <svg
      width="44"
      height="53"
      viewBox="0 0 44 53"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.68 45.892C26.6587 45.892 28.1893 45.4813 29.272 44.66C30.3547 43.8387 30.896 42.6813 30.896 41.188C30.896 38.1267 28.1893 36.596 22.776 36.596H14.152C14.936 39.5453 16.2053 41.8413 17.96 43.484C19.752 45.0893 21.992 45.892 24.68 45.892ZM30.112 14.98C29.552 9.79067 27.3493 7.196 23.504 7.196C19.696 7.196 16.896 9.79067 15.104 14.98H30.112ZM23.672 30.268C25.912 30.268 27.592 29.8573 28.712 29.036C29.8693 28.2147 30.448 27.1133 30.448 25.732V21.252H13.704C13.368 23.6413 13.2 26.1613 13.2 28.812V30.268H23.672ZM0.88 21.252V14.98H7.376C8.79467 10.4253 10.904 6.86 13.704 4.284C16.504 1.67066 19.7893 0.363997 23.56 0.363997C26.472 0.363997 29.0293 1.11066 31.232 2.604C35.3013 5.404 37.5413 9.52933 37.952 14.98H43.048V21.252H38.4V26.572C38.4 28.252 37.8027 29.6893 36.608 30.884C35.4133 32.0787 33.92 32.9 32.128 33.348C34.1067 33.9827 35.712 35.0653 36.944 36.596C38.2133 38.0893 38.848 39.8627 38.848 41.916C38.848 45.052 37.5973 47.6093 35.096 49.588C32.632 51.5293 29.3467 52.5 25.24 52.5C19.192 52.5 14.3573 50.316 10.736 45.948C7.152 41.58 5.36 35.8867 5.36 28.868C5.36 26.068 5.54667 23.5293 5.92 21.252H0.88Z"
        fill={color}
      />
    </svg>
  </div>
);

export default PunjabiIcon;
