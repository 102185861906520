import React from 'react'


function FinancialYearPicker({
  startYear, 
  endYear, 
  selectedYear, 
  setSelectedYear,
  setSelectedMonth,
  setSelectedQuarter,
  selectedMonth,
  selectedQuarter,
  handleHeading,
  isStarReward
}) {

  // isStarReward is used to hide the month and quarter dropdown in the start rewards page

    const arr = new Array(endYear - startYear).fill(null)

    const handleYear = (e) => {
      setSelectedYear(e.target.value)
      setSelectedQuarter("default")
      setSelectedMonth("default")
      console.log(e.target.value)
      handleHeading("year", e.target.value)
    }

    const handleMonth = (e) => {
      setSelectedMonth(e.target.value)
      setSelectedQuarter("default")
      handleHeading("month", e.target.value)
    }

    const handleQuarter = (e) => {
      setSelectedQuarter(e.target.value)
      setSelectedMonth("default")
      handleHeading("quarter", e.target.value)
    }

    const showYearly = () => {
      setSelectedMonth("default")
      setSelectedQuarter("default")
      handleHeading("year", selectedYear)
    }

  return (
    <>
    <div className='d-flex justify-content-evenly my-2'>
  
    <p style={{fontSize: "17px"}} className='text-center my-2'>Financial Year</p>
    {/* Financial Year select start */}
    <select style={{
      border: "0",
      outline: "1px solid",
      padding: "0 6px 0px 10px",
      borderRight: "8px solid transparent"
      }} 
      className='rounded fw-bold' 
      value={selectedYear} 
      onChange={handleYear} >
        {arr.length > 0 && arr.map((_, i) => (
            <option key={i} value={`${startYear + i}-${startYear + i + 1}`}>{`${startYear + i}-${startYear + i + 1}`}</option>
        ))
        }
    </select>
    {/* financial year select end */}
  </div>

  {!isStarReward && <div className='d-flex mx-3 gap-2 justify-content-center my-3'>
      <button onClick={showYearly} style={{fontSize: "15px"}} className='btn rounded border border-dark fw-bold'>YEARLY</button>
      {/* Montly select start */}
    <select style={{
      border: "0",
      outline: "1px solid",
      padding: "0 6px 0px 10px",
      borderRight: "8px solid transparent"
      }} 
      className='rounded fw-bold'
      value={selectedMonth}
      onChange={handleMonth}
      >
      <option value="default" disabled >MONTHLY</option>
      <option value="january">January</option>
      <option value="february">February</option>
      <option value="march">March</option>
      <option value="april">April</option>
      <option value="may">May</option>
      <option value="june">June</option>
      <option value="july">July</option>
      <option value="august">August</option>
      <option value="september">September</option>
      <option value="october">October</option>
      <option value="november">November</option>
      <option value="december">December</option>
    </select>
    {/* Monthly select end */}

      {/* Quarterly select start */}
    <select style={{
      border: "0",
      outline: "1px solid",
      padding: "0 6px 0px 10px",
      borderRight: "8px solid transparent"
      }} 
      className='rounded fw-bold'
      value={selectedQuarter}
      onChange={handleQuarter}
      >
      <option value="default" disabled >QUARTERLY</option>
      <option value="q1">Q1</option>
      <option value="q2">Q2</option>
      <option value="q3">Q3</option>
      <option value="q4">Q4</option>
    </select>
    {/* Quarterly select end */}

    </div>}
  </>
  )
}

export default FinancialYearPicker