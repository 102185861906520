import React from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

function SpecialTargetModal({show, onHide,SkuType,showAchievement, ...items}) {
    
  return (
    <div className='mx-auto'>
    <Modal isOpen={show} toggle={onHide} className="mx-5ß d-flex"
              style={{marginTop: "12vh"}} >
      <ModalHeader className='text-center' toggle={onHide}>Target Detail</ModalHeader>
      <ModalBody>
        <div className='m-3 d-flex justify-content-between'>
            <div>
                {items?.item1}
            </div>
            <div>
                {items?.item2}
            </div>
        </div>
        <div className='m-3 d-flex justify-content-between'>
            <div>
                {items?.item3}
            </div>
            <div>
                {items?.item4}
            </div>
        </div>
        <div className='m-3 d-flex justify-content-between'>
            <div>
                {items?.item5}
            </div>
            <div>
                {items?.item6}
            </div>
        </div>
        <div className='m-3 d-flex justify-content-between'>
            <div>
                {items?.item7}
            </div>
        </div>
        {showAchievement && (
            <div>
                <hr />
                <h5>Your Achievement</h5>
            </div>
        )}
        <div className='m-3 d-flex justify-content-between'>
            <div>
                {items?.item8}
            </div>
            <div>
                {items?.item9}
            </div>
        </div>
      </ModalBody>
    </Modal>
    </div>
  )
}

export default SpecialTargetModal