import { useEffect, useState } from "react";
import styled from "styled-components";
import { useHistory, useParams } from "react-router";
import { asyncWrap } from "../utils/utils";
import { Button } from "reactstrap";
import axios from "axios";
import Swal from "sweetalert2";
import { Slider } from "antd";

import { useTranslation } from "react-i18next";
const Survey = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const params = useParams();
  const [data, setData] = useState([]);
  const [surveyAsnwer, setSurveyAnswer] = useState([]);

  const getSurveyQuestions = async () => {
    //eslint-disable-next-line
    const [error, result] = await asyncWrap(
      axios.get(`survey/questions?surveyId=${params.id}`)
    );
    if (!result) {
      Swal.fire("Something Went Wrong!");
      return;
    }
    setData(result.data.data);
    let question = result.data.data.map((item) => ({
      SurveyDetailID: item.SurveyDetailID,
      value: 3,
    }));
    setSurveyAnswer(question);
  };

  const onChange = (id, value) => {
    let answer = surveyAsnwer.map((item) =>
      item.SurveyDetailID === id ? { ...item, value: value } : item
    );
    setSurveyAnswer(answer);
  };

  const submitSurvey = async () => {
    let data = {
      SurvayAnswer: surveyAsnwer,
    };
    //eslint-disable-next-line
    const [error, result] = await asyncWrap(axios.post("survey/answer", data));
    if (error) {
      console.log(error);
    } else {
      Swal.fire("Thank you for your valuable Feedback!");
      history.push("/home");
    }
  };

  useEffect(() => {
    getSurveyQuestions();
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <div className="mainPage">
        {data.length ? (
          data.map((item, idx) => (
            <StyledCardDiv key={idx}>
              <h6>{item.Questions}</h6>
              <p style={{ fontSize: "14px" }} className="text-muted">
                {item.ShortDescription}
              </p>
              <Slider
                tooltipVisible
                defaultValue={3}
                min={0}
                max={10}
                onChange={(value) => onChange(item.SurveyDetailID, value)}
              />
            </StyledCardDiv>
          ))
        ) : (
          <p>{t("no_questions_yet")}</p>
        )}

        {data.length ? (
          <div className="mt-3">
            <Button
              onClick={submitSurvey}
              style={{
                backgroundColor: "#dc7832",
                border: 0,
                borderRadius: "20px",
                padding: "8px 35px 8px 35px",
              }}
            >
              {t("submit")}
            </Button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

const StyledCardDiv = styled.div`
  width: 380px;
  height: 183px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 30px #00000014;
  border-radius: 4px;
  margin-top: 20px;
  margin-right: 20px;
  text-align: left;
  padding: 20px;
`;

export default Survey;
