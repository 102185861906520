import Resizer from 'react-image-file-resizer';

export const imageResize = async (file) => {
    const dataURIToBlob = (dataURI) => {
        const splitDataURI = dataURI.split(',');
        const byteString =
            splitDataURI[0].indexOf('base64') >= 0
                ? atob(splitDataURI[1])
                : decodeURI(splitDataURI[1]);
        const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

        const ia = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

        return new Blob([ia], { type: mimeString });
    };

    return new Promise((resolve, reject) => {
        Resizer.imageFileResizer(
            file,
            800, // Set the desired width
            800, // Set the desired height
            'JPEG', // Format of the compressed image (JPEG, PNG, or WEBP)
            90, // Quality of the compressed image (0 to 100)
            0, // Rotation in degrees (0 to 360)
            (uri) => {
                const blob = dataURIToBlob(uri);
                resolve(blob);
            },
            'base64', // Output type (base64 or file)
            1000, // Maximum size limit in bytes (1000KB)
            200 // Minimum size limit in bytes (200KB)
        );
    });
};
