import React, { useEffect, useState } from "react";
import { Badge, Modal } from "react-bootstrap";
import { asyncWrap } from "../../utils/utils";
import axios from "axios";
import PollGame from "./Poll";

const SelectPolls = ({ show, setShow }) => {
  const [pollsData, setPollsData] = useState([]);

  const getPollsData = async () => {
    setPollsData([]);
    const [error, result] = await asyncWrap(axios.get("/poll"));
    if (!result) {
      console.log(error);
    }

    setPollsData(result.data.data);
  };

  useEffect(() => {
    if (show) {
      getPollsData();
    }
    // eslint-disable-next-line
  }, [show]);

  const optfilter = [
    { title: "Active", key: 1 },
    { title: "Complete", key: 2 },
  ];
  const [active, setActive] = useState(1);

  return (
    <>
      <Modal
        fullscreen={true}
        show={show}
        style={{ backgroundColor: "white !important" }}
        onHide={() => setShow(false)}
      >
        <Modal.Body
          style={{
            marginTop: "-15px",
            background: "#fffef2",
            boxShadow: "#00000005",
            borderRadius: " 20px 20px 0px 0px",
            textAlign: "center",
          }}
        >
          <div className="mainPage">
            <div className="horizontal-scroll mt-20">
              {optfilter.map(({ title, key }) => (
                <Badge
                  key={key}
                  onClick={() => setActive(key)}
                  pill
                  className={key === active ? "Active" : "Complete"}
                  style={{
                    background: key === active ? "#fad6a5" : "white",
                  }}
                >
                  {title}
                </Badge>
              ))}
            </div>

            {active === 1 && (
              <PollGame data={pollsData} getPollsData={() => getPollsData()} />
            )}

            {active === 2 && (
              <PollGame data={pollsData} getPollsData={() => getPollsData()} />
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SelectPolls;
