// import SecoundaryHeader from "component/Nav/SecoundaryHeader";
import React, { useState } from "react";
import {
  Card,
  Carousel,
  Col,
  Image,
  Modal,
  ProgressBar,
  Row,
} from "react-bootstrap";
import cricket from "/assets/images/PredictionImage/cricket.svg";
import scorecard from "/assets/images/PredictionImage/scorecard.svg";
import coupons from "/assets/images/PredictionImage/coupons.svg";
import share from "/assets/images/PredictionImage/share.svg";
import funzone from "/assets/images/PredictionImage/funzone.svg";
import csk from "/assets/images/PredictionImage/csk.jpg";
import rcb from "/assets/images/PredictionImage/rcb.jpg";
import tick from "/assets/images/games/history/tick.svg";
import winner from "/assets/images/PredictionImage/winner.svg";
import how_to_play from "/assets/images/PredictionImage/how_to_play.svg";
import ScoreboardPrediction from "./ScoreboardPrediction";
import FanZonePrediction from "./FanZonePrediction";

const PredictionGame = ({ show, setShow }) => {
  const [showScoreboard, setShowScoreboard] = useState(false);
  const [showFanZone, setShowFanZone] = useState(false);
  return (
    <>
      <ScoreboardPrediction show={showScoreboard} setShow={setShowScoreboard} />
      <FanZonePrediction show={showFanZone} setShow={setShowFanZone} />
      <Modal
        fullscreen={true}
        show={show}
        style={{ backgroundColor: "white !important" }}
        onHide={() => setShow(false)}
      >
        {/* <Modal.Header
          style={{
            border: 0,
            display: "flex",
            flexDirection: "row-reverse",
          }}
        > */}
          {/* <SecoundaryHeader
            text="Prediction Game"
            sideHide="false"
            onBack={() => {
              console.log("about us back clicked");
              setShow(false);
            }}
          /> */}
        {/* </Modal.Header> */}
        <Modal.Body className="d-flex flex-column align-items-center mt-4">
          <Card
            style={{
              background: "#308753",
              color: "white",
              borderRadius: "12px",
              width: "100%",
            }}
          >
            <Card.Body>
              <div className="d-flex flex-row ">
                <div
                  style={{
                    fontSize: "20px",
                    fontWeight: "400",
                    borderRight: "3px dotted white",
                  }}
                  className="flex-fill"
                >
                  <span style={{ fontWeight: "600" }}>Predict</span> And win{" "}
                  <br></br> coupons{" "}
                  <span style={{ fontWeight: "600" }}>everyday!</span>
                </div>
                <div
                  className="align-self-center"
                  style={{ marginLeft: "1rem" }}
                >
                  <Image width="50px" src={cricket} />
                </div>
              </div>
              <div
                style={{ fontSize: "12px", marginTop: "10px" }}
                className="text-center"
              >
                3 lucky winners get season-end{" "}
                <span style={{ fontWeight: "800" }}>Megaprice!</span>
              </div>
            </Card.Body>
          </Card>
          <div className="mt-3" style={{ color: "#FF800B", fontSize: "16px" }}>
            How to play?
          </div>
          <Row
            className="mt-3"
            style={{
              width: "100%",
              marginLeft: "0px",
              marginRight: "0px",
              paddingLeft: "0px",
              paddingRight: "0px",
            }}
          >
            <Col
              onClick={() => {
                setShowScoreboard(true);
              }}
              style={{
                textAlign: "-webkit-center",
                paddingLeft: "0px",
                paddingRight: "0px",
              }}
            >
              <div style={{}}>
                <div
                  className="text-center p-3"
                  style={{
                    background: "#ff800b",
                    borderRadius: "7px",
                    width: "70px",
                    height: "55px",
                  }}
                >
                  <Image src={scorecard} />
                </div>
                <div className="mt-2 text-center " style={{ fontSize: "12px" }}>
                  ScoreCard
                </div>
              </div>
            </Col>
            <Col
              onClick={() => {
                setShowFanZone(true);
              }}
              style={{
                textAlign: "-webkit-center",
                paddingLeft: "0px",
                paddingRight: "0px",
              }}
            >
              <div style={{}}>
                <div
                  className="text-center p-3"
                  style={{
                    background: "#0F519F",
                    borderRadius: "7px",
                    width: "70px",
                    height: "55px",
                  }}
                >
                  <Image src={funzone} />
                </div>
                <div className="mt-2 text-center " style={{ fontSize: "12px" }}>
                  Fun Zone
                </div>
              </div>
            </Col>
            <Col
              style={{
                textAlign: "-webkit-center",
                paddingLeft: "0px",
                paddingRight: "0px",
              }}
            >
              <div style={{}}>
                <div
                  className="text-center p-3"
                  style={{
                    background: "#ff800b",
                    borderRadius: "7px",
                    width: "70px",
                    height: "55px",
                  }}
                >
                  <Image src={coupons} />
                </div>
                <div className="mt-2 text-center " style={{ fontSize: "12px" }}>
                  Coupons
                </div>
              </div>
            </Col>
            <Col
              style={{
                textAlign: "-webkit-center",
                paddingLeft: "0px",
                paddingRight: "0px",
              }}
            >
              <div style={{}}>
                <div
                  className="text-center p-3"
                  style={{
                    background: "#0F519F",
                    borderRadius: "7px",
                    width: "70px",
                    height: "55px",
                  }}
                >
                  <Image src={share} />
                </div>
                <div className="mt-2 text-center " style={{ fontSize: "12px" }}>
                  Share & Earn
                </div>
              </div>
            </Col>
          </Row>
          {/* <div
            style={{ width: "100%" }}
            className="d-flex flex-row justify-content-evenly mt-3"
          >
            <div style={{}}>
              <div
                className="text-center p-3"
                style={{
                  background: "#ff800b",
                  borderRadius: "7px",
                  width: "70px",
                  height: "60px",
                }}
              >
                <Image src={scorecard} />
              </div>
              <div className="mt-2 text-center " style={{ fontSize: "15px" }}>
                ScoreCard
              </div>
            </div>
          </div> */}

          <Card
            className="mt-5"
            style={{
              width: "100%",
              boxShadow: "0px 0px 10px 3px rgba(0,0,0,0.15)",
              borderRadius: "11px",
              position: "relative",
            }}
          >
            <div
              style={{
                width: "50%",
                boxShadow: "0px 4px 11px rgba(0,0,0,0.15)",
                borderRadius: "11px",
                position: "absolute",
                top: "-9%",
                left: "25%",
                zIndex: 100,
                background: "white",
                color: "#0F519F",
                fontSize: "16px",
                fontWeight: "600",
                padding: "10px 15px",
                textAlign: "center",
              }}
            >
              Predict the WInner!
            </div>
            <Card.Body>
              <div
                style={{
                  fontSize: "12px",
                  fontWeight: "400",
                  color: "#FF800b",
                  marginTop: "30px",
                }}
                className="text-center"
              >
                Awaiting result..
              </div>
              <div
                style={{ marginTop: "20px" }}
                className="d-flex flex-row justify-content-around"
              >
                <div>
                  <div style={{ position: "relative" }}>
                    <Image
                      style={{
                        border: "1px solid grey",
                        // background: "#FFE712",
                        width: "82px",
                        height: "82px",
                        objectFit: "cover",
                        // padding: "15px",
                      }}
                      roundedCircle
                      src={csk}
                    />
                    <Image
                      src={tick}
                      style={{
                        width: 20,
                        position: "absolute",
                        height: 20,
                        right: 0,
                        bottom: 0,
                      }}
                      alt=""
                    />
                  </div>

                  <div
                    className="text-center mt-1"
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "black",
                    }}
                  >
                    Bangalore
                  </div>
                </div>
                <div
                  style={{
                    fontSize: "14px",
                    fontWeight: "400",
                    alignSelf: "center",
                    color: "black",
                  }}
                >
                  VS
                </div>
                <div>
                  <Image
                    style={{
                      //   background: "yellow",
                      width: "82px",
                      //   padding: "15px",
                      height: "82px",
                      objectFit: "cover",
                    }}
                    roundedCircle
                    src={rcb}
                  />
                  <div
                    className="text-center mt-1"
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "black",
                    }}
                  >
                    Chennai
                  </div>
                </div>
              </div>
              <div
                style={{
                  fontSize: "10px",
                  marginTop: "20px",
                  fontWeight: "400",
                  color: "black",
                }}
                className="d-flex flex-row justify-content-between"
              >
                <div>Fri,27May:Qualifiers</div>
                <div>1,54,333 predicted!</div>
              </div>
            </Card.Body>
          </Card>
          <div
            style={{
              alignItems: "end",
              paddingLeft: "12px",
              paddingRight: "12px",
              width: "100%",
            }}
            className="d-flex flex-row justify-content-between mt-3"
          >
            <div
              style={{ fontSize: "16px", color: "#0F519F", fontWeight: "600" }}
            >
              Prediction History
            </div>
            <div
              style={{ fontSize: "12px", color: "#FF800B", fontWeight: "500" }}
            >
              See Scoreboard
            </div>
          </div>
          <div style={{ width: "100%" }}>
            <Carousel style={{ width: "100%" }}>
              <Carousel.Item
                style={{
                  width: "95%",
                  marginLeft: "2.5%",
                  marginBottom: "10px",
                }}
              >
                <Card
                  className="mt-5"
                  style={{
                    width: "100%",
                    boxShadow: "0px 0px 10px 3px rgba(0,0,0,0.15)",
                    borderRadius: "11px",
                  }}
                >
                  <Card.Body>
                    <div
                      style={{
                        fontSize: "12px",
                        fontWeight: "400",
                        color: "black",
                        marginTop: "30px",
                      }}
                      className="text-center"
                    >
                      You got It Right!
                    </div>
                    <div
                      style={{ marginTop: "20px" }}
                      className="d-flex flex-row justify-content-around"
                    >
                      <div>
                        <div style={{ position: "relative" }}>
                          <Image
                            style={{
                              border: "1px solid grey",
                              // background: "#FFE712",
                              width: "82px",
                              height: "82px",
                              objectFit: "cover",
                              // padding: "15px",
                            }}
                            roundedCircle
                            src={csk}
                          />
                          <Image
                            src={winner}
                            style={{
                              width: 20,
                              position: "absolute",
                              height: 20,
                              right: 0,
                              top: 0,
                            }}
                            alt=""
                          />
                        </div>

                        <div
                          className="text-center mt-1"
                          style={{
                            fontSize: "14px",
                            fontWeight: "400",
                            color: "black",
                          }}
                        >
                          41%
                        </div>
                      </div>
                      <div
                        style={{
                          fontSize: "14px",
                          fontWeight: "400",
                          alignSelf: "center",
                          color: "black",
                        }}
                      >
                        VS
                      </div>
                      <div>
                        <Image
                          style={{
                            //   background: "yellow",
                            width: "82px",
                            //   padding: "15px",
                            height: "82px",
                            objectFit: "cover",
                          }}
                          roundedCircle
                          src={rcb}
                        />
                        <div
                          className="text-center mt-1"
                          style={{
                            fontSize: "14px",
                            fontWeight: "400",
                            color: "black",
                          }}
                        >
                          59%
                        </div>
                      </div>
                    </div>
                    <div className="mt-3">
                      <ProgressBar style={{ height: "5px" }}>
                        <ProgressBar
                          striped
                          variant="success"
                          now={35}
                          key={1}
                        />
                        <ProgressBar
                          striped
                          variant="danger"
                          now={65}
                          key={3}
                        />
                      </ProgressBar>
                    </div>

                    <div
                      style={{
                        fontSize: "10px",
                        marginTop: "20px",
                        fontWeight: "400",
                        color: "black",
                      }}
                      className="d-flex flex-row justify-content-between"
                    >
                      <div>Fri,27May:Qualifiers</div>
                      <div>1,54,333 predicted!</div>
                    </div>
                  </Card.Body>
                </Card>
              </Carousel.Item>
              <Carousel.Item
                style={{
                  width: "95%",
                  marginLeft: "2.5%",
                  marginBottom: "10px",
                }}
              >
                <Card
                  className="mt-5"
                  style={{
                    width: "100%",
                    boxShadow: "0px 0px 10px 3px rgba(0,0,0,0.15)",
                    borderRadius: "11px",
                  }}
                >
                  <Card.Body>
                    <div
                      style={{
                        fontSize: "12px",
                        fontWeight: "400",
                        color: "black",
                        marginTop: "30px",
                      }}
                      className="text-center"
                    >
                      You got It Right!
                    </div>
                    <div
                      style={{ marginTop: "20px" }}
                      className="d-flex flex-row justify-content-around"
                    >
                      <div>
                        <div style={{ position: "relative" }}>
                          <Image
                            style={{
                              border: "1px solid grey",
                              // background: "#FFE712",
                              width: "82px",
                              height: "82px",
                              objectFit: "cover",
                              // padding: "15px",
                            }}
                            roundedCircle
                            src={csk}
                          />
                          <Image
                            src={winner}
                            style={{
                              width: 20,
                              position: "absolute",
                              height: 20,
                              right: 0,
                              top: 0,
                            }}
                            alt=""
                          />
                        </div>

                        <div
                          className="text-center mt-1"
                          style={{
                            fontSize: "14px",
                            fontWeight: "400",
                            color: "black",
                          }}
                        >
                          41%
                        </div>
                      </div>
                      <div
                        style={{
                          fontSize: "14px",
                          fontWeight: "400",
                          alignSelf: "center",
                          color: "black",
                        }}
                      >
                        VS
                      </div>
                      <div>
                        <Image
                          style={{
                            //   background: "yellow",
                            width: "82px",
                            //   padding: "15px",
                            height: "82px",
                            objectFit: "cover",
                          }}
                          roundedCircle
                          src={rcb}
                        />
                        <div
                          className="text-center mt-1"
                          style={{
                            fontSize: "14px",
                            fontWeight: "400",
                            color: "black",
                          }}
                        >
                          59%
                        </div>
                      </div>
                    </div>
                    <div className="mt-3">
                      <ProgressBar style={{ height: "5px" }}>
                        <ProgressBar
                          striped
                          variant="success"
                          now={35}
                          key={1}
                        />
                        <ProgressBar
                          striped
                          variant="danger"
                          now={65}
                          key={3}
                        />
                      </ProgressBar>
                    </div>
                    <div
                      style={{
                        fontSize: "10px",
                        marginTop: "20px",
                        fontWeight: "400",
                        color: "black",
                      }}
                      className="d-flex flex-row justify-content-between"
                    >
                      <div>Fri,27May:Qualifiers</div>
                      <div>1,54,333 predicted!</div>
                    </div>
                  </Card.Body>
                </Card>
              </Carousel.Item>
              <Carousel.Item
                style={{
                  width: "95%",
                  marginLeft: "2.5%",
                  marginBottom: "10px",
                }}
              >
                <Card
                  className="mt-5"
                  style={{
                    width: "100%",
                    boxShadow: "0px 0px 10px 3px rgba(0,0,0,0.15)",
                    borderRadius: "11px",
                  }}
                >
                  <Card.Body>
                    <div
                      style={{
                        fontSize: "12px",
                        fontWeight: "400",
                        color: "black",
                        marginTop: "30px",
                      }}
                      className="text-center"
                    >
                      You got It Right!
                    </div>
                    <div
                      style={{ marginTop: "20px" }}
                      className="d-flex flex-row justify-content-around"
                    >
                      <div>
                        <div style={{ position: "relative" }}>
                          <Image
                            style={{
                              border: "1px solid grey",
                              // background: "#FFE712",
                              width: "82px",
                              height: "82px",
                              objectFit: "cover",
                              // padding: "15px",
                            }}
                            roundedCircle
                            src={csk}
                          />
                          <Image
                            src={winner}
                            style={{
                              width: 35,
                              position: "absolute",
                              height: 35,
                              right: -15,
                              top: -15,
                            }}
                            alt=""
                          />
                        </div>

                        <div
                          className="text-center mt-1"
                          style={{
                            fontSize: "14px",
                            fontWeight: "400",
                            color: "black",
                          }}
                        >
                          41%
                        </div>
                      </div>
                      <div
                        style={{
                          fontSize: "14px",
                          fontWeight: "400",
                          alignSelf: "center",
                          color: "black",
                        }}
                      >
                        VS
                      </div>
                      <div>
                        <Image
                          style={{
                            //   background: "yellow",
                            width: "82px",
                            //   padding: "15px",
                            height: "82px",
                            objectFit: "cover",
                          }}
                          roundedCircle
                          src={rcb}
                        />
                        <div
                          className="text-center mt-1"
                          style={{
                            fontSize: "14px",
                            fontWeight: "400",
                            color: "black",
                          }}
                        >
                          59%
                        </div>
                      </div>
                    </div>
                    <div className="mt-3">
                      <ProgressBar style={{ height: "5px" }}>
                        <ProgressBar
                          striped
                          variant="success"
                          now={35}
                          key={1}
                        />
                        <ProgressBar
                          striped
                          variant="danger"
                          now={65}
                          key={3}
                        />
                      </ProgressBar>
                    </div>
                    <div
                      style={{
                        fontSize: "10px",
                        marginTop: "20px",
                        fontWeight: "400",
                        color: "black",
                      }}
                      className="d-flex flex-row justify-content-between"
                    >
                      <div>Fri,27May:Qualifiers</div>
                      <div>1,54,333 predicted!</div>
                    </div>
                  </Card.Body>
                </Card>
              </Carousel.Item>
            </Carousel>
          </div>
          <div
            className="mt-3"
            style={{
              alignItems: "end",
              paddingLeft: "12px",
              paddingRight: "12px",
              width: "100%",
            }}
          >
            <div
              style={{ fontSize: "16px", color: "#0F519F", fontWeight: "600" }}
            >
              How to Play?
            </div>
          </div>
          <div style={{ width: "100%" }}>
            <Carousel style={{ width: "100%" }}>
              <Carousel.Item
                style={{
                  width: "95%",
                  marginLeft: "2.5%",
                  marginBottom: "10px",
                }}
              >
                <Card
                  style={{
                    marginTop: "10px",
                    width: "100%",
                    boxShadow: "0px 0px 10px 3px rgba(0,0,0,0.15)",
                    borderRadius: "11px",
                    height: "15rem",
                    overflow: "hidden",
                  }}
                >
                  <Card.Body>
                    <p
                      style={{
                        color: "black",
                        fontSize: "12px",
                        fontWeight: "400",
                        textAlign: "center",
                      }}
                    >
                      Submit Your <span>Prdictions</span> <br></br>
                      before the timer ends
                    </p>

                    <img
                      className="d-block w-100"
                      src={how_to_play}
                      alt="First slide"
                    />
                  </Card.Body>
                </Card>
              </Carousel.Item>
            </Carousel>
          </div>
          <div></div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PredictionGame;
