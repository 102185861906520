import { useState } from "react";
import { Modal } from "react-bootstrap";
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";

const OrderOverview = ({ show, setShow, productData, orderData }) => {
  const [status, setStatus] = useState(false);

  return (
    <Modal fullscreen={true} show={show}>
      <Modal.Body>
        <div className="d-flex flex-column mt-4">
          <div
            className="d-flex flex-column"
            style={{
              borderRadius: "20px",
              position: "relative",
              background: `${status ? "#95E7BB" : "#E79595"}`,
            }}
          >
            <div
              style={{
                position: "absolute",
                left: "calc(50% - 30px)",
                top: "-30px",
              }}
            >
              {status ? (
                <AiFillCheckCircle
                  style={{
                    color: "#95E7BB",
                    width: "60px",
                    height: "60px",
                    border: "4px solid white",
                    background: "white",
                    borderRadius: "50%",
                  }}
                />
              ) : (
                <AiFillCloseCircle
                  style={{
                    color: "#E79595",
                    width: "60px",
                    height: "60px",
                    border: "4px solid white",
                    background: "white",
                    borderRadius: "50%",
                  }}
                />
              )}
            </div>
            <div className="text-center fs-4 mt-5">Thank you for the order</div>
            <div className="text-center fs-6">the order has been confirmed</div>
            <div className="d-flex flex-row mt-4 mx-2 my-2 text-center justify-content-between px-2">
              <div className="d-flex flex-column">
                <div style={{ fontSize: "16px", fontWeight: "bold" }}>
                  $261.38
                </div>
                <div style={{ fontSize: "14px", fontWeight: "normal" }}>
                  Price Quote
                </div>
              </div>
              <div className="d-flex flex-column">
                <div style={{ fontSize: "16px", fontWeight: "bold" }}>
                  x1000
                </div>
                <div style={{ fontSize: "14px", fontWeight: "normal" }}>
                  Units Order
                </div>
              </div>
              <div className="d-flex flex-column">
                <div style={{ fontSize: "16px", fontWeight: "bold" }}>
                  11.06-14.06
                </div>
                <div style={{ fontSize: "14px", fontWeight: "normal" }}>
                  Est. Delivery
                </div>
              </div>
            </div>
            <div
              className="text-center"
              style={{
                background: `${status ? "#B0EDCD" : "#EDB0B0"}`,
                borderRadius: "0px 0px 20px 20px",
                padding: "10px 20px",
                color: "#555555",
                fontSize: "15px",
                fontWeight: "normal",
              }}
            >
              Order in place
            </div>
          </div>

          <div className="d-flex flex-column mt-4">
            <div
              className="d-flex flex-column p-3 text-start"
              style={{
                borderRadius: "20px",
                position: "relative",
                background: "#F6F6F6",
                fontSize: "14px",
              }}
            >
              <div
                className="d-flex flex-column"
                style={{ borderBottom: "1px solid #A7A7A7" }}
              >
                <div className="d-flex flex-row">
                  <div style={{ width: "40%" }}>Transaction Date: </div>
                  <div>17-07-2023 (14:45+GMT+7)</div>
                </div>
                <div className="d-flex flex-row py-2">
                  <div style={{ width: "40%" }}>Delivery Methods: </div>
                  <div className="d-flex flex-column">
                    <div>Express Shipping (1 to 3 days)</div>
                    <div
                      style={{ fontSize: "10px" }}
                      className="text-decoration-underline"
                    >
                      TRACK ORDER
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column mt-2">
                <div>Your Order : </div>
                <div className="d-flex flex-column">
                  {productData?.map((item, index) => (
                    <div
                      key={index}
                      style={{ borderBottom: "1px dashed #a7a7a7" }}
                      className="d-flex flex-row py-2"
                    >
                      <img
                        src="https://cdn-media.buildersmart.in/media/catalog/product/cache/1/image/9df78eab33525d08d6e5fb8d27136e95/e/l/elwicarrk0028.png"
                        width="80px"
                        className="shadow"
                        style={{ height: "fit-content", borderRadius: "10px" }}
                      />
                      <div
                        className="d-flex flex-column"
                        style={{ paddingLeft: "20px", marginTop: "4px" }}
                      >
                        <div>{item.SkuUniqueId}</div>
                        <div>Quantity : {item.Quantity}</div>
                        <div>UnitPrice : {item.UnitPrice}</div>
                        <div>Sub Total : {item.SubTotal}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div
                className="mt-2 p-1 text-center"
                style={{
                  borderRadius: "20px",
                  background: "#9f7dfe",
                  border: "2px solid #7341ff",
                  color: "#fff",
                  fontWeight: "bold",
                }}
              >
                View Detailed InVoice
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default OrderOverview;
