import { useEffect, useState } from "react";
import {
  AiOutlineBell,
  AiOutlineMail,
  AiOutlineCamera,
  AiOutlineAim,
} from "react-icons/ai";
import { BsCircleHalf } from "react-icons/bs";
import SwitchComponent from "../component/SwitchComponent";
import { useTranslation } from "react-i18next";
import { GrFormNext } from "react-icons/gr";
import LanguageSettings from "../component/LanguageSelect/LanguageSetting";
function Settings() {
  const { t } = useTranslation();
  //eslint-disable-next-line
  const [current, setCurrent] = useState("en");
  const [languageSelectModel, setLanguageSelectModel] = useState(false);
  useEffect(() => {}, [current]);
  return (
    <>
      <LanguageSettings
        show={languageSelectModel}
        setShow={setLanguageSelectModel}
      />
      <div>
        {/* <SecoundaryHeader
          color="white"
          sideHide={true}
          text={t("settings")}
          onBack={() => history.goBack()}
        /> */}
        <div style={{ padding: "10px" }} className="mainPage">
          <div
            onClick={() => {
              setLanguageSelectModel(true);
            }}
            className="d-flex flex-row justify-content-between shadow p-3 mb-3 bg-white rounded"
          >
            <div className="d-flex flex-row">
              <img
                style={{ width: "28px" }}
                alt="language.svg"
                src="/assets/images/language.svg"
              />
              <div
                style={{
                  color: "#7B7B7B",
                  fontSize: "15px",
                  marginLeft: "10px",
                }}
                className="align-self-center fw-bold"
              >
                {t("select_language")}
              </div>
            </div>
            <GrFormNext style={{ fontSize: "24px" }} />
          </div>

          <div className="d-flex flex-row justify-content-between shadow p-3 my-3 bg-white rounded">
            <div className="d-flex flex-row">
              <BsCircleHalf style={{ width: "28px", height: "28px" }} />
              <div
                style={{
                  color: "#7B7B7B",
                  fontSize: "15px",
                  marginLeft: "10px",
                }}
                className="align-self-center fw-bold"
              >
                {t("application_theme")}
              </div>
            </div>
            <GrFormNext style={{ fontSize: "24px" }} />
          </div>

          <div className="d-flex flex-row justify-content-between shadow p-3 my-3 bg-white rounded">
            <div className="d-flex flex-row">
              <AiOutlineBell style={{ width: "28px", height: "28px" }} />
              <div
                style={{
                  color: "#7B7B7B",
                  fontSize: "15px",
                  marginLeft: "10px",
                }}
                className="align-self-center fw-bold"
              >
                {t("push_notification")}
              </div>
            </div>
            <SwitchComponent style={{ fontSize: "24px" }} />
          </div>

          <div className="d-flex flex-row justify-content-between shadow p-3 my-3 bg-white rounded">
            <div className="d-flex flex-row">
              <AiOutlineMail style={{ width: "28px", height: "28px" }} />
              <div
                style={{
                  color: "#7B7B7B",
                  fontSize: "15px",
                  marginLeft: "10px",
                }}
                className="align-self-center fw-bold"
              >
                {t("email_notification")}
              </div>
            </div>
            <SwitchComponent style={{ fontSize: "24px" }} />
          </div>

          {/* <h6
            className="mt-4"
            style={{ textAlign: "left", color: "#3A69DD", fontWeight: "600" }}
          >
            {t("app_permissions")}
          </h6> */}

          <div className="d-flex flex-row justify-content-between shadow p-3 my-3 bg-white rounded">
            <div className="d-flex flex-row">
              <AiOutlineCamera style={{ width: "28px", height: "28px" }} />
              <div
                style={{
                  color: "#7B7B7B",
                  fontSize: "15px",
                  marginLeft: "10px",
                }}
                className="align-self-center fw-bold"
              >
                {t("camera")}
              </div>
            </div>
            <SwitchComponent style={{ fontSize: "24px" }} />
          </div>

          <div className="d-flex flex-row justify-content-between shadow p-3 my-3 bg-white rounded">
            <div className="d-flex flex-row">
              <AiOutlineAim style={{ width: "28px", height: "28px" }} />
              <div
                style={{
                  color: "#7B7B7B",
                  fontSize: "15px",
                  marginLeft: "10px",
                }}
                className="align-self-center fw-bold"
              >
                {t("location")}
              </div>
            </div>
            <SwitchComponent style={{ fontSize: "24px" }} />
          </div>
        </div>
      </div>
    </>
  );
}

export default Settings;
