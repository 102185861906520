const EnglishIcons = ({ color }) => (
  <div className="flex flex-col items-center justify-center p-[10px]">
    <svg
      width="41"
      height="39"
      viewBox="0 0 41 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.832 0.65625C22.8763 0.65625 24.599 0.75651 25 0.957031C25.401 1.15755 27.1966 5.95182 30.3867 15.3398C33.5768 24.7096 35.8828 31.181 37.3047 34.7539H39.875C40.1849 35.4102 40.3398 36.0846 40.3398 36.7773C40.3398 37.4154 40.2214 37.862 39.9844 38.1172C39.7474 38.3724 39.3646 38.5 38.8359 38.5H24.8633C24.5534 37.6979 24.3984 36.9232 24.3984 36.1758C24.3984 35.6836 24.5352 35.3281 24.8086 35.1094C25.082 34.8724 25.4831 34.7539 26.0117 34.7539H27.2148C26.9414 33.4414 26.2396 30.8255 25.1094 26.9062H13.5156C12.4948 30.7344 11.8568 33.3503 11.6016 34.7539H14.1992C14.5091 35.4102 14.6641 36.0846 14.6641 36.7773C14.6641 37.4154 14.5456 37.862 14.3086 38.1172C14.0716 38.3724 13.6979 38.5 13.1875 38.5H1.26562C0.955729 37.6979 0.800781 36.9232 0.800781 36.1758C0.800781 35.6836 0.928385 35.3281 1.18359 35.1094C1.45703 34.8724 1.85807 34.7539 2.38672 34.7539H3.86328C5.15755 31.2357 7.10807 25.5299 9.71484 17.6367C12.3398 9.72526 14.0807 4.92188 14.9375 3.22656C15.7943 1.51302 17.4258 0.65625 19.832 0.65625ZM23.7695 22.3398C20.9805 13.1523 19.4128 8.55859 19.0664 8.55859C18.7383 8.55859 17.2891 13.1523 14.7188 22.3398H23.7695Z"
        fill={color}
      />
    </svg>
  </div>
);

export default EnglishIcons;
