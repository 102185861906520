  import React, { useState, useEffect } from "react";
import { asyncWrap } from "../../utils/utils";
import axios from "axios";
import { Button, Modal } from "react-bootstrap";
import { Col, FormGroup, Input, Label } from "reactstrap";
import Swal from "sweetalert2";
import { useAuth } from "../../context/auth-context";

export default function AddressPopup({ show, setShow, userDetail }) {
  const [pincode, setPincode] = useState(userDetail?.pinCode);
  // const [stateId, setStateId] = useState(userDetail?.StateId);
  // const [districtId, setDistrictId] = useState(userDetail?.districtId);
  // const [state, setStates] = useState([]);
  // const [district, setDistrict] = useState();
  // const [stateDist, setStateDist] = useState([]);
  const [ErrorMessage, setErrorMessage] = useState("");
  const [disableBtn, setDisableBtn] = useState(false);
  const { user } = useAuth();
  // useEffect(() => {
    // fetchRegions();
  // }, []);

  const submitAddress = async () => {
    setDisableBtn(true);
    if (!pincode) {
      setErrorMessage("Please Insert Pincode!");
      setDisableBtn(false);
      return;
    } 
    // if (districtId === "0") {
      // setErrorMessage("Please Select District!");
      // setDisableBtn(false);
      // return;
    // }

    const [error, result] = await asyncWrap(
      axios.post("/editProfile", {
        userType: userDetail.roleName,
        userId: user?.profile?.userId || user.userId,
        // state: state.filter(item => item.stateId === stateId)[0]?.label,
        // district: districtId,
        pinCode: pincode,
      })
    );
    if (!result) {
      Swal.fire(error.response.data.data.message);
      window.location.reload();
    } else if (result.data?.success) {
      Swal.fire(result.data?.message).then(() => {
        window.location.reload();
      });
    } else {
      Swal.fire(result?.data?.message).then(() => {
        window.location.reload();
      });
    }
  };

  // const fetchRegions = async () => {
  //   const [error, result] = await asyncWrap(axios.get("masterData/regions"));
  //   if (error) {
  //     Swal.fire("Failed to get region data!");
  //     return;
  //   }
  //   const {
  //     data: { districts, states },
  //   } = result.data;

  //   setDistrict(
  //     districts.map(d => ({
  //       ...d,
  //       label: d.districtName,
  //       value: d.districtId,
  //     }))
  //   );
  //   setStateDist(
  //     districts.map(d => ({
  //       ...d,
  //       label: d.districtName,
  //       value: d.districtId,
  //     }))
  //   );

  //   setStates(
  //     states.map(st => ({ ...st, label: st.stateName, value: st.stateId }))
  //   );

  //   if (userDetail?.StateId) {
  //     const mappedDistricts = districts.map(d => ({
  //       ...d,
  //       label: d.districtName,
  //       value: d.districtId,
  //     }));

  //     const filteredDistricts = mappedDistricts.filter(
  //       item => item.stateId === userDetail?.StateId
  //     );

  //     if (userDetail?.districtId && userDetail?.districtId === "0") {
  //       setDistrictId(filteredDistricts[0]?.districtId);
  //     }
  //     setStateDist(filteredDistricts);
  //   }
  // };

  // const stateDistFilter = value => {
  //   setStateId(value);
  //   setDistrictId(district.filter(item => item.stateId === value)[0]?.districtId)
  //   setStateDist(district.filter(item => item.stateId === value));
  // };

  return (
    <Modal
      show={show}
      //   onHide={() => setShow(false)}
      dialogClassName="modal-90w"
      //   style={{ top: "35%" }}
      contentClassName="model-content-popup"
      centered
      backdrop="static"
      aria-labelledby="example-custom-modal-styling-title"
      backdropClassName="custom-backdrop"
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Edit Pincode
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormGroup row>
          <Label for="exampleText" sm={2}>
            Pincode
          </Label>
          <Col sm={10}>
            <Input
              required
              onChange={event => setPincode(event.target.value)}
              value={pincode}
              id="exampleText"
              name="text"
            />
          </Col>
        </FormGroup>
        {/* <FormGroup>
          <Label for="exampleSelect">State</Label>
          <Input
            required
            id="exampleSelect"
            onChange={event => stateDistFilter(event.target.value)}
            name="select"
            type="select"
            value={stateId}
          >
            {state.map((item, index) => (
              <option key={index} value={item.value}>
                {item.label}
              </option>
            ))}
          </Input>
        </FormGroup>
        <FormGroup>
          <Label for="exampleSelect">District</Label>
          <Input
            required
            id="exampleSelect"
            name="select"
            type="select"
            onChange={event => {
              setDistrictId(event.target.value);
            }}
            value={districtId}
          >
            {stateDist.map((item, index) => (
              <option key={index} value={item.value}>
                {item.label}
              </option>
            ))}
          </Input>
        </FormGroup> */}
        <div style={{ color: "red", fontSize: "20px", textAlign: "center" }}>
          {ErrorMessage}
        </div>
        <div>
          <Button disable={disableBtn} onClick={submitAddress} color="primary">
            Submit
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}