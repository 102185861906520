import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { useAuth } from "../context/auth-context";

const LoadingScreen = () => {
  const { user } = useAuth();
  const history = useHistory();

  useEffect(() => {
    if (user) {
      history.replace("/home");
    } else {
      history.replace("/login");
    }
    // eslint-disable-next-line
  });

  return <div>Loading...</div>;
};

export default LoadingScreen;
