import React, { useState } from 'react'
import { Card, Row } from 'react-bootstrap'
import CustomDatePicker from '../component/CustomDatePicker'
import DropdownSelect from '../component/DropdownSelect';
import PaymentBtn from '../component/PaymentInfo/PaymentBtn';

function PaymentHistory() {
  const [paymentStatus, setPaymentStatus] = useState('');

  const statusOptions = [
    { label: 'Success', value: 'Success' },
    { label: 'Failed', value: 'Failed' },
    { label: 'Pending', value: 'Pending' },
    { label: 'All', value: 'All' },
  ];

  const historyData = [
    {
      transactionNumber: 'CN/VJ/19-20/223',
      paymentDate: '05-03-2020 19:35',
      referenceNo: '20200305/879',
      totalSaving: '₹ 250',
      status: 'Success',
      amountPaid: '₹ 99,750',
      totalAmount: '₹ 1,00,000'
    },
    {
      transactionNumber: 'CN/VJ/19-20/223',
      paymentDate: '05-03-2020 19:35',
      referenceNo: '20200305/879',
      totalSaving: '₹ 250',
      status: 'Failed',
      amountPaid: '₹ 99,750',
      totalAmount: '₹ 1,00,000'
    },
  ];

  const getStatusStyle = (status) => {
    switch (status) {
      case 'Success':
        return { color: 'green' };
      case 'Failed':
        return { color: 'red' };
      default:
        return { color: 'black' }; // Default color for other statuses
    }
  };

  return (
    <div>
    <div className="container mt-1">

      {/* Date Pickers */}
      <div style={{ width: '90vw' }}>
        <Row className="mb-3">
          <CustomDatePicker label="From Date" controlId="fromDate" />
          <CustomDatePicker label="To Date" controlId="toDate" />
        </Row>
      </div>

      <div style={{ width: '90vw' }}>
      <DropdownSelect
        selectId="paymentStatus"
        label="Payment Status"
        selectedValue={paymentStatus}
        setSelectedValue={setPaymentStatus}
        options={statusOptions}
        width='50vw'
      />
      </div> 

      {historyData.map((paymentHistory, index) => (
        <Card
          key={index}
          className="mb-1"
          style={{
            width: '90vw',
            backgroundColor: '#E9EAEB',
            fontSize: '0.8em',
            color: '#323e3e',
            margin: '1em 0.1em'
          }}>
          <Card.Header
            style={{
              backgroundColor: '#E9EAEB',
              borderBottom: 'none'
            }}>
            <strong>Transaction Number</strong>
            <br />
            <span
              style={{ fontSize: '14px' , lineHeight: '0.01em'}}>
              {paymentHistory.transactionNumber}
            </span>
          </Card.Header>
          {/* Vertical bar */}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row'
            }}>
            <div
              style={{
                backgroundColor: 'black',
                width: '5px',
                margin: '8px 2px 10px 16px'
              }}>
            </div>
            <div
              style={{
                display: 'flex',
                width: '100vw',
                padding: '0.4rem'
              }}>
              <div
                style={{
                  flex: 1,
                  textAlign: 'left',
                  paddingRight: '1.2em'
                }}>
                <div>
                  <strong>Payment Date</strong>
                  <br />
                  {paymentHistory.paymentDate}
                </div>
                <div>
                  <strong>Amount Paid</strong>
                  <br />
                  {paymentHistory.amountPaid}
                </div>
                <div>
                  <strong>Total Amount</strong>
                  <br />
                  {paymentHistory.totalAmount}
                </div>
              </div>
              <div
                style={{
                  flex: 1,
                  textAlign: 'left'
                }}>
                <div>
                  <strong>Reference No</strong>
                  <br />
                  {paymentHistory.referenceNo}
                </div>
                <div>
                  <strong>Payment Status</strong>
                  <br />
                  <span style={getStatusStyle(paymentHistory.status)}>
                    {paymentHistory.status}
                  </span>
                </div>
                <div>
                  <strong>Total Saving</strong>
                  <br />
                  {paymentHistory.totalSaving}
                </div>
              </div>
            </div>
          </div>
          <Card.Footer
            style={{
              backgroundColor: '#E9EAEB',
              borderTop: 'none'
            }}>
            <span>
              <PaymentBtn text="Need Help?" style={{borderRadius:"4px", width: '7em', padding: '4px 2px'}} />
            </span>
          </Card.Footer>
        </Card>
        
      ))}
    </div>
    <div style={{display:'flex', justifyContent: 'end', alignItems: 'end', position: 'fixed', bottom: '0'}}>
      <PaymentBtn text="Refresh" style={{borderRadius:'0',width:'100vw' }}/>
    </div>
    </div>
  )
}

export default PaymentHistory