import React from "react";

const PassQuizPageModal = React.createContext({
  setShowQuizPage: Boolean,
});

const UsersPointsData = React.createContext({
  UsersPointsData: [],
});

const LeaderBoardData = React.createContext({
  LeaderBoardData: [],
});

export { PassQuizPageModal, UsersPointsData, LeaderBoardData };
