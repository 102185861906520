import { useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import ReactPlayer from "react-player";
import VideoPlayerDetails from "./VideoPlayerDetails";

const VideoPlayer = ({ show, setShow, data, playerid }) => {
  const [play, setPlay] = useState(true);
  const [showDetails, setShowDetails] = useState(false);
  const [playedTime, setPlayedTime] = useState();
  const vidRef = useRef(null);

  const handlePlayPause = () => {
    const timeToStart = playedTime + 0.6;
    vidRef.current.seekTo(timeToStart, "seconds");
    setPlay(!play);
  };

  const handleProgress = (state) => {
    if (playerid === 1) {
      if (+state.playedSeconds.toFixed() === 8) {
        setPlay(false);
        setPlayedTime(8);
        setShowDetails(true);
      } else if (+state.playedSeconds.toFixed() === 17) {
        setPlay(false);
        setPlayedTime(17);
        setShowDetails(true);
      } else if (+state.playedSeconds.toFixed() === 26) {
        setPlay(false);
        setPlayedTime(26);
        setShowDetails(true);
      }
    } else if (playerid === 3) {
      if (+state.playedSeconds.toFixed() === 3) {
        setPlay(false);
        setPlayedTime(3);
        setShowDetails(true);
      } else if (+state.playedSeconds.toFixed() === 6) {
        setPlay(false);
        setPlayedTime(6);
        setShowDetails(true);
      } else if (+state.playedSeconds.toFixed() === 9) {
        setPlay(false);
        setPlayedTime(9);
        setShowDetails(true);
      } else if (+state.playedSeconds.toFixed() === 12) {
        setPlay(false);
        setPlayedTime(12);
        setShowDetails(true);
      } else if (+state.playedSeconds.toFixed() === 15) {
        setPlay(false);
        setPlayedTime(15);
        setShowDetails(true);
      }
    } else if (playerid === 4) {
      if (+state.playedSeconds.toFixed() === 9) {
        setPlay(false);
        setPlayedTime(9);
        setShowDetails(true);
      } else if (+state.playedSeconds.toFixed() === 16) {
        setPlay(false);
        setPlayedTime(16);
        setShowDetails(true);
      } else if (+state.playedSeconds.toFixed() === 21) {
        setPlay(false);
        setPlayedTime(21);
        setShowDetails(true);
      }
    }
  };

  const handleEndVideo = () => {
    setShowDetails(true);
    setPlayedTime(0);
  };

  return (
    <>
      <VideoPlayerDetails
        show={showDetails}
        setShow={setShowDetails}
        handlePlayPause={handlePlayPause}
        playerid={playerid}
        time={playedTime}
      />
      <Modal fullscreen={true} show={show} onHide={() => setShow(false)}>
        <Modal.Body className="p-0 text-center">
          <ReactPlayer
            onProgress={handleProgress}
            ref={vidRef}
            playing={play}
            className="react-player"
            url={data.video}
            width="100%"
            height="98%"
            onEnded={handleEndVideo}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default VideoPlayer;
