import React from 'react';
import { Card} from 'react-bootstrap';

function Policy() {
  // Example array of policies
  const policies = [
    {
      name: 'Goldmedal Trade Policy (gtp)',
      fromDate: '01-04-2019',
      toDate: '31-03-2020',
      logo: 'https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcR_U4xeUEjytrVLE4NOQ64YPYrqfpUt0QMk2rk-p9si6UOaSaa7', 
    },
    {
      name: 'Goldmedal Tod Policy (executive)',
      fromDate: '01-04-2019',
      toDate: '31-03-2020',
      logo: 'https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcR_U4xeUEjytrVLE4NOQ64YPYrqfpUt0QMk2rk-p9si6UOaSaa7',
    },
    {
      name: 'Replacement Procedure Circular',
      fromDate: '01-08-2018',
      toDate: 'To Date',
      logo: 'https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcR_U4xeUEjytrVLE4NOQ64YPYrqfpUt0QMk2rk-p9si6UOaSaa7',
    },
    // ... add more policies as needed
  ];
  return (
    <div className="d-flex flex-column align-items-center">
      {policies.map((policy, index) => (
        <Card key={index} style={{ width: '90vw', marginBottom: '1rem',display: 'flex', flexDirection: 'row', backgroundColor: '#E9EAEB' }}>
          <div style={{ width: '40%', padding: '0 2px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <img src={policy.logo} alt="Logo" style={{ width: '25vw', height: '15vh', marginLeft:'1rem'}} />
          </div>
          <div style={{ width: '60%', backgroundColor: '#E9EAEB', textAlign: 'left', margin:'1em 1.5em' }}>
            <div style={{ paddingBottom: '1px', color: 'black' }}>
              <strong>Policy Name:</strong><br/>{policy.name}
            </div>
            <div style={{ paddingBottom: '1px', color: 'black' }}>
              <strong>From Date:</strong><br/>{policy.fromDate}
            </div>
            <div style={{ color: 'black' }}>
              <strong>To Date:</strong><br/>{policy.toDate}
            </div>
          </div>
        </Card>
      ))}
    </div>
  );
}

export default Policy;