import React from 'react';
import { AiOutlineFilePdf } from 'react-icons/ai';

function TargetScheme() {
  const targetSchemeColumn = [];

  const targetSchemeData = [];

  // Style object for table headings
  const headingStyle = {
    backgroundColor: '#ADAEB0',
    fontSize: '12px'
  };

  // Style object for table border
  const tableStyle = {
    borderColor: 'white',
  };

  // Style object for no data message
  const noDataStyle = {
    height: '100vh', 
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  };

  return (
    <div className="container mt-3">
      {targetSchemeColumn.length > 0 ? (
        <table className="table table-sm" style={tableStyle}>
          <thead>
            <tr>
              {targetSchemeColumn.map(column => (
                <th scope="col" key={column.id} style={headingStyle}>{column?.name}</th>
              ))}
            </tr>
          </thead>
          <tbody>
          {targetSchemeData.map((data, index) => (
              <tr key={index}>
                {targetSchemeColumn.map(column => (
                  <td key={column.id}>{data[column?.name]}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div style={noDataStyle}>
          <AiOutlineFilePdf size={48} color='red'/>
          <span>NO DATA AVAILABLE</span>
        </div>
      )}
    </div>
  );
}

export default TargetScheme;
