import { Modal } from "react-bootstrap";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, FormGroup, Input, Label } from "reactstrap";
import CloseModalTab from "../../component/Nav/CloseModalTab";

const PaytmModal = ({
  show,
  setShow,
  paytmNumber,
  setPaytmNumber,
  point,
  setPoint,
  editProfile,
  addRedeemClick,
}) => {
  const [disableEditDetail, setDisableEditDetail] = useState(false);
  const { t } = useTranslation();

  return (
    <Modal fullscreen={true} show={show}>
      <Modal.Body className="mt-4">
        <CloseModalTab onclick={() => setShow(false)} />
        <Form className="w-100">
          <FormGroup floating className="p-0">
            <Input
              style={{ border: "1px solid #B0B0B0", width: "100%" }}
              required
              id="PaytmNumber"
              disabled={!disableEditDetail}
              value={paytmNumber}
              onChange={e => setPaytmNumber(e.target.value)}
              placeholder="Enter Your Paytm Wallet Number"
              type="tel"
            />
            <Label htmlFor="PaytmNumber">{t("paytm_number")}</Label>
            <div
              onClick={() => setDisableEditDetail(!disableEditDetail)}
              style={{ color: "#3A69DD" }}
              className="text-end mt-0"
            >
              {t("edit_details")}
            </div>
          </FormGroup>

          {disableEditDetail ? (
            <div className="d-flex justify-content-center">
              <button
                onClick={editProfile}
                style={{ background: "#3A69DD", color: "white" }}
                className="fw-bold fs-5 shadow align-self-center rounded-5 px-4 py-2 border-0 "
              >
                {t("submit")}
              </button>
            </div>
          ) : null}

          <FormGroup floating className="p-0 mt-3">
            <Input
              style={{ border: "1px solid #B0B0B0", width: "100%" }}
              required
              onChange={e => setPoint(e.target.value)}
              value={point}
              placeholder="Point"
              id="point"
              type="number"
            />
            <Label htmlFor="point">{t("points")}</Label>
          </FormGroup>
          <div className="d-flex flex-column justify-content-center">
            <button
              onClick={e => {
                e.preventDefault();
                addRedeemClick();
              }}
              style={{ background: "#3A69DD", color: "white" }}
              className="fw-bold fs-5 shadow align-self-center rounded-5 px-4 py-2 border-0 "
            >
              {t("redeem")}
            </button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default PaytmModal;
