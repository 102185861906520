import { useState } from "react";
import { Modal } from "react-bootstrap";
import { Slider } from "antd";

const GoalReward = ({ show, setShow }) => {
  const [values, setValues] = useState([25, 10, 35, 20, 10]);

  const sliders = [
    { title: "ULIP - (25%)" },
    { title: "Smart Wealth - (10%)" },
    { title: "Traditional - (35%)" },
    { title: "Annuity - (20%)" },
    { title: "YONO Premium - (10%)" },
  ];

  const handleChange = (index, value) => {
    let maxValue = 100;
    const remaining = maxValue - parseInt(value, 10);
    setValues((vs) =>
      vs.map((v, i) => {
        if (i === index) return parseInt(value, 10);
        const oldRemaining = maxValue - parseInt(vs[index], 10);
        if (oldRemaining) return (remaining * v) / oldRemaining;
        return remaining / (sliders.length - 1);
      })
    );
  };

  return (
    <>
      <Modal fullscreen={true} show={show} onHide={() => setShow(false)}>
        <Modal.Body style={{ overflowX: "hidden" }} className="text-center">
          <div className="m-2">
            {sliders.map((item, index) => (
              <div>
                <div>{item.title}</div>
                <Slider
                  key={index}
                  index={index}
                  value={values[index]}
                  title={item.title}
                  onChange={(e) => {
                    handleChange(index, e);
                  }}
                />
              </div>
            ))}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default GoalReward;
