import axios from "axios";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { Col, Row } from "react-bootstrap";
import OtpInput from "react-otp-input";
import { useHistory } from "react-router";
// import styled from "styled-components";
import { Button, Spinner } from "reactstrap";
import Swal from "sweetalert2";
import { asyncWrap } from "../utils/utils";

const AadhaarCard = ({ show, setShow, mobile }) => {
  const [otpaadhar, setOtpAadhar] = useState("");
  const [aadharCardNumber, setAadharCardNumber] = useState("");
  const [referenceid, setReferenceId] = useState("");
  const [showOtp, setShowOtp] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const handleAadhaarCard = async () => {
    setLoading(true);
    if (aadharCardNumber === "") {
      Swal.fire("Please enter aadhaar card no");
    } else if (aadharCardNumber.length !== 12) {
      Swal.fire("Please enter valid aadhaar card no and click get code first");
    } else {
      const [error, result] = await asyncWrap(
        axios.post("/verifyKyc/send-aadhar-otp", {
          addhar: aadharCardNumber,
        })
      );

      if (!result) {
        Swal.fire(error?.response.data.data.message);
        return;
      }
      // console.log(result)
      setReferenceId(result.data.data.ref_id);
      setShowOtp(true);
      Swal.fire(result.data.data.message);
    }
    setLoading(false);
  };

  const handleOtpAadharCardVerify = async () => {
    setLoading(true);
    if (aadharCardNumber === "") {
      Swal.fire("Please enter aadhaar card no");
    } else if (aadharCardNumber.length !== 12) {
      Swal.fire("Please enter valid aadhaar card no and click get code first");
    } else if (otpaadhar === "") {
      Swal.fire("Please enter otp.");
    } else {
      const [error, result] = await asyncWrap(
        axios.post("/verifyKyc/verify-aadhar-otp", {
          ref_id: referenceid,
          otp: otpaadhar,
        })
      );
      if (!result) {
        Swal.fire(error.response.data.data.message);
      } else {
        history.push(`/add-user`, {
          userdata: result.data.data,
          aadharNumber: aadharCardNumber,
          mobileNumber: mobile,
        });
      }
    }
    setLoading(false);
  };

  return (
    <Modal fullscreen={true} show={show}>
      <Modal.Body style={{ marginTop: "30px" }}>
        <div
          style={{ height: "100%", background: "white" }}
          className="p-20 pt-50"
        >
          <p
            className="text-center"
            style={{ fontSize: 30, marginTop: "70px" }}
          >
            <span style={{ paddingBottom: 5 }}>Aadhaar Card Verify</span>{" "}
          </p>

          <div className="text-center mt-5">
            <p>Your Aadhaar Card Number</p>
            <Row
              style={{
                background: "#FAFAFA",
                padding: "10px",
                margin: "10px 0px",
                alignItems: "center",
                justifyContent: "start",
              }}
            >
              <Col className="p-0 d-flex">
                <input
                  type="number"
                  value={aadharCardNumber}
                  style={{
                    background: "transparent",
                    border: "2px solid #CC7E02",
                    padding: "0.2rem",
                    borderRadius: "5px",
                    width: "100%",
                  }}
                  onChange={val => {
                    setAadharCardNumber(val.target.value);
                  }}
                />
              </Col>
            </Row>
            {showOtp ? (
              <Row>
                <Col className="p-0" style={{ color: "#CC7E02" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <OtpInput
                      value={otpaadhar}
                      onChange={val => setOtpAadhar(val)}
                      numInputs={6}
                      isInputNum={true}
                      inputStyle={{
                        width: "38px",
                        textAlign: "center",
                        background: "rgb(250, 250, 250)",
                        margin: "5px",
                        border: "1px solid #CC7E02",
                        padding: "10px",
                        borderRadius: "5px",
                      }}
                    />
                  </div>
                </Col>
              </Row>
            ) : null}

            <Row
              style={{
                background: "#FAFAFA",
                padding: "10px",
                margin: "10px 0px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {showOtp ? (
                <Col className="p-0" style={{ color: "#CC7E02" }}>
                  <Button
                    style={{
                      width: "max-content",
                      background: "#CC7E02",
                      color: "white",
                      border: "none",
                      padding: "0.6rem 2rem",
                      marginTop: "0.5rem",
                    }}
                    onClick={handleOtpAadharCardVerify}
                    disabled={loading}
                  >
                    {loading ? (
                      <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                      "Verify Otp"
                    )}
                  </Button>
                </Col>
              ) : (
                <Col className="p-0" style={{ color: "#CC7E02" }}>
                  <Button
                    disabled={aadharCardNumber.length !== 12 || loading}
                    style={
                      aadharCardNumber.length !== 12
                        ? {
                            width: "max-content",
                            background: "gray",
                            border: "none",
                            padding: "0.6rem 2rem",
                            marginTop: "0.5rem",
                          }
                        : {
                            width: "max-content",
                            background: "#CC7E02",
                            color: "white",
                            border: "none",
                            padding: "0.6rem 2rem",
                            marginTop: "0.5rem",
                          }
                    }
                    onClick={handleAadhaarCard}
                  >
                    {loading ? (
                      <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                      "Send Otp"
                    )}
                  </Button>
                </Col>
              )}
            </Row>
            {loading ? (
              <Row
                style={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  color: "gray",
                }}
              >
                <p>
                  Please wait, We are fetching your details. This may take 2-3
                  minutes
                </p>
              </Row>
            ) : (
              ""
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AadhaarCard;
