const HindiIcons = ({ color }) => (
  <div className="flex flex-col items-center justify-center p-[10px]">
    <svg
      width="49"
      height="39"
      viewBox="0 0 49 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M48.984 7.196H28.6V13.3C30.2427 11.844 32.2213 11.116 34.536 11.116C37.8587 11.116 40.528 12.1613 42.544 14.252C44.5973 16.3053 45.624 18.9373 45.624 22.148C45.624 25.62 44.4667 28.4013 42.152 30.492C39.8373 32.5453 36.8693 33.572 33.248 33.572C32.688 33.572 32.2587 33.5533 31.96 33.516V27.188C32.184 27.2253 32.4827 27.244 32.856 27.244C34.3867 27.244 35.5627 26.7773 36.384 25.844C37.2427 24.9107 37.672 23.716 37.672 22.26C37.672 20.916 37.28 19.7773 36.496 18.844C35.712 17.8733 34.5733 17.388 33.08 17.388C31.7733 17.388 30.6907 17.8733 29.832 18.844C29.0107 19.7773 28.6 21.028 28.6 22.596V38.5H20.648V32.004C19.0427 33.4227 17.064 34.132 14.712 34.132C11.3893 34.132 8.70133 33.1053 6.648 31.052C4.632 28.9613 3.624 26.3107 3.624 23.1C3.624 19.628 4.78133 16.8653 7.096 14.812C9.41067 12.7213 12.3787 11.676 16 11.676C16.5227 11.676 16.952 11.6947 17.288 11.732V18.06C17.064 18.0227 16.7653 18.004 16.392 18.004C14.8613 18.004 13.6667 18.4707 12.808 19.404C11.9867 20.3373 11.576 21.532 11.576 22.988C11.576 24.332 11.968 25.4893 12.752 26.46C13.536 27.3933 14.6747 27.86 16.168 27.86C17.4747 27.86 18.5387 27.3933 19.36 26.46C20.2187 25.4893 20.648 24.22 20.648 22.652V7.196H0.32V0.924H48.984V7.196Z"
        fill={color}
      />
    </svg>
  </div>
);

export default HindiIcons;
