import React, {useState} from 'react'
import { IoIosInformationCircleOutline } from "react-icons/io";
import { Tooltip } from "reactstrap";

function PaymentHeader() {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);
  return (
    <div>
        <div>
        <span>
            <p style={{
                fontSize: "16px",
                fontWeight: "bold",
                margin: "1em 0",
                paddingLeft: "0.8em"
            }}>
                Ledger Balance : 
                <span className='fw-normal'
                style={{fontSize: "16px"}}
                > &#8377; 25,05,206</span>
                
                <span
            id="TooltipExample"
            style={{
                margin: "0em 1em",
            }}
          >
            <IoIosInformationCircleOutline 
            color="red"
            size={22}
            />
          </span>
          <Tooltip
            placement="bottom"
           isOpen={tooltipOpen}
           target="TooltipExample"
           toggle={toggle}
   
          >
            Here's your ledger balance
          </Tooltip>
            <button type='button' className='btn btn-xs btn-danger p-1'>QwikPay</button>
            </p>
            </span>

        </div>
        <div className='d-flex border'>
            <div className='flex-grow-1 py-2'>
                <p className="fw-bold m-0">Due</p>
                <div className='text-white mx-auto mb-0' style={{
                    width: "6.3rem",
                    backgroundColor: "rgb(18, 152, 66)",
                    fontSize: "15px"
                    }}>
                    &#8377;14,94,299
                </div>
            </div>
            <div className='flex-grow-1 py-2 border-start'>
                <p className='fw-bold m-0'>Overdue</p>
                <div className='text-white mx-auto mb-0' style={{
                    width: "6.3rem",
                    backgroundColor: "#E73838",
                    fontSize: "15px"
                    }}>
                    &#8377;14,94,299
                </div>
            </div>
        </div>
    </div>
  )
}

export default PaymentHeader