import { Row, Col } from "react-bootstrap";
import dummy from "/assets/images/rank/dummy.png";
import { AiOutlineArrowDown } from "react-icons/ai";

const ParticipantsCard = (props) => {
  const { data, i } = props;
  return (
    <div>
      <Row className="m10" style={{ background: "#f7e7ce", borderRadius: 10 }}>
        <Col
          xs={2}
          style={{
            display: "flex",
            background: "#DC7832",
            color: "white",
            borderRadius: "10px 0 0 10px",
          }}
        >
          <div style={{ margin: "auto 0", width: "100%" }}>
            <AiOutlineArrowDown icon="arrow-down" /> <p>{i + 1}</p>
          </div>
        </Col>
        <Col xs={2} style={{ display: "flex" }}>
          <div style={{ position: "relative", margin: "auto 0" }}>
            <img
              src={data.ProfilePhotoPath ? data.ProfilePhotoPath : dummy}
              alt=""
              style={{ width: "50px", height: "50px", borderRadius: "50%" }}
            />
          </div>
        </Col>
        <Col xs={5} style={{ paddingBottom: 10, paddingTop: 10 }}>
          <div className="text-left mb5">
            <p className="mb0">{data.FullName}</p>
            <p className="mb5">
              {data.DistrictName} - {data.StateName}
            </p>
            {/* <span className="rank-point">
              <img
                className="rank-img"
                src={require("../../assets/images/rank/star.png").default}
                alt=""
              />
              {data.PointEarned}
            </span> */}
          </div>
        </Col>
        <Col
          xs={3}
          style={{
            display: "flex",
            color: "",
            paddingBottom: 10,
            paddingTop: 10,
          }}
        >
          <div
            className="d-flex flex-column justify-content-center align-items-end"
            style={{ margin: "auto 0", width: "100%" }}
          >
            <p className="m-0">Amount</p>
            <span className="fs-6 fw-bold" style={{ color: "green" }}>
              {/* <img
                className="rank-img"
                src={require("../../assets/images/rank/star.png").default}
                alt=""
              /> */}
              ₹{data.LastBIDValue}
            </span>
            {/* <p className={"like-thumb mb0"}>
              <img
                src={require("../../assets/images/rank/thumb.png").default}
                alt=""
              />
            </p> */}
            {/* <p style={{ paddingRight: 5 }} className="mb0">{data.like}</p> */}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ParticipantsCard;
