import { useEffect, useState } from "react";
import { Badge } from "react-bootstrap";
import { asyncWrap } from "../utils/utils";
import CityDetailModal from "../component/CityDetailModel/CityDetailModal";
import RankCard from "../component/RankCard";
import axios from "axios";
import { useAuth } from "../context/auth-context";
import { useTranslation } from "react-i18next";

const Rank = props => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const [countryData, setCountryData] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [districtData, setDistrictData] = useState([]);

  const getTopUsersInCountry = async () => {
    const [error, result] = await asyncWrap(
      axios({
        method: "get",
        url: "topUsers/country",
      })
    );
    if (!result) {
      console.log(error);
      return;
    }

    setCountryData(result.data.data);
  };

  const getTopUsersInState = async () => {
    const [error, result] = await asyncWrap(
      axios({
        method: "get",
        url: "topUsers/state",
        params: {
          StateID: user?.profile?.StateId || user?.StateId,
        },
      })
    );
    if (!result) {
      console.log(error);
      return;
    }

    setStateData(result.data.data);
  };

  const getTopUsersInDistrict = async () => {
    const [error, result] = await asyncWrap(
      axios({
        method: "get",
        url: "topUsers/district",
        params: {
          DistrictId: user?.profile?.districtId || user?.districtId,
        },
      })
    );
    if (!result) {
      console.log(error);
      return;
    }

    setDistrictData(result.data.data);
  };

  useEffect(() => {
    getTopUsersInCountry();
    getTopUsersInState();
    getTopUsersInDistrict();
    // eslint-disable-next-line
  }, []);

  const optfilter = [
    { title: t("district"), key: 1 },
    { title: t("state"), key: 2 },
    { title: t("country"), key: 3 },
  ];
  const [active, setActive] = useState(1);
  const [show, setShow] = useState(false);

  return (
    <div className="mainPage">
      <CityDetailModal show={show} setShow={setShow} />

      <div className="horizontal-scroll mt-20">
        {optfilter.map(({ title, key }) => (
          <Badge
            key={key}
            onClick={() => setActive(key)}
            pill
            className={key === active ? "rank-badge badge" : "badge"}
            style={{
              background:
                key === active ? "#fad6a5 !important" : "white !important",
            }}
          >
            {title}
          </Badge>
        ))}
      </div>

      {active === 1 && <RankCard data={districtData} />}

      {active === 2 && <RankCard data={stateData} />}

      {active === 3 && <RankCard data={countryData} />}
    </div>
  );
};

export default Rank;
