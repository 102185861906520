// import SecoundaryHeader from "component/Nav/SecoundaryHeader";
import { Card, Image, Modal } from "react-bootstrap";
import scoreboard from "/assets/images/PredictionImage/scoreboard.svg";

const ScoreboardPrediction = ({ show, setShow }) => {
  return (
    <>
      <Modal
        fullscreen={true}
        show={show}
        style={{ backgroundColor: "white !important" }}
        onHide={() => setShow(false)}
      >
        <Modal.Body className="d-flex flex-column mt-4">
          <div>
            <Card
              style={{
                background: "#308753",
                color: "white",
                borderRadius: "12px",
                width: "100%",
              }}
            >
              <Card.Body>
                <div
                  style={{ borderBottom: "3px dotted white" }}
                  className="pb-3 d-flex flex-row justify-content-between"
                >
                  <div style={{ fontSize: "14px", fontWeight: "600" }}>
                    Prediction
                  </div>
                  <div style={{ fontSize: "14px", fontWeight: "600" }}>Won</div>
                  <div style={{ fontSize: "14px", fontWeight: "600" }}>
                    Loss
                  </div>
                </div>

                <div
                  style={{ paddingLeft: "23px", paddingRight: "16px" }}
                  className="mt-3 d-flex flex-row justify-content-between"
                >
                  <div style={{ fontSize: "14px", fontWeight: "600" }}>11</div>
                  <div style={{ fontSize: "14px", fontWeight: "600" }}>5</div>
                  <div style={{ fontSize: "14px", fontWeight: "600" }}>6</div>
                </div>
              </Card.Body>
            </Card>
            <div
              className="mt-3"
              style={{
                alignItems: "end",
                paddingLeft: "12px",
                paddingRight: "12px",
                width: "100%",
              }}
            >
              <div
                style={{
                  fontSize: "16px",
                  color: "#0F519F",
                  fontWeight: "600",
                }}
              >
                Prediction History
              </div>
            </div>
            <div
              className="d-flex flex-row justify-content-between mt-2"
              style={{
                borderBottom: "2px solid #d9d9d9",
                fontSize: "14px",
                fontWeight: "400",
                paddingBottom: "10px",
              }}
            >
              <div>Match</div>
              <div style={{ paddingRight: "3rem" }}>Result</div>
            </div>
          </div>
          <div
            style={{ marginTop: "22px" }}
            className="d-flex flex-row justify-content-between align-items-center"
          >
            <div>
              <span style={{ fontSize: "12px", fontWeight: "400" }}>
                12th May,2022
              </span>
              <br></br>
              <span style={{ fontSize: "16px", fontWeight: "400" }}>
                IND vs SL
              </span>
            </div>
            <Image src={scoreboard} />
            <div
              style={{ fontSize: "14px", fontWeight: "600", color: "#0BB76F" }}
            >
              You Won
            </div>
          </div>
          <div
            style={{ marginTop: "22px" }}
            className="d-flex flex-row justify-content-between align-items-center"
          >
            <div>
              <span style={{ fontSize: "12px", fontWeight: "400" }}>
                12th May,2022
              </span>
              <br></br>
              <span style={{ fontSize: "16px", fontWeight: "400" }}>
                IND vs SL
              </span>
            </div>
            <Image src={scoreboard} />
            <div
              style={{ fontSize: "14px", fontWeight: "600", color: "#0BB76F" }}
            >
              You Won
            </div>
          </div>
          <div
            style={{ marginTop: "22px" }}
            className="d-flex flex-row justify-content-between align-items-center"
          >
            <div>
              <span style={{ fontSize: "12px", fontWeight: "400" }}>
                12th May,2022
              </span>
              <br></br>
              <span style={{ fontSize: "16px", fontWeight: "400" }}>
                IND vs SL
              </span>
            </div>
            <Image src={scoreboard} />
            <div
              style={{ fontSize: "14px", fontWeight: "600", color: "#FF5757" }}
            >
              You Loss
            </div>
          </div>
          <div
            style={{ marginTop: "22px" }}
            className="d-flex flex-row justify-content-between align-items-center"
          >
            <div>
              <span style={{ fontSize: "12px", fontWeight: "400" }}>
                12th May,2022
              </span>
              <br></br>
              <span style={{ fontSize: "16px", fontWeight: "400" }}>
                IND vs SL
              </span>
            </div>
            <Image src={scoreboard} />
            <div
              style={{ fontSize: "14px", fontWeight: "600", color: "#0BB76F" }}
            >
              You Won
            </div>
          </div>
          <div
            style={{ marginTop: "22px" }}
            className="d-flex flex-row justify-content-between align-items-centerz"
          >
            <div>
              <span style={{ fontSize: "12px", fontWeight: "400" }}>
                12th May,2022
              </span>
              <br></br>
              <span style={{ fontSize: "16px", fontWeight: "400" }}>
                IND vs SL
              </span>
            </div>
            <Image src={scoreboard} />
            <div
              style={{ fontSize: "14px", fontWeight: "600", color: "#0BB76F" }}
            >
              You Won
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ScoreboardPrediction;
