import React from 'react'

function PaymentTable() {
    const data = [
        {

        }
    ]

    return (
        <div style={{margin: "0"}}>
    <table className="table table-sm" style={{margin: "0"}} >
      <thead>
        <tr>
          <th rowSpan={2} scope="col" style={{
            backgroundColor: "#ADAEB0", 
            border: "2px solid white", 
            minWidth: "calc(36vw - 2px)",
            verticalAlign: "middle"
          }} >
            Division
            </th>
          <th scope="col" style={{
            backgroundColor: "#ADAEB0",  
            
            minWidth: "calc(32vw - 2px)"
          }} >
            Due
            </th>
          <th rowSpan={2} scope="col" style={{
            backgroundColor: "#ADAEB0", 
            border: "2px solid white", 
            minWidth: "calc(32vw - 2px)",
            verticalAlign: "middle"
          }} >
            Total
            </th>
        </tr>
        <tr>
          <th scope="col" style={{
            backgroundColor: "#ADAEB0", 
            borderTop: "2px solid #ADAEB0",
            minWidth: "calc(32vw - 2px)",
          }} >
            Over Due
            </th>
        </tr>
      </thead>
      <tbody>
          <>
              <tr>
                <td
                rowSpan={2}
                style={{backgroundColor: "#E9EAEB", 
                border: "2px solid white", 
                minWidth: "calc(36vw - 2px)",
                verticalAlign: "middle"
                }}>
                  Lights
                </td>
                <td
                style={{backgroundColor: "#E9EAEB", 
                border: 0, 
                minWidth: "calc(32vw - 2px)",
                }}>
                  ₹ 8,35,772
                </td>
                <td
                rowSpan={2}
                style={{backgroundColor: "#E9EAEB", 
                border: "2px solid white", 
                minWidth: "calc(36vw - 2px)",
                verticalAlign: "middle"
                }}>
                  ₹ 8,35,772
                </td>
            </tr>
              <tr>
                <td
                style={{backgroundColor: "#E9EAEB", 
                borderTop: "2px solid #E9EAEB", 
                minWidth: "calc(32vw - 2px)",
                }}>
                  ₹ 8,35,772
                </td>
            </tr>
            <tr>
                <td
                rowSpan={2}
                style={{backgroundColor: "#E9EAEB", 
                border: "2px solid white", 
                minWidth: "calc(36vw - 2px)",
                verticalAlign: "middle"
                }}>
                  Mcs & Dbs
                </td>
                <td
                style={{backgroundColor: "#E9EAEB", 
                border: 0, 
                minWidth: "calc(32vw - 2px)",
                }}>
                  ₹ 8,35,772
                </td>
                <td
                rowSpan={2}
                style={{backgroundColor: "#E9EAEB", 
                border: "2px solid white", 
                minWidth: "calc(36vw - 2px)",
                verticalAlign: "middle"
                }}>
                  ₹ 8,35,772
                </td>
            </tr>
              <tr>
                <td
                style={{backgroundColor: "#E9EAEB", 
                borderTop: "2px solid #E9EAEB", 
                minWidth: "calc(32vw - 2px)",
                }}>
                  ₹ 8,35,772
                </td>
            </tr>
            <tr>
                <td
                rowSpan={2}
                style={{backgroundColor: "#E9EAEB", 
                border: "2px solid white", 
                minWidth: "calc(36vw - 2px)",
                verticalAlign: "middle"
                }}>
                  Wire & Cable
                </td>
                <td
                style={{backgroundColor: "#E9EAEB", 
                border: 0, 
                minWidth: "calc(32vw - 2px)",
                }}>
                  ₹ 8,35,772
                </td>
                <td
                rowSpan={2}
                style={{backgroundColor: "#E9EAEB", 
                border: "2px solid white", 
                minWidth: "calc(36vw - 2px)",
                verticalAlign: "middle"
                }}>
                  ₹ 8,35,772
                </td>
            </tr>
              <tr>
                <td
                style={{backgroundColor: "#E9EAEB", 
                borderTop: "2px solid #E9EAEB", 
                minWidth: "calc(32vw - 2px)",
                }}>
                  ₹ 8,35,772
                </td>
            </tr>
            <tr>
                <td
                rowSpan={2}
                style={{backgroundColor: "#E9EAEB", 
                border: "2px solid white", 
                minWidth: "calc(36vw - 2px)",
                verticalAlign: "middle"
                }}>
                  Wiring Devices
                </td>
                <td
                style={{backgroundColor: "#E9EAEB", 
                border: 0, 
                minWidth: "calc(32vw - 2px)",
                }}>
                  ₹ 8,35,772
                </td>
                <td
                rowSpan={2}
                style={{backgroundColor: "#E9EAEB", 
                border: "2px solid white", 
                minWidth: "calc(36vw - 2px)",
                verticalAlign: "middle"
                }}>
                  ₹ 8,35,772
                </td>
            </tr>
              <tr>
                <td
                style={{backgroundColor: "#E9EAEB", 
                borderTop: "2px solid #E9EAEB", 
                minWidth: "calc(32vw - 2px)",
                }}>
                  ₹ 8,35,772
                </td>
            </tr>
            </>
      </tbody>
    </table>
        </div>
      )
}

export default PaymentTable