import React from "react";
import { IoCloseSharp } from "react-icons/io5";
import { useHistory } from "react-router";

function CloseModalTab({onclick}) {
  const history = useHistory();

  const handleClostBtn = () => {
    history.push("/home")
  }
  return (
    <div className="text-end">
      <IoCloseSharp
        onClick={onclick || handleClostBtn}
        style={{ width: "25px", height: "30px", color: "gray" }}
      />
    </div>
  );
}

export default CloseModalTab;
