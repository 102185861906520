import React from 'react'
import { Card } from 'react-bootstrap';

function Catatogue() {
  const catalogue = [
    {
      brandName: 'Gold Medal',
      rangeName: 'Led Catalogue',
      effectiveFrom: '31-03-2020',
      logo: 'https://rukminim2.flixcart.com/image/416/416/xif0q/ceiling-lamp/r/c/w/-original-imaggy7mf57wpzfd.jpeg', 
    },
    {
      brandName: 'Gold Medal',
      rangeName: 'Led Catalogue',
      effectiveFrom: '31-03-2020',
      logo: 'https://rukminim2.flixcart.com/image/416/416/xif0q/ceiling-lamp/r/c/w/-original-imaggy7mf57wpzfd.jpeg',
    },
  
  ];
  return (
    <div className="d-flex flex-column align-items-center">
      {catalogue.map((catalogue, index) => (
        <Card key={index} style={{ width: '90vw', marginBottom: '1rem',display: 'flex', flexDirection: 'row', backgroundColor: '#E9EAEB' }}>
          <div style={{ width: '40%', padding: '0 2px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <img src={catalogue.logo} alt="Logo" style={{ width: '25vw', height: '15vh', marginLeft:'1rem'}} />
          </div>
          <div style={{ width: '60%', backgroundColor: '#E9EAEB', textAlign: 'left', margin:'1em 1.5em', padding:'1px 0' }}>
            <div style={{ paddingTop: '1px'}}>
              <strong>Brand Name:</strong><br/>{catalogue.brandName}
            </div>
            <div style={{ paddingTop: '1px'}}>
              <strong>Range Name:</strong><br/>{catalogue.rangeName}
            </div>
            <div style={{ paddingTop: '1px'}}>
              <strong>Effective From:</strong><br/>{catalogue.effectiveFrom}
            </div>
          </div>
        </Card>
      ))}
    </div>
  );
}

export default Catatogue