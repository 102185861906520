import { Label, Input } from "reactstrap";
import { Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import { asyncWrap } from "../utils/utils";
import axios from "axios";
import Swal from "sweetalert2";
import ReadQr from "./ReadQr";
import ScanDetails from "../component/Scan/ScanDetails";
import { useTranslation } from "react-i18next";

const ScanCode = () => {
  const { t } = useTranslation();
  const [qrCode, setQrCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [lat, setLat] = useState();
  const [long, setLong] = useState();
  const [scanShow, setScanShow] = useState(false);
  const [scanData, setScanData] = useState([]);

  // const RandomNumberLuckyDraw = () => {
  //   const random = Math.floor(Math.random() * (999999 - 100000)) + 100000;
  //   return random;
  // };

  const OnSubmit = async () => {
    if (qrCode.length === 0) {
      Swal.fire("Please Scan properly or input qr code!");
      return;
    }
    setLoading(true);
    let data = {
      code: qrCode,
      longitude: long || "",
      latitude: lat || "",
    };
    setScanData([]);
    //eslint-disable-next-line
    const [error, result] = await asyncWrap(axios.post("/scanCode", data));
    if (!result) {
      console.log("code scanning failed!");
    }
    console.log("Code", result.data);
    Swal.fire(result.data.message);
    setLoading(false);

    //Scan details modal will only open on scan success
    if (result.data.code === 1) {
      setScanShow(true);
      setScanData(result.data);
    }
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      setLat(position.coords.latitude);
      setLong(position.coords.longitude);
    });
  }, []);

  return (
    <div>
      {scanData && (
        <ScanDetails show={scanShow} setShow={setScanShow} data={scanData} />
      )}
      <ReadQr setQrCode={setQrCode} />

      <Label>
        {t("qr_code")}
        <Input
          placeholder={t("enter_qr_code")}
          value={qrCode}
          onChange={(e) => setQrCode(e.target.value)}
        />
      </Label>

      <div style={{ marginTop: "20px", textAlign: "left" }}>
        <Button
          disabled={loading}
          onClick={() => {
            OnSubmit();
          }}
          style={{
            width: "50%",
            marginBottom: "50px",
            marginLeft: "25%",
            background: "#F8D6A5",
            color: "black",
            borderRadius: "20px",
            border: "1px solid #f8d6a5",
          }}
        >
          {t("proceed")}
        </Button>
      </div>
    </div>
  );
};

export default ScanCode;
