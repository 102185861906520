import { PassQuizPageModal } from "../../context/QuizContext";
import { useContext, useEffect, useState } from "react";
import { Modal, Nav, Row, Col } from "react-bootstrap";
import { asyncWrap } from "../../utils/utils";
import close from "/assets/images/svgicon/close.svg";
import Frames from "./Frames";
import QuizTopicCard from "./QuizTopicCard";
import axios from "axios";
import { useAuth } from "../../context/auth-context";

const LeaderboardAfterQuiz = ({ show, setShow }) => {
  const { user } = useAuth();
  const setQuizShow = useContext(PassQuizPageModal);
  const [leaderBoardData, setLeaderBoardData] = useState([]);
  const [quizUserData, setQuizUserData] = useState([]);

  const GetLeaderboardData = async () => {
    const [error, result] = await asyncWrap(axios.get("quiz/leader-board"));
    if (!result) {
      console.log(error);
      return;
    }
    setLeaderBoardData(result.data.data);

    setQuizUserData(
      result.data.data?.filter((data) => data.UserId === user?.profile?.userId || user.userId)
    );
  };

  useEffect(() => {
    GetLeaderboardData();
    //eslint-disable-next-line
  }, [show]);

  return (
    <>
      <Modal fullscreen={true} show={show} onHide={() => setShow(false)}>
        <Modal.Header
          style={{
            border: 0,
            display: "flex",
            flexDirection: "row-reverse",
            marginBottom: 20,
          }}
        >
          <Nav className="navbar-nav ms-auto fixed-top navbar-light ">
            <Row className="pt10 text-end">
              <Col className="text-end">
                <img
                  onClick={() => {
                    setShow(false);
                    setQuizShow(false);
                    console.log("setQuiz", setQuizShow);
                  }}
                  src={close}
                  alt="act"
                  style={{ marginRight: " 21px", width: "14px" }}
                />
              </Col>
            </Row>
          </Nav>
        </Modal.Header>
        <Modal.Body className="pt-0 pb-0">
          <QuizTopicCard userPointData={quizUserData} />
          <div
            className=""
            style={{
              background: "#8D69F9",
              color: "white",
              borderRadius: "15px 15px 0px 0px",
              paddingBottom: "10px",
            }}
          >
            <p
              style={{
                margin: "56px auto 19px 31px",
                fontSize: "16px",
                fontWeight: "500",
                paddingTop: "31px",
              }}
            >
              4/10 Players completed
            </p>
            <Row
              className="mt-2 d-flex flex-row"
              style={{ marginLeft: "30px", marginRight: "30px" }}
            >
              <Col
                xs={2}
                className="m-auto"
                style={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  paddingRight: "0",
                }}
              >
                <span>Rank</span>
              </Col>
              <Col
                xs={7}
                className="m-auto"
                style={{
                  fontSize: "12px",
                  fontWeight: "bold",
                }}
              >
                <span>Name</span>
              </Col>
              <Col
                xs={3}
                className="m-auto"
                style={{
                  paddingLeft: "30px",
                  paddingRight: "0px",
                  fontSize: "12px",
                  fontWeight: "bold",
                }}
              >
                <span>Points</span>
              </Col>
            </Row>
            {leaderBoardData?.map((data, index) => (
              <Frames key={index} {...data} />
            ))}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LeaderboardAfterQuiz;
