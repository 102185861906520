import React, { useState } from 'react'
import SalesInfoHeader from '../component/SalesInfoHeader/SalesInfoHeader'
import ProductAgreement from '../component/ProductAgreement/ProductAgreement'

function Agreement() {
  const [activeHeaderTab, setActiveHeaderTab] = useState(1)

  const headerData = [
    {
        id: 1,
        icon: "/assets/images/salesInfo/sales.svg",
        width: "24px"
    },
    {
        id: 2,
        icon: "/assets/images/salesInfo/orders.svg",
        width: "24px"
    },
    {
        id: 3,
        icon: "/assets/images/salesInfo/payment.svg",
        width: "24px"
    },
    {
        id: 4,
        icon: "/assets/images/salesInfo/sales.svg",
        width: "24px"
    }
]
  return (
    <div>
      <SalesInfoHeader
      data={headerData}
      active={activeHeaderTab}
      setActive={setActiveHeaderTab}
      />

      {activeHeaderTab === 1 && <ProductAgreement />}
    </div>
  )
}

export default Agreement