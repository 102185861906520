import React, { useState } from "react";
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";
import LuckyDrawDetail from "./LuckyDrawDetail";

function LusckyDrawCard() {
  const [showLuckyDrawDetail, setShowLuckyDrawDetail] = useState(false);

  return (
    <>
      <LuckyDrawDetail
        show={showLuckyDrawDetail}
        setShow={setShowLuckyDrawDetail}
      />
      <Card className="mt-2" onClick={() => setShowLuckyDrawDetail(true)}>
        <CardHeader>Card Header Name</CardHeader>
        <CardBody>
          <CardTitle>End Date : 21/1/2023</CardTitle>
          <CardTitle>Your code : 145678</CardTitle>
        </CardBody>
      </Card>
    </>
  );
}

export default LusckyDrawCard;
