import React from 'react'
import { Card } from 'react-bootstrap';

function Document() {
  const documents = [
    {
      name: 'GBLC Discover the world',
      fromDate: '01-04-2019',
      toDate: '31-03-2020',
      logo: "" 
    },
    
  ];
  return (
    <div className="d-flex flex-column align-items-center">
      {documents.map((document, index) => (
        <Card key={index} style={{ width: '90vw', marginBottom: '0.9rem',display: 'flex', flexDirection: 'row', backgroundColor: '#E9EAEB' }}>
          <div style={{ width: '40%', padding: '0 2px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            {document.logo ? (
              <img src={document.logo} alt="Logo" style={{ width: '25vw', height: '15vh', marginLeft: '1rem' }} />
            ) : (
              <div style={{ width: '28vw', height: '20vh',display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor:'#fff',marginLeft: '0.9rem' }}>
                <span>No Image</span>
              </div>
            )}
          </div>
          <div style={{ width: '60%', backgroundColor: '#E9EAEB', textAlign: 'left', margin:'1em 1.5em' }}>
            <div style={{ paddingBottom: '1px', color: 'black' }}>
              <strong>Scheme Name:</strong><br/>{document.name}
            </div>
            <div style={{ paddingBottom: '1px', color: 'black' }}>
              <strong>From Date:</strong><br/>{document.fromDate}
            </div>
            <div style={{ color: 'black' }}>
              <strong>To Date:</strong><br/>{document.toDate}
            </div>
          </div>
        </Card>
      ))}
    </div>
  );
}

export default Document