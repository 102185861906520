// import SecoundaryHeader from "component/Nav/SecoundaryHeader";
import React from "react";
import { Modal } from "react-bootstrap";
import EBidCard from "./EBidCard";

const EBidProductDetail = ({ show, setShow, data }) => {
  return (
    <Modal fullscreen={true} show={show} onHide={() => setShow(false)}>
      <Modal.Body className="mb-20 p-0 text-center">
        <div className="d-flex justify-content-center mt-4 flex-column">
          <EBidCard data={data} />
        </div>
        <div className="mt-2 text-left ps-5">
          <p className="fs-4 fw-bold mb-0">Product Name</p>
          <span className="ps-0 fs-5">{data.SkuName}</span>
          <p className="fs-4 fw-bold mb-0">Description</p>
          <span className="ps-0 fs-6">{data.Descriptions}</span>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default EBidProductDetail;
