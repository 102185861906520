import React, { useEffect, useState, useContext } from "react";
import {
  Modal,
  Row,
  Col,
  ProgressBar,
  Card,
  Button,
  Image,
} from "react-bootstrap";
import { FiPause, FiPlay } from "react-icons/fi";
import readloud from "/assets/images/Quiz/readloud.svg";
import sound from "/assets/images/Quiz/music.svg";
import AnswerPage from "./AnswerPage";
import LeaderboardAfterQuiz from "./LeaderBoardAfterQuiz";
import { asyncWrap } from "../../utils/utils";
import axios from "axios";
import { UsersPointsData } from "../../context/QuizContext";
import { useTranslation } from "react-i18next";

const StartQuiz = ({ show, setShow, quizId }) => {
  const { t } = useTranslation();
  const [play, setPlay] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [active, setActive] = useState(null);
  const [time, setTime] = useState();
  const [totalTime, setTotalTime] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [correctAnswer, setCorrectAnswer] = useState();
  const [showAnswerPage, setShowAnswerPage] = useState(false);
  const [showLeaderBoardAfterQuiz, setShowLeaderBoardAfterQuiz] =
    useState(false);
  const [interval, setTimeInterval] = useState();
  const UsersPointData = useContext(UsersPointsData);

  var newtime;
  const getQuestions = async () => {
    if (show) {
      const [error, result] = await asyncWrap(
        axios.get(`quiz-questions?quizId=${quizId}`)
      );
      if (!result) {
        console.log(error);
      }
      setQuestions(result.data.data);
      setTotalTime(result.data.data[currentQuestion]?.Time);
      setTime(result.data.data[currentQuestion]?.Time);
      newtime = +result.data.data[currentQuestion]?.Time;
      setTimer();
    }
  };

  const checkAnswer = (quizquestionid) => {
    clearInterval(interval);
    setTotalTime(0);
    setTime(0);
    const answer = questions.filter(
      (item) => item.QuizQuestionID === quizquestionid
    );
    if (answer[0].CorrectAnswer === selectedOption) {
      setCorrectAnswer(true);
      setShowAnswerPage(true);
    } else {
      setCorrectAnswer(false);
      setShowAnswerPage(true);
    }
    if (questions.length === currentQuestion + 1) {
      setShow(false);
    }
  };

  const toggle = (position) => {
    if (active === position) {
      setActive(null);
    } else {
      setActive(position);
    }
  };

  const myColor = (position) => {
    if (active === position) {
      return "#FEA965";
    }
    return "#8D69F9";
  };

  const skip = () => {
    clearInterval(interval);
    setTotalTime(0);
    setTime(0);
    if (questions.length !== currentQuestion + 1) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      setShow(false);
      setShowLeaderBoardAfterQuiz(true);
      setCurrentQuestion(0);
    }
  };

  const setTimer = () => {
    if (show) {
      const timer = setInterval(() => {
        if (newtime === 0) {
          clearInterval(timer);
          setTotalTime(0);
          setShowAnswerPage(true);
        } else {
          newtime--;
          setTime(newtime);
        }
      }, 1000);
      setTimeInterval(timer);
    }
  };

  useEffect(() => {
    if (show) {
      if (questions.length > 0) {
        setTotalTime(questions[currentQuestion]?.Time);
        setTime(questions[currentQuestion]?.Time);
        //eslint-disable-next-line
        newtime = +questions[currentQuestion]?.Time;
        setTimer();
      }
      setCorrectAnswer(false);
      setSelectedOption(null);
      setActive(false);
      setShowAnswerPage(false);
    }
    //eslint-disable-next-line
  }, [currentQuestion]);

  useEffect(() => {
    getQuestions();
    // eslint-disable-next-line
  }, [show]);

  return (
    <>
      <AnswerPage
        quizId={quizId}
        show={showAnswerPage}
        setShow={setShowAnswerPage}
        currentQuestion={currentQuestion}
        questions={questions}
        correctAnswer={correctAnswer}
        selectedOption={selectedOption}
        setCurrentQuestion={setCurrentQuestion}
      />
      <LeaderboardAfterQuiz
        show={showLeaderBoardAfterQuiz}
        setShow={setShowLeaderBoardAfterQuiz}
      />
      <Modal fullscreen={true} show={show} onHide={() => setShow(false)}>
        {/* <Modal.Header> */}
          {/* <SecoundaryHeader sideHide="false" onBack={() => setShow(false)} /> */}
        {/* </Modal.Header> */}
        <Modal.Body>
          <Row
            style={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <Col
              xs={2}
              style={{
                background: "#8D69F9",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "10px",
              }}
            >
              {play ? (
                <FiPlay
                  style={{ fontSize: "30px", color: "#fff" }}
                  onClick={() => setPlay(false)}
                />
              ) : (
                <FiPause
                  style={{ fontSize: "30px", color: "#fff" }}
                  onClick={() => setPlay(true)}
                />
              )}
            </Col>
            <Col
              xs={2}
              style={{
                background: "#D288FF",
                borderRadius: "10px",
                color: "#fff",
              }}
            >
              <span style={{ fontSize: "30px" }}>{currentQuestion + 1}</span>/
              {questions.length}
              <p style={{ fontSize: "12px", fontWeight: "400" }}>
                {t("question")}
              </p>
            </Col>
            {/* <Col
              xs={2}
              style={{
                background: "#D288FF",
                borderRadius: "10px",
                color: "#fff",
              }}
            >
              <span style={{ fontSize: "30px" }}>5</span>/15
              <p style={{ fontSize: "12px", fontWeight: "400" }}>Avg.Time</p>
            </Col> */}
            <Col
              xs={2}
              style={{
                background: "#D288FF",
                borderRadius: "10px",
                color: "#fff",
              }}
            >
              <span style={{ fontSize: "30px" }}>
                {UsersPointData[0]?.Rank}
              </span>
              <p style={{ fontSize: "12px", fontWeight: "400" }}>
                {t("rank")}
              </p>
            </Col>
            <Col
              xs={2}
              style={{
                background: "#D288FF",
                borderRadius: "10px",
                color: "#fff",
              }}
            >
              <span style={{ fontSize: "30px" }}>
                {UsersPointData[0]?.Points}
              </span>
              <p style={{ fontSize: "12px", fontWeight: "400" }}>
                {t("points")}
              </p>
            </Col>
          </Row>
          <Row style={{ marginTop: "10px" }}>
            <Col stle={{ display: "flex", justifyContent: "" }}>
              <ProgressBar striped={true} now={(time / totalTime) * 100} />
            </Col>
          </Row>
          {questions.length === 0 && (
            <>
              <Row style={{ marginTop: "20px" }}>
                <Col>
                  <Card
                    style={{
                      height: "250px",
                      borderRadius: "20px",
                      backgroundColor: "#CEBDFF",
                    }}
                  >
                    <Card.Body
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: "#000000",
                          fontSize: "20px",
                          fontWeight: "700",
                        }}
                      >
                        {t("something_wrong")}
                      </p>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </>
          )}
          {questions.length !== 0 && currentQuestion <= questions.length ? (
            <>
              <Row style={{ marginTop: "20px" }}>
                <Col>
                  <Card
                    style={{
                      height: "250px",
                      borderRadius: "20px",
                      backgroundColor: "#CEBDFF",
                    }}
                  >
                    <Card.Body
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: "#000000",
                          fontSize: "20px",
                          fontWeight: "700",
                        }}
                      >
                        {questions[currentQuestion].Question}
                      </p>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row style={{ marginTop: "20px" }}>
                <Col
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    onClick={() => {
                      toggle(0);
                      setSelectedOption(questions[currentQuestion].Option1);
                    }}
                    style={{
                      width: "80%",
                      borderRadius: "10px",
                      background: myColor(0),
                    }}
                  >
                    {questions[currentQuestion].Option1}
                  </Button>
                </Col>
              </Row>
              <Row style={{ marginTop: "20px" }}>
                <Col
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    onClick={() => {
                      toggle(1);
                      setSelectedOption(questions[currentQuestion].Option2);
                    }}
                    style={{
                      width: "80%",
                      borderRadius: "10px",
                      background: myColor(1),
                    }}
                  >
                    {questions[currentQuestion].Option2}
                  </Button>
                </Col>
              </Row>
              <Row style={{ marginTop: "20px" }}>
                <Col
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    onClick={() => {
                      toggle(2);
                      setSelectedOption(questions[currentQuestion].Option3);
                    }}
                    style={{
                      width: "80%",
                      borderRadius: "10px",
                      background: myColor(2),
                    }}
                  >
                    {questions[currentQuestion].Option3}
                  </Button>
                </Col>
              </Row>
              <Row style={{ marginTop: "20px" }}>
                <Col
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    onClick={() => {
                      toggle(3);
                      setSelectedOption(questions[currentQuestion].Option4);
                    }}
                    style={{
                      width: "80%",
                      borderRadius: "10px",
                      background: myColor(3),
                    }}
                  >
                    {questions[currentQuestion].Option4}
                  </Button>
                </Col>
              </Row>
            </>
          ) : null}
          <Row style={{ marginTop: "20px" }}>
            <Col>
              <Image src={readloud} alt="" />
              <Image src={sound} alt="" style={{ marginLeft: "20px" }} />
            </Col>
            <Col style={{ display: "flex", justifyContent: "center" }}>
              <Button
                disabled={!questions.length}
                onClick={() => skip()}
                style={{
                  width: "80px",
                  height: "40px",
                  background: "#fff",
                  border: "2px solid #8D69F9",
                  color: "#8D69F9",
                  fontWeight: "500",
                  borderRadius: "20px",
                }}
              >
                {t("skip")}
              </Button>
              <Button
                disabled={!selectedOption}
                onClick={() => {
                  checkAnswer(questions[currentQuestion].QuizQuestionID);
                }}
                style={{
                  width: "80px",
                  height: "40px",
                  background: "#8D69F9",
                  color: "#fff",
                  fontWeight: "500",
                  marginLeft: "10px",
                  borderRadius: "20px",
                }}
              >
                {t("submit")}
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default StartQuiz;
