import { Modal } from "react-bootstrap";
const Banner = ({ show, setShow, data }) => {
  return (
    <>
      <Modal fullscreen={true} show={show}>
        <Modal.Body>
          <div className="d-flex flex-column">
            {data &&
              data.HomePageBanner.map((item, index) => (
                <div
                  key={index}
                  onClick={() => {
                    const newWindow = window.open(
                      item?.PDFPath,
                      "_blank",
                      "noopener,noreferrer"
                    );
                    if (newWindow) newWindow.opener = null;
                  }}
                  style={{ borderRadius: "20px" }}
                  className="d-flex flex-column border mt-3"
                >
                  <div
                    style={{
                      height: "150px",
                      background: "#E5ECFD",
                      borderRadius: "15px",
                    }}
                    className="d-flex justify-content-center"
                  >
                    <img
                      alt="bannerimg"
                      style={{
                        maxWidth: "100%",
                        height: "100%",
                      }}
                      src={item.BannerImage}
                    />
                  </div>
                </div>
              ))}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Banner;
