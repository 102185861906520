import React, { useState } from 'react'
import PaymentOutstandingCard from '../component/PaymentOutstandingCard/PaymentOutstandingCard'

function PaymentOutstanding() {
  const [division, setDivision] = useState("default")
  const [outStanding, setOutStanding] = useState("default")

  const handleDivision = (e) => {
    setDivision(e.target.value)
  }

  const handleOutStanding = (e) => {
    setOutStanding(e.target.value)
  }

  return (
    <div>
      <div className='d-flex mx-3 gap-4 justify-content-center'>
      {/* Division select start */}
      <div className='flex-grow-1'>
      <p className='mb-2 fw-bold'>Division *</p>
    <select style={{
      border: "0",
      outline: "1px solid",
      padding: "1em 6px 1em 10px",
      borderRight: "8px solid transparent",
      maxWidth: "200px",
      fontSize: "16px",
      width: "100%"
      }} 
      className='rounded fw-bold'
      value={division}
      onChange={handleDivision}
      >
      <option value="default" disabled >ALL</option>
      <option value="january">DIVISION1</option>
      <option value="february">DIVISION2</option>
      <option value="march">DIVISION3</option>
      <option value="april">DIVISION4</option>
    </select>

    <div>
        <p className='m-0 mt-3 fw-bold'>DUE</p>
        <p>₹ 10,34,737</p>
      </div>
    </div>
    {/* Division select end */}

      {/* Quarterly select start */}
      <div className='flex-grow-1'>
      <p className='mb-2 fw-bold'>OutStanding *</p>
    <select style={{
      border: "0",
      outline: "1px solid",
      padding: "1em 6px 1em 10px",
      borderRight: "8px solid transparent",
      maxWidth: "200px",
      fontSize: "16px",
      width: "100%"
      }} 
      className='rounded fw-bold flex-grow-1'
      value={outStanding}
      onChange={handleOutStanding}
      >
      <option value="default" disabled >ALL</option>
      <option value="q1">BELOW 15</option>
      <option value="q2">BELOW 30</option>
      <option value="q3">BELOW 45</option>
      <option value="q4">BELOW 60</option>
    </select>

    <div>
        <p className='m-0 mt-3 fw-bold'>OVERDUE</p>
        <p>₹ 10,34,737</p>
      </div>
    </div>
    {/* Quarterly select end */}
    </div>

    <PaymentOutstandingCard />
    <PaymentOutstandingCard />
    <PaymentOutstandingCard />
    <PaymentOutstandingCard />
    </div>
  )
}

export default PaymentOutstanding