import React, { useEffect, useState } from "react";
import { asyncWrap } from "../../utils/utils";
import axios from "axios";
import OrderOverview from "./OrderOverview";

function OrderList() {
  const [orderList, setOrderList] = useState([]);
  const [orderProductData, setOrderProductData] = useState([]);
  const [showOrderOverview, setShowOrderOverview] = useState(false);
  const [orderData, setOrderData] = useState();

  useEffect(() => {
    getOrderListData();
  }, []);
  const getOrderListData = async () => {
    const [error, result] = await asyncWrap(axios.get("/order"));
    if (!result) {
      console.log(error);
      return;
    }
    setOrderList(result.data.data);
  };

  const getOrderProductData = async orderId => {
    const [error, result] = await asyncWrap(
      axios.get("/order/products", { params: { orderId: orderId } })
    );
    if (!result) {
      console.log(error);
      return;
    }
    setOrderProductData(result.data?.data);
    console.log(result.data.data);
    setShowOrderOverview(true);
  };

  return (
    <>
      <OrderOverview
        show={showOrderOverview}
        setShow={setShowOrderOverview}
        productData={orderProductData}
        orderData={orderData}
      />
      <div className="p-2">
        <div className="my-3">
          <input
            className="input-grey-rounded"
            type="text"
            placeholder="Search"
          ></input>
        </div>

        <div className="d-flex flex-column">
          {orderList.map((item, index) => (
            <div
              key={index}
              onClick={() => {
                setOrderData(item);
                getOrderProductData(item.OrderID);
              }}
              className="d-flex flex-column rounded-4 p-2 text-start mt-2"
              style={{ background: "#dddddd" }}
            >
              <div className="d-flex flex-row">
                <img
                  src="https://cdn-media.buildersmart.in/media/catalog/product/cache/1/image/9df78eab33525d08d6e5fb8d27136e95/e/l/elwicarrk0028.png"
                  width="100px"
                  className="shadow"
                  style={{ height: "fit-content", borderRadius: "10px" }}
                />
                <div
                  className="d-flex flex-column "
                  style={{ marginLeft: "10px", fontSize: "0.8rem" }}
                >
                  <div>{item.OrderNumber}</div>
                  <div>RM Distributor</div>
                  <div>Quantity : 5 tons</div>
                  <div>Reached : 11.02.2023</div>
                  <div
                    className="d-flex flex-row justify-content-between"
                    style={{ width: "80px", fontSize: "18px" }}
                  >
                    {[1, 2, 3, 4, 5].map(star => (
                      <span
                        key={star}
                        style={star <= 3 ? { color: "red" } : { color: "gray" }}
                      >
                        ★
                      </span>
                    ))}
                  </div>
                  <div>Price Quote : $6000.00</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default OrderList;
