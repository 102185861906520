import styled from 'styled-components';
import { Card } from 'react-bootstrap';

export const StyledCard = styled(Card)`
    height: 190px;
    margin-bottom: 20px;
    box-shadow: 5px 10px 30px #0000001A;
    border: 0;
    border-radius: 7px;
    background-color: white;
    width: 85%;
`

export const StyledCardTitle = styled.h5`
    text-align: left;
    margin-top: 22px;
    margin-left: 17px;
`

export const StyledDateText = styled.p`
    font-size: 13px;
    text-align: left;
    margin-top: -5px;
    margin-left: 17px;
`

export const StyledEarnText = styled.p`
    font-size: 13px;
    text-align: left;
    margin-left: 17px;
    margin-top: 8px;
    font-weight: 600;
`

export const StyledDataContainer = styled.div`
    border-right: 1px solid black;
    padding-right: 20px;
`

export const StyledTitle = styled.div`
    font-weight: 600;
`