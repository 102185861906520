import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { usePagination } from '../hooks';
import { Card, Table, Pagination, Space, DatePicker, Input } from 'antd'

const { RangePicker } = DatePicker;

const Ledger = (props) => {
    const { userId, mobile } = props;

    const { data, gotoPage, currentPage, loading, total, fetchData } =
        usePagination(`/user-invoice/ledger?mobile=${mobile}&userId=${userId}`);

    const columns = [
        {
            title: 'Name',
            dataIndex: 'FullName',
            key: 'FullName'
        },
        {
            title: 'Date',
            dataIndex: 'TranscationDate',
            key: 'TranscationDate'
        },
        {
            title: 'Type',
            dataIndex: 'TransType',
            key: 'TransType'
        },
        {
            title: 'Amount',
            dataIndex: 'Amount',
            key: 'Amount'
        },
        {
            title: 'ClosingBalance',
            dataIndex: 'ClosingBalance',
            key: 'ClosingBalance'
        },
        {
            title: 'Description',
            dataIndex: 'Descriptions',
            key: 'Descriptions'
        }
    ]

    const [urlParams, setUrlParams] = useState({
        transId: '',
        startDate: '',
        endDate: ''
    })

    const headerELements = () => (
        <Space size="small" wrap className="customHeader">
            <RangePicker
                onChange={(value, dateString) => {
                    const startDate = dateString[0];
                    const endDate = dateString[1];
                    setUrlParams({
                        ...urlParams,
                        startDate: startDate,
                        endDate: endDate,
                    });
                    fetchData(currentPage, { ...urlParams, startDate, endDate });
                }}
            />
            <Input
                placeholder={`Search Transaction Id`}
                value={urlParams.transId}
                onChange={e => setUrlParams({ ...urlParams, transId: e.target.value })}
                onPressEnter={() => fetchData(currentPage, { ...urlParams })}
                style={{ width: 200 }}
            />
        </Space>
    )

    return (
        <Card title="View invoice Ledger" extra={headerELements()}>
            <Table
                loading={loading}
                size="small"
                bordered
                columns={columns}
                pagination={false}
                dataSource={data}
                scroll={{ x: 1000 }}
            />
            <nav
                className="mt-4"
                style={{ display: 'flex', justifyContent: 'flex-end' }}
            >
                <Pagination
                    showQuickJumper
                    current={currentPage}
                    defaultPageSize={10}
                    total={total}
                    onChange={page => gotoPage(page)}
                    showSizeChanger={false}
                />
            </nav>
        </Card>
    )
}


const ViewInvoiceLedger = (props) => {

    const location = useLocation();

    return (
        <Ledger userId={location.state.userId} mobile={location.state.mobile} />
    )
}

export default ViewInvoiceLedger;
