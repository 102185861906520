import axios from "axios";
import { useEffect, useState } from "react";
import { Badge, Card, Col, Modal, Row } from "react-bootstrap";
import { asyncWrap } from "../../utils/utils";
import HitNumber from "./HitNumber";

const optFilter = [
  { title: "Active", key: 1 },
  { title: "Completed", key: 2 },
];

const SelectHitTheNumber = ({ show, setShow }) => {
  // const { t } = useTranslation();
  const [activeOptFilter, setActiveOptFilter] = useState(1);
  const [hitTheNumberData, setHitTheNumberData] = useState([]);
  const [selectedHitNumberData, setSelectedHitNumberData] = useState([]);
  const [showHitTheNumber, setShowHitTheNumber] = useState(false);
  const [noOfTries, setNoOfTries] = useState(0);

  const color = ["#FEA965", "#CC4056", "#A13964", "#4E866A"];
  const getHitTheNumberData = async (title) => {
    const [error, result] = await asyncWrap(
      axios.get(`/hit-the-number/?hitNumberStatus=${title}`)
    );
    if (!result) {
      console.log(error);
      return;
    }

    let data = result.data.data.map((item, i) =>
      i % 3 === 0
        ? { ...item, Color: color[i % 4] }
        : { ...item, Color: color[i % 4] }
    );
    setHitTheNumberData(data);
  };

  useEffect(() => {
    if (show || showHitTheNumber === false) {
      getHitTheNumberData("Active");
    }
    // if (show && showHitTheNumber === false) {
    //   getHitTheNumberData("Active");
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, showHitTheNumber]);

  return (
    <>
      <HitNumber
        show={showHitTheNumber}
        setShow={setShowHitTheNumber}
        data={selectedHitNumberData}
        noOfTries={noOfTries}
        setNoOfTries={setNoOfTries}
      />
      <Modal fullscreen={true} show={show} onHide={() => setShow(false)}>
        <Modal.Body className="mb-20 p-0 text-center">
          <Row className="p-4">
            <div className="horizontal-scroll mt-20">
              {optFilter.map(({ title, key }) => (
                <Badge
                  key={key}
                  onClick={() => {
                    setActiveOptFilter(key);
                    getHitTheNumberData(title);
                  }}
                  pill
                  className={
                    key === activeOptFilter ? "badgeActive badge" : "badge"
                  }
                  style={{
                    background:
                      key === activeOptFilter
                        ? "#fad6a5 !important"
                        : "white !important",
                  }}
                >
                  {title}
                </Badge>
              ))}
            </div>

            {hitTheNumberData &&
              hitTheNumberData.map((item) => (
                <Col
                  xs={22}
                  style={{
                    marginTop: "40px",
                  }}
                >
                  <Card
                    onClick={
                      activeOptFilter === 1
                        ? () => {
                            setSelectedHitNumberData(item);
                            setShowHitTheNumber(true);
                            setNoOfTries(item.NoOfHit);
                          }
                        : () => {}
                    }
                    style={{
                      backgroundColor: item.Color,
                      height: "160px",
                      boxShadow: "0px 4px 20px rgba(254, 169, 101, 0.5)",
                      borderRadius: "15px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        width: "90px",
                        height: "90px",
                        backgroundColor: "#FFCA9F",
                        borderRadius: "15px",
                        boxDhadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Card.Img
                        src={item.HitTheNumberImage}
                        style={{ width: "64px", height: "64px" }}
                      />
                    </div>
                    <Card.Text
                      style={{
                        marginTop: "5px",
                        textAlign: "center",
                        color: "#fff",
                        fontWeight: "500",
                        fontSize: "16px",
                      }}
                    >
                      {item.HitTheNumberName}
                    </Card.Text>
                  </Card>
                </Col>
              ))}
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SelectHitTheNumber;
