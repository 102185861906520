import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Card, Table, Tabs, Pagination } from "antd";
import { usePagination } from "../hooks";
import { asyncWrap } from "../utils/utils";
import axios from "axios";
import Swal from "sweetalert2";
import { Modal, Image } from "react-bootstrap";
import close from "/assets/images/popclose.png";
import moment from "moment";

const ListInvoices = (props) => {
  const { status, userId } = props;
  const [showInvoiceModal, setShowInvoiceModal] = useState(false);
  const [invoiceData, setInvoiceData] = useState([]);

  const { data, gotoPage, currentPage, loading, total, fetchData } =
    usePagination(`user-invoice?userId=${userId}&status=${status}`);

  const columns = [
    {
      title: "Date",
      dataIndex: "InvoiceDate",
      key: "InvoiceDate",
    },
    {
      title: "InvoiceID",
      dataIndex: "InvoiceID",
      key: "InvoiceID",
    },
    {
      title: "Amount",
      dataIndex: "TotalAmount",
      key: "TotalAmount",
    },
  ];

  const getInvoiceDetail = async (record) => {
    const [error, result] = await asyncWrap(
      axios.get(`/user-invoice/details?id=${record?.InvoiceID}`)
    );

    if (!result) {
      console.log(error);
      Swal.fire("Error in getting invoice data");
      return;
    }

    setInvoiceData([{...result.data.data[0], InvoiceDate: record?.InvoiceDate}]);
    setShowInvoiceModal(true);
  };

  return (
    <>
      <Modal
        fullscreen={true}
        show={showInvoiceModal}
        onHide={() => setShowInvoiceModal(false)}
      >
        <Modal.Body className="my-5 mx-1 p-2">
          <span
            style={{
              position: "absolute",
              top: "12px",
              right: "12px",
            }}
          >
            <Image
              src={close}
              alt=""
              onClick={() => setShowInvoiceModal(false)}
            />
          </span>
          <table className="table bordered">
            <tbody>
              <tr>
                <th scope="row" colSpan={3}>InvoiceDetailId</th>
                <td>{invoiceData[0]?.InvoiceDetailID}</td>
              </tr>
              <tr>
                <th scope="row" colSpan={3}>Date</th>
                <td>{moment(invoiceData[0]?.InvoiceDate,).format("DD-MM-YYYY")}</td>
              </tr>
              <tr>
                <th scope="row">SkuId</th>
                <th scope="row">Quantity</th>
                <th scope="row">UnitPrice</th>
                <th scope="row">SubTotal</th>
              </tr>
              <tr>
                {invoiceData.length > 0 &&  invoiceData.map((item, i) => (
                    <React.Fragment key={i}>
                    <td>{item?.SkuUniqueId}</td>
                    <td>{item?.Quantity}</td>
                    <td>{item?.UnitPrice}</td>
                    <td>{item?.SubTotal}</td>
                    </React.Fragment>
                ))
                }
              </tr>
              <tr>
              </tr>
              <tr>
              </tr>
            </tbody>
          </table>
        </Modal.Body>
      </Modal>
      <Table
        size="small"
        bordered
        columns={columns}
        pagination={false}
        dataSource={data}
        onRow={(record, rowIndex) => {
          return {
            onClick: (e) => getInvoiceDetail(record),
          };
        }}
      />
      <nav
        className="mt-4"
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        <Pagination
          showQuickJumper
          current={currentPage}
          defaultPageSize={10}
          total={total}
          onChange={(page) => gotoPage(page)}
          showSizeChanger={false}
        />
      </nav>
    </>
  );
};

const ViewInvoices = (props) => {
  const location = useLocation();
  const [activated, setActive] = useState(0);

  return (
    <Card title="View Invoices">
      <Tabs
        activeKey={activated.toString()}
        onTabClick={(activeKey) => {
          setActive(parseInt(activeKey));
        }}
      >
        <Tabs.TabPane tab="Pending" key="0">
          <ListInvoices status={0} userId={location.state.userId} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Approved" key="1">
          <ListInvoices status={1} userId={location.state.userId} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Rejected" key="2">
          <ListInvoices status={2} userId={location.state.userId} />
        </Tabs.TabPane>
      </Tabs>
    </Card>
  );
};

export default ViewInvoices;
