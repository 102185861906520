const SpanishIcon = ({ color }) => (
  <div className="flex flex-col items-center justify-center p-[10px]">
    <svg
      width="53"
      height="30"
      viewBox="0 0 53 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.768 12.7V12.028C18.5067 8.70533 17.0507 7.044 14.4 7.044C13.0933 7.044 11.936 7.52933 10.928 8.5C9.92 9.43333 9.248 10.8333 8.912 12.7H18.768ZM24.648 21.044V27.876C22.7067 29.1453 19.7387 29.78 15.744 29.78C11.0773 29.78 7.43733 28.436 4.824 25.748C2.248 23.0227 0.96 19.532 0.96 15.276C0.96 10.7587 2.21067 7.23067 4.712 4.692C7.25067 2.116 10.368 0.827999 14.064 0.827999C17.872 0.827999 20.8773 1.948 23.08 4.188C25.2827 6.39067 26.384 9.69467 26.384 14.1C26.384 14.9587 26.2347 16.4333 25.936 18.524H9.248C9.808 20.092 10.7787 21.3053 12.16 22.164C13.5413 23.0227 15.2027 23.452 17.144 23.452C20.0187 23.452 22.52 22.6493 24.648 21.044ZM51.2594 1.948V8.948C49.2807 7.79067 46.966 7.212 44.3154 7.212C43.3447 7.212 42.598 7.41733 42.0754 7.828C41.5527 8.20133 41.2914 8.68667 41.2914 9.284C41.2914 9.62 41.3847 9.93733 41.5714 10.236C41.758 10.5347 42.0567 10.8147 42.4674 11.076C42.9154 11.3373 43.326 11.58 43.6994 11.804C44.11 11.9907 44.6514 12.2333 45.3234 12.532C46.0327 12.8307 46.5927 13.092 47.0034 13.316C48.7207 14.212 50.102 15.2573 51.1474 16.452C52.1927 17.6093 52.7154 19.1027 52.7154 20.932C52.7154 23.732 51.7074 25.8973 49.6914 27.428C47.6754 28.9587 45.006 29.724 41.6834 29.724C38.2487 29.724 35.374 29.1453 33.0594 27.988V20.708C35.6354 22.5 38.3607 23.396 41.2354 23.396C43.2887 23.396 44.3154 22.7427 44.3154 21.436C44.3154 21.1 44.222 20.7827 44.0354 20.484C43.8487 20.1853 43.5314 19.9053 43.0834 19.644C42.6354 19.3827 42.2247 19.1773 41.8514 19.028C41.478 18.8413 40.9367 18.5987 40.2274 18.3C39.518 18.0013 38.9767 17.7587 38.6034 17.572C34.8327 15.78 32.9474 13.092 32.9474 9.508C32.9474 6.74533 33.9927 4.59867 36.0834 3.068C38.2114 1.53733 40.8434 0.771999 43.9794 0.771999C46.8914 0.771999 49.318 1.164 51.2594 1.948Z"
        fill={color}
      />
    </svg>
  </div>
);

export default SpanishIcon;
