import React from 'react';

const DropdownSelect = ({ selectId, label, selectedValue, setSelectedValue, options, width }) => {
  const selectStyle = {
    border: '1px solid black',
    width: width,
    padding: '8px 12px',
    backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M7 10l5 5 5-5z"/></svg>')`,
    backgroundPosition: 'right 0.1rem center',
    backgroundSize: '1.25rem',
    backgroundRepeat: 'no-repeat',
    appearance: 'none',
    boxShadow: 'none',
    outline: 'none',
    fontSize: '0.9em'
  };

  return (
    <div className='d-flex justify-content-between align-items-center w-100vw'>
      <label htmlFor={selectId} className="col-sm-2 col-form-label text-4">{label}</label>
      <div>
        <select
          className="form-control"
          id={selectId}
          value={selectedValue}
          onChange={(e) => setSelectedValue(e.target.value)}
          style={selectStyle}
        >
          <option value="" >Select</option>
          {options.map((option, index) => (
            <option key={index} value={option.value} >{option.label}</option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default DropdownSelect;
