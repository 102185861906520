import React from "react";
import { AiFillHeart } from "react-icons/ai";

const Life = ({ i }) => {
  return (
    <div key={i}>
      <AiFillHeart />
    </div>
  );
};

export default Life;
