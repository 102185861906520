import { Modal, Tabs, Tab, Form } from "react-bootstrap";
import { useEffect, useState } from "react";
// import SecoundaryHeader from "../component/Nav/SecoundaryHeader";
// import { useHistory } from "react-router";
// import { useTranslation } from "react-i18next";
import { asyncWrap } from "../utils/utils";
import axios from "axios";
import Swal from "sweetalert2";
import RedeemGamePoint from "../component/GamePoints/RedeemPoints";

const GamePointsInformation = () => {
  // eslint-disable-next-line
  const [show, setShow] = useState(true);
  // const history = useHistory();
  // const { t } = useTranslation();

  const [pointTypeId, setPointTypeId] = useState();
  const [pointType, setPointType] = useState([]);

  const getPointType = async () => {
    const [error, result] = await asyncWrap(axios.get("point-type"));
    if (!result) {
      console.log(error);
      Swal.fire("Something Went Wrong!");
    }
    setPointType(result.data.data);
  };

  useEffect(() => {
    getPointType();
  }, []);

  const handleChange = (event) => {
    setPointTypeId(event.target.value);
  };

  return (
    <Modal fullscreen={true} show={show}>
      {/* <Modal.Header> */}
        {/* <SecoundaryHeader
          text={t("points_information")}
          sideHide="false"
          onBack={() => {
            history.push("/home");
            setShow(false);
          }}
        /> */}
      {/* </Modal.Header> */}
      <Modal.Body style={{ marginTop: "30px" }}>
        <Form.Select
          // placeholder="Please select PointType"
          style={{ width: "250px", marginBottom: "20px", textAlign: "center" }}
          onChange={handleChange}
        >
          {pointType &&
            pointType.map((item) => (
              <option key={item.PointTypeID} value={item.PointTypeID}>
                {item.PointType}
              </option>
            ))}
        </Form.Select>
        <Tabs defaultActiveKey="earn" id="uncontrolled-tab" className="mb-3">
          {/* <Tab eventKey="earn" title={t("earned_points")}>
            {pointTypeId && <EarnedGamePoints id={pointTypeId} />}
          </Tab> */}
          <Tab eventKey="redeem" title="Ledger">
            {pointTypeId && <RedeemGamePoint id={pointTypeId} />}
          </Tab>
        </Tabs>
      </Modal.Body>
    </Modal>
  );
};

export default GamePointsInformation;
