import React, { useEffect, useState } from "react";
import { Col, Image, Row } from "react-bootstrap";
import humanities from "/assets/images/Quiz/humanities.svg";

const QuizTopicCard = ({userPointData}) => {
  const [xP, setXP] = useState(0)

  const generateRandomNumber = () => {
    const range = 999 - 100 + 1;
    let result = Math.floor(Math.random() * range) + 100
    setXP(result)
  }

  useEffect(() => {
    generateRandomNumber()
  },[])
  return (
    <div>
      <Row
        className="m10"
        style={{
          background: "#D288FF",
          borderRadius: "15px",
          height: "130px",
        }}
      >
        <Col
          xs={3}
          style={{
            background: "#F0A6F5",
            width: "90px",
            height: "90px",
            margin: "auto 0px auto 21px",
            borderRadius: "15px",
          }}
        >
          <Image src={humanities} style={{ marginTop: "15px" }} />
        </Col>
        <Col
          style={{
            display: "flex",
            color: "white",
            margin: "auto 0px auto 21px",
            flexDirection: "column",
          }}
        >
          <p
            style={{
              fontSize: "16px",
              fontWeight: "500",
              margin: "8px 0 0 0",
            }}
          >
            Quiz Topic-1
          </p>
          <p style={{ fontSize: "12px", fontWeight: "700", margin: 0 }}>
            15 Questions
          </p>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginRight: "22px",
              marginBottom: "16px",
            }}
          >
            <div className="d-flex flex-column align-items-center">
              <p
                style={{
                  fontSize: "30px",
                  fontWeight: "500",
                  margin: "0px",
                }}
              >
                {userPointData[0]?.Rank}
              </p>
              <p
                style={{
                  fontSize: "12px",
                  fontWeight: "500",
                  margin: "0px",
                }}
              >
                Rank
              </p>
            </div>
            <div className="d-flex flex-column align-items-center">
              <p
                style={{
                  fontSize: "30px",
                  fontWeight: "500",
                  margin: "0px",
                }}
              >
                {userPointData[0]?.Points}
              </p>
              <p
                style={{
                  fontSize: "12px",
                  fontWeight: "500",
                  margin: "0px",
                }}
              >
                Points
              </p>
            </div>
            <div className="d-flex flex-column align-items-center">
              <p
                style={{
                  fontSize: "30px",
                  fontWeight: "500",
                  margin: "0px",
                }}
              >
                {xP}
              </p>
              <p
                style={{
                  fontSize: "12px",
                  fontWeight: "500",
                  margin: "0px",
                }}
              >
                Xp
              </p>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default QuizTopicCard;