import React, { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import "./HangmanGame.css";
import { GrFormClose } from "react-icons/gr";
import Figure from "./Figure";
import Header from "./Header";
import WrongLetters from "./WrongLetters";
import Word from "./Word";
import { generateWord, show as showNotify } from "./helper";
import Popup from "./Popup";
import Notification from "./Notification";

const HangmanGame = ({ show, setShow }) => {
  const [playable, setPlayable] = useState(true);
  const [correctLetters, setCorrectLetters] = useState([]);
  const [wrongLetters, setWrongLetters] = useState([]);
  const [selectedWord, setSelectedWord] = useState("");
  const [showNotification, setShowNotification] = useState(false);
  const [selectword, setSelectWord] = useState("");
  // const [playing, setPlaying] = useState(false);

  useEffect(() => {
    if (show === true) {
      setSelectedWord(generateWord());
    } else {
      setSelectedWord("");
      setCorrectLetters([]);
      setWrongLetters([]);
    }
  }, [show]);

  const handleKeyDown = (event) => {

    const { key, keyCode } = event;
    if (playable && keyCode >= 65 && keyCode <= 90) {
      const letter = key.toLowerCase();
      const word = selectedWord;
      setSelectWord(letter);
      if (word.includes(letter)) {
        if (!correctLetters.includes(letter)) {
          setCorrectLetters((correctLetters) => [...correctLetters, letter]);
        } else {
          showNotify(setShowNotification);
        }
      } else {
        if (!wrongLetters.includes(letter)) {
          setWrongLetters((wrongLetters) => [...wrongLetters, letter]);
        } else {
          showNotify(setShowNotification);
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);

    return () => window.removeEventListener("keydown", handleKeyDown);
    // eslint-disable-next-line
  }, [correctLetters, wrongLetters, playable]);

  const playAgain = () => {
    //Empty Array
    setCorrectLetters([]);
    setWrongLetters([]);
    setSelectedWord(generateWord());
  };

  const handleClick = (selectword) => {
    // setSelectWord(selectword);
    if (playable) {
      const letter = selectword.toLowerCase();
      const word = selectedWord;

      if (word.includes(letter)) {
        if (!correctLetters.includes(letter)) {
          setCorrectLetters((correctLetters) => [...correctLetters, letter]);
        } else {
          showNotify(setShowNotification);
        }
      } else {
        if (!wrongLetters.includes(letter)) {
          setWrongLetters((wrongLetters) => [...wrongLetters, letter]);
        } else {
          showNotify(setShowNotification);
        }
      }
    }
  }

  return (
    <>
      {/* <ReactHowler playing={playing} src={[newSound]} loop={true} /> */}
      <Modal
        fullscreen={true}
        show={show}
        onHide={() => setShow(false)}
        style={{ backgroundColor: "#282c34" }}
      >
        <Modal.Body style={{ textAlign: "center", backgroundColor: "#282c34" }}>
          <Row className="p-10" style={{ width: "100%" }}>
            <Col className="text-end" xs={{ span: 1, offset: 11 }}>
              <GrFormClose
                onClick={() => setShow(false)}
                style={{
                  marginRight: "10px",
                  fontSize: "25px",
                  backgroundColor: "white",
                  borderRadius: "50%",
                }}
              />
            </Col>
          </Row>
          <Header />
          <div className="game-container">
            <Figure wrongLetters={wrongLetters} />
            <WrongLetters wrongLetters={wrongLetters} />
            <Word selectedWord={selectedWord} correctLetters={correctLetters} />
          </div>
          <div className="input-letter" style={{ marginTop: "20px" }}>
            <input
              value={selectword}
              onChange={(e) => {
                handleClick(e.target.value);
                // setSelectWord("");
              }}
              type="text"
              id="userLetter"
              maxLength={1}
              size="15"
              autoComplete="off"
            />
            <button onClick={handleClick} type="button" style={{ background: "transparent", border: "1px solid white", borderRadius: "5px", marginLeft: "5px", color: "white", }} id="guessButton">Guess</button>
          </div>
          <Popup
            correctLetters={correctLetters}
            wrongLetters={wrongLetters}
            selectedWord={selectedWord}
            setPlayable={setPlayable}
            playAgain={playAgain}
          />
          <Notification showNotification={showNotification} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default HangmanGame;
