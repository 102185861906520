import React, { useEffect } from "react";

const Timer = ({ loading, seconds, setSeconds, setShowGameOver }) => {
  useEffect(() => {
    if (!loading) {
      var timer =
        seconds > 0 &&
        setTimeout(() => {
          setSeconds(seconds - 1);
        }, 1000);
      return () => {
        clearInterval(timer);
        if (seconds === 1) {
          setShowGameOver(true);
        }
      };
    }
    // eslint-disable-next-line
  }, [seconds]);

  return <div>{seconds}</div>;
};

export default Timer;
