export const convertManagerForSelect = (ids, names) => {
    if (!ids || !names) return;
    let value = ids.split(',');
    let label = names.split(',');
  
    let out = value.map((curr, idx) => {
      let obj = {};
      obj['value'] = curr;
      obj['label'] = label[idx];
      return obj;
    });
    return out;
  };
  