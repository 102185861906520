const PortugueseIcon = ({ color }) => (
  <div className="flex flex-col items-center justify-center p-[10px]">
    <svg
      width="59"
      height="39"
      viewBox="0 0 59 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.764 19.46H11.62C16.1747 19.46 18.452 17.388 18.452 13.244C18.452 9.51067 16.3427 7.644 12.124 7.644H8.764V19.46ZM0.14 0.755999H13.132C17.3133 0.755999 20.692 1.932 23.268 4.284C25.8813 6.636 27.188 9.62266 27.188 13.244C27.188 17.388 25.8253 20.5987 23.1 22.876C20.412 25.1533 17.0893 26.292 13.132 26.292H8.764V38.5H0.14V0.755999ZM45.0787 38.948C40.636 38.948 37.2014 37.6227 34.7747 34.972C32.348 32.284 31.1347 28.8493 31.1347 24.668C31.1347 20.3 32.404 16.716 34.9427 13.916C37.4814 11.0787 40.86 9.66 45.0787 9.66C49.484 9.66 52.9 11.0227 55.3267 13.748C57.7534 16.4733 58.9667 19.9453 58.9667 24.164C58.9667 28.4573 57.7347 32.004 55.2707 34.804C52.844 37.5667 49.4467 38.948 45.0787 38.948ZM45.0227 16.1C43.2307 16.1 41.8867 16.8653 40.9907 18.396C40.0947 19.9267 39.6467 21.9053 39.6467 24.332C39.6467 26.7587 40.0947 28.756 40.9907 30.324C41.924 31.8547 43.3054 32.62 45.1347 32.62C46.9267 32.62 48.252 31.8733 49.1107 30.38C50.0067 28.8493 50.4547 26.8707 50.4547 24.444C50.4547 18.8813 48.644 16.1 45.0227 16.1Z"
        fill={color}
      />
    </svg>
  </div>
);

export default PortugueseIcon;
