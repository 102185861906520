import { Row, Col } from "react-bootstrap";
import dummy from "/assets/images/rank/dummy.png";
import { Pagination } from "antd";
import { useState } from "react";

const RankCard = ({ data }) => {
  const [currentPage, setCurrentPage] = useState(1);
  // eslint-disable-next-line
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;
  const currentItems = data.slice(firstIndex, lastIndex);

  function handlePageChange(pageNumber) {
    setCurrentPage(pageNumber);
  }

  return (
    <div>
      {currentItems.map((data, index) => (
        <Row
          key={index}
          className="m10"
          style={{ background: "#f7e7ce", borderRadius: 10 }}
        >
          <Col
            xs={2}
            style={{
              display: "flex",
              background: "#DC7832",
              color: "white",
              borderRadius: "10px 0 0 10px",
            }}
          >
            <div style={{ margin: "auto 0", width: "100%" }}>
              <p>{index + 1}</p>
            </div>
          </Col>
          <Col xs={2} style={{ display: "flex" }}>
            <div style={{ position: "relative", margin: "auto 0" }}>
              <div id="hexagon">{data.UserRank}</div>
              <img
                src={dummy}
                alt=""
                style={{ width: "50px", height: "50px", borderRadius: "50%" }}
              />
            </div>
          </Col>
          <Col xs={6} style={{ paddingBottom: 10 }}>
            <div className="text-left mb5">
              <p className="mt5"></p>
              <p className="mb5">
                {data.DistrictName} - {data.StateName}
              </p>
              <span className="rank-point">
                <img
                  className="rank-img"
                  src="/assets/images/rank/star.png"
                  alt=""
                />
              </span>
            </div>
          </Col>
          <Col xs={2} style={{ display: "flex", color: "" }}>
            <div style={{ margin: "auto 0", width: "100%" }}>
              <p className={"like-thumb mb0"}>
                <img src="/assets/images/rank/thumb.png" alt="" />
              </p>
            </div>
          </Col>
        </Row>
      ))}
      <nav
        className="mt-3"
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        <Pagination
          showQuickJumper
          current={currentPage}
          defaultPageSize={10}
          total={data.length}
          showSizeChanger={false}
          onChange={(page) => handlePageChange(page)}
        />
      </nav>
    </div>
  );
};

export default RankCard;
