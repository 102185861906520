import React from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
const style = {
  buttonRetry: {
    fontSize: "1.5em",
    color: "#ff0000",
    fontFamily: "fantasy",
    cursor: "pointer",
  },
  otherButton: {
    color: "white",
    fontFamily: "fantasy",
    cursor: "pointer",
    fontSize: "1em",
    margin: "0.1em auto 3em",
  },
  divider: {
    height: 5,
    border: "0.5px solid #ff0000",
    backgroundColor: "#ff0000",
    width: "70%",
  },
  poinstStyle: {
    color: "#ff0000",
    fontWeight: "bold",
  },
};

const GameOver = (props) => {
  const { t } = useTranslation();
  const {
    show,
    setShow,
    setSeconds,
    setLifes,
    setPoints,
    points,
    getProblem,
    setShowStartGame,
  } = props;
  const onRetry = () => {
    setSeconds(15);
    setLifes([1, 2, 3]);
    setPoints(0);
    getProblem(0);
    setShow(false);
  };
  return (
    <Modal fullscreen={true} show={show} onHide={() => setShow(false)}>
      <Modal.Body className="d-flex flex-column justify-content-center align-items-center">
        <h1>{t("game_over")}</h1>
        <hr style={style.divider} />
        <h3>
          {t("final_score")} <b style={style.poinstStyle}>{points}</b>
        </h3>
        <h3 style={style.buttonRetry} onClick={onRetry}>
          {t("retry")}
        </h3>
        <h5 style={style.otherButton}>{t("another_player")}</h5>
        <h5
          style={style.buttonRetry}
          onClick={() => {
            setShow(false);
            setShowStartGame(false);
            setSeconds(15);
            setLifes([1, 2, 3]);
            setPoints(0);
          }}
        >
          {t("Exit Game")}
        </h5>
      </Modal.Body>
    </Modal>
  );
};

export default GameOver;
