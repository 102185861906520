import { useEffect, useState } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import { Row, Col, Image, Card } from "react-bootstrap";
import classnames from "classnames";
import StoryModal from "../component/StoryModal/StoryModal";
import Swal from "sweetalert2";
// import { PlusCircleFilled } from "@ant-design/icons";
import { asyncWrap } from "../utils/utils";
import axios from "axios";
import { useTranslation } from "react-i18next";

const Group = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState("1");
  const [show, setShow] = useState(false);
  const [stories, setStories] = useState([]);
  const [index, setIndex] = useState(0);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useEffect(() => {
    getStories();
  }, []);

  const getStories = async () => {
    const [error, result] = await asyncWrap(axios.get("/stories"));
    if (!result) {
      console.log(error);
      return;
    } else {
      setStories(
        result.data.data.map((item) => ({
          url: item.Stories,
          duration: 5000,
          type: "image",
          header: {
            heading: item.UserName,
          },
          innerWidth: "100%",
          ...item,
        }))
      );
    }

    // try {
    //   const { data } = await ApiGetCall(`items/usersstories?fields=*.*`);
    //   setStories(
    //     data.map((item) => ({
    //       url: getImageName(
    //         item.stories.id + "?fit=cover&width=411&height=731"
    //       ),
    //       duration: 5000,
    //       type: item.stories.type.includes("video") ? "video" : "image",
    //       header: {
    //         heading: item.userid.username,
    //         subheading: moment(item.date_created).fromNow(),
    //         profileImage: getImageName(item.userid.profilephoto),
    //       },
    //       innerWidth: "100%",
    //       ...item,
    //     }))
    //   );
    // } catch (error) {
    //   // raise a error toast
    //   console.log(error);
    // }
  };

  return (
    <div className="mainPage">
      {stories && (
        <StoryModal
          show={show}
          setShow={setShow}
          stories={stories}
          index={index}
        />
      )}
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "1" })}
            onClick={() => {
              toggle("1");
            }}
          >
            {t("recents")}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "2" })}
            onClick={() => {
              toggle("2");
              if (stories.length > 0) {
                setShow(true);
              } else {
                Swal.fire("Please upload a story first!");
              }
            }}
          >
            {t("seen")}
          </NavLink>
        </NavItem>
        {/* <div
          style={{
            height: 50,
            width: 50,
            position: "absolute",
            right: 10,
            borderRadius: "50%",
          }}
        >
          <PlusCircleFilled
            style={{ fontSize: "24px", color: "blue" }}
            onClick={() => {
              var link = document.getElementById("storyFile");
              link.click();
            }}
          />
          <input
            type="file"
            accept="image/*"
            onChange={async (event) => {
              const formData = new FormData();
              formData.append("title", "Story Image");
              formData.append("file", event.target.files[0]);

              //eslint-disable-next-line
              const [err, result] = await asyncWrap(
                axios.post("upload", formData)
              );
              //eslint-disable-next-line
              const [error, res] = await asyncWrap(
                axios.post("/stories", {
                  stories: result.data.fileUrl,
                })
              );
              if (res.data) {
                Swal.fire("Story added successfully");
                getStories();
              } else {
                Swal.fire("Story uploaded failed");
              }
            }}
            style={{ display: "none" }}
            id="storyFile"
          />
        </div> */}
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <Card body>
            <Row style={{ margin: 5 }}>
              {stories.map((data, i) => (
                <Col sm="3" key={i}>
                  <Image
                    src={data.url}
                    alt=""
                    style={{
                      width: 50,
                      height: 50,
                      borderRadius: "50%",
                    }}
                    onClick={() => {
                      setIndex(i);
                      setShow(true);
                    }}
                  />
                </Col>
              ))}
            </Row>
          </Card>
        </TabPane>
        <TabPane tabId="2">
          <Card body>
            <Row>
              <Col sm="3"></Col>
            </Row>
          </Card>
        </TabPane>
      </TabContent>
    </div>
  );
};

export default Group;
