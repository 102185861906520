import React, {useState, useRef} from 'react'
import { Spinner } from 'reactstrap'

function SchemeTable({column, data, onClick, loading, pageSize = 4, setLoading}) {
  const sliderContainer = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  let initialX;

  let totalPage, endIndex = 0;
  endIndex = endIndex + currentPage * pageSize;
  totalPage = Math.ceil(data.length / pageSize);

    const handleTouchStart = (event) => {
     initialX = event.touches[0].clientX;
    sliderContainer.current.addEventListener('touchmove', handleTouchMove);
    sliderContainer.current.addEventListener('touchend', handleTouchEnd);
  };

  const handleTouchMove = (event) => {
    // Prevent default scrolling behavior
    event.preventDefault();
  };

  const handleTouchEnd = (event) => {
    const finalX = event.changedTouches[0].clientX;

    // changing the swipeDirection only if the difference between finalX and initialX is larger than 40
    if(Math.abs(initialX - finalX) > 40) {
    const swipeDirection = finalX < initialX ? 'left' : 'right';


    if (swipeDirection === 'right' && currentPage > 1) {
        setLoading(true)

        // creating fake delay

        setTimeout(() => setLoading(false), 200)
        setCurrentPage((prevIndex) => prevIndex - 1);
    } else if (swipeDirection === 'left' && currentPage < totalPage) {
      setLoading(true)

        // creating fake delay

        setTimeout(() => setLoading(false), 200)
        setCurrentPage((prevIndex) => prevIndex + 1);
    }
  }

    sliderContainer.current.removeEventListener('touchmove', handleTouchMove);
    sliderContainer.current.removeEventListener('touchend', handleTouchEnd);
  };

  // console.log(currentPage, totalPage)
  return (
    <div className={`${column?.length > 4 ? "table-responsive": ""}`} style={{margin: "0"}}>
    <table className="table" style={{margin: "0"}} >
      <thead>
        <tr>
          {column.length > 0 && column.map(item => (
          <th scope="col" style={{
            backgroundColor: "#ADAEB0", 
            border: "2px solid white", 
            minWidth: "calc(25vw - 2px)",
            verticalAlign: "middle"
          }} key={item.id} >
            {item?.name}
            </th>
          ))}
        </tr>
      </thead>
      <tbody ref={sliderContainer} onTouchStart={handleTouchStart} style={{minHeight: "150px"}}>
        {loading ? (
          <tr>
          <td style={{border: "none", verticalAlign: "middle", height: `${pageSize * 40.33}px`}} colSpan={column.length}>
            <Spinner>
              Loading...
            </Spinner>
          </td>
          </tr>
        ) : 
        data && data?.length > 0 ? data.slice(endIndex - pageSize,endIndex).map((item, key) => (
              <tr key={key} onClick={() => onClick(item)} >
            {column.length > 0 && column.map((el, i) => (
                <td key={i} 
                style={{backgroundColor: "#E9EAEB", 
                border: "2px solid white", 
                minWidth: "calc(25vw - 2px)",
                verticalAlign: "middle"
                }}>
                  <div className="d-flex justify-content-center">
                  {item[el?.dataIndex]}
                </div>
                </td>
            ))
            }
            </tr>
        ))
        : (
        <tr style={{height: "150px"}}>
          <td style={{border: "none", verticalAlign: "middle"}} colSpan={column.length}>No data available</td>
        </tr>
        )
        }
      </tbody>
    </table>
        {currentPage < totalPage && <div style={{
          fontSize: "10px"
        }} className='text-end mx-1'>
          swipe for more
          <img style={{
            width: "10px",
            filter: "invert(100%)",
            margin: "0 2px"
          }} src="../assets/images/arrow-table.gif" alt="arrow" />
          </div>}
        </div>
  )
}

export default SchemeTable