import React from 'react';
import { useLocation } from 'react-router-dom';
import { usePagination } from '../hooks';
import { Card, Table, Tabs, Pagination } from 'antd'

const ListPayments = (props) => {
    const { userId } = props;

    const { data, gotoPage, currentPage, loading, total, fetchData } =
        usePagination(`/user-invoice/payments?userId=${userId}`);

    const columns = [
        {
            title: 'Date',
            dataIndex: 'Paymentdate',
            key: 'Paymentdate'
        },
        {
            title: 'PaymentID',
            dataIndex: 'PaymentID',
            key: 'PaymentID'
        },
        {
            title: 'Paymentvalue',
            dataIndex: 'Paymentvalue',
            key: 'Paymentvalue'
        },
        {
            title: 'UtrNumber',
            dataIndex: 'UtrNumber',
            key: 'UtrNumber'
        }
    ]

    return (
        <>
            <Table
                loading={loading}
                size="small"
                bordered
                columns={columns}
                pagination={false}
                dataSource={data}
            />
            <nav
                className="mt-4"
                style={{ display: 'flex', justifyContent: 'flex-end' }}
            >
                <Pagination
                    showQuickJumper
                    current={currentPage}
                    defaultPageSize={10}
                    total={total}
                    onChange={page => gotoPage(page)}
                    showSizeChanger={false}
                />
            </nav>
        </>
    )
}


const ViewInvoicePayments = (props) => {

    const location = useLocation();

    return (
        <Card title="View invoice payments">
            <ListPayments userId={location.state.userId} />
        </Card>
    )
}

export default ViewInvoicePayments;
