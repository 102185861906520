import React from "react";

function Manufactures() {
  return (
    <div className="p-2">
      <div className="my-3">
        <input
          className="input-grey-rounded"
          type="text"
          placeholder="Search"
        ></input>
      </div>

      <div className="d-flex flex-column">
        <div
          className="d-flex flex-column rounded-4 p-3 text-start"
          style={{ background: "#dddddd" }}
        >
          <div className="d-flex flex-row">
            <div
              style={{
                width: "80px",
                height: "80px",
                borderRadius: "50%",
                background: "white",
              }}
            ></div>
            <div className="d-flex flex-column" style={{ marginLeft: "15px" }}>
              <div
                className="text-start"
                style={{ fontWeight: "600", fontSize: "16px" }}
              >
                SS Manufactures
              </div>
              <div className="text-start" style={{ fontSize: "16px" }}>
                Contact : +939939399023{" "}
              </div>
            </div>
          </div>
          <div className="mt-2" style={{ fontWeight: "500", fontSize: "16px" }}>
            Based : Mumbai
          </div>
          <div style={{ fontWeight: "500", fontSize: "16px" }}>
            Established : 1934
          </div>
          <div style={{ fontWeight: "500", fontSize: "16px" }}>
            Fiscal Sales : $54,112
          </div>
          <div style={{ fontWeight: "500", fontSize: "16px" }}>
            website : WWW.axltrade.com
          </div>
        </div>
      </div>
    </div>
  );
}

export default Manufactures;
