import { useEffect, useState } from "react";
import { Image, Row, Col, Card } from "react-bootstrap";
import { Progress } from "reactstrap";
import avatar from "/assets/images/Quiz/avatar.svg";
import coin from "/assets/images/Quiz/coin.svg";
import exp from "/assets/images/Quiz/exp.svg";

import QuizPage from "./QuizPage";
import { asyncWrap } from "../../utils/utils";
import axios from "axios";
import { useAuth } from "../../context/auth-context";
import { PassQuizPageModal, UsersPointsData } from "../../context/QuizContext";
import { useTranslation } from "react-i18next";
const HomePage = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const [quizData, setQuizData] = useState([]);
  const [showQuizPage, setShowQuizPage] = useState(false);
  const [quizId, setQuizId] = useState();
  const [quizUserData, setQuizUserData] = useState([]);
  const [xp, setXp] = useState(0);

  const generateRandomNumber = () => {
    const range = 999 - 100 + 1;
    let result = Math.floor(Math.random() * range) + 100;
    setXp(result);
  };

  const GetQuiz = async () => {
    const [error, res] = await asyncWrap(axios.get("/quiz"));
    if (!res) {
      console.log(error);
      return;
    }
    setQuizData(res.data.data);
  };

  const GetLeaderboardData = async () => {
    const [error, result] = await asyncWrap(axios.get("quiz/leader-board"));
    if (!result) {
      console.log(error);
      return;
    }

    setQuizUserData(
      result.data.data?.filter((data) => data.UserId === user?.profile?.userId || user.userId)
    );
  };

  useEffect(() => {
    GetQuiz();
    GetLeaderboardData();
    generateRandomNumber();
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <PassQuizPageModal.Provider value={setShowQuizPage}>
        <UsersPointsData.Provider value={quizUserData}>
          <QuizPage
            quizId={quizId}
            show={showQuizPage}
            setShow={setShowQuizPage}
          />
        </UsersPointsData.Provider>
      </PassQuizPageModal.Provider>
      <Row>
        <Col style={{ display: "flex", marginTop: "-20px" }}>
          <Image src={avatar} alt="" />
          <p
            style={{
              color: "#000000",
              fontWeight: "500",
              fontSize: "16px",
              marginTop: "15px",
              marginLeft: "10px",
            }}
          >
            {user?.profile?.fullName || user.fullName}
          </p>
        </Col>
      </Row>
      <Row
        style={{
          height: "34px",
          marginTop: "20px",
          marginLeft: "10px",
          marginRight: "10px",
        }}
      >
        <Col
          style={{
            display: "flex",
            background: "#D288FF",
            justifyContent: "space-around",
            borderRadius: "20px",
          }}
        >
          <Image
            src={coin}
            alt=""
            style={{
              width: "26px",
              height: "26px",
              position: "relative",
              top: "3px",
              right: "14px",
              backgroundColor: "#fff",
              borderRadius: "30px",
            }}
          />
          <p
            style={{
              color: "#ffffff",
              fontSize: "16px",
              fontWeight: 500,
              marginTop: "5px",
              marginBottom: "0rem",
              marginRight: "16px",
            }}
          >
            {quizUserData[0]?.Points} points
          </p>
        </Col>
        <Col
          style={{
            display: "flex",
            background: "#D288FF",
            justifyContent: "space-around",
            borderRadius: "20px",
            marginLeft: "10px",
          }}
        >
          <Image
            src={exp}
            alt=""
            style={{
              width: "26px",
              height: "26px",
              position: "relative",
              top: "3px",
              right: "16px",
              backgroundColor: "#fff",
              borderRadius: "30px",
            }}
          />
          <p
            style={{
              color: "#ffffff",
              fontSize: "16px",
              fontWeight: 500,
              marginTop: "5px",
              marginBottom: "0rem",
              marginRight: "39px",
            }}
          >
            {xp} Xp
          </p>
        </Col>
      </Row>
      {/* <Row style={{ marginTop: "10px" }}>
      <Col
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "20px",
        }}
      >
        <Input
          placeholder="Enter Quiz Pin"
          style={{
            width: "250px",
            borderRadius: "30px",
            border: "2px solid #8D69F9",
            textAlign: "center",
          }}
        />
      </Col>
    </Row>
    <Row style={{ marginTop: "20px" }}>
      <Col
        style={{
          display: "flex",
          justifyContent: "center",
          width: "250px",
          borderRadius: "30px",
        }}
      >
        <Button
          style={{
            background: "#8D69F9",
            color: "#fff",
            width: "250px",
            borderRadius: "30px",
          }}
        >
          Join
        </Button>
      </Col>
    </Row> */}
      <Row style={{ marginTop: "20px" }}>
        <Col>
          <p
            style={{
              fontSize: "16px",
              marginBottom: 0,
              textAlign: "left",
              color: "#8D69F9",
            }}
          >
            {t("new_quiz")}
          </p>
        </Col>
        <Col>
          <p
            style={{
              fontSize: "16px",
              marginBottom: 0,
              textAlign: "right",
              color: "#8D69F9",
            }}
          >
            {t("see_all")}
          </p>
        </Col>
      </Row>
      <Row>
        {quizData &&
          quizData.map((quiz, index) => (
            <Col xs={6} key={index} style={{ marginTop: "20px" }}>
              <Card
                onClick={
                  quiz.CntQuestionsAnsewers !== quiz.CntQuestions
                    ? () => {
                        setQuizId(quiz.QuizID);
                        setShowQuizPage(true);
                      }
                    : () => {
                        setShowQuizPage(false);
                      }
                }
                style={{
                  backgroundColor: "#FEA965",
                  width: "164px",
                  height: "200px",
                  boxShadow: "0px 4px 20px rgba(254, 169, 101, 0.5)",
                  borderRadius: "15px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: "90px",
                    height: "90px",
                    backgroundColor: "#FFCA9F",
                    borderRadius: "15px",
                    boxDhadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Card.Img
                    src={quiz.QuizImage}
                    style={{ width: "64px", height: "64px" }}
                  />
                </div>
                <Card.Text
                  style={{
                    marginTop: "5px",
                    textAlign: "center",
                    color: "#fff",
                    fontWeight: "500",
                    fontSize: "16px",
                  }}
                >
                  {quiz.QuizName}
                </Card.Text>
                <Card.Text
                  style={{
                    marginTop: "-15px",
                    textAlign: "center",
                    color: "#fff",
                    fontWeight: "500",
                    fontSize: "12px",
                  }}
                >
                  {quiz.CntQuestions === null ? 0 : quiz.CntQuestions} Questions
                </Card.Text>
                <div
                  style={{
                    width: "120px",
                    borderRadius: "8px",
                    justifyContent: "center",
                  }}
                >
                  <div className="text-center">
                    {`${
                      quiz.CntQuestionsAnsewers === null
                        ? 0
                        : quiz.CntQuestionsAnsewers
                    }/${
                      quiz.CntQuestions === null ? 0 : quiz.CntQuestions
                    } Completed`}
                  </div>
                  <Progress
                    max={quiz.CntQuestions}
                    value={quiz.CntQuestionsAnsewers}
                  />
                </div>
              </Card>
            </Col>
          ))}
      </Row>
    </>
  );
};

export default HomePage;
