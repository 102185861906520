import React, {useState} from "react";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { Tooltip } from "reactstrap";
import Header from "../component/SalesInfo/Header";
import PaymentOutstandingCard from "../component/PaymentOutstandingCard/PaymentOutstandingCard";

function Payment() {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  return (
    <div>
      {/* header start */}
      <div className="p-2">
        <div className="d-flex gap-2 justify-content-between">
          <div>
            <p className="m-0 fw-semibold">Due</p>
            <p className="m-0">₹ 10,34,737</p>
          </div>
          <div>
            <p className="m-0 fw-semibold">Over Due</p>
            <p className="m-0">₹ 14,94,299</p>
          </div>
          <div>
            <p className="m-0 fw-semibold">Total Outst Amt</p>
            <p className="m-0">₹ 25,29,036</p>
          </div>
        </div>
        <div className="d-flex gap-2 justify-content-between">
          <div>
            <p className="m-0 fw-semibold">Total Amount To Pay</p>
            {/* <p className="m-0">₹ 10,34,737</p> */}
            <input className="rounded" type="text" />
          </div>
          <div>
            <p className="m-0 fw-semibold">Balance Left</p>
            <p style={{color: "#ff2f30"}} className="m-0">₹ 25,29,036</p>
          </div>
          <div>
            <p className="m-0 fw-semibold">
              Total Savings
                        {/* tooltip start */}
          <span
            id="Tooltip"
            style={{
                margin: "0 0 0 0.5em",
            }}
          >
            <IoIosInformationCircleOutline 
            color="red"
            size={24}
            />
          </span>
          <Tooltip
            placement="bottom"
           isOpen={tooltipOpen}
           target="Tooltip"
           toggle={toggle}
   
          >
            tooltip text
          </Tooltip>
          {/* tooltip end */}
            </p>
            <p style={{color: "#129842"}} className="m-0">₹ 0</p>
          </div>
        </div>
      </div>
      {/* header end */}
      <div className="mb-2">
      <Header text="Over Due" />
      </div>
      <PaymentOutstandingCard />
      <PaymentOutstandingCard />
      <PaymentOutstandingCard />
    </div>
  );
}

export default Payment;
