import axios from "axios";
// import SecoundaryHeader from "component/Nav/SecoundaryHeader";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import { asyncWrap } from "../../utils/utils";

const HitNumber = ({ show, setShow, data, noOfTries, setNoOfTries }) => {
  const [Number, setNumber] = useState(121);
  const [numberChange, setNumberChange] = useState(false);

  useEffect(() => {
    if (numberChange) {
      const interval = setInterval(() => {
        random();
      }, 50);

      return () => clearInterval(interval);
    }
  }, [Number, numberChange]);

  function random() {
    const random = Math.floor(Math.random() * (999 - 100 + 1)) + 100;
    setNumber(random);
  }

  const insertResult = async () => {
    const params = {
      randomNumber: String(Number),
      winningSequence: data.LuckyNumber,
      hitNumberId: data.HitTheNumberID,
      min: data.MinPoints,
      max: data.MaxPoints,
      noOfHit: data.NoOfHit || 0,
      luckyPoints: data.LuckyNumberPoint,
    };

    const [error, result] = await asyncWrap(
      axios.get("/hit-the-number/result", {
        params: params,
        paramsSerializer: function paramsSerializer(params) {
          return Object.entries(Object.assign({}, params))
            .map(([key, value]) => `${key}=${value}`)
            .join("&");
        },
      })
    );

    if (!result) {
      console.log(error);
      return;
    }
    setNoOfTries(result.data.noOfHit);
    Swal.fire(`You Won ${result.data.points} points`);
  };

  return (
    <>
      <Modal
        fullscreen={true}
        show={show}
        style={{ backgroundColor: "white !important" }}
        onHide={() => setShow(false)}
      >
        {/* <Modal.Header
          style={{
            border: 0,
            display: "flex",
            flexDirection: "row-reverse",
          }}
        > */}
          {/* <SecoundaryHeader
            text="Hit The Number"
            sideHide="false"
            onBack={() => {
              console.log("about us back clicked");
              setShow(false);
              setNumberChange(false);
            }}
          /> */}
        {/* </Modal.Header> */}
        <Modal.Body
          style={{
            marginTop: "-15px",
            background: "#fffef2",
            boxShadow: "#00000005",
            borderRadius: " 20px 20px 0px 0px",
            textAlign: "center",
          }}
        >
          <div style={{ marginTop: "7rem" }}>
            <h3 className="fw-bold" style={{ color: "#cf9c03" }}>
              Get {data.LuckyNumber} and win jackpot of {data.LuckyNumberPoint}{" "}
              points
            </h3>
            <h1
              style={{
                alignItems: "center",
                // marginTop: "10rem",
                letterSpacing: "1rem",
                color: "#565656",
                fontSize: "10rem",
                textShadow: "#2c2c2c 3px 3px 5px",
              }}
            >
              {Number}
            </h1>
            {numberChange ? (
              <div>
                <Button
                  disabled={noOfTries > 9}
                  variant="danger"
                  onClick={() => {
                    setNumberChange(false);
                    insertResult();
                    // startchangenumber();
                  }}
                  style={{
                    textAlign: "center",
                    fontSize: "20px",
                    color: "white",
                    width: "100px",
                    fontWeight: "650",
                    paddingLeft: "15px",
                    paddingRight: "15px",
                  }}
                >
                  STOP
                </Button>
              </div>
            ) : (
              <div>
                <Button
                  disabled={noOfTries > 9}
                  variant="success"
                  onClick={() => {
                    setNumberChange(true);
                    // startchangenumber();
                  }}
                  style={{
                    textAlign: "center",
                    fontSize: "20px",
                    color: "white",
                    width: "100px",
                    fontWeight: "650",
                    paddingLeft: "15px",
                    paddingRight: "15px",
                  }}
                >
                  START
                </Button>
              </div>
            )}
            <p className="mt-5" style={{ color: "gray", fontSize: "1rem" }}>
              To {numberChange ? "Stop" : "Start"} the game click here
            </p>
            <p>No of Hits Left: {10 - noOfTries}</p>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default HitNumber;
