import {
  StyledCard,
  StyledTitleDiv,
  StyledClockImage,
  StyledDaysLeftText,
} from "./styledComponents";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";

import ClockIcon from "/assets/images/games/Shape.svg";
import { Col, Row } from "reactstrap";

const GamesActiveCard = ({
  title,
  // badgeEarned,
  // badgeTotal,
  // scoreEarned,
  // socreTotal,
  onClick,
  days,
}) => {
  const history = useHistory();

  return (
    <StyledCard color="#F9E7F9" onClick={onClick}>
      <Row>
        <Col xs={12}>
          <InfoCircleOutlined
            onClick={() => history.push("/game-point")}
            style={{
              fontSize: "24px",
              display: "flex",
              justifyContent: "end",
              margin: "10px",
            }}
          />
          <StyledTitleDiv color="#FFF9F0">
            <h4>{title}</h4>
          </StyledTitleDiv>
          <div
            style={{
              display: "flex",
              marginTop: "10px",
              justifyContent: "center",
              alignItems: "center",
              width: "90%",
              padding: "10px",
            }}
          >
            <StyledClockImage src={ClockIcon} alt="Badge" height="20px" />
            <StyledDaysLeftText>{days} days left</StyledDaysLeftText>
          </div>
        </Col>
        <Col
          xs={12}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
        >
          {/* <div>
            <StyledEarnedTitle>Earned</StyledEarnedTitle>
          </div>
          <Row style={{ textAlign: "left" }}>
            <Col xs={4}>
              <img src={BadgeIcon} alt="Badge" height="30px" />
            </Col>
            <Col
              xs={8}
              style={{
                display: "flex",
                flexDirection: "column",
                marginBottom: "10px",
              }}
            >
              <StyledBadgeTitle>{`${badgeEarned}/${badgeTotal}`}</StyledBadgeTitle>
              <StyledBadgeText>Badge</StyledBadgeText>
            </Col>
            <Col xs={4}>
              <img width="27px" src={MeterIcon} alt="Badge" height="20px" />
            </Col>
            <Col
              xs={8}
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <StyleddMeterTitle>{`${scoreEarned}/${socreTotal}`}</StyleddMeterTitle>
              <StyledScoreText>Score</StyledScoreText>
            </Col>
          </Row> */}
          {/* <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-around',
                            alignItems: 'center'
                        }}
                    >
                        <StyledBadgeImage src={BadgeIcon} alt="Badge" height="30px" />
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                marginLeft: '-10px'
                            }}
                        >
                            <StyledBadgeTitle>{`${badgeEarned}/${badgeTotal}`}</StyledBadgeTitle>
                            <StyledBadgeText>Badge</StyledBadgeText>
                        </div>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-around',
                            alignItems: 'center'
                        }}
                    >
                        <StyledMeterImage src={MeterIcon} alt="Badge" height="20px" />
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'flex-start',
                                alignItems: 'center'
                            }}
                        >
                            <StyleddMeterTitle>{`${scoreEarned}/${socreTotal}`}</StyleddMeterTitle>
                            <StyledScoreText>Score</StyledScoreText>
                        </div>
                    </div> */}
        </Col>
      </Row>
      {/* <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <StyledMilestoneButton pill>
          Next Milestone : Mission l
        </StyledMilestoneButton>
      </div> */}
    </StyledCard>
  );
};

export default GamesActiveCard;
