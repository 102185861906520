import React from "react";
import Poll from "react-polls";
import { asyncWrap } from "../../utils/utils";
import axios from "axios";

const PollGame = ({ data, getPollsData }) => {
  const insertVote = async (item, answer, questionId) => {
    let selectedOption;
    if (answer === item.Option1) {
      selectedOption = "Option1";
    } else if (answer === item.Option2) {
      selectedOption = "Option2";
    } else if (answer === item.Option3) {
      selectedOption = "Option3";
    } else {
      selectedOption = "Option4";
    }

    const config = {
      method: "post",
      url: "/poll/vote",
      data: {
        pollQuestionId: questionId,
        option: selectedOption,
      },
    };

    const [error, result] = await asyncWrap(axios(config));
    // getPollsData();

    if (!result) {
      console.log(error);
    }

    getPollsData();
    console.log("polls data ..", data);
  };

  const handleAnswer = (value) => {
    return value.option !== null;
  };

  return (
    <>
      {data &&
        data.map((item, index) => (
          <div key={index}>
            <Poll
              question={item.PollQuestion}
              answers={[
                {
                  option: item.Option1,
                  votes: Number(item?.Option1Cnt),
                },
                {
                  option: item.Option2,
                  votes: Number(item?.Option2Cnt),
                },
                {
                  option: item.Option3,
                  votes: Number(item?.Option3Cnt),
                },
                {
                  option: item.Option4,
                  votes: Number(item?.Option4Cnt),
                },
              ].filter(handleAnswer)}
              onVote={(e) => {
                insertVote(item, e, item.PollQuestionID);
              }}
              noStorage
              customStyles={{
                questionColor: "#348feb",
                theme: "blue",
                questionBold: 700,
              }}
              vote={item?.UsersAnswers}
            />
          </div>
        ))}
    </>
  );
};

export default PollGame;
