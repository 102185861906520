import { create } from "zustand";
import { getMokKeys } from "../utils/getMokKeys";

export const useMokKeysStore = create((set) => ({
  mokKeys: null,
  getUpdateKeys: async () => {
    const data = await getMokKeys();
    if (!data) {
      // handle error
    } else {
      set({ mokKeys: data });
    }
  },
}));
