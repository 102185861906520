import { Button, Col, Image, Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import SwitchComponent from "../../component/SwitchComponent";
import StartQuiz from "./StartQuiz";
import geo from "/assets/images/Quiz/geo.svg";

const QuizPage = (props) => {
  const { show, quizId, setShow } = props;
  const [startQuiz, setStartQuiz] = useState(false);
  const [playersCount, setPlayersCount] = useState(0);

  const generateRandomNumber = () => {
    const range = 999 - 100 + 1;
    let result = Math.floor(Math.random() * range) + 100;
    setPlayersCount(result);
  };

  useEffect(() => {
    generateRandomNumber();
  }, [show]);

  return (
    <div>
      <StartQuiz show={startQuiz} quizId={quizId} setShow={setStartQuiz} />
      <Modal fullscreen={true} show={show} onHide={() => setShow(false)}>
        <Modal.Body style={{ justifyContent: "center", alignItems: "center" }}>
          <div
            style={{
              marginTop: "20px",
              width: "200px",
              height: "50px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "60px",
                height: "60px",
                background: "#FFCA9F",
                borderRadius: "10px",
                marginTop: "30px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Image
                src={geo}
                alt="img"
                style={{ width: "50px", height: "50px" }}
              />
            </div>
            <p
              style={{
                fontSize: "20px",
                fontweight: "600",
                marginTop: "40px",
                marginLeft: "20px",
              }}
            >
              Quiz Topic
            </p>
          </div>
          <div style={{ marginTop: "60px" }}>
            <Col
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                style={{
                  border: "2px solid #8D69F9",
                  borderRadius: "20px",
                  boxSizing: "border-box",
                  background: "#FFFFFF",
                  fontSize: "16px",
                  color: "#8D69F9",
                  width: "250px",
                  fontWeight: "500",
                }}
              >
                Tips
              </Button>
            </Col>
            <Col style={{ display: "flex", justifyContent: "center" }}>
              <Button
                onClick={() => setStartQuiz(true)}
                style={{
                  border: "2px solid #8D69F9",
                  borderRadius: "20px",
                  boxSizing: "border-box",
                  background: "#8D69F9",
                  fontSize: "16px",
                  color: "#fff",
                  fontWeight: "500",
                  width: "250px",
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
              >
                Start
              </Button>
            </Col>
          </div>
          <div style={{ marginTop: "10px", marginBottom: "30px" }}>
            <p>You are Playing With {playersCount} other Players</p>
            <p>Number of Questions: {15}</p>
          </div>
          <div>
            <p>Settings</p>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  width: "100%",
                }}
              >
                <div style={{ textAlign: "left", marginLeft: "15px" }}>
                  Music
                </div>
              </div>
              <div>
                <SwitchComponent />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  width: "100%",
                }}
              >
                <div style={{ textAlign: "left", marginLeft: "15px" }}>
                  Sound Effect
                </div>
              </div>
              <div>
                <SwitchComponent />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  width: "100%",
                }}
              >
                <div style={{ textAlign: "left", marginLeft: "15px" }}>
                  Read Aloud
                </div>
              </div>
              <div>
                <SwitchComponent />
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default QuizPage;
