import React, { useState } from 'react'
import SalesHeader from '../component/SalesInfo/SalesHeader'
import Chart from "react-apexcharts"
import PaymentBtn from '../component/PaymentInfo/PaymentBtn'

function Outstanding() {
  const [chartData, setChartData] = useState({
          
      series: [10.35, 14.94],
      options: {
        colors: ['#129842', '#FF2F30'],
        chart: {
          width: 400,
          type: 'pie',
        },
        labels: ['DUE', 'OVERDUE'],
        legend: {
          position: 'bottom'
        },
        dataLabels: {
          enabled: true,
          formatter: function (val, { seriesIndex, dataPointIndex, w }) {
            return `${w.config.series[seriesIndex]}`
        }
        },
  
      },
    
  })
  return (
    <div>
      <SalesHeader 
      heading1="Outstanding Amount"
      heading2="Due"
      heading3="Over Due"
      value1="₹ 25.29 Lacs"
      value2="10.35"
      value3="14.94"
      tooltipText="Outstanding tooltip text"
      isOutstanding={true}
      />

      <div className='d-flex justify-content-center'>
        <Chart 
        options={chartData.options}
        series={chartData.series}
        type="pie" 
        width={400}
        />
      </div>

      <PaymentBtn text="View Details" style={{
        borderRadius: 0,
        fontSize: "17px"
      }} />
    </div>
  )
}

export default Outstanding