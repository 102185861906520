import React, { useState, useEffect } from "react";
import ProductDetail from "./ProductDetail";
import { asyncWrap } from "../../utils/utils";
import axios from "axios";

export default function Product() {
  const [showProductDetails, setShowProductDetails] = useState();
  const [ProductDetails, setProductDetails] = useState();
  const [selectProduct, setSelectProduct] = useState();

  useEffect(() => {
    getSkusDetails();
    // eslint-disable-next-line
  }, []);

  const getSkusDetails = async () => {
    const [error, result] = await asyncWrap(axios.get("api/skus"));
    if (!result) {
      console.log(error);
      return;
    }

    setProductDetails(result.data?.data);
  };

  return (
    <div>
      <ProductDetail
        show={showProductDetails}
        setShow={setShowProductDetails}
        selectProduct = {selectProduct}
      />
      <div className="d-flex flex-column p-2">
        <div className="d-flex flex-row justify-content-between align-items-center">
          <div className="fs-1">Place an Order</div>
          <div className="d-flex flex-row">
            <div
              style={{
                background: "#efefef",
                width: "50px",
                height: "50px",
                borderRadius: "50%",
              }}
            ></div>
          </div>
        </div>

        <div className="my-3">
          <input
            className="input-grey-rounded"
            type="text"
            placeholder="Search"
          ></input>
        </div>

        <div className="d-flex flex-column">
          <div className="d-flex flex-row gap-2 justify-content-around">
            <div style={{ width: "70px" }} className="d-flex flex-column">
              <div
                style={{
                  background: "#efefef",
                  borderRadius: "15px",
                  width: "60px",
                  height: "60px",
                }}
              ></div>
              <div className="text-wrap">Cleaning & Janitor</div>
            </div>
            <div style={{ width: "70px" }} className="d-flex flex-column">
              <div
                style={{
                  background: "#efefef",
                  borderRadius: "15px",
                  width: "60px",
                  height: "60px",
                }}
              ></div>
              <div className="text-wrap">Cleaning & Janitor</div>
            </div>
            <div style={{ width: "70px" }} className="d-flex flex-column">
              <div
                style={{
                  background: "#efefef",
                  borderRadius: "15px",
                  width: "60px",
                  height: "60px",
                }}
              ></div>
              <div className="text-wrap">Cleaning & Janitor</div>
            </div>
            <div style={{ width: "70px" }} className="d-flex flex-column">
              <div
                style={{
                  background: "#efefef",
                  borderRadius: "15px",
                  width: "60px",
                  height: "60px",
                }}
              ></div>
              <div className="text-wrap">Cleaning & Janitor</div>
            </div>
          </div>
          <div className="text-start my-2 mx-2" style={{ color: "#ccc" }}>
            More Categories...
          </div>
        </div>

        <div className="d-flex flex-column">
          <h3 className="text-start my-2">Best Sellers</h3>
          {ProductDetails &&
            ProductDetails.map((item, index) => (
              <div
                key={index}
                onClick={() => {
                  setSelectProduct(item)
                  setShowProductDetails(true);
                }}
                className="d-flex flex-column my-2"
              >
                <div
                  style={{ borderBottom: "1px solid #efefef" }}
                  className="d-flex flex-row justify-content-between py-2"
                >
                  <div className="d-flex flex-row">
                    <div
                      style={{
                        borderRadius: "15px",
                        background: "#efefef",
                        width: "70px",
                        height: "70px",
                      }}
                    ></div>
                    <div className="d-flex flex-column text-start px-2">
                      <div>{item.skuName}</div>
                      <div>{item.skuUniqueId}</div>
                      <div>{item.type}</div>
                      <div>{item.size}</div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
