// PaymentAdjustmentModal.jsx
import React from 'react';
import { Modal } from 'react-bootstrap';
import { AiOutlineClose } from "react-icons/ai";

const PaymentAdjustmentModal = ({ show, onHide, adjustmentData }) => {
    const modalBodyStyle = {
        padding: 0,
        backgroundColor: 'transparent'
      };
    
      const headerStyle = {
        backgroundColor: '#E9EAEB',
        textAlign: 'center',
        verticalAlign: 'middle',
        padding: '1em',
        fontSize: '1em',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      };

  const cellStyle = {
    padding: '1em',
    textAlign: 'center',
    verticalAlign: 'top',
    backgroundColor: '#ffffff',
    border: '2px solid #E9EAEB',
    fontSize: '0.9em',
  };

  const secondHeaderStyle = {
    ...cellStyle,
    fontWeight: '700'
  }

  const firstColumnStyle = {
    ...cellStyle,
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
    hyphens: 'auto',
  };

  const secondColumnStyle = {
    ...cellStyle,
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
    hyphens: 'auto',
    paddingTop: '1.6em'
  };

  const closeButtonStyle = {
    position: 'absolute',
    left: '1em',
    top: '50%',
    transform: 'translateY(-50%)',
    border: 'none',
    background: 'transparent',
    cursor: 'pointer'
  };

  const renderInvDetails = (invDetails) => {
    const [invoice, date] = invDetails.split(' ');
    return (
      <>
        <div>{invoice}</div>
        <div style={{ fontWeight: 'normal' }}>{date}</div>
      </>
    );
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Body style={modalBodyStyle}>
        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
          <tbody>
            <tr>
              <td colSpan={2}>
                <button onClick={onHide} style={closeButtonStyle}>
                  <AiOutlineClose size={24} />
                </button>
                <span style={headerStyle}>Payment Adjustment</span>
              </td>
            </tr>
            <tr>
              <td style={secondHeaderStyle}>INV DETAILS</td>
              <td style={secondHeaderStyle}>ADJUSTED AMNT</td>
            </tr>
            {adjustmentData.map((item, index) => (
              <tr key={index}>
                <td style={firstColumnStyle}>{renderInvDetails(item.invDetails)}</td>
                <td style={secondColumnStyle}>{item.adjustedAmount}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Modal.Body>
    </Modal>
  );
};

export default PaymentAdjustmentModal;
