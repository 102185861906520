import React, {useState} from "react";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { Tooltip } from "reactstrap";



function SalesHeader({
  heading1,
  heading2, 
  heading3,
  value1,
  value2,
  value3,
  tooltipText,
  isOutstanding
}) {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);
  return (
    <div className="my-2">
      <div>
        <p
          style={{
            fontSize: "17px",
            fontWeight: "bold",
          }}
        >
          {heading1} :
          <span className="fw-normal" style={{ fontSize: "17px" }}>
            {" "}
            {value1 ? value1 : 0}
          </span>
          {/* tooltip start */}
          <span
            id="Tooltip"
            style={{
                margin: "0 1em",
            }}
          >
            <IoIosInformationCircleOutline 
            color="red"
            size={24}
            />
          </span>
          <Tooltip
            placement="bottom"
           isOpen={tooltipOpen}
           target="Tooltip"
           toggle={toggle}
   
          >
            {tooltipText}
          </Tooltip>
          {/* tooltip end */}
        </p>
      </div>
      <div className="d-flex border">
        <div className="flex-grow-1 py-2">
          <p className="fw-bold m-0">{heading2}</p>
          <span className="mb-0 mx-auto px-3 py-1" style={{ 
            fontSize: "17px",
            backgroundColor: isOutstanding ? "#129842" : "transparent",
            color: isOutstanding ? "#fff" : "#000",
            }}>
            {value2 ? value2 : 0}
          </span>
        </div>
        <div className="flex-grow-1 py-2 border-start">
          <p className="fw-bold m-0">{heading3}</p>
          <span
            className="text-white mx-auto mb-0 px-3 py-1 d-inline-block"
            style={{
              backgroundColor: `${value3 <= 0 ? "#FF2F30" : "#129842"}`,
              fontSize: "17px",
              minWidth: "70px"
            }}
          >
            {value3}
          </span>
        </div>
      </div>
    </div>
  );
}

export default SalesHeader;
