import React, { useState } from 'react';
import Header from '../component/SalesInfo/Header';
import DropdownSelect from '../component/DropdownSelect';

function Enquiry() {
  const [enquiryType, setEnquiryType] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log({ enquiryType, message });
  };

  const enquiryOptions = [
    { label: 'Accounts', value: 'Accounts' },
    { label: 'Service', value: 'Service' },
    { label: 'Technical', value: 'Technical' },
    { label: 'Other', value: 'Other' },
  ];

  return (
    <div>
      <Header text="Product Enquiry" />
      <div style={{ fontSize: "15px", marginTop: "10px" }}>
        <p>Email id-</p>
      </div>
      <div className="container text-left ">
        <form onSubmit={handleSubmit}>
          <div className="form-group row">
            <DropdownSelect
              selectId="enquiryType"
              label="Enquire for"
              selectedValue={enquiryType}
              setSelectedValue={setEnquiryType}
              options={enquiryOptions}
              width='60vw'
            />
          </div>
          {/*Text box for message */}
          <div className="form-group row mt-2">
            <label htmlFor="message" className="col-sm-2 col-form-label">Enquiry</label>
            <div className="col-sm-10">
              <textarea
                className="form-control"
                id="message"
                rows="8"
                placeholder="Your Message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                style={{
                  border: '1px solid black', boxShadow: 'none',
                  outline: 'none'
                }}
              ></textarea>
            </div>
          </div>
          <div className="form-group row text-center py-4">
            <div className="col-sm-10">
              <button type="submit" className="btn btn-danger px-4">Send</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default Enquiry;
