import React from "react";

function Invoices() {
  return (
    <div className="p-2">
      <div className="d-flex flex-row justify-content-between align-items-center">
        <div className="fs-1">Invoices</div>
        <div className="d-flex flex-row">
          <div
            style={{
              background: "#efefef",
              width: "50px",
              height: "50px",
              borderRadius: "50%",
            }}
          ></div>
        </div>
      </div>

      <div className="my-3">
        <input
          className="input-grey-rounded"
          type="text"
          placeholder="Search"
        ></input>
      </div>

      <div
        style={{ background: "#EFEFEF" }}
        className="d-flex flex-column p-2 rounded-2"
      >
        <div className="d-flex flex-row">
          <div
            style={{
              width: "80px",
              height: "80px",
              background: "#d9d9d9",
              borderRadius: "5px",
            }}
          ></div>
          <div
            className="d-flex flex-row justify-content-between"
            style={{ marginLeft: "10px", width: "100%" }}
          >
            <div className="d-flex flex-column text-start">
              <div>Concept LLC</div>
              <div>concept LLC web Banners</div>
              <div>Issue no. 99</div>
              <div>Issue date 09.02.2022</div>
            </div>
            <div className="d-flex flex-column">
              <div>$1090.90</div>
              <div>Outstanding Amt</div>
              <div>Approved</div>
            </div>
          </div>
        </div>
        <hr />
      </div>
    </div>
  );
}

export default Invoices;
