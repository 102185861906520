import React from 'react'
import SearchBar from '../component/SearchBar'
import CustomDatePicker from '../component/CustomDatePicker'
import { Card, Row } from 'react-bootstrap';
import { FaFileDownload } from 'react-icons/fa';

function DispatchedMaterial() {

  const dispatchedData = [
    {
        invoiceNumber: 'I/VJ/19-20/223',
        date: '05-03-2020',
        division: 'Lights',
        downloadInvoice: <FaFileDownload color='#FF3031' fontSize={24} />,
        transporter: 'Amravathi',
        amount: '₹ 99,750',
        lrNo: ''
    },
];

  const handleSearch = (searchValue) => {
    // Search logic here
    console.log('Search value:', searchValue);
  };
  return (
    <div className="container mt-3">
      {/* Search Bar */}
      <SearchBar placeholder=" SEARCH " onSearch={handleSearch} />

      {/* Date Pickers */}
      <div style={{ width: '90vw' }}>
        <Row className="mb-3">
          <CustomDatePicker label="From Date" controlId="fromDate" />
          <CustomDatePicker label="To Date" controlId="toDate" />
        </Row>
      </div>

      {dispatchedData.map((material, index) => (
                <Card
                    key={index}
                    className="mb-1"
                    style={{
                        width: '90vw',
                        backgroundColor: '#E9EAEB',
                        fontSize: '12px',
                        color: '#323e3e'
                    }}>
                    <Card.Header
                        style={{
                            backgroundColor: '#E9EAEB',
                            borderBottom: 'none'
                        }}>
                        <strong>Invoice Number</strong>
                        <br />
                        <span
                            style={{ fontSize: '16px' }}>
                            {material.invoiceNumber}
                        </span>
                    </Card.Header>
                    {/* Vertical bar */}
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row'
                        }}>
                        <div
                            style={{
                                backgroundColor: 'black',
                                width: '5px',
                                margin: '8px 2px 10px 16px'
                            }}>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                width: '100%',
                                padding: '0.8rem'
                            }}>
                            <div
                                style={{
                                    flex: 1,
                                    textAlign: 'left'
                                }}>
                                <div>
                                    <strong>Date</strong>
                                    <br />
                                    {material.date}
                                </div>
                                <div>
                                    <strong>Amount</strong>
                                    <br />
                                    {material.amount}
                                </div>
                                <div>
                                    <strong>LR No</strong>
                                    <br />
                                    {material.lrNo}
                                </div>
                            </div>
                            <div
                                style={{
                                    flex: 1,
                                    textAlign: 'left'
                                }}>
                                <div>
                                    <strong>Division</strong>
                                    <br />
                                    {material.division}
                                </div>
                                <div>
                                    <strong>Transporter</strong>
                                    <br />
                                    {material.transporter}
                                </div>
                                <div>
                                    <strong>DownLoad Invoice</strong>
                                    <br />
                                    {material.downloadInvoice}
                                </div>
                            </div>
                        </div>
                    </div>
                </Card>
            ))}
    </div>
  )
}

export default DispatchedMaterial