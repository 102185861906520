import React, { useState } from "react";
import Credit from "../component/CreditDebit/Credit";
import Debit from "../component/CreditDebit/Debit";

function SalesInfoHeader({ data, active, setActive }) {
  return (
    <div className="tabs d-flex justify-content-between px-2">
      {data.length > 0 &&
        data.map((item) => (
          <div
            key={item.id}
            onClick={() => setActive(item.id)}
            className={`item ${
              active === item.id ? "border-bottom border-4 border-danger" : ""
            } flex-grow-1 pb-2`}
          >
            <p className="text-uppercase fw-bold m-0">{item.title}</p>
          </div>
        ))}
    </div>
  );
}

function CreditDebitNote() {
  const [activeHeaderTab, setActiveHeaderTab] = useState(1);

  const headerData = [
    {
      id: 1,
      title: "Credit",
      width: "20px",
    },
    {
      id: 2,
      title: "Debit",
      width: "20px",
    },
  ];

  return (
    <div>
      <SalesInfoHeader data={headerData} active={activeHeaderTab} setActive={setActiveHeaderTab} />

      {activeHeaderTab === 1 && <Credit/>}
      {activeHeaderTab === 2 && <Debit/>}
    </div>
  );
}

export default CreditDebitNote;
