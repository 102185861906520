import { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router";
import Swal from "sweetalert2";
import { asyncWrap } from "../utils/utils";
import axios from "axios";
import { useAuth } from "../context/auth-context";
import { setToken } from "../axiosDefaults";
import VerificationCode from "../component/VerificationCode";
import AadhaarCard from "./AadhaarCard";

const Login = props => {
  const { setUser } = useAuth();
  const [mobile, setMobile] = useState("");
  const [otp, setOTP] = useState("");
  const history = useHistory();
  const captchaRef = useRef(null);
  const [showVerificationCode, setShowVerificationCode] = useState(false);
  const [showAadharCard, setShowAadharCard] = useState(false);
  const [cmsValues, setCmsValues] = useState();

  const getCmsValues = async () => {
    const [error, result] = await asyncWrap(axios.get("/cmsValues"));
    if (!result) {
      console.log(error);
      return;
    }
    setCmsValues(result.data.data);
  };

  useEffect(() => {
    getCmsValues();
    //eslint-disable-next-line
  }, []);

  const handleChange = otp => {
    setOTP(otp);
  };

  const handleLogin = async () => {
    if (mobile === "") {
      Swal.fire("Please enter mobile no and click get code");
    } else if (mobile.length !== 10) {
      Swal.fire("Please enter valid mobile no and click get code first");
    } else if (otp === "") {
      Swal.fire("Please enter otp.");
    } else {
      const [error, result] = await asyncWrap(
        axios.post("/verifyOtp", {
          phoneNumber: mobile,
          otp: otp ,
          // deviceKey: "",
          // deviceName: "MI 7 Note pro max",
          // deviceOs: "Android",
          // deviceVersion: "7.0",
          // referralCode: "",
        })
      );
      if (!result) {
        Swal.fire(error.response.data.message);
      }
      if (result.data.code === -2) {
        Swal.fire(result.data.message);
        return;
      }
      if (!result.data.isProfileCreated) {
        setToken(result.data.token);
        const [configError, configResult] = await asyncWrap(
          axios.get("config")
        );
        if (error) {
          console.log(configError);
        } else {
          const kyc_flag = configResult.data.data.filter(
            item => item.FlagName === "KYC Automatic verification"
          )[0].Value;
          if (kyc_flag === "true") {
            setShowAadharCard(true);
          } else {
            history.push(`/add-user`, { mobileNumber: mobile });
          }
        }
      } else {
        if (setUser) setUser(result.data);
        setToken(result.data.token);
        history.replace("/home");
      }
    }
  };

  const getCode = async () => {
    if (mobile === "") {
      Swal.fire("Please enter mobile no");
      return;
    } else if (mobile.length !== 10) {
      Swal.fire("Please enter valid mobile no");
      return;
    } else {
      const [error, result] = await asyncWrap(
        axios.post("/addNewUser", {
          phoneNumber: mobile,
        })
      );

      if (!result) {
        Swal.fire(error.response.data.message);
        return;
      }
      // Swal.fire(result.data.message);
    }
    setShowVerificationCode(true);
  };

  return (
    <>
      <VerificationCode
        show={showVerificationCode}
        setShow={setShowVerificationCode}
        handleChange={handleChange}
        handleLogin={handleLogin}
        otp={otp}
        mobile={mobile}
      />
      <AadhaarCard
        show={showAadharCard}
        setShow={setShowAadharCard}
        mobile={mobile}
      />
      <div
        style={{ height: "95vh" }}
        className="d-flex flex-column justify-content-between"
      >
        <div className="d-flex flex-column bg-white m-4">
          <div style={{ color: "#434343" }} className="fs-5 fw-bold">
            Login
          </div>
          <div className="mt-4">
            <div className="d-flex justify-content-center">
              <div style={{ color: "#7b7b7b", width: "80%" }}>
                Enter Your Mobile Number To A Recieve Verification Code
              </div>
            </div>
            <div className="mt-2">
              <div className="input-group mb-3">
                <span
                  style={{ background: "white" }}
                  className="input-group-text"
                  id="basic-addon1"
                >
                  +91
                </span>
                <input
                  value={mobile}
                  type="tel"
                  onChange={val => {
                    setMobile(val.target.value);
                  }}
                  className="form-control p-2"
                  placeholder="Phone Number"
                />
              </div>
              <button
                type="button"
                style={{ background: "#3a69dd" }}
                onClick={() => {
                  getCode();
                }}
                className="btn btn-primary fw-bold rounded-5 px-4"
              >
                Get Code
              </button>
            </div>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            width: "100%",
            bottom: "50px",
          }}
        >
          <img
            style={{
              height: "60px",
            }}
            key={cmsValues?.Logo.CMSType}
            src={cmsValues?.Logo.URL}
            alt=""
          />
        </div>
        <div id="recaptcha-container" ref={captchaRef}></div>
      </div>
    </>
  );
};

export default Login;
