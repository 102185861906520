import { usePagination } from "../../hooks";
import { List, Pagination, Card, Descriptions, Skeleton } from "antd";

const RedeemGamePoint = (props) => {
  const { data, gotoPage, loading } = usePagination(
    `point-type/ledger?id=${props.id}`
  );

  return (
    <Skeleton active loading={loading}>
      <Card>
        <List
          dataSource={data}
          renderItem={(item, key) => (
            <List.Item key={key}>
              <Descriptions>
                <Descriptions.Item>{item.CreatedOn}</Descriptions.Item>
              </Descriptions>
              <List.Item.Meta
                // title={item.WithdrawType}
                description={item.Comment}
              />
              <div style={{ color: "green" }}>{item.Point}</div>
            </List.Item>
          )}
        />
        <nav
          className="mt-5"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Pagination
            showQuickJumper
            current={1}
            defaultPageSize={10}
            total={data.length}
            showSizeChanger={false}
            onChange={(page) => gotoPage(page)}
          />
        </nav>
      </Card>
    </Skeleton>
  );
};

export default RedeemGamePoint;
