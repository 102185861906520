const RedeemIcon = () => (
  <div className="flex flex-col items-center justify-center p-[10px]">
    <svg
      width="34"
      height="32"
      viewBox="0 0 34 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.3333 7.00065H26.7C26.8833 6.48398 27 5.91732 27 5.33398C27 2.56732 24.7667 0.333984 22 0.333984C20.25 0.333984 18.7333 1.23398 17.8333 2.58398L17 3.70065L16.1667 2.56732C15.2667 1.23398 13.75 0.333984 12 0.333984C9.23333 0.333984 6.99999 2.56732 6.99999 5.33398C6.99999 5.91732 7.11666 6.48398 7.29999 7.00065H3.66666C1.81666 7.00065 0.349995 8.48398 0.349995 10.334L0.333328 28.6673C0.333328 30.5173 1.81666 32.0007 3.66666 32.0007H30.3333C32.1833 32.0007 33.6667 30.5173 33.6667 28.6673V10.334C33.6667 8.48398 32.1833 7.00065 30.3333 7.00065ZM22 3.66732C22.9167 3.66732 23.6667 4.41732 23.6667 5.33398C23.6667 6.25065 22.9167 7.00065 22 7.00065C21.0833 7.00065 20.3333 6.25065 20.3333 5.33398C20.3333 4.41732 21.0833 3.66732 22 3.66732ZM12 3.66732C12.9167 3.66732 13.6667 4.41732 13.6667 5.33398C13.6667 6.25065 12.9167 7.00065 12 7.00065C11.0833 7.00065 10.3333 6.25065 10.3333 5.33398C10.3333 4.41732 11.0833 3.66732 12 3.66732ZM30.3333 28.6673H3.66666V25.334H30.3333V28.6673ZM30.3333 20.334H3.66666V10.334H12.1333L8.66666 15.0507L11.3667 17.0007L15.3333 11.6007L17 9.33398L18.6667 11.6007L22.6333 17.0007L25.3333 15.0507L21.8667 10.334H30.3333V20.334Z"
        fill="#434343"
      />
    </svg>
  </div>
);

export default RedeemIcon;
