import {
  translationsEnglish,
  translationsHindi,
  translationsTamil,
  translationsKannada,
  translationsMarathi,
  translationsBengali,
  translationsFrench,
  translationsGerman,
  translationsGujarati,
  translationsJapanese,
  translationsMalyalam,
  translationsPanjabi,
  translationsPortugese,
  translationsSpanish,
  translationsTelugu,
} from "./language";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  resources: {
    english: { translation: translationsEnglish },
    hindi: { translation: translationsHindi },
    tamil: { translation: translationsTamil },
    kannada: { translation: translationsKannada },
    malyalam: { translation: translationsMalyalam },
    panjabi: { translation: translationsPanjabi },
    marathi: { translation: translationsMarathi },
    bengali: { translation: translationsBengali },
    gujarati: { translation: translationsGujarati },
    telugu: { translation: translationsTelugu },
    french: { translation: translationsFrench },
    spanish: { translation: translationsSpanish },
    german: { translation: translationsGerman },
    portuguese: { translation: translationsPortugese },
    japanese: { translation: translationsJapanese },
  },
  lng: "english",
  fallbackLng: "english",
  interpolation: { escapeValue: false },
});
export default i18n;
