import React from "react";
import { AiFillStar } from "react-icons/ai";

const Star = ({ points }) => {
  return (
    <div>
      <AiFillStar />{" "}
      <span style={{ color: "black", fontSize: "25px" }}>{points}</span>
    </div>
  );
};

export default Star;
