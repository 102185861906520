import React from 'react'
import Header from '../SalesInfo/Header'
import PaymentHeader from './PaymentHeader'
import SalesTable from '../CategoryWiseSales/SalesTable'
import PaymentTable from './PaymentTable'
import { AiOutlineFilePdf } from "react-icons/ai";
import PaymentBtn from './PaymentBtn'

function PaymentInfo() {
  const agingReportColumn = [
    {
      id: 1,
      name: "Period (Days)",
    },
    {
      id: 2,
      name: "Amount",
    },
  ];

  const agingReportData = [
    {
      period: "0-30",
      amount: "₹ 18,077"
    },
    {
      period: "31-60",
      amount: "₹ 10,16,660"
    },
    {
      period: "61-90",
      amount: "₹ 11,74,434"
    },
    {
      period: "91-120",
      amount: "₹ 3,19,434"
    }
  ];

  const lastPaymentColumns = [
    {
      id: 1,
      name: "Date",
    },
    {
      id: 2,
      name: "Amount",
    },
    {
      id: 3,
      name: "Mode",
    },
    {
        id: 4,
        name: "Status",
      },
  ];

  const lastPaymentDatas = [
    {
      date: "05-03-2020",
      amount: "₹ 26,551",
      mode: "55.36%",
      status: "Approved"
    },
    {
      date: "22-02-2020",
      amount: "₹ 26,551",
      mode: "55.36%",
      status: "Approved"
    },
    {
      date: "20-02-2020",
      amount: "₹ 26,551",
      mode: "55.36%",
      status: "Approved"
    },
    {
      date: "18-02-2020",
      amount: "₹ 26,551",
      mode: "55.36%",
      status: "Approved"
    },
    {
      date: "06-02-2020",
      amount: "₹ 26,551",
      mode: "55.36%",
      status: "Approved"
    },
    {
      date: "05-02-2020",
      amount: "₹ 26,551",
      mode: "55.36%",
      status: "Approved"
    }
  ];

  
  return (
    <div>
      <Header text="Outstanding payment"/>
      <PaymentHeader/>
      <PaymentTable/>
      <div className='mt-2'></div>
      <Header text="Aging Report"/>
      <SalesTable column={agingReportColumn} data={agingReportData} />
      <PaymentBtn text={<>Download PDF <span><AiOutlineFilePdf size={18} className='mb-1' color='white'/></span> </>} style={{borderRadius:"0px", border: 'none'}}/>
      <div className='mt-2'></div>
      <Header text="Last Payments"/>
      
      <SalesTable column={lastPaymentColumns} data={lastPaymentDatas}/>
      <PaymentBtn text="All Payments" style={{borderRadius:"0px", border: 'none'}} />
    </div>
  )
}

export default PaymentInfo