import React, { useState, useContext } from "react";
import { Modal, Row, Col, Card, Button, Image } from "react-bootstrap";
import { FiPause } from "react-icons/fi";
import LeaderBoard from "./LeaderBoard";
import correct from "/assets/images/correct.svg";
import wrong from "/assets/images/wrong.svg";
import { asyncWrap } from "../../utils/utils";
import axios from "axios";
import {  UsersPointsData } from "../../context/QuizContext";

const AnswerPage = (props) => {
  const [showLeaderBoard, setShowLeaderBoard] = useState(false);
  const [leaderBoardData, setLeaderBoardData] = useState();
  const UsersPointsContextData = useContext(UsersPointsData)

  const {
    show,
    setShow,
    currentQuestion,
    questions,
    correctAnswer,
    selectedOption,
    setCurrentQuestion,
    quizId,
  } = props;

  const SubmitAnswer = async () => {
    setShow(false);
    setShowLeaderBoard(true);
    GetLeaderboardData();
    const data = {
      quizId: quizId,
      quizQuestionID: questions[currentQuestion].QuizQuestionID,
      timeTaken: questions[currentQuestion].Time,
      answerStatus:
        questions[currentQuestion].CorrectAnswer === selectedOption
          ? "Correct"
          : "Wrong",
      points: questions[currentQuestion].PointValue,
      UserAnswer: selectedOption || "",
    };

    const [err, result] = await asyncWrap(axios.post("/quiz-questions", data));
    if (!result) {
      console.log(err);
    }
  };

  const GetLeaderboardData = async () => {
    const [error, result] = await asyncWrap(axios.get("quiz/leader-board"));
    if (!result) {
      console.log(error);
      return;
    }
    setLeaderBoardData(result.data.data);
  };

  const CheckAnswer = () => {
    if (
      !correctAnswer &&
      questions[currentQuestion].CorrectAnswer === selectedOption
    ) {
      return (
        <Row style={{ marginTop: "20px" }}>
          <Col
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              style={{
                width: "80%",
                borderRadius: "10px",
                background: "#6BD35A",
              }}
            >
              {questions[currentQuestion].CorrectAnswer}
              <Image src={correct} style={{ marginLeft: "50px" }} alt="" />
            </Button>
          </Col>
        </Row>
      );
    } else if (correctAnswer) {
      return (
        <Row style={{ marginTop: "20px" }}>
          <Col
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              style={{
                width: "80%",
                borderRadius: "10px",
                background: "#6BD35A",
              }}
            >
              {questions[currentQuestion].CorrectAnswer}
              <Image src={correct} style={{ marginLeft: "50px" }} alt="" />
            </Button>
          </Col>
        </Row>
      );
    } else if (!selectedOption) {
      return (
        <>
          <Row style={{ marginTop: "20px" }}>
            <Col
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                style={{
                  width: "80%",
                  borderRadius: "10px",
                  background: "#6BD35A",
                }}
              >
                {questions[currentQuestion].CorrectAnswer}
                <Image src={correct} style={{ marginLeft: "50px" }} alt="" />
              </Button>
            </Col>
          </Row>
          <Row style={{ marginTop: "20px" }}>
            <Col
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                style={{
                  width: "80%",
                  borderRadius: "10px",
                  background: "#E44747",
                }}
              >
                No option selected
                <Image src={wrong} style={{ marginLeft: "50px" }} alt="" />
              </Button>
            </Col>
          </Row>
        </>
      );
    } else {
      return (
        <>
          <Row style={{ marginTop: "20px" }}>
            <Col
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                style={{
                  width: "80%",
                  borderRadius: "10px",
                  background: "#6BD35A",
                }}
              >
                {questions[currentQuestion].CorrectAnswer}
                <Image src={correct} style={{ marginLeft: "50px" }} alt="" />
              </Button>
            </Col>
          </Row>
          <Row style={{ marginTop: "20px" }}>
            <Col
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                style={{
                  width: "80%",
                  borderRadius: "10px",
                  background: "#E44747",
                }}
              >
                {selectedOption}
                <Image src={wrong} style={{ marginLeft: "50px" }} alt="" />
              </Button>
            </Col>
          </Row>
        </>
      );
    }
  };

  return (
    <div>
      <LeaderBoard
        show={showLeaderBoard}
        setShow={setShowLeaderBoard}
        currentQuestion={currentQuestion}
        setCurrentQuestion={setCurrentQuestion}
        questions={questions}
        leaderBoardData={leaderBoardData}
      />
      <Modal fullscreen={true} show={show} onHide={() => setShow(false)}>
        {/* <Modal.Header> */}
          {/* <SecoundaryHeader sideHide="false" onBack={() => setShow(false)} /> */}
        {/* </Modal.Header> */}
        <Modal.Body>
          <Row
            style={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <Col
              xs={2}
              style={{
                background: "#8D69F9",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "10px",
              }}
            >
              <FiPause style={{ fontSize: "30px", color: "#fff" }} />
            </Col>
            <Col
              xs={2}
              style={{
                background: "#D288FF",
                borderRadius: "10px",
                color: "#fff",
              }}
            >
              <span style={{ fontSize: "30px" }}>{currentQuestion + 1}</span>/
              {questions.length}
              <p style={{ fontSize: "12px", fontWeight: "400" }}>Question</p>
            </Col>
            {/* <Col
              xs={2}
              style={{
                background: "#D288FF",
                borderRadius: "10px",
                color: "#fff",
              }}
            >
              <span style={{ fontSize: "30px" }}>5</span>/15
              <p style={{ fontSize: "12px", fontWeight: "400" }}>Avg.Time</p>
            </Col> */}
            <Col
              xs={2}
              style={{
                background: "#D288FF",
                borderRadius: "10px",
                color: "#fff",
              }}
            >
              <span style={{ fontSize: "30px" }}>{UsersPointsContextData[0]?.Rank}</span>
              <p style={{ fontSize: "12px", fontWeight: "400" }}>Rank</p>
            </Col>
            <Col
              xs={2}
              style={{
                background: "#D288FF",
                borderRadius: "10px",
                color: "#fff",
              }}
            >
              <span style={{ fontSize: "30px" }}>{UsersPointsContextData[0]?.Points}</span>
              <p style={{ fontSize: "12px", fontWeight: "400" }}>Points</p>
            </Col>
          </Row>
          {questions.length !== 0 && currentQuestion <= questions.length ? (
            <>
              <Row style={{ marginTop: "20px" }}>
                <Col>
                  <Card
                    style={{
                      height: "250px",
                      borderRadius: "20px",
                      backgroundColor: "#CEBDFF",
                    }}
                  >
                    <Card.Body
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: "#000000",
                          fontSize: "20px",
                          fontWeight: "700",
                        }}
                      >
                        {questions[currentQuestion].Question}
                      </p>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              {CheckAnswer()}
            </>
          ) : null}
          <Row style={{ marginTop: "20px" }}>
            <Col style={{ display: "flex", justifyContent: "right" }}>
              <Button
                onClick={SubmitAnswer}
                style={{
                  width: "80px",
                  height: "40px",
                  background: "#8D69F9",
                  color: "#fff",
                  fontWeight: "500",
                  marginLeft: "10px",
                  borderRadius: "20px",
                }}
              >
                Next
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AnswerPage;
