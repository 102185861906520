import { Modal } from "react-bootstrap";
import tropyImage from "/assets/images/Redeem/trophy.png";
import { useEffect, useState } from "react";
import { asyncWrap } from "../../utils/utils";
import axios from "axios";
import RedeemIcon from "../../icons/redeemIcon";
import { useTranslation } from "react-i18next";

const RedeemPointDetails = ({ show, setShow, data }) => {
  const [totalRedeemPoints, setTotalRedeemPoint] = useState();
  const { t } = useTranslation();

  const GetTotalPoints = async () => {
    const [error, result] = await asyncWrap(axios.get("/earnedPoints"));
    if (!result) {
      console.log(error);
      return;
    }
    setTotalRedeemPoint(result.data.data.totalPoints[0].point);
  };

  useEffect(() => {
    GetTotalPoints();
    //eslint-disable-next-line
  }, []);

  return (
    <Modal fullscreen={true} show={show}>
      {/* <Modal.Header> */}
      {/* <SecoundaryHeader
          text={t("earned_points")}
          sideHide="false"
          onBack={() => {
            history.push("/points-info");
            setShow(false);
          }}
        /> */}
      {/* </Modal.Header> */}
      <Modal.Body style={{ marginTop: "30px" }}>
        <div
          style={{ color: "#7B7B7B" }}
          className="d-flex flex-column justify-content-center"
        >
          <div className="d-flex flex-column align-items-center">
            <div className="d-flex flex-row align-items-center justify-content-center mt-3">
              <img
                style={{ marginRight: "10px", width: "35px", height: "35px" }}
                src={tropyImage}
                alt="trophyimage"
              />
              <div
                style={{ color: "#3A69DD", fontSize: "35px" }}
                className="fw-bold"
              >
                {totalRedeemPoints}
              </div>
              <img
                style={{ marginLeft: "10px", width: "35px", height: "35px" }}
                src={tropyImage}
                alt="tropyimage"
              />
            </div>
            <div
              style={{ color: "#7B7B7B", fontSize: "18px" }}
              className="fw-bold mt-1"
            >
              {t("rewards_points")}
            </div>
          </div>
          <div className="d-flex flex-column shadow m-2 p-3 rounded-2">
            <div className="d-flex flex-row">
              <div
                style={{ fontSize: "16px" }}
                className="d-flex flex-column w-50 flex-wrap justify-content-start"
              >
                <div className="d-flex flex-column mt-3">
                  <div>{t("transcation_date")}</div>
                  <div style={{ color: "#434343" }} className="fw-bold">
                    {data?.CreatedOn}
                  </div>
                </div>
                <div className="d-flex flex-column mt-3">
                  <div>Withdraw Type</div>
                  <div style={{ color: "#434343" }} className="fw-bold">
                    {data.WithdrawType ? data.WithdrawType : "NA"}
                  </div>
                </div>
              </div>
              <div
                style={{ fontSize: "16px" }}
                className="d-flex flex-column w-50 justify-content-start align-content-center"
              >
                <div className="d-flex flex-column text-center mt-3">
                  <RedeemIcon />
                  <div style={{ fontSize: "14px" }} className="fw-light mt-2">
                    {data?.Point} {t("points")}
                  </div>
                </div>
                <div className="d-flex flex-column text-center mt-3">
                  <div>{t("status")}</div>
                  <div style={{ color: "#434343" }} className="fw-bold">
                    {data.StatusText ? data.StatusText : "NA"}
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex flex-column mt-3">
              <div>Transaction Id</div>
              <div style={{ color: "#434343" }} className="fw-bold">
                {data.TransId ? data.TransId : "NA"}
              </div>
            </div>
            <div className="d-flex flex-column mt-3">
              <div>Descriptions : </div>
              <div style={{ color: "#434343" }} className="fw-bold text-break">
                {data.Description ? data.Description : "NA"}
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default RedeemPointDetails;
