import { useEffect, useState } from "react";
import { Modal, Image, Row, Col, Button } from "react-bootstrap";
import { AiFillRightCircle, AiFillLeftCircle } from "react-icons/ai";
import JourneyDetails from "./JourneyDetails";
import medal from "/assets/images/medal.png";

import Background from "/assets/images/Journey/01.jpg";
import aeroplane from "/assets/images/Journey/aeroplane.png";
import { useHistory } from "react-router";
import anime from "animejs/lib/anime.es.js";
import "./journey.css";

// const { Anime } = ReactAnime;
const Journey = ({ show, setShow, data, userLevel }) => {
  const [showJourneyDetails, setShowJourneyDetails] = useState(false);
  const [showAchivement, setShowAchievement] = useState(false);
  const history = useHistory();

  // const [gameRanking, setGameRanking] = useState(0);
  const [points, setPoints] = useState();

  // useEffect(() => {
  //   getGameRanking();
  //   // eslint-disable-next-line
  // }, [show])

  // const ordinalSuffix = ["st", "nd", "rd"];
  // const addSuffix = (n) => n + (ordinalSuffix[(n - 1) % 10] || "th");
  // const numberToOrdinal = (n) =>
  //   `${n}`.match(/1\d$/) ? n + "th" : addSuffix(n);

  // const getGameRanking = async () => {
  //   let res = await ApiGetCall(`items/usersrank?filter[userid]=1&filter[gameid]=${data?.gameid ?? 0}`);
  //   if (res.data[0]?.overallrank) {
  //     setGameRanking(numberToOrdinal(res.data[0]?.overallrank));
  //   }
  // }
  // eslint-disable-next-line
  const [active, setActive] = useState(6);

  useEffect(() => {
    var path = anime.path(".motion-path-demo path");
    anime({
      targets: ".motion-path-demo .el",
      translateX: path("x"),
      translateY: path("y"),
      rotate: path("angle"),
      easing: "linear",
      duration: 10000,
      // loop: true
    });
  }, []);

  const showPoints = (level) => {
    setPoints(null);
    let points = data.filter((item) => item.PointType === level)[0]?.Points;
    setPoints(points);
    setShowJourneyDetails(true);
  };

  // const [meta, setMeta] = useState({
  //   progress: 0,
  //   currentTime: 0,
  //   duration: 0,
  // });

  return (
    <>
      {data && (
        <JourneyDetails
          show={showJourneyDetails}
          setShow={setShowJourneyDetails}
          data={data}
          points={points}
        />
      )}
      <Modal fullscreen={true} show={show} onHide={() => setShow(false)}>
        <Modal.Body className="pb-20 p-0 text-left">
          <div
            style={
              {
                // backgroundImage: `url(${Background})`,
                // backgroundSize: "cover",
                // backgroundPosition: "top",
                // overflowX: "hidden",
                // height: "1800px",
              }
            }
          >
            <Row className="pt10">
              <Col className="text-left" xs={2}>
                <img
                  onClick={() => setShow(false)}
                  src={
                    require("/assets/images/topbar/Arrows@2x.png")
                      .default
                  }
                  alt="act"
                  style={{ width: "30px", marginRight: " 10px" }}
                />
              </Col>
              <Col
                className="text-center nav-title pt-1"
                style={{ color: "white" }}
              >
                {data?.title}
                <p style={{ color: "white", fontSize: 10, margin: 0 }}>
                  Updated on
                </p>
                <p style={{ color: "#DC7832", fontSize: 10, margin: 0 }}>
                  {data?.days} Days Left
                </p>
              </Col>
              <Col className="text-right" xs={2}>
                <span></span>
              </Col>
            </Row>

            {/* <h1 className="mt-5 text-center">Travel The World</h1> */}

            {showAchivement ? (
              <div
                className="float-right"
                style={{
                  position: "absolute",
                  right: 0,
                  top: "50px",
                  width: "200px",
                  zIndex: 100,
                }}
              >
                <p className="text-center mt-5">
                  <div
                    className="rounded"
                    style={{
                      background:
                        "linear-gradient(145deg,#000000, #484848 100%)",
                    }}
                  >
                    <AiFillRightCircle
                      onClick={() => setShowAchievement(false)}
                      style={{
                        color: "#DC7832",
                        position: "relative",
                        display: "flex",
                        right: "0.5rem",
                        top: "5rem",
                        fontSize: "23px",
                      }}
                    />
                    <p style={{ color: "#FFFEF2", fontSize: 13 }}>
                      Your Achievements
                    </p>
                    <Row className="text-white">
                      <Col>
                        <p className="text-white m-0">
                          3/4
                          {/* {data?.badgeEarned}/{data?.badgeTotal} */}
                        </p>
                      </Col>
                      <Col>
                        <p className="text-white m-0">
                          60/100
                          {/* {data?.scoreEarned}/{data?.socreTotal} */}
                        </p>
                      </Col>
                    </Row>
                    <Row className="text-white">
                      <Col>
                        <p style={{ fontSize: "12px" }}>Bagde</p>
                      </Col>
                      <Col>
                        <p style={{ fontSize: "12px" }}>Score</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p className="text-white m-0">
                          1/3
                          {/* {data?.badgeEarned}/{data?.totalCertificate} */}
                        </p>
                      </Col>
                      <Col>
                        {/* <p className="text-white m-0">{gameRanking}</p> */}
                      </Col>
                    </Row>
                    <Row className="text-white">
                      <Col>
                        <p style={{ fontSize: "12px" }}>Certificates</p>
                      </Col>
                      <Col onClick={() => history.push("/rank")}>
                        <p style={{ fontSize: "12px" }}>Rank</p>
                      </Col>
                    </Row>
                  </div>
                </p>
              </div>
            ) : (
              <div
                style={{
                  top: "100px",
                  width: "80px",
                  height: "60px",
                  position: "absolute",
                  right: "0",
                  background: "#FAD6A5 0% 0% no-repeat padding-box",
                  borderRadius: "10px",
                  border: "1px solid #FFFFFF",
                  zIndex: 100,
                }}
              >
                <div className="rounded">
                  <AiFillLeftCircle
                    onClick={() => {
                      setShowAchievement(true);
                    }}
                    style={{
                      color: "#DC7832",
                      justifyContent: "center",
                      position: "absolute",
                      top: "20px",
                      alignItems: "center",
                      fontSize: "20px",
                    }}
                  />
                  <Image
                    src={medal}
                    style={{
                      width: "50px",
                      marginLeft: "20px",
                      marginTop: "5px",
                    }}
                  />
                </div>
              </div>
            )}
            {/* <Anime
              // setMeta={setMeta}
              animeConfig={{
                duration: 3000,
                easing: "linear",
              }}
              initial={[
                {
                  targets: "#img",
                  translateX: 100,
                  translateY:
                    userLevel === "Level 1"
                      ? 80
                      : userLevel === "Level 2"
                      ? 250
                      : userLevel === "Level 3"
                      ? 430
                      : userLevel === "Level 4"
                      ? 700
                      : userLevel === "Level 5"
                      ? 920
                      : "",
                  easing: "linear",
                },
              ]}
            >
              <Image src={person} alt="" id="img" style={{ width: 80 }} />
            </Anime> */}

            <div
              style={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
                width: "100%",
                overflowX: "hidden",
              }}
            >
              <div>
                <img src={Background} alt="" />
              </div>
              {/* <div
        style={{
          backgroundImage: `url(${img})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          overflowX: "hidden",
          width: "480px",
          // height: "1600px"
        }}
      > */}
              <div
                // style={{ width: "100%" }}
                className="animationgroup motion-path-demo"
              >
                <div style={{ visibility: "hidden" }}>
                  {active === 1 ? (
                    <svg
                      // style={{ marginTop: "100px" }}
                      width="197"
                      // height="1285"
                      X=""
                      viewBox="0 0 197 1285"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M1.5 1H196V204" stroke="black" />
                    </svg>
                  ) : active === 2 ? (
                    <svg
                      // style={{ marginTop: "100px" }}
                      width="197"
                      // height="1285"
                      X=""
                      viewBox="0 0 197 1285"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M1.5 1H196V204.5H1V430" stroke="black" />
                    </svg>
                  ) : active === 3 ? (
                    <svg
                      // style={{ marginTop: "100px" }}
                      width="197"
                      // height="1285"
                      X=""
                      viewBox="0 0 197 1285"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.5 1H196V204.5H1V430.5H194.5V632"
                        stroke="black"
                      />
                    </svg>
                  ) : active === 4 ? (
                    <svg
                      // style={{ marginTop: "100px" }}
                      width="197"
                      // height="1285"
                      X=""
                      viewBox="0 0 197 1285"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.5 1H196V204.5H1V430.5H194.5V632.5H1.5V859"
                        stroke="black"
                      />
                    </svg>
                  ) : active === 5 ? (
                    <svg
                      // style={{ marginTop: "100px" }}
                      width="197"
                      // height="1285"
                      X=""
                      viewBox="0 0 197 1285"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.5 1H196V204.5H1V430.5H194.5V632.5H1.5V859H194V1060"
                        stroke="black"
                      />
                    </svg>
                  ) : (
                    <svg
                      // style={{ marginTop: "100px" }}
                      width="197"
                      // height="1285"
                      X=""
                      viewBox="0 0 197 1285"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.5 1H196V204.5H1V430.5H194.5V632.5H1.5V859H194V1060.5H2.5V1285"
                        stroke="black"
                      />
                    </svg>
                  )}
                </div>

                <div>
                  <img className="el" alt="" src={aeroplane} />
                </div>
              </div>
            </div>

            <div style={{position:"absolute",zIndex:100,top:0}}>
              <p className="text-center mt-5" style={{ height: "120px" }}>
                {showAchivement && (
                  <Button
                    className="fs-10"
                    style={{
                      background:
                        "linear-gradient(150deg,#C7ADA2, #AD8676 100%)",
                      marginRight: 90,
                      position: "relative",
                    }}
                    onClick={() => {
                      showPoints("L1 Points");
                    }}
                  >
                    {userLevel >= "Level 1" ? (
                      <Image
                        src={
                          require("/assets/images/check.png").default
                        }
                        alt=""
                        style={{
                          fontSize: 20,
                          color: "green",
                          position: "absolute",
                          top: -7,
                          left: 26,
                        }}
                      />
                    ) : (
                      <Image
                        style={{ width: 8 }}
                        src={require("/assets/images/lock.png").default}
                        alt=""
                      />
                    )}
                    Bronze
                  </Button>
                )}
              </p>
              <p className="text-center mt-5" style={{ height: "50px" }}>
                {showAchivement && (
                  <Button
                    className="fs-10"
                    style={{
                      background:
                        "linear-gradient(150deg,#F1F1F1, #9E9999 100%)",
                      marginLeft: 20,
                      position: "relative",
                    }}
                    onClick={() => {
                      showPoints("L2 Points");
                    }}
                  >
                    <span
                      style={{
                        padding: "0px 5px",
                        background: "#484848",
                        border: "1px solid white",
                        position: "absolute",
                        top: -10,
                        left: 22,
                        borderRadius: 30,
                      }}
                    >
                      {userLevel >= "Level 2" ? (
                        <Image
                          src={
                            require("/assets/images/check.png").default
                          }
                          alt=""
                          style={{
                            // fontSize: 20,
                            color: "green",
                            position: "absolute",
                            // top: -7,
                            left: 1,
                          }}
                        />
                      ) : (
                        <Image
                          style={{ width: 8 }}
                          src={
                            require("/assets/images/lock.png").default
                          }
                          alt=""
                        />
                      )}
                    </span>
                    Silver
                  </Button>
                )}
              </p>
              <p className="text-center mt-5 pt-5" style={{ height: "150px" }}>
                {showAchivement && (
                  <Button
                    className="fs-10"
                    style={{
                      background:
                        "linear-gradient(150deg,#FED526, #F9A02C 100%)",
                      marginRight: 40,
                      position: "relative",
                    }}
                    onClick={() => {
                      showPoints("L3 Points");
                    }}
                  >
                    <span
                      style={{
                        padding: "0px 5px",
                        background: "#E29925",
                        border: "1px solid white",
                        position: "absolute",
                        top: -10,
                        left: 17,
                        borderRadius: 30,
                      }}
                    >
                      {userLevel >= "Level 3" ? (
                        <Image
                          src={
                            require("/assets/images/check.png").default
                          }
                          alt=""
                          style={{
                            // fontSize: 20,
                            color: "green",
                            position: "absolute",
                            // top: -7,
                            left: 1,
                          }}
                        />
                      ) : (
                        <Image
                          style={{ width: 8 }}
                          src={
                            require("/assets/images/lock.png").default
                          }
                          alt=""
                        />
                      )}
                    </span>
                    Gold
                  </Button>
                )}
              </p>
              <p className="text-center mt-5 pt-5" style={{ height: "140px" }}>
                {showAchivement && (
                  <Button
                    className="fs-10"
                    style={{
                      background:
                        "linear-gradient(150deg,#26B4FE, #2CF2F9 100%)",
                      marginRight: 70,
                      marginTop: 95,
                      position: "relative",
                    }}
                    onClick={() => {
                      showPoints("L4 Points");
                    }}
                  >
                    <span
                      style={{
                        padding: "0px 5px",
                        background: "#1999C8",
                        border: "1px solid white",
                        position: "absolute",
                        top: -10,
                        left: 30,
                        borderRadius: 30,
                      }}
                    >
                      {userLevel >= "Level 4" ? (
                        <Image
                          src={
                            require("/assets/images/check.png").default
                          }
                          alt=""
                          style={{
                            // fontSize: 20,
                            color: "green",
                            position: "absolute",
                            // top: -7,
                            left: 1,
                          }}
                        />
                      ) : (
                        <Image
                          style={{ width: 8 }}
                          src={
                            require("/assets/images/lock.png").default
                          }
                          alt=""
                        />
                      )}
                    </span>
                    Diamond
                  </Button>
                )}
              </p>
              <p
                className="text-center mt-5 pt-5 mb-0 pb-5"
                style={{ height: "100px" }}
              >
                {showAchivement && (
                  <Button
                    className="fs-10"
                    style={{
                      background:
                        "linear-gradient(150deg,#26FE7C, #07C157 100%)",
                      marginLeft: 80,
                      marginTop: 125,
                      position: "relative",
                    }}
                    onClick={() => {
                      showPoints("L5 Points");
                    }}
                  >
                    <span
                      style={{
                        padding: "0px 5px",
                        background: "#049E45",
                        border: "1px solid white",
                        position: "absolute",
                        top: -10,
                        left: 32,
                        borderRadius: 30,
                      }}
                    >
                      {userLevel >= "Level 5" ? (
                        <Image
                          src={
                            require("/assets/images/check.png").default
                          }
                          alt=""
                          style={{
                            // fontSize: 20,
                            color: "green",
                            position: "absolute",
                            // top: -7,
                            left: 1,
                          }}
                        />
                      ) : (
                        <Image
                          style={{ width: 8 }}
                          src={
                            require("/assets/images/lock.png").default
                          }
                          alt=""
                        />
                      )}
                    </span>
                    Signature
                  </Button>
                )}
              </p>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Journey;
