import React from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap';


function SummaryModal({show, onHide, column, data}) {

  return (
    <div className='mx-auto'>
  <Modal isOpen={show} toggle={onHide} className="mx-5ß d-flex"
            style={{marginTop: "25vh"}} >
    <ModalHeader className='text-center' toggle={onHide}>Agreement Details</ModalHeader>
    <ModalBody>
      <table className='table table-bordered'>
      <thead>
    <tr>
      {column.length > 0 && column.map((item,i) => (
        <th scope="col" style={{
          backgroundColor: "#ADAEB0", 
          border: "2px solid white", 
          minWidth: "calc(25% - 2px)"
        }} key={i} >
          {item.name}
          </th>
      ))
      }
    </tr>
  </thead>
  <tbody>
    {data.length > 0 && data.map((item, key) => (
          <tr key={key}>
            {column.length > 0 && column.map((el, i) => (
          <td
          key={i}
          style={{
            backgroundColor: "#E9EAEB", 
          border: "2px solid white", 
          minWidth: "calc(25% - 2px)",
          }}>
            {item[el?.name]}
          </td>
            ))}
        </tr>
    ))
    }
  </tbody>
      </table>
    </ModalBody>
  </Modal>
  </div>
  )
}

export default SummaryModal