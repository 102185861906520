import { Image, Modal } from "react-bootstrap";
import close from "/assets/images/popclose.png";
import popupbg from "/assets/images/popupbg.png";

const StickerDetail = ({ show, setShow, data }) => {
  return (
    <>
      {console.log(data)}
      <Modal
        fullscreen={false}
        backdrop="static"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={() => setShow(false)}
      >
        <Modal.Body
          style={{
            backgroundImage: `url(${popupbg})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            overflowY: "hidden",
            width: "70vw",
            overflowX: "hidden",
          }}
          className="m-0 p-0 text-left"
        >
          <span className="modal-close">
            <Image src={close} alt="" onClick={() => setShow(false)} />
          </span>
          <div className="text-center">
            <span className="mt-3 popup-image">
              <Image src={data?.StickerImage} alt="" style={{ width: 50 }} />
            </span>

            <p className="mt-1 mb-0 fs-20">{data?.StickerName}</p>
            <p className="mt-1 mb-0 fs-15">{data?.Descriptions}</p>
            <p
              className="mt-1 mb-0 fs-10 mb-5"
              style={{ color: "#DC7832" }}
            ></p>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default StickerDetail;
