import React from "react";
import { useTranslation } from "react-i18next";

const Header = () => {

  const { t } = useTranslation();
  return (
    <>
      <div>
        <h1 style={{color: 'white'}}>{t('hangman')}</h1>
        <p style={{ color: 'white' }}>{ t('find_the_hidden_word')}</p>
      </div>
    </>
  );
};

export default Header;
