import React from 'react';

function PaymentBtn({ text, style, onClick }) {
  const defaultStyle = {
    backgroundColor: "#FF3031", 
    color: "white",
    borderRadius: "25px",
    fontSize: "14px",
    padding: "0.6em",
    width: "100%"
  };

  const newStyle = { ...defaultStyle, ...style };

  return (
    <button style={newStyle} onClick={onClick}>
      {text}
    </button>
  );
}

export default PaymentBtn;
