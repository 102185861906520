import { Nav, NavItem } from "reactstrap";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
const BottomTab = (props) => {
  const { t } = useTranslation();
  const { selected, setSelected } = props;

  return (
    <div>
      <nav
        className="navbar fixed-bottom navbar-light d-block  bottom-tab-nav"
        role="navigation"
        style={{ background: "white" }}
      >
        <Nav className="w-100">
          <div
            className=" d-flex flex-row justify-content-around w-100"
            style={{ boxShadow: "0 -13px 20px -8px rgb(115 115 115 / 25%)" }}
          >
            <NavItem>
              <NavLink
                to="/home"
                className="nav-link bottom-nav-link"
                onClick={() => {
                  setSelected("home");
                }}
              >
                <div className="row d-flex flex-column justify-content-center align-items-center">
                  <div style={{ height: "35px" }}>
                    {selected === "home" ? (
                      <img
                        className="img-fluid for-light"
                        src="/assets/images/Quiz/home.svg"
                        alt="Home"
                      />
                    ) : (
                      <img
                        className="img-fluid for-light"
                        src="/assets/images/Quiz/home.svg"
                        alt="Home"
                      />
                    )}
                  </div>
                  <div
                    style={
                      selected === "home"
                        ? { color: "#FEA965" }
                        : { color: "black" }
                    }
                    className="bottom-tab-label"
                  >
                    {t("home")}
                  </div>
                </div>
              </NavLink>
            </NavItem>

            <NavItem>
              <li
                className="nav-link bottom-nav-link"
                onClick={() => {
                  setSelected("activity");
                }}
              >
                <div className="row d-flex flex-column justify-content-center align-items-center">
                  <div style={{ height: "35px" }}>
                    {selected === "activity" ? (
                      <img
                        className="img-fluid for-light"
                        src="/assets/images/Quiz/activity.svg"
                        style={{ color: "#8D69F9" }}
                        alt="Game"
                      />
                    ) : (
                      <img
                        className="img-fluid for-light"
                        src="/assets/images/Quiz/activity.svg"
                        alt="Game"
                      />
                    )}
                  </div>
                  <div
                    style={
                      selected === "activity"
                        ? { color: "#FEA965" }
                        : { color: "black" }
                    }
                    className="bottom-tab-label"
                  >
                    {t("activity")}
                  </div>
                </div>
              </li>
            </NavItem>
          </div>
        </Nav>
      </nav>
    </div>
  );
};

export default BottomTab;
