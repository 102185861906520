import React, { useEffect, useState } from 'react';
import { Card} from 'react-bootstrap';
import SalesInfoHeader from '../component/SalesInfoHeader/SalesInfoHeader';
import { useAuth } from '../context/auth-context';
import { asyncWrap } from '../utils/utils';
import axios from 'axios';
import moment from "moment";


function ActiveScheme() {
  const [activeTab, setActiveTab] = useState(1)
  const [schemes, setSchemes] = useState([])
  const {user} = useAuth()
  const[loading, setLoading] = useState(true)

  const getSchemes = async () => {
    let type = activeTab === 1 ? "Growth" : activeTab === 2 ? "Order" : activeTab === 3 ? "Invoice" : "";
    let userId = user?.userId || user?.profile?.userId

    // passing the userId and type in the query
    const [error, result] = await asyncWrap(axios.get(`schemeList/user-scheme?userId=${userId}&type=${type}`))
    
    setLoading(false)

    if(!result) {
      console.log("Error in active schemes")
      console.log(error)
      return
    }
    
    setSchemes(result.data.data)
  }

  useEffect(() => {
    getSchemes()
  }, [activeTab])

  const headerData = [
    {
        id: 1,
        title: "Growth",
        icon: "/assets/images/salesInfo/sales.svg",
        width: "20px"
    },
    {
        id: 2,
        title: "Order",
        icon: "/assets/images/salesInfo/orders.svg",
        width: "20px"
    },
    {
        id: 3,
        title: "Invoice",
        icon: "/assets/images/salesInfo/payment.svg",
        width: "20px"
    }
]
  return (
    <>
    <SalesInfoHeader 
    data={headerData}
    active={activeTab}
    setActive={setActiveTab}
    />
    <div className="d-flex flex-column align-items-center my-3">
      {loading ? (
        <div>loading...</div>
      ) :
        schemes.length > 0 ? schemes.map((scheme, index) => (
        <Card key={index} style={{ width: '90vw', marginBottom: '1rem',display: 'flex', flexDirection: 'row', backgroundColor: '#E9EAEB' }}>
          <div style={{ width: '40%', padding: '0 2px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <div alt="Logo" style={{ width: '25vw', height: '15vh', marginLeft:'1rem'}}>Image</div>
          </div>
          <div style={{ width: '60%', backgroundColor: '#E9EAEB', textAlign: 'left', margin:'1em 1.5em', padding:'1px 0' }}>
            <div style={{ paddingTop: '1px'}}>
              <strong>Scheme Name:</strong><br/>{scheme.SchemeName}
            </div>
            <div style={{ paddingTop: '1px'}}>
              <strong>Start Date:</strong><br/>{moment(scheme.StartDate).format("DD-MM-YYYY")}
            </div>
            <div style={{ paddingTop: '1px'}}>
              <strong>Effective From:</strong><br/>{moment(scheme.EndData).format("DD-MM-YYYY")}
            </div>
          </div>
        </Card>
      ))
    : (
      <div>
        No schemes available
      </div>
    )
    }
    </div>
    </>
  );
}

export default ActiveScheme;