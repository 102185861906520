import React from 'react'
import Chart from 'react-apexcharts'

function SalesRewardsChart({data}) {
  return (
    <div>
        <Chart options={data.options} series={data.series} type="bar" height={350} />
    </div>
  )
}

export default SalesRewardsChart