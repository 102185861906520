import React, { useState } from 'react';
import Header from '../SalesInfo/Header';
import { AiOutlineFilePdf } from 'react-icons/ai';
import SalesChart from '../CategoryWiseSales/SalesChart';
import SalesTable from '../CategoryWiseSales/SalesTable';
import PaymentBtn from '../PaymentInfo/PaymentBtn';


function OrdersInfo() {
  const [chartData, setChartData] = useState({
    series: [68, 23, 9,],
    options: {
      labels: ["Wiring Devices", "Mcb & Dbs", "Lights"],
      colors: ["#FF3031", "#3498DB", "#129842"],
      chart: {
        type: "donut",
      },
      legend: {
        show: false
      },
      dataLabels: {
        enabled: false,
      },

      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 310,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  });

  const column = [
    {
      id: 1,
      name: "Category",
    },
    {
      id: 2,
      name: "Amount",
    },
    {
      id: 3,
      name: "Percentage",
    },
  ];

  const data = [
    {
      category: "Wiring Devices",
      amount: "₹ 52,365",
      percentage: "68.8%",
    },
    {
      category: "Mcbs & Dbs",
      amount: "₹ 16,056",
      percentage: "21.1%",
    },
    {
      category: "Lights",
      amount: "₹ 7691",
      percentage: "10.1%",
    },
    {
      category: "Total",
      amount: "₹ 76,111",
      percentage: "100%",
    },
  ];
  
  const orderTableColumn = [
    {
      id:1,
      name: "Date/PDF",
      dataIndex: ""
    },
    {
      id:2,
      name: "Amount"
    },
    {
      id:3,
      name: "Invoice",
    },
    {
      id:4,
      name: "Division"
    },
    {
      id:5,
      name: "Transporter"
    },
    {
      id: 6,
      name: "Lr. No."
    }
  ]
  

  const orderTableData = [
    { 
      date: (
        <span>
          23-05-2020<span className="p-2"></span>
          <a href='#'><AiOutlineFilePdf size={18} className='mb-1' color='red'/></a>
        </span>
      ), 
      amount: '₹ 18,077', 
      invoice: 'I/V/J/19-20/52344', 
      division: 'Lights', 
      transporter: 'Amravathi', 
      lrNo: '8600' },
    { 
      date: (
        <span>
          23-05-2020<span className="p-2"></span>
          <a href='#'><AiOutlineFilePdf size={18} className='mb-1 ' color='red'/></a>
        </span>
      ), 
      amount: '₹ 18,077', 
      invoice: 'I/V/J/19-20/52344', 
      division: 'Lights', 
      transporter: 'Amravathi', 
      lrNo: '8600' },
    { 
      date: (
        <span>
          23-05-2020<span className="p-2"></span>
          <a href='#'><AiOutlineFilePdf size={18} className='mb-1 ' color='red'/></a>
        </span>
      ), 
      amount: '₹ 18,077', 
      invoice: 'I/V/J/19-20/52344', 
      division: 'Lights', 
      transporter: 'Amravathi', 
      lrNo: '8600' },
    { 
      date: (
        <span>
          23-05-2020<span className="p-2"></span>
          <a href='#'><AiOutlineFilePdf size={18} className='mb-1 ' color='red'/></a>
        </span>
      ),  
      amount: '₹ 18,077', 
      invoice: 'I/V/J/19-20/52344', 
      division: 'Lights', 
      transporter: 'Amravathi', 
      lrNo: '8600' },
    { 
      date: (
        <span>
          23-05-2020<span className="p-2"></span>
          <a href='#'><AiOutlineFilePdf size={18} className='mb-1 ' color='red'/></a>
        </span>
      ), 
      amount: '₹ 18,077', 
      invoice: 'I/V/J/19-20/52344', 
      division: 'Lights', 
      transporter: 'Amravathi', 
      lrNo: '8600' },
    { 
      date: (
        <span>
          23-05-2020<span className="p-2"></span>
          <a href='#'><AiOutlineFilePdf size={18} className='mb-1 ' color='red'/></a>
        </span>
      ),  
      amount: '₹ 18,077', 
      invoice: 'I/V/J/19-20/52344', 
      division: 'Lights', 
      transporter: 'Amravathi', 
      lrNo: '8600' },
      { 
        date: (
        <span>
          23-05-2020<span className="p-2"></span>
          <a href='#'><AiOutlineFilePdf size={18} className='mb-1 ' color='red'/></a>
        </span>
      ), 
        amount: '₹ 18,077', 
        invoice: 'I/V/J/19-20/52344', 
        division: 'Lights', 
        transporter: 'Amravathi', 
        lrNo: '8600' },
      { 
        date: (
        <span>
          23-05-2020<span className="p-2"></span>
          <a href='#'><AiOutlineFilePdf size={18} className='mb-1 ' color='red'/></a>
        </span>
      ), 
          amount: '₹ 18,077', 
          invoice: 'I/V/J/19-20/52344', 
          division: 'Lights', 
          transporter: 'Amravathi', 
          lrNo: '8600' },
  ];
  
  const tableStyles = {
    headerTh: {
      border: "2px solid #ffffff",
      verticalAlign: "middle",
      backgroundColor: "#ADAEB0"
    },
    bodyTd: {
      border: "2px solid #ffffff",
      verticalAlign: "middle",
      whiteSpace: "nowrap",
      backgroundColor: "#E9EAEB"
    },
    firstChild: {
      position: "sticky",
      left: "1px",
      // display: "inline-block",
      // marginLeft: "10px !important",
      zIndex: 1,
      backgroundColor: "#ADAEB0", // for thead
      backgroundColorBody: "#E9EAEB" // for tbody
    },
    row: {
      backgroundColor: "#E9EAEB",
      border: "2px solid #ffffff"
    },
    grayBackground: {
      backgroundColor: "#ADAEB0"
    }
  };

  return (
    <div>
      <Header text="Pending Order"/>
      <div className='mt-2'></div>
      <SalesChart chartData={chartData} />
      <SalesTable column={column} data={data} isShowColor={true} />
      <div className="d-flex gap-2 mx-1 mt-2">
      <PaymentBtn text={<>Item Wise Pdf <span><AiOutlineFilePdf size={18} className='mb-1' color='white'/></span> </>} style={{border: 'none', padding: '10px 5px', fontSize: '0.9em'}}/>
        <PaymentBtn text={<>Summary Wise Pdf <span><AiOutlineFilePdf size={18} className='mb-1' color='white'/></span> </>} style={{border: 'none', padding: '10px 5px', fontSize: '0.9em'}}/>
      </div>
      <div className="d-flex gap-2 mx-1 mt-2 flex-nowrap">
        <PaymentBtn text="Item Wise Pending" style={{border: 'none', padding: '10px 7px', fontSize: '0.9em'}}/>
        <PaymentBtn text="Summary Wise Pending" style={{border: 'none', padding: '10px 7px', fontSize: '0.9em'}} />
      </div>
      <div className='mt-3'></div>
      <Header text="Last Dispatches"/>
      {/* <div className='table-responsive mt-2'>
        <table className="table table-bordered border">
          <thead className="gray">
            <tr>
              {headers.map(header => (
                <th key={header}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableData.map((row, index) => (
              <tr key={index}>
                <td>{row.date}</td>
                <td>{row.amount}</td>
                <td>{row.invoice}</td>
                <td>{row.division}</td>
                <td>{row.transporter}</td>
                <td>{row.lrNo}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div> */}
    <div className="table-responsive" style={{ margin: "0" }}>
        <table className="table table-md" style={{ margin: "0" }}>
          <thead style={tableStyles.grayBackground}>
            <tr>
              {orderTableColumn.map((item, index) => (
                <th key={item.id} style={index === 0 ? {...tableStyles.headerTh, ...tableStyles.firstChild} : tableStyles.headerTh}>
                  {item.name}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {orderTableData.map((row, index) => (
              <tr key={index} style={tableStyles.row}>
                {Object.values(row).map((value, i) => (
                  <React.Fragment key={i}>
                  <td style={i === 0 ? {...tableStyles.bodyTd, ...tableStyles.firstChild, backgroundColor: tableStyles.firstChild.backgroundColorBody} : tableStyles.bodyTd}>
                    {value}
                  </td>
                  </React.Fragment>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default OrdersInfo;
