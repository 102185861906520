import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import AnswerModal from "./AnswerModal";
import GameOver from "./GameOver";
import Life from "./Life";
import MathHelper from "./math";
import Star from "./Star";
import Timer from "./Timer";
import { AiFillHeart } from "react-icons/ai";
import { useTranslation } from "react-i18next";

import ReactHowler from "react-howler";
import newSound from "/assets/audio/spinandwin.mp3";

const StartGame = ({ show, setShow }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [seconds, setSeconds] = useState(30);
  const [msg, setMsg] = useState(3);
  const [showGameOver, setShowGameOver] = useState(false);
  const [problem, setProblem] = useState("");
  const [answer, setAnswer] = useState("");
  const [usersAnswer, setUsersAnswer] = useState();
  const [lifes, setLifes] = useState([1, 2, 3]);
  const [points, setPoints] = useState(0);
  const [showAnswerModal, setShowAnswerModal] = useState(false);
  const [type, setType] = useState(false);
  const [playing, setPlaying] = useState(false);
  const onChangeLoading = () => {
    setLoading(false);
  };

  useEffect(() => {
    
    if (show) {
      var timer =
        msg > 0 &&
        setTimeout(() => {
          setMsg(msg - 1);
        }, 1000);
      return () => {
        clearInterval(timer);
        if (msg === 1) {
          onChangeLoading();
          getProblem(points);
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [msg, show]);

  const solveProblem = (newProblem) => {
    // eslint-disable-next-line no-eval
    setAnswer(eval(`${newProblem}`));
  };

  const getProblem = (earnedPoints) => {
    setPlaying(true)
    const newProblem = MathHelper.generateProblem(earnedPoints);
    setProblem(newProblem);
    if (newProblem) {
      solveProblem(newProblem);
    }
  };

  const compare = () => {
    setUsersAnswer("");
    if (usersAnswer === answer) {
      setSeconds(seconds + 20);
      setPoints(points + 100);
      if (points === 300) {
        setLifes((prev) => [...prev, prev + 1]);
      }
      setType(true);
      getProblem(points);
      setShowAnswerModal(true);
      setTimeout(() => {
        setShowAnswerModal(false);
      }, 2000);
    } else {
      if (lifes.length === 1) {
        setPlaying(false);
        setShowGameOver(true);
      } else {
        setType(false);
        lifes.pop();
        getProblem(points);
        setShowAnswerModal(true);
        setTimeout(() => {
          setShowAnswerModal(false);
        }, 2000);
      }
    }
  };

  return (
    <>
      <ReactHowler playing={playing} src={[newSound]} loop={true} />
      <AnswerModal
        show={showAnswerModal}
        setShow={setShowAnswerModal}
        type={type}
      />
  
      <GameOver
        show={showGameOver}
        setShow={setShowGameOver}
        setSeconds={setSeconds}
        setLifes={setLifes}
        setPoints={setPoints}
        points={points}
        getProblem={getProblem}
        setShowStartGame={setShow}
      />
      <Modal fullscreen={true} show={show} onHide={() => setShow(false)}>
        {loading === false && (
          <Modal.Header style={{ padding: "0px 10px" }}>
            <Row className="p-10" style={{ width: "100vw" }}>
              <Col
                className="text-left nav-title pt-1"
                xs={4}
                style={{
                  color: "#8D69F9",
                  fontSize: "28px",
                  fontWeight: "700",
                }}
              >
                <Timer
                  loading={loading}
                  seconds={seconds}
                  setSeconds={setSeconds}
                  setShowGameOver={setShowGameOver}
                />
              </Col>
              <Col
                className="text-center nav-title pt-1 d-flex flex-row"
                xs={4}
                style={{
                  color: "#dc3545",
                  fontSize: "28px",
                  fontWeight: "700",
                }}
              >
                {lifes.length > 3 ? (
                  <div>
                    <AiFillHeart />{" "}
                    <span style={{ color: "black" }}>x{lifes.length}</span>
                  </div>
                ) : (
                  <>
                    {lifes.map((item, key) => (
                      <Life i={key} />
                    ))}
                  </>
                )}
              </Col>
              <Col
                className="text-center nav-title pt-1"
                xs={4}
                style={{
                  color: "#FFD700",
                  fontSize: "28px",
                  fontWeight: "700",
                }}
              >
                <Star points={points} />
              </Col>
            </Row>
          </Modal.Header>
        )}
        <Modal.Body
          style={{ backgroundColor: "#282c34" }}
          className="d-flex flex-column justify-content-center align-items-center"
        >
          {loading ? (
            <div className="text-center">
              <h3 style={{ color: "white" }}>{t("get_ready")}</h3>
              <h1 style={{ color: "white" }}>{msg}</h1>
            </div>
          ) : (
            <div className="text-center">
              <div>
                <h1 style={{ color: "white" }}>{problem}</h1>
              </div>
              <div>
                <Form.Control
                  type="number"
                  style={{
                    width: "100%",
                    textAlign: "center",
                    border: "1px solid lightgrey",
                    margin: "auto",
                  }}
                  onChange={(e) => setUsersAnswer(Number(e.target.value))}
                  placeholder="Enter Answer"
                  value={usersAnswer}
                />
              </div>
              <div>
                <Button
                  style={{
                    marginTop: "20px",
                    padding: "10px",
                    width: "50%",
                    fontSize: "20px",
                    fontWeight: "700",
                    backgroundColor: "#5e6675",
                    border: "none",
                  }}
                  onClick={compare}
                >
                  {t("submit")}
                </Button>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default StartGame;
