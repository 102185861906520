import { useState, useEffect } from "react";
import { Row, Col, Badge } from "react-bootstrap";

import GamesActiveCard from "../component/GamesActiveCard/GamesActiveCard";
import GamesHistoryCard from "../component/GamesHistoryCard/GamesHistoryCard";
import GamesUpcomingCard from "../component/GamesUpcomingCard/GamesUpcomingCard";

import Journey from "../component/Journey/journey";
import Journey1 from "../component/Journey/Journey1/journey";
import Journey2 from "../component/Journey/Journey2/journey";
import Journey3 from "../component/Journey/Journey3/journey";
import improvement from "../videos/improvement.mp4";
import monuments from "../videos/monuments.mp4";
import race from "../videos/race.mp4";
import mountain from "../videos/mountain.mp4";

import { calcDaysLeft } from "../utils/calcDaysLeftProgress";
// import moment from 'moment';
import { useTranslation } from "react-i18next";
import { asyncWrap } from "../utils/utils";
import axios from "axios";
import Swal from "sweetalert2";
import VideoPlayer from "../component/Videoplayer";
import LudoGame from "../component/LudoGame/LudoGame";
import Goal from "../component/Goal/Goal";

function Challenges() {
  const { t } = useTranslation();
  const optfilter = [
    { title: t("active"), key: 1 },
    { title: t("upcoming"), key: 2 },
    { title: t("history"), key: 3 },
  ];
  // eslint-disable-next-line
  const [FillterThemes, setFillterThemes] = useState(1);
  const [activeOptFilter, setActiveOptFilter] = useState(1);
  const [show, setShow] = useState(false);
  const [showVideoPlayer, setVideoPlayer] = useState(false);
  const [videoData, setVideoData] = useState();

  //eslint-disable-next-line
  const [activeCard, setActiveCard] = useState([]);
  //eslint-disable-next-line
  const [upcomingGame, setUpcomingGame] = useState([]);
  //eslint-disable-next-line
  const [historicalGame, setHistoricalGame] = useState([]);
  const [detail, setDetail] = useState();
  // const [toatalBadge,setTotalBadge] = useState([]);
  // const [userLevel, setUserLevel] = useState();
  const [showLudo, setShowLudo] = useState(false);
  const [showGoal, setShowGoal] = useState(false);
  const { user } = useAuth();

  const openJourney = (data) => {
    setShow(true);
  };

  useEffect(() => {
    getGames();
    getJouney();
    setHistoricalGame(videos);
    setUpcomingGame(ludo);
    // eslint-disable-next-line
  }, []);

  const getGames = async () => {
    const [error, result] = await asyncWrap(axios.get("/opportunity"));
    if (!result) {
      console.log(error);
      Swal.fire("something went Wrong!");
    }

    let data = result.data.data.map((item) => ({
      title: item.NameOfOpportunity,
      days: calcDaysLeft(item.EndDate),
      ...item,
    }));

    let newTemplates = [
      { title: "Incentive Opportunity 1", days: "20", templateId: 2 },
      { title: "Incentive Opportunity 2", days: "15", templateId: 3 },
      { title: "Incentive Opportunity 3", days: "10", templateId: 4 },
    ];

    //eslint-disable-next-line
    newTemplates.map((item) => {
      data.push(item);
    });

    setActiveCard(data);
  };

  const videos = [
    { title: "Monuments", days: 20, status: "Expired", video: monuments },
    { title: "Race", days: 30, status: "Expired", video: race },
    { title: "Improvement", days: 10, status: "Expired", video: improvement },
    { title: "Mountain", days: 20, status: "Expired", video: mountain },
  ];

  const ludo = [
    {
      title: "Ludo",
      startdate: "07/05/2022",
      enddate: "07/31/2022",
    },
    {
      title: "Goal",
      startdate: "07/05/2022",
      enddate: "07/31/2022",
    },
  ];

  const getJouney = async () => {
    const [error, result] = await asyncWrap(axios.get("/journey"));
    if (!result) {
      console.log(error);
      Swal.fire("Something went wrong!");
    }
    setDetail(result.data.data);
  };

  return (
    <>
      {showVideoPlayer && (
        <VideoPlayer
          show={showVideoPlayer}
          setShow={setVideoPlayer}
          data={videoData}
        />
      )}
      <Goal show={showGoal} setShow={setShowGoal} />
      <LudoGame show={showLudo} setShow={setShowLudo} />
      <div className="mainPage">
        {FillterThemes === 1 ? (
          <Journey
            show={show}
            setShow={setShow}
            data={detail}
            userLevel={user?.profile?.UserLevel || user?.UserLevel}
          />
        ) : FillterThemes === 2 ? (
          <Journey1
            show={show}
            setShow={setShow}
            data={detail}
            userLevel={user?.profile?.UserLevel || user?.UserLevel}
          />
        ) : FillterThemes === 3 ? (
          <Journey2
            show={show}
            setShow={setShow}
            data={detail}
            userLevel={user?.profile?.UserLevel || user?.UserLevel}
          />
        ) : FillterThemes === 4 ? (
          <Journey3
            show={show}
            setShow={setShow}
            data={detail}
            userLevel={user?.profile?.UserLevel || user?.UserLevel}
          />
        ) : null}

        <div className="horizontal-scroll mt-20">
          {optfilter.map(({ title, key }) => (
            <Badge
              key={key}
              onClick={() => setActiveOptFilter(key)}
              pill
              className={
                key === activeOptFilter ? "badgeActive badge" : "badge"
              }
              style={{
                background:
                  key === activeOptFilter
                    ? "#fad6a5 !important"
                    : "white !important",
              }}
            >
              {title}
            </Badge>
          ))}
        </div>
        <Row className="mt-5">
          {activeOptFilter === 1 &&
            activeCard.map((item, idx) => (
              <Col
                key={idx}
                sm={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <GamesActiveCard
                  onClick={() => {
                    openJourney(item);
                    item?.templateId
                      ? setFillterThemes(item?.templateId)
                      : setFillterThemes(1);
                  }}
                  {...item}
                />
              </Col>
            ))}
          {activeOptFilter === 2 &&
            upcomingGame.map((item, idx) => (
              <Col
                key={idx}
                sm={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {item.title === "Ludo" ? (
                  <GamesUpcomingCard
                    onClick={() => {
                      setShowLudo(true);
                    }}
                    {...item}
                  />
                ) : (
                  <GamesUpcomingCard
                    onClick={() => {
                      setShowGoal(true);
                    }}
                    {...item}
                  />
                )}
              </Col>
            ))}
          {activeOptFilter === 3 &&
            historicalGame.map((item, idx) => (
              <Col
                key={idx}
                sm={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <GamesHistoryCard
                  onClick={() => {
                    setVideoPlayer(true);
                    setVideoData(item);
                  }}
                  {...item}
                />
              </Col>
            ))}
        </Row>
      </div>
    </>
  );
}

export default Challenges;
