import moment from "moment";
import React, { useState } from "react";
import { Button, Card } from "react-bootstrap";
import dummy from "/assets/images/dummyProductImage.jpg";
import EBidProductDetail from "./EBidProductDetail";
import MakeBid from "./MakeBid";

const EBidCard = ({ data, latestBid, dateFormat, updateBid }) => {
  const [showProductDetail, setShowProductDetail] = useState(false);
  const [showMakeBid, setShowMakeBid] = useState(false);

  return (
    <>
      <EBidProductDetail
        show={showProductDetail}
        setShow={setShowProductDetail}
        data={data}
      />
      <MakeBid
        show={showMakeBid}
        setShow={setShowMakeBid}
        productData={data}
        updateBid={updateBid}
      />
      <div className="d-flex justify-content-center">
        <Card
          className="mt-3 "
          style={{ width: "21rem" }}
          key={data.ProductBIDID}
        >
          <Card.Img
            onClick={() => {
              setShowProductDetail(true);
            }}
            variant="top"
            src={data.ProductImage ? data.ProductImage : dummy}
            style={{ height: "150px" }}
          />
          <Card.Body style={{ backgroundColor: "lightgray" }}>
            <div className="d-flex justify-content-between align-items-center">
              <Card.Title className="fs-4">{data.ProductPrice}</Card.Title>
              <Card.Title className="fs-6">
                {dateFormat} {moment(data.EndDate).format("DD-MM-YYYY")}-
                {data.EndTime}
              </Card.Title>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <Card.Title className="fs-6 text-left">{data.SkuName}</Card.Title>
              {latestBid ? (
                <div className="d-flex flex-column text-start">
                  <span>Latest Bid Value</span>
                  <span style={{ fontWeight: "600", fontSize: "24px" }}>
                    {data.LastBidValue}
                  </span>
                </div>
              ) : (
                <Button
                  className="fs-4"
                  style={{ width: "7rem", fontWeight: "600 !important" }}
                  variant="primary"
                  onClick={() => {
                    setShowMakeBid(true);
                    updateBid();
                  }}
                  disabled={
                    data.ProductStatus === "Live" ||
                    data.ProductStatus === "MyBid"
                      ? false
                      : true
                  }
                >
                  BID
                </Button>
              )}
            </div>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

export default EBidCard;
