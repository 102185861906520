import React, { useState } from "react";
import { Badge, Modal } from "react-bootstrap";
import LusckyDrawCard from "./LusckyDrawCard";

const LuckyDraw = ({ show, setShow }) => {
  const optfilter = [
    { title: "Current", key: 1 },
    { title: "Complete", key: 2 },
    { title: "Won", key: 3 },
  ];
  const [active, setActive] = useState(1);

  return (
    <>
      <Modal
        fullscreen={true}
        show={show}
        style={{ backgroundColor: "white !important" }}
        onHide={() => setShow(false)}
      >
        <Modal.Body
          style={{
            marginTop: "-15px",
            background: "#fffef2",
            boxShadow: "#00000005",
            borderRadius: " 20px 20px 0px 0px",
            textAlign: "center",
          }}
        >
          <div className="mainPage">
            <div className="horizontal-scroll mt-20">
              {optfilter.map(({ title, key }) => (
                <Badge
                  key={key}
                  onClick={() => setActive(key)}
                  pill
                  style={{
                    background: key === active ? "#fad6a5" : "white",
                  }}
                >
                  {title}
                </Badge>
              ))}
            </div>

            {active === 1 && (
              <>
                <LusckyDrawCard />
                <LusckyDrawCard />
                <LusckyDrawCard />
                <LusckyDrawCard />
                <LusckyDrawCard />
                <LusckyDrawCard />
              </>
            )}

            {active === 2 && <LusckyDrawCard />}
            {active === 3 && <LusckyDrawCard />}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LuckyDraw;
