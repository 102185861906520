export function asyncWrap(promise) {
  return promise.then((result) => [null, result]).catch((err) => [err]);
}

export function delay(delayInms) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(2);
    }, delayInms);
  });
}

export function getUrl() {
  if (process.env.NODE_ENV === "production") {
    if(window.location.origin === 'https://webappt2.rr.unomok.com'){
      return 'https://kabelconnect.rrkabel.com/api/mobile'
    }
    if(window.location.origin === 'https://webappt2.gmm.unomok.com'){
      return 'https://loyalty.gmmodular.com/api/mobile'
    }
    if (window.location.origin === 'https://webappt2.rr-lms.dev.unomok.com') {
      return 'https://rr-lms.dev.unomok.com/api/mobile'
    } else {
      return `https://${window.location.origin.split(".")[1]}.unomok.com/api/mobile`;
    }
  } else {
    return import.meta.env.VITE_BACKEND;
  }
}