import styled from 'styled-components';
import { Card, Badge } from 'react-bootstrap';

export const StyledCard = styled(Card)`
    height: 200px;
    margin-bottom: 20px;
    box-shadow: 0px 8px 30px #FAD6A51A;
    border: 0;
    border-radius: 7px;
    background-color: ${props => props.color};
    width: 85%;
`
export const StyledTitleDiv = styled.div`
    background-color: ${props => props.color};
    width: 100%;
    height: 70px;
    border-radius: 0 8px 8px 0;
    align-items: center;
    justify-content: center;
    display: flex;
    padding-left: 8px;
    margin-top: 25px;
`

export const StyledEarnedTitle = styled.h6`
    text-align: right;
`


export const StyledBadgeTitle = styled.h6`
margin: 0;
`
export const StyledClockImage = styled.img`
    padding: 1px;
`;

export const StyleddMeterTitle = styled.h6`
margin: 0;
`
export const StyledScoreText = styled.p`
    margin: 0;
    font-size: 14px;
    font-weight: 600;
`

export const StyledBadgeText = styled.p`
    margin: 0;
    font-size: 14px;
    font-weight: 600;
`

export const StyledDaysLeftText = styled.p`
    font-size: 14px;
    font-weight: 600;
    margin: 0;
    text-align: left;
    
`

export const StyledMilestoneButton = styled(Badge)`
    
    padding: 8px !important;
    width: 60%;
    background:transparent !important;
`