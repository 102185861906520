// CustomDatePicker.jsx
import React from 'react';
import { Form, Col } from 'react-bootstrap';

const CustomDatePicker = ({ label, controlId }) => {
  return (
    <Col xs={6}>
      <Form.Group controlId={controlId}>
        <Form.Label style={{ fontWeight: '600' }}>{label}</Form.Label>
        <Form.Control 
          type="date" 
          style={{ 
            border: '0.1em solid black', 
            padding: '10px', 
            boxShadow: 'none',
            outline: 'none' 
          }} 
        />
      </Form.Group>
    </Col>
  );
};

export default CustomDatePicker;
