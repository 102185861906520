import React, { useState } from 'react'

const tabArr = [
  {
    id: 0,
    name: "EVENT"
  },
  {
    id: 1,
    name: "ADVERTISEMENT"
  },
  {
    id: 2,
    name: "PRODUCT"
  },
  {
    id: 3,
    name: "TECHNICAL"
  },
]

function Videos() {
  const [activeTab, setActiveTab] = useState(0)

  return (
    <div>
      <div className='d-flex'>
        {tabArr.length > 0 && tabArr.map((item, i) => (
          <p 
          onClick={() => setActiveTab(i)}
          className='text-center p-2 flex-grow-1 fw-semibold'
          style={{
            backgroundColor: item.id === activeTab ? "#ff2f30" : "#ADAEB0",
            color: item.id === activeTab ? "#fff" : "#000",
            border: "1px solid white",
            fontSize: "13px"
          }}>{item.name}</p>
        ))}
      </div>

      {/* video */}
      <div>
      <iframe width="100%" height="250" src="https://www.youtube.com/embed/HKYn0zIZ6Ws?si=kJ1RAEmQeUQXE-UK" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </div>

      <hr className='mx-3' />

      {/* bottom card start */}
      <div style={{backgroundColor: "#ADAEB0"}} className='d-flex px-2 py-3 gap-2 mx-2'>
        <div style={{width: "80%"}} className="img bg-white"> image </div>
        <div className="text text-start">
          <h6 className='m-0 fw-semibold fs-5'>Malad Masti 2020</h6>
          <p className='m-0 lh-sm my-2'>Malad Masti was an unforgettable four days of fun activities, entertainment</p>
          <p className='m-0'>02:49</p>
        </div>
      </div>
      {/* bottom card end */}
    </div>
  )
}

export default Videos