import axios from "axios";
import { getUrl } from "./utils/utils";

export function setAxiosDefault() {
  axios.defaults.timeout = 30 * 1000;
  axios.defaults.baseURL = getUrl();
  axios.defaults.headers.common["Content-Type"] = "application/json";
  axios.defaults.headers.common["Accept"] = "application/json";
}

export function setToken(token) {
  axios.defaults.headers.common["token"] = `${token}`;
}

export function getToken() {
  return axios.defaults.headers.common["token"];
}
