import React from 'react'

function AgreementCard({heading}) {
  return (
    <div className='rounded shadow-sm m-2 p-2 mb-3' style={{
        backgroundColor: "#efeff0"
    }}>
        <p className='fw-bold m-0 mb-1' style={{fontSize: "17px"}}>{heading}</p>
        <div className='d-flex justify-content-between'>
            <div>
                <p className='m-0'>Target Amount</p>
                <p>₹ 2,29,500</p>
            </div>
            <div>
                <p className='m-0'>Sales Amount</p>
                <p>₹ 0</p>
            </div>
            <div>
                <p className='m-0'>Short Fall</p>
                <p>₹ 0</p>
            </div>
        </div>
        <div className='d-flex justify-content-between'>
            <div>
                <p className='m-0'>Target Amount</p>
                <p>₹ 2,29,500</p>
            </div>
            <div>
                <p className='m-0'>Sales Amount</p>
                <p>₹ 0</p>
            </div>
            <div>
                <p className='m-0'>Short Fall</p>
                <p>₹ 0</p>
            </div>
        </div>

        {/* progress bar start */}
        <div className='d-flex' style={{width: "100%", height: "12px"}}>
            <div className="left" style={{width: "25%", height: "12px", backgroundColor: "#FF2F30"}}></div>
            <div className="right bg-secondary" style={{width: "75%", height: "12px"}}></div>
        </div>
        {/* progress bar end */}

        <div className='d-flex justify-content-between'>
            <div>25%</div>
            <div>75%</div>
        </div>
    </div>
  )
}

export default AgreementCard