// import SecoundaryHeader from "component/Nav/SecoundaryHeader";
import React, { useState } from "react";
import { Button, FormControl, InputGroup, Modal } from "react-bootstrap";
import EBidCard from "./EBidCard";
import { asyncWrap } from "../../utils/utils";
import axios from "axios";
import ParticipantsList from "./ParticipantsList";
import Transaction from "./Transaction";
import { usePagination } from "../../hooks";

const MakeBid = ({ show, setShow, productData, updateBid }) => {
  const { data, gotoPage, currentPage, loading, total, fetchData } =
    usePagination(`e-bid/history?productBidId=${productData.ProductBIDID}`);
  const [bidValue, setBidValue] = useState(0);
  const [showParticipants, setShowParticipants] = useState(false);
  const [showTransaction, setShowTransaction] = useState(false);
  const [participantsData, setParticipantsData] = useState([]);

  const insertUpdateUsersBid = async () => {
    const config = {
      method: "post",
      url: "/e-bid",
      data: {
        productBidId: productData.ProductBIDID,
        bidValue: bidValue,
        pointTypeId: productData.PointTypeID,
      },
    };

    const [error, result] = await asyncWrap(axios(config));

    if (!result) {
      console.log(error);
      return;
    }

    setShow(false);
    fetchData(1);
    updateBid();
  };

  const getParticipantData = async () => {
    const [error, result] = await asyncWrap(
      axios(`/e-bid/participant/?productBidId=${productData.ProductBIDID}`)
    );

    if (!result) {
      console.log(error);
      return;
    }
    console.log(result.data.data);
    setParticipantsData(result.data.data);
    setShowParticipants(true);
  };

  return (
    <>
      <ParticipantsList
        show={showParticipants}
        setShow={setShowParticipants}
        data={participantsData}
      />
      <Transaction
        show={showTransaction}
        setShow={setShowTransaction}
        data={data}
        currentPage={currentPage}
        gotoPage={gotoPage}
        loading={loading}
        total={total}
      />
      <Modal fullscreen={true} show={show} onHide={() => setShow(false)}>
        <Modal.Body className="mb-20 p-0 text-center">
          <div className="d-flex justify-content-center mt-4 flex-column">
            <EBidCard data={productData} latestBid />
            <div>
              <InputGroup className="px-5 mt-3 mb-3">
                <FormControl
                  aria-label="Default"
                  aria-describedby="inputGroup-sizing-default"
                  className="border border-4"
                  onChange={(e) => setBidValue(e.target.value)}
                />
              </InputGroup>
              <Button
                className="fs-4 px-5"
                variant="primary"
                style={{ width: "87%", fontWeight: "600 !important" }}
                onClick={insertUpdateUsersBid}
              >
                Make BID
              </Button>
            </div>
            <div className="mt-2">
              <Button
                className="fs-4 me-2"
                variant="danger"
                style={{ width: "11rem", fontWeight: "600 !important" }}
                onClick={getParticipantData}
              >
                Participants
              </Button>
              <Button
                className="fs-4"
                variant="success"
                style={{ width: "11rem", fontWeight: "600 !important" }}
                onClick={() => setShowTransaction(true)}
              >
                Transaction
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default MakeBid;
