import React from "react";


function SalesInfoHeader({ 
    data,
    active,
    setActive
 }) {
  return (
  <div className="tabs d-flex justify-content-between">
    {data.length > 0 && data.map((item, i) =>  (
    <div
    key={i}
    onClick={() => setActive(i+1)}
    className={`item ${active === i + 1 ? "border-bottom border-4 border-danger" : ""} flex-grow-1 pb-2`}>
      <img
        style={{ width: item.width }}
        src={item.icon}
        alt={item.title}
      />
      <p className="text-uppercase fw-bold m-0">{item.title}</p>
    </div>))}
  </div>
  );
}

export default SalesInfoHeader;
