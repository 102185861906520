import { Modal } from "react-bootstrap";
import { useState, useEffect } from "react";
import CustomCarousel from "../Home/CustomCarousel";
import OrderOverview from "./OrderOverview";
import { asyncWrap } from "../../utils/utils";
import { useHistory } from "react-router";
import axios from "axios";
import { useAuth } from "../../context/auth-context";
import Swal from "sweetalert2";

const ProductDetail = ({ show, setShow, selectProduct }) => {
  const [showOrderOverview, setShowOrderOverview] = useState();
  const [showCartOrder, setShowCartOrder] = useState();
  const [quantity, setQuantity] = useState(1);
  const { user } = useAuth();
  const history = useHistory();

  useEffect(() => {
    setQuantity(1);
  }, [show]);

  const createOrder = async () => {
    const data = [
      {
        SkuID: selectProduct.skuId,
        Quantity: quantity,
        UnitPrice: selectProduct?.UnitPrice || 0,
        SubTotal: (selectProduct?.UnitPrice || 0) * quantity,
      },
    ];
    const [error, result] = await asyncWrap(
      axios.post("/order", {
        orderJson: data,
        discount: 0,
        amount: 11,
      })
    );

    if (!result) {
      Swal.fire(error.response.data.message);
      return;
    } else {
      Swal.fire(result.data?.message).then(result => {
        if (result.isConfirmed) {
          setShow(false);
        }
      });
    }
  };

  const addToCart = () => {
    const productData = {
      SkuID: selectProduct.skuId,
      SkuUniqueId: selectProduct.skuUniqueId,
      Quantity: quantity,
      UnitPrice: selectProduct?.UnitPrice || 0,
      SubTotal: (selectProduct?.UnitPrice || 0) * quantity,
    };

    let existingCart = JSON.parse(localStorage.getItem("cart")) || {};

    if (Object.keys(existingCart).length === 0) {
      // If cart item with userId doesn't exist, add a new entry
      existingCart = {
        userId: user?.profile?.userId || user?.userId,
        products: [productData],
      };
    } else {
      // Update the existing cart item by adding the new product
      existingCart.products.push(productData);
    }

    localStorage.setItem("cart", JSON.stringify(existingCart));
    Swal.fire("Your order add in cart").then(result => {
      if (result.isConfirmed) {
        setShow(false);
      }
    });
  };

  return (
    <>
      <OrderOverview show={showOrderOverview} setShow={setShowOrderOverview} />
      {/* <CartOrder show={showCartOrder} setShow={setShowCartOrder} /> */}
      <Modal fullscreen={true} show={show}>
        <Modal.Body>
          <div className="d-flex flex-column">
            <div className="d-flex flex-row justify-content-between align-items-center">
              <div className="fs-1">Place an Order</div>
              <div className="d-flex flex-row">
                <div
                  style={{
                    background: "#efefef",
                    width: "50px",
                    height: "50px",
                    borderRadius: "50%",
                  }}
                ></div>
              </div>
            </div>

            <div className="my-4">
              <CustomCarousel
                images={[
                  "https://images.unsplash.com/photo-1536599018102-9f803c140fc1?auto=format&fit=crop&w=440&h=220&q=60",
                  "https://images.unsplash.com/photo-1536599018102-9f803c140fc1?auto=format&fit=crop&w=440&h=220&q=60",
                ]}
              />
            </div>

            <div className="d-flex flex-column">
              <div>{selectProduct?.skuName}</div>
              <div>{selectProduct?.skuUniqueId}</div>
              <div>{selectProduct?.type}</div>
              <div>{selectProduct?.size}</div>
              <div>Manufacture : RM Manufacture</div>
              <div className="d-flex flex-row my-2">
                <div>Quantity</div>
                <input
                  type="number"
                  style={{
                    width: "100px",
                    textAlign: "center",
                    borderRadius: "10px",
                    marginLeft: "20px",
                  }}
                  value={quantity}
                  onChange={e => {
                    setQuantity(parseInt(e.target.value));
                  }}
                />
              </div>
              <div className="d-flex flex-row align-items-center">
                <div>Select Retailer</div>
                <select
                  style={{ width: "100px", marginLeft: "10px" }}
                  className="form-select"
                  aria-label="Default select example"
                >
                  <option selected>Open </option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
              </div>
              <div className="d-flex flex-column my-2">
                <div className="d-flex flex-row  justify-content-between">
                  <div>Product Details</div>
                  <div>View All</div>
                </div>
                <div className="d-flex flex-row mt-2">
                  <div style={{ width: "40%" }}>SKU</div>
                  <div>FB0023</div>
                </div>
                <div className="d-flex flex-row">
                  <div style={{ width: "40%" }}>Unit</div>
                  <div>PCS</div>
                </div>
                <div className="d-flex flex-row">
                  <div style={{ width: "40%" }}>Type</div>
                  <div>Cookware</div>
                </div>
              </div>
            </div>

            <div className="d-flex flex-column">
              {/* <div
                onClick={() => {
                  createOrder();
                  // setShowOrderOverview(true);
                }}
                style={{
                  width: "100%",
                  background: "#efefef",
                  padding: "5px 10px",
                  borderRadius: "5px",
                  textAlign: "center",
                }}
              >
                {" "}
                order{" "}
              </div> */}
              <div
                onClick={() => {
                  addToCart();
                }}
                style={{
                  width: "100%",
                  background: "#efefef",
                  padding: "5px 10px",
                  borderRadius: "5px",
                  textAlign: "center",
                  marginTop: "10px",
                }}
              >
                {" "}
                Add to cart{" "}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ProductDetail;
