import { Modal } from "react-bootstrap";
import "./ludogame.css";
import { IoLocationSharp } from "react-icons/io5";
import { useState } from "react";
import Draggable from "react-draggable";
import LudoGameDetail from "./LudoGameDetail";
import DragModal from "./DragModal";
import improvement from "../../videos/improvement.mp4";
import monuments from "../../videos/monuments.mp4";
import race from "../../videos/race.mp4";
import mountain from "../../videos/mountain.mp4";

const LudoGame = ({ show, setShow }) => {
  //eslint-disable-next-line
  const [a1, seta1] = useState(25);
  //eslint-disable-next-line
  const [a2, seta2] = useState(26);
  //eslint-disable-next-line
  const [a3, seta3] = useState(42);
  //eslint-disable-next-line
  const [a4, seta4] = useState(57);

  const [showLudoGameDetails, setShowLudoGameDetails] = useState(false);
  const [showDragModal, setShowDragModal] = useState(false);
  const [videoData, setVideoData] = useState([]);
  const [draggable, setDraggable] = useState(false);
  const [player, setplayer] = useState();
  const videos = [
    { title: "Monuments", days: 20, status: "Expired", video: monuments },
    { title: "Race", days: 30, status: "Expired", video: race },
    { title: "Mountain", days: 10, status: "Expired", video: mountain },
    { title: "Improvement", days: 20, status: "Expired", video: improvement },
  ];

  const handleClickListenrYellowA1 = () => {
    setVideoData(videos[0]);
    setplayer(1);
    setShowLudoGameDetails(true);
  };

  const handleClickListenrYellowA2 = () => {
    setVideoData(videos[1]);
    setplayer(2);
    setShowLudoGameDetails(true);
  };
  const handleClickListenrYellowA3 = () => {
    setVideoData(videos[2]);
    setplayer(3);
    setShowLudoGameDetails(true);
  };
  const handleClickListenrYellowA4 = () => {
    setVideoData(videos[3]);
    setplayer(4);
    setShowLudoGameDetails(true);
  };

  function draggablestopyellowA1() {
    if (draggable) {
      setShowDragModal(true);
      setDraggable(false);
    } else {
      handleClickListenrYellowA1();
    }
  }

  function draggablestopyellowA2() {
    setplayer(2);
    if (draggable) {
      setShowDragModal(true);
      setDraggable(false);
    } else {
      handleClickListenrYellowA2();
    }
  }

  function draggablestopyellowA3() {
    setplayer(3);
    if (draggable) {
      setShowDragModal(true);
      setDraggable(false);
    } else {
      handleClickListenrYellowA3();
    }
  }

  function draggablestopyellowA4() {
    setplayer(4);
    if (draggable) {
      setShowDragModal(true);
      setDraggable(false);
    } else {
      handleClickListenrYellowA4();
    }
  }

  function setyellowa1boxvalue(i, j) {
    if (i === 5 && j === 0) {
      return 19;
    } else if (i === 4 && j === 0) {
      return 20;
    } else if (i === 3 && j === 0) {
      return 21;
    } else if (i === 2 && j === 0) {
      return 22;
    } else if (i === 1 && j === 0) {
      return 23;
    } else if (i === 0 && j === 0) {
      return 24;
    } else if (i === 0 && j === 1) {
      return 25;
    } else if (i === 0 && j === 2) {
      return 26;
    } else if (i === 1 && j === 2) {
      return 27;
    } else if (i === 2 && j === 2) {
      return 28;
    } else if (i === 3 && j === 2) {
      return 29;
    } else if (i === 4 && j === 2) {
      return 30;
    } else if (i === 5 && j === 2) {
      return 31;
    }
  }
  function setyellowa2boxvalue(i, j) {
    if (i === 2 && j === 5) {
      return 6;
    } else if (i === 2 && j === 4) {
      return 7;
    } else if (i === 2 && j === 3) {
      return 8;
    } else if (i === 2 && j === 2) {
      return 9;
    } else if (i === 2 && j === 1) {
      return 10;
    } else if (i === 2 && j === 0) {
      return 11;
    } else if (i === 1 && j === 0) {
      return 12;
    } else if (i === 0 && j === 0) {
      return 13;
    } else if (i === 0 && j === 1) {
      return 14;
    } else if (i === 0 && j === 2) {
      return 15;
    } else if (i === 0 && j === 3) {
      return 16;
    } else if (i === 0 && j === 4) {
      return 17;
    } else if (i === 0 && j === 5) {
      return 18;
    }
  }
  function setyellowa3boxvalue(i, j) {
    if (i === 0 && j === 0) {
      return 32;
    } else if (i === 0 && j === 1) {
      return 33;
    } else if (i === 0 && j === 2) {
      return 34;
    } else if (i === 0 && j === 3) {
      return 35;
    } else if (i === 0 && j === 4) {
      return 36;
    } else if (i === 0 && j === 5) {
      return 37;
    } else if (i === 1 && j === 5) {
      return 38;
    } else if (i === 2 && j === 5) {
      return 39;
    } else if (i === 2 && j === 4) {
      return 40;
    } else if (i === 2 && j === 3) {
      return 41;
    } else if (i === 2 && j === 2) {
      return 42;
    } else if (i === 2 && j === 1) {
      return 43;
    } else if (i === 2 && j === 0) {
      return 44;
    }
  }
  function setyellowa4boxvalue(i, j) {
    if (i === 4 && j === 0) {
      return 1;
    } else if (i === 3 && j === 0) {
      return 2;
    } else if (i === 2 && j === 0) {
      return 3;
    } else if (i === 1 && j === 0) {
      return 4;
    } else if (i === 0 && j === 0) {
      return 5;
    } else if (i === 0 && j === 2) {
      return 45;
    } else if (i === 1 && j === 2) {
      return 46;
    } else if (i === 2 && j === 2) {
      return 47;
    } else if (i === 3 && j === 2) {
      return 48;
    } else if (i === 4 && j === 2) {
      return 49;
    } else if (i === 5 && j === 2) {
      return 50;
    } else if (j === 1 && i === 5) {
      return 51;
    } else if (j === 1 && i === 4) {
      return 52;
    } else if (j === 1 && i === 3) {
      return 53;
    } else if (j === 1 && i === 2) {
      return 54;
    } else if (j === 1 && i === 1) {
      return 55;
    } else if (j === 1 && i === 0) {
      return 56;
    }
  }

  var a = 0;
  return (
    <>
      {player && (
        <LudoGameDetail
          data={videoData}
          playerid={player}
          show={showLudoGameDetails}
          setShow={setShowLudoGameDetails}
        />
      )}
      {player && (
        <DragModal
          data={videoData}
          playerid={player}
          show={showDragModal}
          setShow={setShowDragModal}
        />
      )}

      <Modal
        fullscreen={true}
        show={show}
        style={{ backgroundColor: "white !important" }}
        onHide={() => setShow(false)}
      >
        <Modal.Body
          style={{
            marginTop: "50px",
            justifyContent: "center",
          }}
        >
          <div className="board-ludo">
            {Array.from({ length: 3 }).map((_, i) =>
              i !== 1 ? (
                <div className="row-content">
                  {Array.from({ length: 3 }).map((_, j) =>
                    i === 0 && j === 0 ? (
                      <div className="red-section">
                        <div className="row-circle">
                          <div
                            onClick={() => console.log("clicked")}
                            className="red-circle"
                          >
                            <IoLocationSharp className="icons-design-main-box" />
                          </div>
                          <div className="red-circle">
                            <IoLocationSharp className="icons-design-main-box" />
                          </div>
                        </div>
                        <div className="row-circle">
                          <div className="red-circle">
                            <IoLocationSharp className="icons-design-main-box" />
                          </div>
                          <div className="red-circle">
                            <IoLocationSharp className="icons-design-main-box" />
                          </div>
                        </div>
                      </div>
                    ) : i === 0 && j === 2 ? (
                      <div className="green-section">
                        <div className="row-circle">
                          <div className="green-circle">
                            <IoLocationSharp className="icons-design-main-box" />
                          </div>
                          <div className="green-circle">
                            <IoLocationSharp className="icons-design-main-box" />
                          </div>
                        </div>
                        <div className="row-circle">
                          <div className="green-circle">
                            <IoLocationSharp className="icons-design-main-box" />
                          </div>
                          <div className="green-circle">
                            <IoLocationSharp className="icons-design-main-box" />
                          </div>
                        </div>
                      </div>
                    ) : i === 2 && j === 0 ? (
                      <div className="yellow-section">
                        <div className="row-circle">
                          {a4 === 0 ? (
                            <div className="yellow-circle">
                              <Draggable
                                scale={1}
                                onDrag={() => setDraggable(true)}
                                onStop={() => {
                                  draggablestopyellowA4();
                                }}
                              >
                                <IoLocationSharp className="icons-design-main-box" />
                              </Draggable>
                            </div>
                          ) : (
                            <div
                              //   onClick={() => console.log("clicked")}
                              className="yellow-circle"
                            >
                              {/* <IoLocationSharp className="icons-design" /> */}
                            </div>
                          )}
                          {a2 === 0 ? (
                            <div className="yellow-circle">
                              <Draggable
                                scale={1}
                                onDrag={() => setDraggable(true)}
                                onStop={() => {
                                  draggablestopyellowA2();
                                }}
                              >
                                <IoLocationSharp className="icons-design-main-box" />
                              </Draggable>
                            </div>
                          ) : (
                            <div
                              //   onClick={() => console.log("clicked")}
                              className="yellow-circle"
                            >
                              {/* <IoLocationSharp className="icons-design" /> */}
                            </div>
                          )}
                        </div>
                        <div className="row-circle">
                          {a3 === 0 ? (
                            <div className="yellow-circle">
                              <Draggable
                                scale={1}
                                onDrag={() => setDraggable(true)}
                                onStop={() => {
                                  draggablestopyellowA3();
                                }}
                              >
                                <IoLocationSharp className="icons-design-main-box" />
                              </Draggable>
                            </div>
                          ) : (
                            <div
                              //   onClick={() => console.log("clicked")}
                              className="yellow-circle"
                            >
                              {/* <IoLocationSharp className="icons-design" /> */}
                            </div>
                          )}
                          {a1 === 0 ? (
                            <div className="yellow-circle">
                              <Draggable
                                scale={1}
                                onDrag={() => setDraggable(true)}
                                onStop={() => {
                                  draggablestopyellowA1();
                                }}
                              >
                                <IoLocationSharp className="icons-design-main-box" />
                              </Draggable>
                            </div>
                          ) : (
                            <div
                              //   onClick={() => console.log("clicked")}
                              className="yellow-circle"
                            >
                              {/* <IoLocationSharp className="icons-design" /> */}
                            </div>
                          )}
                        </div>
                      </div>
                    ) : i === 2 && j === 2 ? (
                      <div className="blue-section">
                        <div className="row-circle">
                          <div className="blue-circle">
                            <IoLocationSharp className="icons-design-main-box" />
                          </div>
                          <div className="blue-circle">
                            <IoLocationSharp className="icons-design-main-box" />
                          </div>
                        </div>
                        <div className="row-circle">
                          <div className="blue-circle">
                            <IoLocationSharp className="icons-design-main-box" />
                          </div>
                          <div className="blue-circle">
                            <IoLocationSharp className="icons-design-main-box" />
                          </div>
                        </div>
                      </div>
                    ) : i === 0 && j === 1 ? (
                      <div className="lane-section">
                        {Array.from({ length: 6 }).map((_, k) => (
                          <div className="lane-section-row">
                            {Array.from({ length: 3 }).map((_, z) =>
                              z === 1 && k !== 0 ? (
                                <div className="box green-box"></div>
                              ) : z === 2 && k === 1 ? (
                                <>
                                  {
                                    <div style={{ display: "none" }}>
                                      {(a = setyellowa1boxvalue(k, z))}
                                    </div>
                                  }
                                  {a === a1 ? (
                                    <div className="box-star green-box star">
                                      <Draggable
                                        scale={1}
                                        onDrag={() => setDraggable(true)}
                                        onStop={() => {
                                          draggablestopyellowA1();
                                        }}
                                      >
                                        <IoLocationSharp className="icons-design-star" />
                                      </Draggable>
                                    </div>
                                  ) : a === a2 ? (
                                    <div className="box-star green-box star">
                                      <Draggable
                                        scale={1}
                                        onDrag={() => setDraggable(true)}
                                        onStop={() => {
                                          draggablestopyellowA2();
                                        }}
                                      >
                                        <IoLocationSharp className="icons-design-star" />
                                      </Draggable>
                                    </div>
                                  ) : a === a3 ? (
                                    <div className="box-star green-box star">
                                      <Draggable
                                        scale={1}
                                        onDrag={() => setDraggable(true)}
                                        onStop={() => {
                                          draggablestopyellowA3();
                                        }}
                                      >
                                        <IoLocationSharp className="icons-design-star" />
                                      </Draggable>
                                    </div>
                                  ) : a === a4 ? (
                                    <div className="box-star green-box star">
                                      <Draggable
                                        scale={1}
                                        onDrag={() => setDraggable(true)}
                                        onStop={() => {
                                          draggablestopyellowA4();
                                        }}
                                      >
                                        <IoLocationSharp className="icons-design-star" />
                                      </Draggable>
                                    </div>
                                  ) : (
                                    <div className="box green-box star"></div>
                                  )}
                                </>
                              ) : (
                                <div>
                                  {
                                    <div style={{ display: "none" }}>
                                      {(a = setyellowa1boxvalue(k, z))}
                                    </div>
                                  }
                                  {a === a1 ? (
                                    <div className="box">
                                      <Draggable
                                        scale={1}
                                        onDrag={() => setDraggable(true)}
                                        onStop={() => {
                                          draggablestopyellowA1();
                                        }}
                                      >
                                        <IoLocationSharp
                                          style={{ color: "black" }}
                                          className="icons-design"
                                        />
                                      </Draggable>
                                    </div>
                                  ) : a === a2 ? (
                                    <div className="box">
                                      <Draggable
                                        scale={1}
                                        onDrag={() => setDraggable(true)}
                                        onStop={() => {
                                          draggablestopyellowA2();
                                        }}
                                      >
                                        <IoLocationSharp
                                          style={{ color: "black" }}
                                          className="icons-design"
                                        />
                                      </Draggable>
                                    </div>
                                  ) : a === a3 ? (
                                    <div className="box">
                                      <Draggable
                                        scale={1}
                                        onDrag={() => setDraggable(true)}
                                        onStop={() => {
                                          draggablestopyellowA3();
                                        }}
                                      >
                                        <IoLocationSharp
                                          style={{ color: "black" }}
                                          className="icons-design"
                                        />
                                      </Draggable>
                                    </div>
                                  ) : a === a4 ? (
                                    <div className="box">
                                      <Draggable
                                        scale={1}
                                        onDrag={() => setDraggable(true)}
                                        onStop={() => {
                                          draggablestopyellowA4();
                                        }}
                                      >
                                        <IoLocationSharp
                                          style={{ color: "black" }}
                                          className="icons-design"
                                        />
                                      </Draggable>
                                    </div>
                                  ) : (
                                    <div className="box"></div>
                                  )}
                                </div>
                              )
                            )}
                          </div>
                        ))}
                      </div>
                    ) : i === 2 && j === 1 ? (
                      <div className="lane-section">
                        {Array.from({ length: 6 }).map((_, k) => (
                          <div className="lane-section-row">
                            {Array.from({ length: 3 }).map((_, z) =>
                              z === 1 && k !== 5 ? (
                                <>
                                  {
                                    <div style={{ display: "none" }}>
                                      {(a = setyellowa4boxvalue(k, z))}
                                    </div>
                                  }
                                  {a === a1 ? (
                                    <div className="box yellow-box">
                                      <Draggable
                                        scale={1}
                                        onDrag={() => setDraggable(true)}
                                        onStop={() => {
                                          draggablestopyellowA1();
                                        }}
                                      >
                                        <IoLocationSharp
                                          style={{ color: "black" }}
                                          className="icons-design"
                                        />
                                      </Draggable>
                                    </div>
                                  ) : a === a2 ? (
                                    <div className="box yellow-box">
                                      <Draggable
                                        scale={1}
                                        onDrag={() => setDraggable(true)}
                                        onStop={() => {
                                          draggablestopyellowA2();
                                        }}
                                      >
                                        <IoLocationSharp
                                          style={{ color: "black" }}
                                          className="icons-design"
                                        />
                                      </Draggable>
                                    </div>
                                  ) : a === a3 ? (
                                    <div className="box yellow-box">
                                      <Draggable
                                        scale={1}
                                        onDrag={() => setDraggable(true)}
                                        onStop={() => {
                                          draggablestopyellowA3();
                                        }}
                                      >
                                        <IoLocationSharp
                                          style={{ color: "black" }}
                                          className="icons-design"
                                        />
                                      </Draggable>
                                    </div>
                                  ) : a === a4 ? (
                                    <div className="box yellow-box">
                                      <Draggable
                                        scale={1}
                                        onDrag={() => setDraggable(true)}
                                        onStop={() => {
                                          draggablestopyellowA4();
                                        }}
                                      >
                                        <IoLocationSharp
                                          style={{ color: "black" }}
                                          className="icons-design"
                                        />
                                      </Draggable>
                                    </div>
                                  ) : (
                                    <div className="box yellow-box"></div>
                                  )}
                                </>
                              ) : z === 0 && k === 4 ? (
                                <>
                                  {
                                    <div style={{ display: "none" }}>
                                      {(a = setyellowa4boxvalue(k, z))}
                                    </div>
                                  }
                                  {a === a1 ? (
                                    <div className="box-star yellow-box star">
                                      <Draggable
                                        scale={1}
                                        onDrag={() => setDraggable(true)}
                                        onStop={() => {
                                          draggablestopyellowA1();
                                        }}
                                      >
                                        <IoLocationSharp
                                          style={{ color: "black" }}
                                          className="icons-design-star"
                                        />
                                      </Draggable>
                                    </div>
                                  ) : a === a2 ? (
                                    <div className="box-star yellow-box star">
                                      <Draggable
                                        scale={1}
                                        onDrag={() => setDraggable(true)}
                                        onStop={() => {
                                          draggablestopyellowA2();
                                        }}
                                      >
                                        <IoLocationSharp
                                          style={{ color: "black" }}
                                          className="icons-design-star"
                                        />
                                      </Draggable>
                                    </div>
                                  ) : a === a3 ? (
                                    <div className="box-star yellow-box star">
                                      <Draggable
                                        scale={1}
                                        onDrag={() => setDraggable(true)}
                                        onStop={() => {
                                          draggablestopyellowA3();
                                        }}
                                      >
                                        <IoLocationSharp
                                          style={{ color: "black" }}
                                          className="icons-design-star"
                                        />
                                      </Draggable>
                                    </div>
                                  ) : a === a4 ? (
                                    <div className="box-star yellow-box star">
                                      <Draggable
                                        scale={1}
                                        onDrag={() => setDraggable(true)}
                                        onStop={() => {
                                          draggablestopyellowA4();
                                        }}
                                      >
                                        <IoLocationSharp
                                          style={{ color: "black" }}
                                          className="icons-design-star"
                                        />
                                      </Draggable>
                                    </div>
                                  ) : (
                                    <div className="box yellow-box star"></div>
                                  )}
                                </>
                              ) : (
                                <div>
                                  {
                                    <div style={{ display: "none" }}>
                                      {(a = setyellowa4boxvalue(k, z))}
                                    </div>
                                  }
                                  {a === a1 ? (
                                    <div className="box">
                                      <Draggable
                                        scale={1}
                                        onDrag={() => setDraggable(true)}
                                        onStop={() => {
                                          draggablestopyellowA1();
                                        }}
                                      >
                                        <IoLocationSharp
                                          style={{ color: "black" }}
                                          className="icons-design"
                                        />
                                      </Draggable>
                                    </div>
                                  ) : a === a2 ? (
                                    <div className="box">
                                      <Draggable
                                        scale={1}
                                        onDrag={() => setDraggable(true)}
                                        onStop={() => {
                                          draggablestopyellowA2();
                                        }}
                                      >
                                        <IoLocationSharp
                                          style={{ color: "black" }}
                                          className="icons-design"
                                        />
                                      </Draggable>
                                    </div>
                                  ) : a === a3 ? (
                                    <div className="box">
                                      <Draggable
                                        scale={1}
                                        onDrag={() => setDraggable(true)}
                                        onStop={() => {
                                          draggablestopyellowA3();
                                        }}
                                      >
                                        <IoLocationSharp
                                          style={{ color: "black" }}
                                          className="icons-design"
                                        />
                                      </Draggable>
                                    </div>
                                  ) : a === a4 ? (
                                    <div className="box">
                                      <Draggable
                                        scale={1}
                                        onDrag={() => setDraggable(true)}
                                        onStop={() => {
                                          draggablestopyellowA4();
                                        }}
                                      >
                                        <IoLocationSharp
                                          style={{ color: "black" }}
                                          className="icons-design"
                                        />
                                      </Draggable>
                                    </div>
                                  ) : (
                                    <div className="box"></div>
                                  )}
                                </div>
                              )
                            )}
                          </div>
                        ))}
                      </div>
                    ) : null
                  )}
                </div>
              ) : (
                <div className="row-content-between">
                  {Array.from({ length: 3 }).map((_, j) =>
                    j === 0 ? (
                      <div className="lane-section-between">
                        {Array.from({ length: 3 }).map((_, k) => (
                          <div className="lane-section-row">
                            {Array.from({ length: 6 }).map((_, z) =>
                              k === 1 && z !== 0 ? (
                                <div className="box red-box"></div>
                              ) : k === 0 && z === 1 ? (
                                <>
                                  {
                                    <div style={{ display: "none" }}>
                                      {(a = setyellowa2boxvalue(k, z))}
                                    </div>
                                  }
                                  {a === a1 ? (
                                    <div className="box-star red-box star">
                                      <Draggable
                                        scale={1}
                                        onDrag={() => setDraggable(true)}
                                        onStop={() => {
                                          draggablestopyellowA1();
                                        }}
                                      >
                                        <IoLocationSharp
                                          style={{ color: "black" }}
                                          className="icons-design-star"
                                        />
                                      </Draggable>
                                    </div>
                                  ) : a === a2 ? (
                                    <div className="box-star red-box star">
                                      <Draggable
                                        scale={1}
                                        onDrag={() => setDraggable(true)}
                                        onStop={() => {
                                          draggablestopyellowA2();
                                        }}
                                      >
                                        <IoLocationSharp
                                          style={{ color: "black" }}
                                          className="icons-design-star"
                                        />
                                      </Draggable>
                                    </div>
                                  ) : a === a3 ? (
                                    <div className="box-star red-box star">
                                      <Draggable
                                        scale={1}
                                        onDrag={() => setDraggable(true)}
                                        onStop={() => {
                                          draggablestopyellowA3();
                                        }}
                                      >
                                        <IoLocationSharp
                                          style={{ color: "black" }}
                                          className="icons-design-star"
                                        />
                                      </Draggable>
                                    </div>
                                  ) : a === a4 ? (
                                    <div className="box-star red-box star">
                                      <Draggable
                                        scale={1}
                                        onDrag={() => setDraggable(true)}
                                        onStop={() => {
                                          draggablestopyellowA4();
                                        }}
                                      >
                                        <IoLocationSharp
                                          style={{ color: "black" }}
                                          className="icons-design-star"
                                        />
                                      </Draggable>
                                    </div>
                                  ) : (
                                    <div className="box red-box star"></div>
                                  )}
                                </>
                              ) : (
                                <div>
                                  {
                                    <div style={{ display: "none" }}>
                                      {(a = setyellowa2boxvalue(k, z))}
                                    </div>
                                  }
                                  {a === a1 ? (
                                    <div className="box">
                                      <Draggable
                                        scale={1}
                                        onDrag={() => setDraggable(true)}
                                        onStop={() => {
                                          draggablestopyellowA1();
                                        }}
                                      >
                                        <IoLocationSharp
                                          style={{ color: "black" }}
                                          className="icons-design"
                                        />
                                      </Draggable>
                                    </div>
                                  ) : a === a2 ? (
                                    <div className="box">
                                      <Draggable
                                        scale={1}
                                        onDrag={() => setDraggable(true)}
                                        onStop={() => {
                                          draggablestopyellowA2();
                                        }}
                                      >
                                        <IoLocationSharp
                                          style={{ color: "black" }}
                                          className="icons-design"
                                        />
                                      </Draggable>
                                    </div>
                                  ) : a === a3 ? (
                                    <div className="box">
                                      <Draggable
                                        scale={1}
                                        onDrag={() => setDraggable(true)}
                                        onStop={() => {
                                          draggablestopyellowA3();
                                        }}
                                      >
                                        <IoLocationSharp
                                          style={{ color: "black" }}
                                          className="icons-design"
                                        />
                                      </Draggable>
                                    </div>
                                  ) : a === a4 ? (
                                    <div className="box">
                                      <Draggable
                                        scale={1}
                                        onDrag={() => setDraggable(true)}
                                        onStop={() => {
                                          draggablestopyellowA4();
                                        }}
                                      >
                                        <IoLocationSharp
                                          style={{ color: "black" }}
                                          className="icons-design"
                                        />
                                      </Draggable>
                                    </div>
                                  ) : (
                                    <div className="box"></div>
                                  )}
                                </div>
                              )
                            )}
                          </div>
                        ))}
                      </div>
                    ) : j === 1 ? (
                      <div className="center-content">
                        <div className="green-center"></div>
                        <div className="blue-center"></div>
                        <div className="yellow-center">
                          {a1 === 57 ? (
                            <IoLocationSharp
                              onClick={handleClickListenrYellowA1}
                              className="icons-design-center-a1"
                            />
                          ) : (
                            <div></div>
                          )}
                          {a2 === 57 ? (
                            <IoLocationSharp
                              onClick={handleClickListenrYellowA2}
                              className="icons-design-center-a2"
                            />
                          ) : (
                            <div></div>
                          )}
                          {a3 === 57 ? (
                            <IoLocationSharp
                              onClick={handleClickListenrYellowA3}
                              className="icons-design-center-a3"
                            />
                          ) : (
                            <div></div>
                          )}
                          {a4 === 57 ? (
                            <IoLocationSharp
                              onClick={handleClickListenrYellowA4}
                              className="icons-design-center-a4"
                            />
                          ) : (
                            <div></div>
                          )}
                        </div>
                        <div className="red-center"></div>
                      </div>
                    ) : j === 2 ? (
                      <div className="lane-section-between">
                        {Array.from({ length: 3 }).map((_, k) => (
                          <div className="lane-section-row">
                            {Array.from({ length: 6 }).map((_, z) =>
                              k === 1 && z !== 5 ? (
                                <div className="box blue-box"></div>
                              ) : k === 2 && z === 4 ? (
                                <>
                                  {
                                    <div style={{ display: "none" }}>
                                      {(a = setyellowa3boxvalue(k, z))}
                                    </div>
                                  }
                                  {a === a1 ? (
                                    <div className="box-star blue-box star">
                                      <Draggable
                                        scale={1}
                                        onDrag={() => setDraggable(true)}
                                        onStop={() => {
                                          draggablestopyellowA1();
                                        }}
                                      >
                                        <IoLocationSharp
                                          style={{ color: "black" }}
                                          className="icons-design-star"
                                        />
                                      </Draggable>
                                    </div>
                                  ) : a === a2 ? (
                                    <div className="box-star blue-box star">
                                      <Draggable
                                        scale={1}
                                        onDrag={() => setDraggable(true)}
                                        onStop={() => {
                                          draggablestopyellowA2();
                                        }}
                                      >
                                        <IoLocationSharp
                                          style={{ color: "black" }}
                                          className="icons-design-star"
                                        />
                                      </Draggable>
                                    </div>
                                  ) : a === a3 ? (
                                    <div className="box-star blue-box star">
                                      <Draggable
                                        scale={1}
                                        onDrag={() => setDraggable(true)}
                                        onStop={() => {
                                          draggablestopyellowA3();
                                        }}
                                      >
                                        <IoLocationSharp
                                          style={{ color: "black" }}
                                          className="icons-design-star"
                                        />
                                      </Draggable>
                                    </div>
                                  ) : a === a4 ? (
                                    <div className="box-star blue-box star">
                                      <Draggable
                                        scale={1}
                                        onDrag={() => setDraggable(true)}
                                        onStop={() => {
                                          draggablestopyellowA4();
                                        }}
                                      >
                                        <IoLocationSharp
                                          style={{ color: "black" }}
                                          className="icons-design-star"
                                        />
                                      </Draggable>
                                    </div>
                                  ) : (
                                    <div className="box blue-box star"></div>
                                  )}
                                </>
                              ) : (
                                <div>
                                  {
                                    <div style={{ display: "none" }}>
                                      {(a = setyellowa3boxvalue(k, z))}
                                    </div>
                                  }
                                  {a === a1 ? (
                                    <div className="box">
                                      <Draggable
                                        scale={1}
                                        onDrag={() => setDraggable(true)}
                                        onStop={() => {
                                          draggablestopyellowA1();
                                        }}
                                      >
                                        <IoLocationSharp
                                          style={{ color: "black" }}
                                          className="icons-design"
                                        />
                                      </Draggable>
                                    </div>
                                  ) : a === a2 ? (
                                    <div className="box">
                                      <Draggable
                                        scale={1}
                                        onDrag={() => setDraggable(true)}
                                        onStop={() => {
                                          draggablestopyellowA2();
                                        }}
                                      >
                                        <IoLocationSharp
                                          style={{ color: "black" }}
                                          className="icons-design"
                                        />
                                      </Draggable>
                                    </div>
                                  ) : a === a3 ? (
                                    <div className="box">
                                      <Draggable
                                        scale={1}
                                        onDrag={() => setDraggable(true)}
                                        onStop={() => {
                                          draggablestopyellowA3();
                                        }}
                                      >
                                        <IoLocationSharp
                                          style={{ color: "black" }}
                                          className="icons-design"
                                        />
                                      </Draggable>
                                    </div>
                                  ) : a === a4 ? (
                                    <div className="box">
                                      <Draggable
                                        scale={1}
                                        onDrag={() => setDraggable(true)}
                                        onStop={() => {
                                          draggablestopyellowA4();
                                        }}
                                      >
                                        <IoLocationSharp
                                          style={{ color: "black" }}
                                          className="icons-design"
                                        />
                                      </Draggable>
                                    </div>
                                  ) : (
                                    <div className="box"></div>
                                  )}
                                </div>
                              )
                            )}
                          </div>
                        ))}
                      </div>
                    ) : null
                  )}
                </div>
              )
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LudoGame;
