import { Modal, Row, Col, Image, Button } from "react-bootstrap";
import { useHistory } from "react-router";

const ScanDetails = ({ show, setShow, data }) => {
  const history = useHistory();
  const Badges = data?.gamificationData?.filter(
    (item) => item.TypeEarned === "Badge"
  );

  const Stickers = data?.gamificationData?.filter(
    (item) => item.TypeEarned === "Sticker"
  );

  const Tambola = data?.gamificationData?.filter(
    (item) => item.TypeEarned === "Tambola"
  );

  const ScratchCard = data?.gamificationData?.filter(
    (item) => item.TypeEarned === "Scratchcard"
  );

  const Certificate = data?.gamificationData?.filter(
    (item) => item.TypeEarned === "Certificate"
  );

  return (
    <Modal fullscreen={true} show={show} onHide={() => setShow(false)}>
      <Modal.Body className="mb-20 p-0 text-left">
        <Row className="m-2">
          <Col
            onClick={() => history.push("/points-info")}
            style={{
              padding: "20px",
              background: "#FFFDD1",
              marginRight: 5,
              borderRadius: 10,
            }}
          >
            <span>
              <Image
                style={{
                  width: 30,
                  marginBottom: 10,
                }}
                src="/assets/images/cash/wallet.png"
                alt=""
              />
            </span>
            <span
              style={{
                fontSize: 30,
                color: "#E96C14",
                fontWeight: 700,
              }}
            >
              {data.reedemPoint}
            </span>

            <p className="mt-3 fw-bold">Total Points Earned</p>
          </Col>
          {Badges?.length > 0 && (
            <Col
              style={{
                padding: "20px",
                background: "#FFF2E1",
                marginLeft: 5,
                borderRadius: 10,
              }}
            >
              <span>
                {" "}
                <Image
                  style={{
                    width: 30,
                    marginBottom: 10,
                  }}
                  src="/assets/images/cash/star.png"
                  alt=""
                />{" "}
              </span>
              <span
                style={{
                  fontSize: 30,
                  color: "#E41A60",
                  fontWeight: 700,
                }}
              >
                {Badges.length}
              </span>

              <p className="mt-3 fw-bold">Total Badges Earned</p>
            </Col>
          )}
        </Row>

        <Row className="m-2">
          {Tambola?.length > 0 && (
            <Col
              style={{
                padding: "20px",
                background: "#FFFDD1",
                marginRight: 5,
                borderRadius: 10,
              }}
            >
              <span>
                <Image
                  style={{
                    width: 30,
                    marginBottom: 10,
                  }}
                  src="/assets/images/cash/wallet.png"
                  alt=""
                />
              </span>
              <span
                style={{
                  fontSize: 30,
                  color: "#E96C14",
                  fontWeight: 700,
                }}
              >
                {Tambola.length}
              </span>

              <p className="mt-3 fw-bold">Tambola Earned</p>
            </Col>
          )}
          {ScratchCard?.length > 0 && (
            <Col
              style={{
                padding: "20px",
                background: "#FFF2E1",
                marginLeft: 5,
                borderRadius: 10,
              }}
            >
              <span>
                <Image
                  style={{
                    width: 30,
                    marginBottom: 10,
                  }}
                  src="/assets/images/cash/star.png"
                  alt=""
                />
              </span>
              <span
                style={{
                  fontSize: 30,
                  color: "#E41A60",
                  fontWeight: 700,
                }}
              >
                {ScratchCard.length}
              </span>

              <p className="mt-3 fw-bold">Scratch Card Earned</p>
            </Col>
          )}
        </Row>

        <Row className="m-2">
          {Certificate?.length > 0 && (
            <Col
              style={{
                padding: "20px",
                background: "#FFFDD1",
                marginRight: 5,
                borderRadius: 10,
              }}
            >
              <span>
                {" "}
                <Image
                  style={{
                    width: 30,
                    marginBottom: 10,
                  }}
                  src="/assets/images/cash/wallet.png"
                  alt=""
                />{" "}
              </span>
              <span
                style={{
                  fontSize: 30,
                  color: "#E96C14",
                  fontWeight: 700,
                }}
              >
                {Certificate.length}
              </span>

              <p className="mt-3 fw-bold">Certificate Earned</p>
            </Col>
          )}
          {Stickers?.length > 0 && (
            <Col
              style={{
                padding: "20px",
                background: "#FFF2E1",
                marginLeft: 5,
                borderRadius: 10,
              }}
            >
              <span>
                {" "}
                <Image
                  style={{
                    width: 30,
                    marginBottom: 10,
                  }}
                  src="/assets/images/cash/star.png"
                  alt=""
                />{" "}
              </span>
              <span
                style={{
                  fontSize: 30,
                  color: "#E41A60",
                  fontWeight: 700,
                }}
              >
                {Stickers.length}
              </span>

              <p className="mt-3 fw-bold">Stickers</p>
            </Col>
          )}
        </Row>

        <div style={{ marginTop: "20px", textAlign: "center" }}>
          <Button
            onClick={() => setShow(false)}
            style={{
              width: "30%",
              background: "#F8D6A5",
              color: "black",
              fontWeight: "bold",
              borderRadius: "20px",
              border: "1px solid #f8d6a5",
            }}
          >
            OK
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ScanDetails;
