// import SecoundaryHeader from "component/Nav/SecoundaryHeader";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { Calendar, Typography, Button } from "antd";

import "./Goal.css";
import GoalReward from "./Reward";

const Goal = ({ show, setShow }) => {
  const [showReward, setShowReward] = useState(false);
  //eslint-disable-next-line
  const [minValue, setMinValue] = useState(0);
  //eslint-disable-next-line
  const [maxValue, setMaxValue] = useState(500000);
  const [currentValue, setCurrentValue] = useState(5000);

  const onChange = (e) => {
    setCurrentValue(e.target.value);
    setValue();
  };

  const setValue = () => {
    const rangeV = document.getElementById("rangeValue");
    const tickContainer = document.querySelector(".range-wrap");

    const newValue = Number(
      ((currentValue - minValue) * 100) / (maxValue - minValue)
    );
    const newPosition = 30 - newValue * 0.85;
    rangeV.style.left = `calc(${newValue}% + (${newPosition}px))`;
    rangeV.innerHTML = `${currentValue}`;

    tickContainer.style.setProperty("--p", `calc(${newValue}%)`);
  };

  return (
    <>
      <GoalReward show={showReward} setShow={setShowReward} />
      <Modal fullscreen={true} show={show} onHide={() => setShow(false)}>
        {/* <Modal.Header
          style={{
            border: 0,
            display: "flex",
            flexDirection: "row-reverse",
            marginBottom: 20,
          }}
        > */}
          {/* <SecoundaryHeader
            text="Goal"
            sideHide="false"
            onBack={() => {
              setShow(false);
            }}
          /> */}
        {/* </Modal.Header> */}
        <Modal.Body style={{ overflowX: "hidden" }} className="text-center">
          <Typography.Title style={{ marginTop: "20px" }} level={4}>
            Set Your Target
          </Typography.Title>

          <div className="range-wrap">
            <div className="ticks" id="tickContainer"></div>
            <div className="range-value" id="rangeValue"></div>
            <input
              onChange={(e) => onChange(e)}
              id="range"
              type="range"
              min={minValue}
              max={maxValue}
              value={currentValue}
              step="5000"
            />
          </div>
          <Typography.Title level={4}>Select a date</Typography.Title>
          <div className="site-calendar-demo-card">
            <Calendar fullscreen={false} mode="month" />
          </div>
          <div style={{ marginTop: "30px" }}>
            <Button
              type="primary"
              shape="round"
              size="large"
              onClick={() => {
                setShowReward(true);
              }}
            >
              How to get your target
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Goal;
