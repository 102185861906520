import { Carousel } from "antd";
import axios from "axios";
import { setAxiosDefault } from "../axiosDefaults";
import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { useHistory } from "react-router";
import styled from "styled-components";
import { asyncWrap } from "../utils/utils";

const GetStarted = () => {
  const [introData, setIntroData] = useState([]);
  const [splaceScreen, setSplaceScreen] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => setLoading(false), 3000);
  }, []);
  const history = useHistory();
  setAxiosDefault();

  const CarouselWrapper = styled(Carousel)`
    > .slick-dots li {
      height: 2px;
      background: gray;
    }
    > .slick-dots li.slick-active button {
      height: 2px;
      background: gray;
    }
  `;

  const getCmsIntro = async () => {
    const [error, result] = await asyncWrap(
      axios.get("/cmsValues/?cmsType=IntoPages")
    );
    if (!result) {
      console.log(error);
      return;
    }
    setIntroData(result.data.data.IntroPages);
    console.log(introData);
  };

  const getCmsSplaceScreen = async () => {
    const [error, result] = await asyncWrap(
      axios.get("/cmsValues/?cmsType=SplashScreen")
    );
    if (!result) {
      console.log(error);
      return;
    }
    setSplaceScreen(result.data.data.SplashScreen.SplashScreen);
  };

  useEffect(() => {
    getCmsSplaceScreen();
    getCmsIntro();
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      {loading ? (
        <div>
          <Image
            style={{
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
            className="splace-screen"
            src={splaceScreen}
            alt=""
          />
        </div>
      ) : introData.length !== 0 ? (
        <div
          style={{ background: "#fffef2", height: "100vh" }}
          className="text-center"
        >
          <div>
            <CarouselWrapper
              width="100vw"
              // height="100vh"
              dots={true}
              dotPosition="bottom"
            >
              {introData.map((item, index) => (
                <div key={index}>
                  {item.VideoPath ? (
                    <video
                      style={{ height: "95vh", marginTop: "2.5vh" }}
                      width="100%"
                      controls
                    >
                      <source src={item.VideoPath} />
                      {/* <source src={item.VideoPath} type="video/ogg" /> */}
                      {/* <source src={item.VideoPath} type="video/web" /> */}
                    </video>
                  ) : (
                    <div
                      style={{
                        position: "relative",
                        marginLeft: "auto",
                        marginRight: "auto",
                        width: "300px",
                        display: "flex",
                        alignItems: "center",
                        marginTop: "2.5vh",
                        height: "95vh",
                      }}
                    >
                      <Image
                        style={{ width: "100%", height: "fit-content" }}
                        src={item.BannerImage}
                        alt="introImage"
                      />
                    </div>
                  )}
                </div>
              ))}
              <div>
                <div
                  style={{
                    background: "white",
                    top: "50%",
                    left: "50%",
                    position: "absolute",
                    transform: "translate(-50%)",
                  }}
                >
                  <button
                    type="button"
                    style={{ background: "#3a69dd" }}
                    className="btn btn-primary fw-bold rounded-5 px-4 mt-2"
                    onClick={() => history.push("/login")}
                  >
                    Login With Mobile Number
                  </button>
                </div>
              </div>
            </CarouselWrapper>
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            width: "100vw",
            height: "100vh",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <button
            type="button"
            style={{ background: "#3a69dd" }}
            className="btn btn-primary fw-bold rounded-5 px-4 mt-2"
            onClick={() => history.push("/login")}
          >
            Login With Mobile Number
          </button>
        </div>
      )}
    </div>
  );
};

export default GetStarted;
