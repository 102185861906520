import React from "react";
import dummy from "/assets/images/rank/dummy.png";
import RankCard from "../RankCard";
import { Modal } from "react-bootstrap";

const CityDetailModal = (props) => {
  const rankData = [
    {
      name: "Johanaa Lyons",
      address: "Adarshnagar - DEL",
      points: 300,
      diff: 66,
      rank: 1,
      profile: dummy,
      like: 3,
      star: 229,
      isYouLiked: false,
      mainBG: "#f7e7ce",
      secoundaryBG: "#DC7832",
    },
    {
      name: "Johanaa Lyons",
      address: "Adarshnagar - DEL",
      points: 233,
      diff: 55,
      rank: 2,
      profile: dummy,
      like: 3,
      star: 25,
      isYouLiked: true,
      mainBG: "#F1F1F1",
      secoundaryBG: "#484848",
    },
    {
      name: "Johanaa Lyons",
      address: "Adarshnagar - DEL",
      points: 232,
      diff: 22,
      rank: 3,
      profile: dummy,
      like: 3,
      star: 21,
      isYouLiked: false,
      mainBG: "#f7e7ce",
      secoundaryBG: "#DC7832",
    },
  ];
  return (
    <Modal
      fullscreen={true}
      show={props.show}
      onHide={() => props.setShow(false)}
    >
      <Modal.Body className="mb-20 p-0 text-center">
        <div style={{ background: "#FFFEF2" }} className="pb-3">
          <div style={{ padding: "20px" }}></div>
        </div>

        <div className="home-card" style={{ padding: "10px 0px" }}>
          <p className="app-color h1 text-left city-header">Chennai</p>

          {rankData.map((data, index) => (
            <RankCard key={index} {...data} />
          ))}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CityDetailModal;
