import moment from 'moment';

export const calcDaysLeftProgress = (startdate, enddate) => {
  const currentDate = moment();
  const endDate = moment(enddate);
  const startDate = moment(startdate);
  const totalDays = endDate.diff(startDate, 'days');
  const totalDaysLeft = endDate.diff(currentDate, 'days');

  return Math.floor((totalDaysLeft / totalDays) * 100);
};

export const calcDaysLeft = (endDate) => {
  let momEndDate = moment(endDate);
  return momEndDate.diff(moment(), 'days') + 1;
};
