import {
  StyledCard,
  StyledDroppedCard,
  StyledStatusDiv,
  StyledCardTitle,
  StyledDateText,
} from "./styledComponents";

function GamesHistoryCard({
  status,
  title,
  days,
  onClick,
  startdate,
  enddate,
  totalCertificate,
  earnedMilestone,
  socreTotal,
  scoreEarned,
  badgeTotal,
  badgeEarned,
  color,
}) {
  return (
    <>
      {status === "Dropped" ? (
        <StyledDroppedCard>
          <StyledStatusDiv color="#0EB6BA">{status}</StyledStatusDiv>
          <StyledCardTitle>{title}</StyledCardTitle>
          {/* <StyledDateText>{`${moment(startdate).format('DD MMM')} - ${moment(enddate).format('DD MMM')}`}</StyledDateText>
                        <div style={{ marginTop: '10px', marginLeft: '25px' }}>
                            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                <img src={tick} alt="tick" style={{ marginRight: '20px' }} />
                                <StyledMileStoneText>{`${1}/`}<span style={{ color: '#9E9999' }}>{totalCertificate}</span></StyledMileStoneText>
                            </div>
                            <p style={{ fontWeight: 600, marginLeft: '-40px' }}>Completed Milestones</p>
                            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                <img src={star} alt="tick" style={{ marginRight: '20px' }} />
                                <StyledMileStoneText>{`${scoreEarned}/`}<span style={{ color: '#9E9999' }}>{socreTotal}</span></StyledMileStoneText>
                            </div>
                            <p style={{ fontWeight: 600, marginLeft: '-151px' }}>Points</p>
                            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                <img src={badge} alt="tick" style={{ marginRight: '20px' }} />
                                <StyledMileStoneText>{`${badgeEarned}/`}<span style={{ color: '#9E9999' }}>{badgeTotal}</span></StyledMileStoneText>
                            </div>
                            <p style={{ fontWeight: 600, marginLeft: '-141px' }}>Badges</p>
                        </div> */}
        </StyledDroppedCard>
      ) : (
        <StyledCard onClick={onClick}>
          <StyledStatusDiv color={status === "Expired" ? "#F5726A" : "#20B849"}>
            {status}
          </StyledStatusDiv>
          <StyledCardTitle>{title}</StyledCardTitle>
          <StyledDateText>{days} days Before</StyledDateText>
        </StyledCard>
      )}
    </>
  );
}

export default GamesHistoryCard;
