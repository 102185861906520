import axios from "axios";
// import SecoundaryHeader from "component/Nav/SecoundaryHeader";
import React, { useEffect, useState } from "react";
import { Badge, Modal } from "react-bootstrap";
import { asyncWrap } from "../../utils/utils";
import EBidCard from "./EBidCard";

const EBid = ({ show, setShow }) => {
  const optFilter = [
    { title: "Live", key: 1 },
    { title: "UpComing", key: 2 },
    { title: "MyBid", key: 3 },
    { title: "Completed", key: 4 },
  ];
  const [activeOptFilter, setActiveOptFilter] = useState(1);
  const [productData, setProductdata] = useState([]);

  const getProductData = async (title) => {
    const [error, result] = await asyncWrap(
      axios.get(`/e-bid/?productStatus=${title}`)
    );

    if (!result) {
      console.log(error);
      return;
    }

    setProductdata(result.data.data);
  };

  useEffect(() => {
    if (show) {
      getProductData("Live");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);
  return (
    <Modal fullscreen={true} show={show} onHide={() => setShow(false)}>
      {/* <Modal.Header
        style={{
          border: 0,
          display: "flex",
          flexDirection: "row-reverse",
          marginBottom: 20,
        }}
      > */}
        {/* <SecoundaryHeader
          text={"E Bid"}
          sideHide="false"
          onBack={() => {
            setShow(false);
          }}
        /> */}
      {/* </Modal.Header> */}
      <Modal.Body className="mb-20 p-0 text-center">
        <div className="horizontal-scroll mt-20">
          {optFilter.map(({ title, key }) => (
            <Badge
              key={key}
              onClick={() => {
                setActiveOptFilter(key);
                getProductData(title);
              }}
              pill
              className={
                key === activeOptFilter ? "badgeActive badge" : "badge"
              }
              style={{
                background:
                  key === activeOptFilter
                    ? "#fad6a5 !important"
                    : "white !important",
              }}
            >
              {title}
            </Badge>
          ))}
        </div>
        <div className="d-flex justify-content-center mt-4 flex-column">
          {activeOptFilter === 1 &&
            productData.map((item, index) => (
              <EBidCard
                key={index}
                data={item}
                dateFormat="Expires on"
                updateBid={() => getProductData("Live")}
              />
            ))}
          {activeOptFilter === 2 &&
            productData.map((item, index) => (
              <EBidCard key={index} data={item} dateFormat="Starts on" />
            ))}
          {activeOptFilter === 3 &&
            productData.map((item, index) => (
              <EBidCard
                key={index}
                data={item}
                dateFormat="Expires on"
                updateBid={() => getProductData("MyBid")}
              />
            ))}
          {activeOptFilter === 4 &&
            productData.map((item, index) => (
              <EBidCard key={index} data={item} dateFormat="Expired on" />
            ))}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default EBid;
