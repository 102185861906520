import axios from "axios";
import { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { asyncWrap } from "../utils/utils";
import { useHistory } from "react-router";

const Home2 = () => {
  const [cmsValues, setCmsValues] = useState();
  const [whatsNewData, setWhatNewData] = useState([]);
  const history = useHistory();

  useEffect(() => {
    getCmsValues();
    getWhatsNew();
    // eslint-disable-next-line
  }, []);

  const getWhatsNew = async () => {
    const [error, result] = await asyncWrap(axios.get("/whats-new"));
    if (!result) {
      console.log(error);
      return;
    } else {
      setWhatNewData(result.data.data);
    }
  };

  const getCmsValues = async () => {
    const [error, result] = await asyncWrap(axios.get("/cmsValues"));
    if (!result) {
      console.log(error);
      return;
    }
    setCmsValues(result.data.data);
  };

  return (
    <div style={{ marginTop: "80px" }}>
      <div className="horizontal-scroll">
        {cmsValues &&
          cmsValues.HomePageBanner.map((data, index) => (
            <Image
              key={index}
              className="img-fluid for-light  rounded-4"
              style={{
                width: "auto",
                marginLeft: "20px",
                height: "125px",
              }}
              onClick={() => {
                const newWindow = window.open(
                  data?.PDFPath,
                  "_blank",
                  "noopener,noreferrer"
                );
                if (newWindow) newWindow.opener = null;
              }}
              src={data.BannerImage}
              alt={data.BannerImage}
            />
          ))}
      </div>
      {cmsValues?.HomePageBanner && <ol className="carousel-indicators mt-3">
        <li data-target="#slider" data-slide-to="0" className="active"></li>
        <li data-target="#slider" data-slide-to="1"></li>
        <li data-target="#slider" data-slide-to="2"></li>
      </ol>}
      <div
        style={{ color: "black", marginLeft: "20px" }}
        className="text-start fs-5 fw-bold mt-4"
      >
        Utilities
      </div>
      <div className="d-flex flex-row flex-wrap justify-content-center mx-2">
        <div
          onClick={() => history.push("/scan")}
          style={{ width: "44%" }}
          className="d-flex flex-column shadow m-2 p-3 rounded-4 justify-content-center"
        >
          <img alt="scan" style={{ width: "50px" }} className="mx-auto" src="/assets/images/home2/scan.svg" />
          <div style={{ color: "#616161" }} className="fs-6 fw-bold mt-3">
            scan points
          </div>
        </div>
        <div
          onClick={() => history.push("/redeem")}
          style={{ width: "44%" }}
          className="d-flex flex-column shadow m-2 p-3 rounded-4 justify-content-center"
        >
          <img alt="redeem" style={{ width: "50px" }} className="mx-auto" src="/assets/images/home2/redeem.svg" />
          <div style={{ color: "#616161" }} className="fs-6 fw-bold mt-3">
            Redeem Gift
          </div>
        </div>
        <div
          onClick={() => history.push("/points-info")}
          style={{ width: "44%" }}
          className="d-flex flex-column shadow m-2 p-3 rounded-4 justify-content-center"
        >
          <img
            alt="point_info"
            style={{ width: "50px" }}
            className="mx-auto"
            src="/assets/images/home2/point_info.svg"
          />
          <div style={{ color: "#616161" }} className="fs-6 fw-bold mt-3">
            Points Information
          </div>
        </div>
        <div
          onClick={() => history.push("/profile")}
          style={{ width: "44%" }}
          className="d-flex flex-column shadow m-2 p-3 rounded-4 justify-content-center"
        >
          <img
            alt="profile"
            style={{ width: "50px" }}
            className="mx-auto"
            src="/assets/images/home2/profile.svg"
          />
          <div style={{ color: "#616161" }} className="fs-6 fw-bold mt-3">
            Profile
          </div>
        </div>
        <div
          onClick={() => history.push("/kycverify")}
          style={{ width: "44%" }}
          className="d-flex flex-column shadow m-2 p-3 rounded-4 justify-content-center"
        >
          <img alt="kyc" style={{ width: "50px" }} className="mx-auto" src="/assets/images/home2/kyc.svg" />
          <div style={{ color: "#616161" }} className="fs-6 fw-bold mt-3">
            KYC
          </div>
        </div>
        <div
          onClick={() => history.push("/games")}
          style={{ width: "44%" }}
          className="d-flex flex-column shadow m-2 p-3 rounded-4 justify-content-center"
        >
          <img alt="game" style={{ width: "50px" }} className="mx-auto" src="/assets/images/home2/games.svg" />
          <div style={{ color: "#616161" }} className="fs-6 fw-bold mt-3">
            Games
          </div>
        </div>
      </div>
      <div
        style={{ color: "black", marginLeft: "20px" }}
        className="text-start fs-5 fw-bold mt-4"
      >
        What's New
      </div>
      <div className="horizontal-scroll">
        {whatsNewData &&
          whatsNewData.map((data, index) => (
            <Image
              key={index}
              className="img-fluid for-light rounded-4"
              style={{
                width: "auto",
                marginLeft: "20px",
                height: "125px",
              }}
              onClick={() => {
                const newWindow = window.open(
                  data?.PDFPath,
                  "_blank",
                  "noopener,noreferrer"
                );
                if (newWindow) newWindow.opener = null;
              }}
              src={data.WhatsNewImage}
              alt={data.WhatsNewImage}
            />
          ))}
      </div>
      <div
        style={{ color: "black", marginLeft: "20px" }}
        className="text-start fs-5 fw-bold mt-4"
      >
        Brochure
      </div>
      <div className="horizontal-scroll">
        {cmsValues &&
          cmsValues.Brochures.map((data, index) => (
            <Image
              key={index}
              className="img-fluid for-light rounded-4"
              style={{
                width: "auto",
                marginLeft: "20px",
                height: "125px",
              }}
              onClick={() => {
                const newWindow = window.open(
                  data?.PDFPath,
                  "_blank",
                  "noopener,noreferrer"
                );
                if (newWindow) newWindow.opener = null;
              }}
              src={data.BannerImage}
              alt={data.BannerImage}
            />
          ))}
      </div>
    </div>
  );
};

export default Home2;
