const GujaratiIcons = ({ color }) => (
  <div className="flex flex-col items-center justify-center p-[10px]">
    <svg
      width="29"
      height="52"
      viewBox="0 0 29 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.56 51.06C11.0213 51.06 8.59467 50.7427 6.28 50.108C4.00267 49.4733 1.93067 48.5773 0.0640001 47.42L3.592 41.988C5.12267 42.8093 6.63467 43.4627 8.128 43.948C9.62133 44.4333 11.2267 44.676 12.944 44.676C14.7733 44.676 16.2107 44.3773 17.256 43.78C18.3013 43.1827 18.824 42.3053 18.824 41.148C18.824 40.5507 18.656 39.9533 18.32 39.356C17.984 38.7587 17.3307 38.1427 16.36 37.508C15.4267 36.8733 14.008 36.2013 12.104 35.492L10.704 33.756C9.54667 33.0467 8.44533 32.2813 7.4 31.46C6.35467 30.6013 5.496 29.6493 4.824 28.604C4.18933 27.5587 3.872 26.3827 3.872 25.076C3.872 22.5373 4.97333 20.5213 7.176 19.028C9.37867 17.5347 12.3093 16.788 15.968 16.788C16.9387 16.788 17.984 16.844 19.104 16.956C20.224 17.0307 21.344 17.2173 22.464 17.516L21.176 23.62C20.1307 23.396 19.2347 23.2467 18.488 23.172C17.7787 23.0973 17.0507 23.06 16.304 23.06C14.4747 23.06 13.2053 23.34 12.496 23.9C11.824 24.4227 11.488 25.0573 11.488 25.804C11.488 26.4013 11.712 26.9613 12.16 27.484C12.6453 27.9693 13.3173 28.4547 14.176 28.94C15.072 29.4253 16.1173 29.9667 17.312 30.564L17.816 31.684C19.496 32.4307 20.9893 33.252 22.296 34.148C23.6027 35.044 24.6293 36.108 25.376 37.34C26.1227 38.5347 26.496 40.028 26.496 41.82C26.496 43.3507 26.0853 44.8253 25.264 46.244C24.48 47.6627 23.136 48.82 21.232 49.716C19.3653 50.612 16.808 51.06 13.56 51.06ZM3.312 39.972L0.792 33.98L11.208 30.508L25.936 25.188L28.568 31.236L17.256 34.988L3.312 39.972ZM16.3027 14.604C15.9294 12.6627 15.4627 11.1133 14.9027 9.956C14.3427 8.76133 13.652 7.90266 12.8307 7.38C12.0094 6.82 10.964 6.54 9.69469 6.54C9.20935 6.54 8.68669 6.57733 8.12669 6.652C7.56669 6.68933 7.02535 6.80133 6.50269 6.988L4.59869 1.108C5.34535 0.846664 6.20402 0.659997 7.17469 0.547999C8.14535 0.398665 9.17202 0.323998 10.2547 0.323998C11.524 0.323998 12.7747 0.491999 14.0067 0.827999C15.2014 1.12666 16.3587 1.76133 17.4787 2.732C18.5614 3.66533 19.588 5.084 20.5587 6.988C21.492 8.892 22.3507 11.4307 23.1347 14.604H16.3027Z"
        fill={color}
      />
    </svg>
  </div>
);

export default GujaratiIcons;
