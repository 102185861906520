import React from "react";
import { Row, Col, ProgressBar } from "react-bootstrap";

const Frames = (props) => {
  return (
    <>
      <div
        style={{
          background: "#D288FF",
          borderRadius: "15px",
          margin: "5px 30px 20px 30px",
          boxShadow: "rgba(0, 0, 0, 0.25)",
        }}
      >
        <Row
          className="mt-2 d-flex flex-row"
          style={{ paddingTop: "16px", margin: "0" }}
        >
          <Col
            xs={2}
            className="m-auto"
            style={{
              fontSize: "16px",
              fontWeight: "500",
              paddingRight: "0",
            }}
          >
            <span>{props.Rank}</span>
          </Col>
          <Col
            xs={7}
            className="m-auto"
            style={{
              fontSize: "16px",
              fontWeight: "500",
            }}
          >
            <span>{props.FullName}</span>
          </Col>
          <Col
            xs={3}
            className="m-auto"
            style={{
              paddingLeft: "30px",
              paddingRight: "0px",
              fontSize: "16px",
              fontWeight: "500",
            }}
          >
            <span>{props.Points}</span>
          </Col>
        </Row>
        <Row
          className="d-flex flex-row"
          style={{
            paddingTop: "11px",
            margin: "0",
            paddingBottom: "16px",
          }}
        >
          <Col xs={12} className="m-auto">
            <ProgressBar
              now={(props.Points/1000) * 100}
              variant="success"
              style={{ height: "7px" }}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Frames;
