import VideoPlayer from "../../component/Videoplayer";
import { useState } from "react";
import { Button, Image, Modal } from "react-bootstrap";
import close from "/assets/images/popclose.png";
import popupbg from "/assets/images/popupbg.png";
import { useHistory } from "react-router";

const DragModal = ({ show, setShow, data, playerid }) => {
  const history = useHistory();
  const [showVideoPlayer, setVideoPlayer] = useState(false);

  const modalData = () => {
    if (playerid === 1) {
      return (
        <Modal
          fullscreen={false}
          backdrop="static"
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={show}
          onHide={() => setShow(false)}
        >
          <Modal.Header>Benchmark Premium</Modal.Header>
          <Modal.Body
            style={{
              backgroundImage: `url(${popupbg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              overflowY: "hidden",
              width: "70vw",
              overflowX: "hidden",
            }}
            className="m-0 p-3 text-left"
          >
            <div className="text-center">
              <div>If you earn 30,00,000 Benchmark Premium.</div>
              <div>You get higher incentives</div>
              <div>Explore Game section</div>
              <div>to Know More</div>
            </div>
            <span className="modal-close">
              <Image src={close} alt="" onClick={() => setShow(false)} />
            </span>
            <div className="text-center">
              <Button
                style={{ margin: "15px" }}
                onClick={() => {
                  setVideoPlayer(true);
                }}
                variant="outline-secondary"
              >
                Game Section
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      );
    } else if (playerid === 2) {
      return (
        <Modal
          fullscreen={false}
          backdrop="static"
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={show}
          onHide={() => setShow(false)}
        >
          <Modal.Header>Total Rated NBP Budget</Modal.Header>
          <Modal.Body
            style={{
              backgroundImage: `url(${popupbg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              overflowY: "hidden",
              width: "70vw",
              overflowX: "hidden",
            }}
            className="m-0 p-3 text-left"
          >
            <div className="text-center">
              <div onClick={() => history.push("/rank")}>
                Check your Rank by going to
              </div>
            </div>
            <span className="modal-close">
              <Image src={close} alt="" onClick={() => setShow(false)} />
            </span>
            <div className="text-center">
              <Button
                style={{ margin: "15px" }}
                onClick={() => {
                  setVideoPlayer(true);
                }}
                variant="outline-secondary"
              >
                Game Section
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      );
    } else if (playerid === 3) {
      return (
        <Modal
          fullscreen={false}
          backdrop="static"
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={show}
          onHide={() => setShow(false)}
        >
          <Modal.Header>Individual Rated Budget </Modal.Header>
          <Modal.Body
            style={{
              backgroundImage: `url(${popupbg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              overflowY: "hidden",
              width: "70vw",
              overflowX: "hidden",
            }}
            className="m-0 p-3 text-left"
          >
            <div className="text-center">
              <div>Climb to the highest of the peak.</div>
              <div>you can earn upto 1,00,000 in incentives.</div>
            </div>
            <span className="modal-close">
              <Image src={close} alt="" onClick={() => setShow(false)} />
            </span>
            <div className="text-center">
              <Button
                style={{ margin: "15px" }}
                onClick={() => {
                  setVideoPlayer(true);
                }}
                variant="outline-secondary"
              >
                Game Section
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      );
    } else if (playerid === 4) {
      return (
        <Modal
          fullscreen={false}
          backdrop="static"
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={show}
          onHide={() => setShow(false)}
        >
          <Modal.Header>Persistency</Modal.Header>
          <Modal.Body
            style={{
              backgroundImage: `url(${popupbg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              overflowY: "hidden",
              width: "70vw",
              overflowX: "hidden",
            }}
            className="m-0 p-3 text-left"
          >
            <div className="text-center">
              <div>Earn higher incentive.</div>
              <div>When you get higher persistency.</div>
            </div>
            <span className="modal-close">
              <Image src={close} alt="" onClick={() => setShow(false)} />
            </span>
            <div className="text-center">
              <Button
                style={{ margin: "15px" }}
                onClick={() => {
                  setVideoPlayer(true);
                }}
                variant="outline-secondary"
              >
                Game Section
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      );
    }
  };

  return (
    <>
      {showVideoPlayer && (
        <VideoPlayer
          playerid={playerid}
          show={showVideoPlayer}
          setShow={setVideoPlayer}
          data={data}
        />
      )}
      {modalData()}
    </>
  );
};

export default DragModal;
