import { Carousel } from "antd";
import axios from "axios";
import CloseModalTab from "../component/Nav/CloseModalTab";
import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import { asyncWrap } from "../utils/utils";

function Products() {
  const [productData, setProductData] = useState([]);

  const getCmsValues = async () => {
    const [error, result] = await asyncWrap(axios.get("/cmsValues"));
    if (!result) {
      console.log(error);
      return;
    }

    setProductData(result.data.data?.ProductDetails);
  };

  useEffect(() => {
    getCmsValues();
  }, []);

  return (
    <div className="d-flex flex-column">
      <CloseModalTab />
      {productData &&
        productData.map((item, index) => (
          <div
            key={index}
            className="d-flex flex-column m-3 p-2 rounded-2 shadow-sm"
          >
            <Carousel>
              {item.Images.split(",").map((item, index) => (
                <img
                  style={{ borderRadius: "10px" }}
                  src={item}
                  height={200}
                  alt="event img"
                />
              ))}
            </Carousel>
            <div className="d-flex flex-column mt-2">
              <div className="d-flex flex-row justify-content-between">
                <div className="text-left">{item.Name}</div>
                <Button
                  onClick={() => {
                    window.location.replace(item.URL);
                  }}
                >
                  {item.ButtonText}
                </Button>
              </div>
              <div className="text-left">{item.Descriptions}</div>
            </div>
          </div>
        ))}
    </div>
  );
}

export default Products;
