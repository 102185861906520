import React from 'react';
import { InputGroup, FormControl } from 'react-bootstrap';
import { BsSearch } from "react-icons/bs";

function SearchBar({ placeholder, onSearch }) {
  return (
    <InputGroup className="mb-3" style={{ width: '90vw', border: '0.7px solid black', borderRadius: '4px' }}>
      <InputGroup.Text style={{ background: "none" }}>
        <BsSearch />
      </InputGroup.Text>
      <FormControl
        placeholder={placeholder}
        aria-label="Search"
        style={{
          boxShadow: 'none',
          outline: 'none',
          border: 'none'
        }}
        onChange={(e) => onSearch(e.target.value)}
      />
    </InputGroup>
  );
}

export default SearchBar;
