import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import { FaFileDownload } from "react-icons/fa";
import { BiFileFind } from "react-icons/bi";
import SearchBar from '../SearchBar';
import PaymentAdjustmentModal from '../PaymentAdjustmentModal/PaymentAdjustmentModal';


function Credit() {
    const [showModal, setShowModal] = useState(false);
    const [adjustmentData, setAdjustmentData] = useState([]);
    // Example data array
    const creditData = [
        {
            referenceNumber: 'CN/VJ/19-20/223',
            date: '05-03-2020',
            ledgerDesc: 'Wire & Cable',
            downloadCN: <FaFileDownload color='#FF3031' fontSize={24} />,
            type: 'Nach',
            amount: '₹ 99,750',
            viewAdjustment: <BiFileFind color='red' fontSize={16} />,
            adjustmentDetails: [ // This property should be added to each credit entry
            {
                invDetails: 'INV/VJ/17-18/S0167 (03-03-2018)',
                adjustedAmount: '₹ 26,210'
            },
            {
                invDetails: 'INV/VJ/17-18/S0322 (05-03-2018)',
                adjustedAmount: '₹ 62,137'
            },
            // ... additional adjustment details specific to this credit entry
        ],
        },
    ];

    const handleViewAdjustmentClick = (data) => {
        setAdjustmentData(data); // Set the data for the modal
        setShowModal(true);
    };

    const handleSearch = (searchValue) => {
        // Search logic here
        console.log('Search value:', searchValue);
    };

    return (
        <div className="container mt-3">
            {/* Search Bar */}
            <SearchBar
                placeholder=" SEARCH BY AMOUNT / REFERENCE NUMBER... "
                onSearch={handleSearch} />

            {/* Credit Entries */}

            {creditData.map((credit, index) => (
                <Card
                    key={index}
                    className="mb-1"
                    style={{
                        width: '90vw',
                        backgroundColor: '#E9EAEB',
                        fontSize: '12px',
                        color: '#323e3e'
                    }}>
                    <Card.Header
                        style={{
                            backgroundColor: '#E9EAEB',
                            borderBottom: 'none'
                        }}>
                        <strong>Reference Number</strong>
                        <br />
                        <span
                            style={{ fontSize: '16px' }}>
                            {credit.referenceNumber}
                        </span>
                    </Card.Header>
                    {/* Vertical bar */}
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row'
                        }}>
                        <div
                            style={{
                                backgroundColor: 'black',
                                width: '5px',
                                margin: '8px 2px 10px 16px'
                            }}>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                width: '100%',
                                padding: '0.8rem'
                            }}>
                            <div
                                style={{
                                    flex: 1,
                                    textAlign: 'left'
                                }}>
                                <div>
                                    <strong>Date</strong>
                                    <br />
                                    {credit.date}
                                </div>
                                <div>
                                    <strong>Amount</strong>
                                    <br />
                                    {credit.amount}
                                </div>
                                <div onClick={() => handleViewAdjustmentClick(credit.adjustmentDetails)}>
                                    <strong>View Adjustment</strong>
                                    <br />
                                    {credit.viewAdjustment}
                                </div>
                            </div>
                            <div
                                style={{
                                    flex: 1,
                                    textAlign: 'left'
                                }}>
                                <div>
                                    <strong>Ledger Desc</strong>
                                    <br />
                                    {credit.ledgerDesc}
                                </div>
                                <div>
                                    <strong>Type</strong>
                                    <br />
                                    {credit.type}
                                </div>
                                <div>
                                    <strong>Download CN</strong>
                                    <br />
                                    {credit.downloadCN}
                                </div>
                            </div>
                        </div>
                    </div>
                </Card>
            ))}
            {/* Reusable Payment Adjustment Modal */}
            <PaymentAdjustmentModal
                show={showModal}
                onHide={() => setShowModal(false)}
                adjustmentData={adjustmentData}
            />

        </div>
    );
}

export default Credit;
