import axios from "axios";
import { useEffect, useState } from "react";
import { Badge, Card, Col, Modal, Row } from "react-bootstrap";
import { asyncWrap } from "../../utils/utils";
import SlotMachineGame from "./SlotMachineGame";
import Swal from "sweetalert2";
const SelectSlotMachine = ({ show, setShow }) => {
  const optFilter = [
    { title: "Active", key: 1 },
    { title: "Completed", key: 2 },
  ];
  const [activeOptFilter, setActiveOptFilter] = useState(1);
  const [showSlotMachine, setShowSlotMachine] = useState(false);
  const [slotMachine, setSlotMachine] = useState([]);
  const [machineId, setMachineId] = useState();
  const [machineName, setMachineName] = useState();

  const color = ["#FEA965", "#CC4056", "#A13964", "#4E866A"];
  const getSlotMachineData = async (title) => {
    const [error, result] = await asyncWrap(
      axios.get(`/slot-machine/?slotStatus=${title}`)
    );
    if (!result) {
      console.log(error);
      return;
    }

    let data = result.data.data.map((item, i) =>
      i % 3 === 0
        ? { ...item, Color: color[i % 4] }
        : { ...item, Color: color[i % 4] }
    );
    setSlotMachine(data);
  };

  const checkOptionDetail = async (id, Name) => {
    const [error, result] = await asyncWrap(
      axios.get(`/slot-machine/option?slotMachineId=${id}`)
    );
    if (!result) {
      console.log(error);
      return;
    }

    // setData(result.data.data);
    if (result.data.data.length < 3) {
      Swal.fire("Please Enter Minimum 3 Option");
    } else {
      setMachineId(id);
      setMachineName(Name);
      setShowSlotMachine(true);
    }
  };

  useEffect(() => {
    if (show) {
      getSlotMachineData("Active");
    }
    if (show && showSlotMachine === false) {
      getSlotMachineData("Active");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, showSlotMachine]);

  return (
    <>
      <SlotMachineGame
        show={showSlotMachine}
        setShow={setShowSlotMachine}
        machineId={machineId}
        machineName={machineName}
      />
      <Modal fullscreen={true} show={show} onHide={() => setShow(false)}>
        <Modal.Body className="mb-20 p-0 text-center">
          <Row className="p-4">
            <div className="horizontal-scroll mt-20">
              {optFilter.map(({ title, key }) => (
                <Badge
                  key={key}
                  onClick={() => {
                    setActiveOptFilter(key);
                    getSlotMachineData(title);
                  }}
                  pill
                  className={
                    key === activeOptFilter ? "badgeActive badge" : "badge"
                  }
                  style={{
                    background:
                      key === activeOptFilter
                        ? "#fad6a5 !important"
                        : "white !important",
                  }}
                >
                  {title}
                </Badge>
              ))}
            </div>
            {slotMachine &&
              slotMachine.map((item, index) => (
                <Col
                  key={index}
                  xs={22}
                  style={{
                    marginTop: "40px",
                  }}
                >
                  <Card
                    onClick={
                      activeOptFilter === 1
                        ? () => {
                            checkOptionDetail(item.SlotMachineID, item.Name);
                            // setMachineId(item.SlotMachineID);
                            // setMachineName(item.Name);
                            // setShowSlotMachine(true);
                          }
                        : () => {}
                    }
                    style={{
                      backgroundColor: item.Color,
                      height: "160px",
                      boxShadow: "0px 4px 20px rgba(254, 169, 101, 0.5)",
                      borderRadius: "15px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        width: "90px",
                        height: "90px",
                        backgroundColor: "#FFCA9F",
                        borderRadius: "15px",
                        boxDhadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Card.Img
                        src={item.SlotMachineImage}
                        style={{ width: "64px", height: "64px" }}
                      />
                    </div>
                    <Card.Text
                      style={{
                        marginTop: "5px",
                        textAlign: "center",
                        color: "#fff",
                        fontWeight: "500",
                        fontSize: "16px",
                      }}
                    >
                      {item.Name}
                    </Card.Text>
                  </Card>
                </Col>
              ))}
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SelectSlotMachine;
