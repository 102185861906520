// import SecoundaryHeader from "component/Nav/SecoundaryHeader";
import React from "react";
import { Modal } from "react-bootstrap";
import ParticipantsCard from "./ParticipantsCard";

const ParticipantsList = ({ show, setShow, data }) => {
  return (
    <Modal fullscreen={true} show={show} onHide={() => setShow(false)}>
      <Modal.Body className="mb-20 p-0 text-center mt-4">
        {data.map((data, i) => (
          <ParticipantsCard key={i} data={data} i={i} />
        ))}
      </Modal.Body>
    </Modal>
  );
};

export default ParticipantsList;
