import React from 'react'

function Button({text, onClick}) {
  return (
    <div onClick={onClick} className='text-white rounded px-3 d-flex justify-content-center align-items-center flex-grow-1' style={{
        backgroundColor: "#FF2F30",
        width: "20%"
    }}>{text}</div>
  )
}

export default Button