import React from "react";
import { Modal } from "react-bootstrap";
import { AiFillCheckCircle, AiFillCloseCircle } from 'react-icons/ai'

const AnswerModal = ({ show, setShow, type }) => {
  return (
    <Modal fullscreen={true} show={show} onHide={() => setShow(false)}>
        <Modal.Body className="d-flex justify-content-center align-items-center" style={{backgroundColor: '#282c34'}}>
            {type ? (
                <AiFillCheckCircle style={{width: '200px', height: '200px', color: '#22bb33'}} />
            ) : (
                <AiFillCloseCircle style={{width: '200px', height: '200px', color: '#bb2124'}} />
            )}
        </Modal.Body>
    </Modal>
  );
};

export default AnswerModal;
