import React from 'react'

function SalesTable({column, data, isShowColor}) {
  // colors for the sales table
  const colors = ["#FF3031", "#3498DB", "#129842", "#EA7773", "#BB2CD9", "#758AA2", "#FAC42F", "#192A56", "#392e4a", "#e6ff6e"]

  return (
    <div className={`${column?.length > 4 ? "table-responsive": ""}`} style={{margin: "0"}}>
<table className="table" style={{margin: "0"}} >
  <thead>
    <tr>
      {column.length > 0 && column.map(item => (
      <th scope="col" style={{
        backgroundColor: "#ADAEB0", 
        border: "2px solid white", 
        minWidth: "calc(25vw - 2px)",
        verticalAlign: "middle"
      }} key={item.id} >
        {item?.name}
        </th>
      ))}
    </tr>
  </thead>
  <tbody>
    {data && data?.length > 0 ? data.map((item, key) => (
          <tr key={key}>
        {Object.values(item).map((el, i) => (
            <td key={i} 
            style={{backgroundColor: "#E9EAEB", 
            border: "2px solid white", 
            minWidth: "calc(25vw - 2px)",
            verticalAlign: "middle"
            }}>
              <div className={`${(i === 0 && isShowColor) ? "d-flex justify-content-between align-items-center" : "d-flex justify-content-center"}`}>
              {el}
              {/* isShowColor is used to show the color in the salestable */}
              <span style={{
              backgroundColor: (isShowColor && i === 0 && key !== data.length - 1) ? colors[key] : "transparent",
              width: "10px",
              height: "10px",
              display: i === 0 ? "inline-block" : "inline"
            }}>
            </span>
            </div>
            </td>
        ))
        }
        </tr>
    ))
    : (
    <tr style={{height: "150px"}}>
      <td style={{border: "none", verticalAlign: "middle"}} colSpan={column.length}>No data available</td>
    </tr>
    )
    }
  </tbody>
</table>
    </div>
  )
}

export default SalesTable