import React from 'react'
import { AiOutlineFilePdf } from 'react-icons/ai';

function ComboSummaryReport() {
  const comboSummaryReportColumn = [
    {
      id: 1,
      name: "Product Name",
    },
    {
      id: 2,
      name: "Booked",
    },
    {
      id: 3,
      name: "Delivered",
    },
    {
      id: 4,
      name: "Pending",
    },
    {
      id: 5,
      name: "Order To Be Placed",
    },
  ];

  const comboSummaryReportData = [];

  // Example data
  // {"Product Name": 'Product 1', 
  // "Booked": 'Yes', 
  // "Delivered": 'Yes', 
  // "Pending": 'No', 
  // "Order To Be Placed": 'Yes'}

  // Style object for table headings
  const headingStyle = {
    backgroundColor: '#ADAEB0',
    fontSize: '12px'
  };

  // Style object for table border
  const tableStyle = {
    borderColor: 'white',
  };

  // Style object for empty tbody
  const noDataStyle = {
    height: '75vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
  };

  return (
    <div className="container">
      <table className="table table-sm" style={tableStyle}>
        <thead>
          <tr>
            {comboSummaryReportColumn.map(column => (
              <th scope="col" key={column.id} style={headingStyle}>{column?.name}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {comboSummaryReportData.length > 0 ? (
            comboSummaryReportData.map((data, index) => (
              <tr key={index}>
                {comboSummaryReportColumn.map(column => (
                  <td key={column.id}>{data[column?.name]}</td>
                ))}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={comboSummaryReportColumn.length} className="text-center border-0">
                <div style={noDataStyle}>
                  <AiOutlineFilePdf size={64} color='red' />
                  <span className='mt-2'>NO DATA AVAILABLE</span>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default ComboSummaryReport;