import React from 'react'

function YearlySalesTable({column,data }) {

    return (
        <div className='table-responsive' style={{margin: "0"}}>
    <table className="table" style={{margin: "0"}} >
      <thead>
        {
            <>
            <tr>
              <th rowSpan={2} scope="col" style={{
                backgroundColor: "#ADAEB0", 
                border: "2px solid white", 
                verticalAlign: "middle"
              }} >
                {column.name1}
                </th>
              <th scope="col" style={{
                backgroundColor: "#ADAEB0", 
                border: "2px solid white", 
              }} >
                {column.name2}
                </th>
              <th scope="col" style={{
                backgroundColor: "#ADAEB0", 
                border: "2px solid white", 
              }} >
                {column.name3}
                </th>
            </tr>
            {column.name4 && column.name5 &&
            <tr>
              <th scope="col" style={{
                backgroundColor: "#ADAEB0", 
                border: "2px solid white", 
              }} >
                {column.name4}
                </th>
              <th scope="col" style={{
                backgroundColor: "#ADAEB0", 
                border: "2px solid white", 
              }} >
                {column.name5}
                </th>
            </tr>
            }
            </>   
        }
      </thead>
      <tbody>
        {data?.length ? data.map((item, i) => 
        <React.Fragment key={i}>
        <tr>
            <td
            rowSpan={2}
            style={{backgroundColor: "#E9EAEB", 
            border: "2px solid white", 
            verticalAlign: "middle",
            }}>
              {item.division}
            </td>
            <td
            style={{backgroundColor: "#E9EAEB", 
            border: "2px solid white", 
            }}>
              {item.ysa}
            </td>
            <td
            style={{backgroundColor: "#E9EAEB", 
            border: "2px solid white", 
            }}>
              {item.overallChg}
            </td>
        </tr>
          <tr>
            <td
            style={{backgroundColor: "#E9EAEB", 
            border: "2px solid white", 
            }}>
              {item.sales}
            </td>
            <td
            style={{backgroundColor: "#E9EAEB", 
            border: "2px solid white", 
            }}>
              {item.overallChgPercent}
            </td>
        </tr>
        </React.Fragment>
        )
        : (
          <tr style={{height: "150px", maxWidth: "100%"}}>
            <td style={{border: "none", verticalAlign: "middle",}} colSpan={Object.values(column).length}>No data available</td>
          </tr>
          )  }
      </tbody>
    </table>
        </div>
      )
}

export default YearlySalesTable