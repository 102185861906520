import React from "react";
import {
  StyledCard,
  StyledCardTitle,
  StyledDateText,
  StyledEarnText,
  StyledDataContainer,
  StyledTitle,
} from "./styledCompinents";
import moment from "moment";

function GamesUpcomingCard({
  title,
  startdate,
  enddate,
  socreTotal,
  badgeTotal,
  totalCertificate,
  onClick,
}) {
  return (
    <StyledCard onClick={onClick}>
      <StyledCardTitle>{title}</StyledCardTitle>
      <StyledDateText>{`${moment(startdate).format("DD MMM")} - ${moment(
        enddate
      ).format("DD MMM")}`}</StyledDateText>
      <StyledEarnText>You will earn</StyledEarnText>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          marginTop: "-8px",
        }}
      >
        <StyledDataContainer>
          <StyledTitle>{socreTotal}</StyledTitle>
          <div>Points</div>
        </StyledDataContainer>
        <StyledDataContainer>
          <StyledTitle>{badgeTotal}</StyledTitle>
          <div>Badges</div>
        </StyledDataContainer>
        <div>
          <StyledTitle>{totalCertificate}</StyledTitle>
          <div>Certificates</div>
        </div>
      </div>
    </StyledCard>
  );
}

export default GamesUpcomingCard;
