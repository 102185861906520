import React, { useState, useEffect } from "react";
import "./SlotMachineGame.css";
import { Modal } from "react-bootstrap";
import CelebrationConfetti from "../../component/Movement/CelebrationConfetti";
import Swal from "sweetalert2";
import { asyncWrap } from "../../utils/utils";
import axios from "axios";
import ReactHowler from "react-howler";
import newSound from "/assets/audio/spinandwin.mp3";

import { useTranslation } from "react-i18next";
const SlotMachineGame = ({ show, setShow, machineId, machineName }) => {
  const [spin, setSpin] = useState(false);
  const [k1, setk1] = useState(1);
  const [k2, setk2] = useState(1);
  const [k3, setk3] = useState(2);
  const [active, setActive] = useState(false);
  const [data, setData] = useState([]);
  const [noOfSpin, setNoOfSpin] = useState(0);
  const { t } = useTranslation();

  const [playing, setPlaying] = useState(false);

  const getSlotOptionData = async () => {
    const [error, result] = await asyncWrap(
      axios.get(`/slot-machine/option?slotMachineId=${machineId}`)
    );
    if (!result) {
      console.log(error);
      return;
    }

    setData(result.data.data);
  };

  useEffect(() => {
    onstop();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [k3]);

  useEffect(() => {
    if (show) {
      getSlotOptionData();
      getMachineData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const getdata = async () => {
    const [error, result] = await asyncWrap(
      axios.get(`/slot-machine/win?slotMachineId=${machineId}`)
    );
    if (!result) {
      console.log(error);
      return;
    }

    setk1(result.data.data.winningSequence[0]);
    setk2(result.data.data.winningSequence[1]);
    setk3(result.data.data.winningSequence[2]);
    Swal.fire(`You Won ${result.data.data.points} points`);
    setActive(false);
    setPlaying(false);
  };

  const getMachineData = async () => {
    const [error, result] = await asyncWrap(
      axios.get(`/slot-machine?slotMachineId=${machineId}`)
    );
    if (!result) {
      console.log(error);
      return;
    }
    setNoOfSpin(result.data.data[0].NoOfSpin);
  };

  function row1(k) {
    if (!spin) {
      return (
        <div>
          {data &&
            data.map((item, index) =>
              index === 1 ? (
                <div className="ringEnd" key={index}>
                  <img width={90} src={data[k - 1]?.OptionImage} alt="option" />
                </div>
              ) : (
                <div className="ringEnd" key={index}>
                  <img width={90} src={item.OptionImage} alt="option" />
                </div>
              )
            )}
        </div>
      );
    } else {
      return (
        <div>
          {data &&
            data.map((item, index) => (
              <div className="ringMoving" key={index}>
                <img width={90} src={item.OptionImage} alt="options" />
              </div>
            ))}
        </div>
      );
    }
  }

  const play = () => {
    setPlaying(true);
    if (!spin) {
      setSpin(true);
      setTimeout(() => {
        setActive(true);
        getdata();
        // getResult();
        setSpin(false);
        // Swal.fire(`You Won ${points} points`);
        setNoOfSpin(noOfSpin + 1);
      }, 2000);
    }
  };

  function onstop() {
    setActive(false);
    // eslint-disable-next-line eqeqeq
    k1 == k2 && k2 == k3 ? console.log("win") : console.log("loss");
  }

  return (
    <>
      <ReactHowler playing={playing} src={[newSound]} loop={true} />
      <Modal
        fullscreen={true}
        show={show}
        style={{ backgroundColor: "white !important" }}
        onHide={() => setShow(false)}
      >
        {/* <Modal.Header
          style={{
            border: 0,
            display: "flex",
            flexDirection: "row-reverse",
            marginBottom: 20,
          }}
        > */}
          {/* <SecoundaryHeader
            text={`${t("slot_machine")}-${machineName}`}
            sideHide="false"
            onBack={() => {
              console.log("about us back clicked");
              setShow(false);
            }}
          /> */}
        {/* </Modal.Header> */}
        <Modal.Body
          style={{
            marginTop: "20px",
            background: "#fffef2",
            boxShadow: "#00000005",
            borderRadius: " 20px 20px 0px 0px",
            textAlign: "center",
          }}
        >
          <CelebrationConfetti active={active} />
          <div className="fullSlot">
            <h1 className="casinoName">{t("casino_montecarlo")}</h1>
            <div className="slot">
              <div className="slotmachine">{row1(k1)}</div>
              <div className="slotmachine">{row1(k2)}</div>
              <div className="slotmachine">{row1(k3)}</div>
            </div>
            {noOfSpin > 9 ? (
              <div className="slotFoot">
                <button
                  disabled
                  className="spinButton"
                  style={{ backgroundColor: "grey", color: "white" }}
                  onClick={play}
                >
                  {t("spin")}
                </button>
              </div>
            ) : (
              <div className="slotFoot">
                <button className="spinButton" onClick={play}>
                  {t("spin")}
                </button>
              </div>
            )}
            <div>
              <span>
                {t("no_of_spin_left")}: {noOfSpin > 9 ? "0" : 10 - noOfSpin}
              </span>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SlotMachineGame;
