import React from 'react'

function CategoryBtn({text}) {
  return (
    <div 
    style={{
        backgroundColor: "#FF3031", 
        color: "white",
        borderRadius: "25px",
        padding: "0.6em",
        width: "100%"
    }}
    >
        {text}</div>
  )
}

export default CategoryBtn