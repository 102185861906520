import { Modal } from "react-bootstrap";
import Board from "./Board";
import "./puzzle.css";

const Puzzle = ({ show, setShow, data }) => {
  return (
    <>
      <Modal
        fullscreen={true}
        show={show}
        style={{ backgroundColor: "white !important" }}
        onHide={() => setShow(false)}
      >
        <Modal.Body className="Puzzle">
          <Board data={data} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Puzzle;
