import { Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import EnglishIcons from "../../icons/languageIcons/english";
import i18next from "i18next";
import HindiIcons from "../../icons/languageIcons/hindi";
import GujaratiIcons from "../../icons/languageIcons/gujarati";
import TamilIcon from "../../icons/languageIcons/tamil";
import KannadaIcon from "../../icons/languageIcons/kannada";
import BanglaIcon from "../../icons/languageIcons/bangla";
import PunjabiIcon from "../../icons/languageIcons/punjabi";
import MarathiIcon from "../../icons/languageIcons/marathi";
import TeluguIcon from "../../icons/languageIcons/telugu";
import FrenchIcon from "../../icons/languageIcons/french";
import SpanishIcon from "../../icons/languageIcons/spanish";
import GermanIcon from "../../icons/languageIcons/german";
import PortugueseIcon from "../../icons/languageIcons/portuguese";
import JapaneseIcon from "../../icons/languageIcons/japanese";
import MalyalamIcon from "../../icons/languageIcons/malyalam";
import CloseModalTab from "../../component/Nav/CloseModalTab";

const LanguageSettings = ({ show, setShow }) => {
  const [currentLanguage, setCurrentLanguage] = useState(i18next.language);

  useEffect(() => {}, [currentLanguage]);
  useEffect(() => {
    if (localStorage.getItem("key_language")) {
      setCurrentLanguage(localStorage.getItem("key_language"));
    }
  }, []);

  const SelectLanguageComponent = ({ current, key_language, name, icons }) => {
    return (
      <div
        onClick={() => {
          localStorage.setItem("key_language", key_language);
          setCurrentLanguage(key_language);
          i18next.changeLanguage(key_language);
        }}
        style={{
          background: `${current === key_language ? "#3A69DD" : "white"}`,
        }}
        className="d-flex flex-column justify-content-center align-items-center shadow p-3 my-2 rounded-4"
      >
        {icons}
        <div
          style={{
            fontSize: "18px",
            color: `${current === key_language ? "white" : "#7B7B7B"}`,
          }}
          className="mt-2 fw-bold"
        >
          {name}
        </div>
      </div>
    );
  };

  return (
    <Modal fullscreen={true} show={show}>
      {/* <Modal.Header> */}
      {/* <SecoundaryHeader
          text={t('select_language')}
          sideHide="false"
          onBack={() => {
            history.push("/home");
            setShow(false);
          }}
        /> */}
      {/* </Modal.Header> */}
      <Modal.Body style={{ marginTop: "30px" }}>
        <CloseModalTab onclick={() => setShow(false)} />
        <Row className="my-2">
          <Col>
            <SelectLanguageComponent
              current={currentLanguage}
              key_language="english"
              icons={
                <EnglishIcons
                  color={currentLanguage === "english" ? "white" : "#352354"}
                />
              }
              name="English"
            />
          </Col>
          <Col>
            <SelectLanguageComponent
              key_language="hindi"
              current={currentLanguage}
              icons={
                <HindiIcons
                  color={currentLanguage === "hindi" ? "white" : "#C273E3"}
                />
              }
              name="हिंदी"
            />
          </Col>
        </Row>
        <Row className="my-2">
          <Col>
            <SelectLanguageComponent
              current={currentLanguage}
              key_language="tamil"
              icons={
                <TamilIcon
                  color={currentLanguage === "tamil" ? "white" : "#8CCB8C"}
                />
              }
              name="தமிழ்"
            />
          </Col>
          <Col>
            <SelectLanguageComponent
              key_language="gujarati"
              current={currentLanguage}
              icons={
                <GujaratiIcons
                  color={currentLanguage === "gujarati" ? "white" : "#D27B25"}
                />
              }
              name="Gujarati"
            />
          </Col>
        </Row>
        <Row className="my-2">
          <Col>
            <SelectLanguageComponent
              current={currentLanguage}
              key_language="kannada"
              icons={
                <KannadaIcon
                  color={currentLanguage === "kannada" ? "white" : "#7676C7"}
                />
              }
              name="ಕನ್ನಡ"
            />
          </Col>
          <Col>
            <SelectLanguageComponent
              key_language="bengali"
              current={currentLanguage}
              icons={
                <BanglaIcon
                  color={currentLanguage === "bengali" ? "white" : "#B8B82B"}
                />
              }
              name="বাংলা"
            />
          </Col>
        </Row>
        <Row className="my-2">
          <Col>
            <SelectLanguageComponent
              current={currentLanguage}
              key_language="panjabi"
              icons={
                <PunjabiIcon
                  color={currentLanguage === "panjabi" ? "white" : "#B82BA1"}
                />
              }
              name="ਪੰਜਾਬੀ"
            />
          </Col>
          <Col>
            <SelectLanguageComponent
              key_language="marathi"
              current={currentLanguage}
              icons={
                <MarathiIcon
                  color={currentLanguage === "marathi" ? "white" : "#8CA967"}
                />
              }
              name="मराठी"
            />
          </Col>
        </Row>
        <Row className="my-2">
          <Col>
            <SelectLanguageComponent
              current={currentLanguage}
              key_language="telugu"
              icons={
                <TeluguIcon
                  color={currentLanguage === "telugu" ? "white" : "#5AB5BB"}
                />
              }
              name="తెలుగు"
            />
          </Col>
          <Col>
            <SelectLanguageComponent
              key_language="french"
              current={currentLanguage}
              icons={
                <FrenchIcon
                  color={currentLanguage === "french" ? "white" : "#8F55A4"}
                />
              }
              name="française"
            />
          </Col>
        </Row>
        <Row className="my-2">
          <Col>
            <SelectLanguageComponent
              current={currentLanguage}
              key_language="spanish"
              icons={
                <SpanishIcon
                  color={currentLanguage === "spanish" ? "white" : "#B8B82B"}
                />
              }
              name="española"
            />
          </Col>
          <Col>
            <SelectLanguageComponent
              key_language="german"
              current={currentLanguage}
              icons={
                <GermanIcon
                  color={currentLanguage === "german" ? "white" : "#D27B25"}
                />
              }
              name="Deutsche"
            />
          </Col>
        </Row>
        <Row className="my-2">
          <Col>
            <SelectLanguageComponent
              current={currentLanguage}
              key_language="portuguese"
              icons={
                <PortugueseIcon
                  color={currentLanguage === "portuguese" ? "white" : "#B8B82B"}
                />
              }
              name="Português"
            />
          </Col>
          <Col>
            <SelectLanguageComponent
              key_language="japanese"
              current={currentLanguage}
              icons={
                <JapaneseIcon
                  color={currentLanguage === "japanese" ? "white" : "#D27B25"}
                />
              }
              name="日本"
            />
          </Col>
        </Row>
        <Row className="my-2 w-50">
          <Col>
            <SelectLanguageComponent
              current={currentLanguage}
              key_language="malyalam"
              icons={
                <MalyalamIcon
                  color={currentLanguage === "malyalam" ? "white" : "#D27B25"}
                />
              }
              name="മലയാളം"
            />
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default LanguageSettings;
