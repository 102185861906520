import React from 'react';
import { Card } from 'react-bootstrap';
import { FaFileDownload } from "react-icons/fa";
import SearchBar from '../SearchBar';


function Debit() {
    // Example data array
    const debitData = [
        {
            referenceNumber: 'DN/VJ/19-20/223',
            date: '05-03-2020',
            ledgerDesc: 'Wire & Cable',
            downloadDN: <FaFileDownload color='#FF3031' fontSize={24} />,
            type: 'Nach',
            amount: '₹ 99,750',

        },
    ];

    const handleSearch = (searchValue) => {
        // Search logic here
        console.log('Search value:', searchValue);
    };

    return (
        <div className="container mt-3">
            {/* Search Bar */}
            <SearchBar
                placeholder=" SEARCH BY AMOUNT / REFERENCE NUMBER... "
                onSearch={handleSearch} />

            {/* Debit Entries */}

            {debitData.map((debit, index) => (
                <Card
                    key={index}
                    className="mb-1"
                    style={{
                        width: '90vw',
                        backgroundColor: '#E9EAEB',
                        fontSize: '12px',
                        color: '#323e3e'
                    }}>
                    <Card.Header
                        style={{
                            backgroundColor: '#E9EAEB',
                            borderBottom: 'none'
                        }}>
                        <strong>Reference Number</strong>
                        <br />
                        <span
                            style={{ fontSize: '16px' }}>
                            {debit.referenceNumber}
                        </span>
                    </Card.Header>
                    {/* Vertical bar */}
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row'
                        }}>
                        <div
                            style={{
                                backgroundColor: 'black',
                                width: '5px',
                                margin: '8px 2px 10px 16px'
                            }}>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                width: '100%',
                                padding: '0.8rem'
                            }}>
                            <div
                                style={{
                                    flex: 1,
                                    textAlign: 'left'
                                }}>
                                <div>
                                    <strong>Date</strong>
                                    <br />
                                    {debit.date}
                                </div>
                                <div>
                                    <strong>Amount</strong>
                                    <br />
                                    {debit.amount}
                                </div>
                                <div>
                                    <strong>DownLoad DN</strong>
                                    <br />
                                    {debit.downloadDN}
                                </div>
                            </div>
                            <div
                                style={{
                                    flex: 1,
                                    textAlign: 'left'
                                }}>
                                <div>
                                    <strong>Ledger Desc</strong>
                                    <br />
                                    {debit.ledgerDesc}
                                </div>
                                <div>
                                    <strong>Type</strong>
                                    <br />
                                    {debit.type}
                                </div>
                            </div>
                        </div>
                    </div>
                </Card>
            ))}

        </div>
    );
}

export default Debit;
