import styled from 'styled-components';
import { Card } from 'react-bootstrap';

export const StyledDroppedCard = styled(Card)`
  height: 336px;
  margin-bottom: 20px;
  box-shadow: 5px 10px 30px #0000001a;
  border: 0;
  border-radius: 7px;
  background-color: white;
  width: 85%;
`;

export const StyledCard = styled(Card)`
  height: 100px;
  margin-bottom: 20px;
  box-shadow: 5px 10px 30px #0000001a;
  border: 0;
  border-radius: 7px;
  background-color: white;
  width: 85%;
`;

export const StyledStatusDiv = styled.div`
  background-color: ${(props) => props.color};
  width: 118px;
  height: 31px;
  border-radius: 10px 0px;
  color: white;
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 13px;
`;

export const StyledCardTitle = styled.h6`
  text-align: left;
  margin: 10px 0 0 10px;
`;

export const StyledDateText = styled.p`
    font-size: 13px;
    text-align: left;
    margin-top: 5px;
    margin-left: 10px;
`

export const StyledMileStoneText = styled.h6``
