import { Modal } from "react-bootstrap";
import Stories from "react-insta-stories";

function StoryModal({ show, setShow, stories, index }) {
  return (
    <Modal fullscreen={true} show={show} onHide={() => setShow(false)}>
      <div className="story-close" onClick={() => setShow(false)}>
        X
      </div>
      <Stories
        stories={stories}
        height={"100vh"}
        width={"100%"}
        styles={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
          margin: "auto",
        }}
        currentIndex={index}
        onAllStoriesEnd={() => {
          setShow(false);
        }}
        next={() => {
          if (index === stories.length) {
            setShow(false);
          }
        }}
      />
    </Modal>
  );
}

export default StoryModal;
