export const PRIMES_NUMBERS = [
  2, 3, 5, 7, 11, 13, 17, 19, 23, 29, 31, 37, 41, 43, 47, 53, 59, 61, 67, 71,
  73, 79, 83, 89, 97,
];

//Get Random Symbols
export const getRandomSymbols = () => {
  const symbols = ["+", "-", "*", "/", "+"];
  var min = 0;
  var max = symbols.length - 1;
  var random = Math.round(Math.random() * (+max - +min) + +min);
  return symbols[random];
};

const division = (prev) => {
    const ram = Math.floor(Math.random() * (10 - 1) + 1);
      const commonMultiple = PRIMES_NUMBERS.filter(
        (n) => prev % n === 0 && ram * n
      );
      const index = Math.floor(Math.random() * (commonMultiple.length - 1));

      return +commonMultiple[index] || 1;
}

//Return a function and it will be used to generate the next number
export const evaluate = (symbol, prev) => {
  //Division
  if (symbol === "/") {
    return division(prev)
  }

  //Multiplicaton
  if (symbol === "*") {
    let ramNum = 0;
    if (prev < 15) {
      ramNum = 15;
    }
    if (prev > 14 && prev < 50) {
      ramNum = 8;
    }
    if (prev > 49) {
      ramNum = 5;
    }

    return getRandomNumber(ramNum);
  }

  //Other
  if (symbol === "+" || symbol === "-") {
    const random = getRandomNumber(70);
    return random;
  }
};

//Get Random Number from 0 to rang passed as Paramater
const getRandomNumber = (range) => {
  return (Math.random() * (range || 100)).toFixed();
};

//Return a Math operation depnding on users point

const generateProblem = (points) => {
  const lv = parseInt(points / 100 / 5 + 1);
  let prevNum = getRandomNumber();
  let problem = prevNum;

  for (let i = 0; i < lv; i++) {
    let symbol = getRandomSymbols();
    let getAnotherNumber = evaluate(symbol, prevNum);
    let anotherNumber = getAnotherNumber || 0; //Avoinding Undefined
    problem += ` ${symbol} ${anotherNumber}`;
    prevNum = anotherNumber;
  }

  return problem;
};

//Evaluate the Math expression with value passed
const compare = (expression, value) => {
  return solve(expression) === Number(value);
};

//Solving the Expresion
const solve = (expression) => {
  // eslint-disable-next-line no-eval
  const result = String(eval(expression));
  //Returning just two fixed points
  if (result.toString().includes(".")) {
    return Number(result.toFixed(2));
  }

  return result;
};

const MathHelper = {
  generateProblem,
  compare,
  solve,
};

export default MathHelper;
