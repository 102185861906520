import axios from "axios";
import { useEffect, useState } from "react";
import { Carousel, Image, Modal } from "react-bootstrap";
import ScratchCard from "react-scratchcard-v3";
import { asyncWrap } from "../../utils/utils";
import close from "/assets/images/popclose.png";
import scratchImage from "/assets/images/scrachcard.png";
import { useAuth } from "../../context/auth-context";
import Swal from "sweetalert2";

const ScrachCardModal = ({ show, setShow }) => {
  const [scratchcardData, setScratchCardData] = useState([]);
  const { user } = useAuth();

  useEffect(() => {
    getScratchCard();
    //eslint-disable-next-line
  }, []);

  const getScratchCard = async () => {
    const [error, result] = await asyncWrap(axios.get("/scratch-card"));
    if (!result) {
      console.log(error);
      return;
    }
    setScratchCardData(result.data.data.filter((item) => !item.IsScrached));
    // setScratchCardData(result.data.data);
  };

  return (
    <>
      <Modal
        fullscreen={false}
        backdrop="static"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={() => setShow(false)}
      >
        <Modal.Body className="m-0 p-0 text-left">
          {scratchcardData.length !== 0 && (
            <Image
              src={close}
              alt=""
              className="modal-close"
              onClick={() => {
                setShow(false);
                // history.push("/home");
              }}
            />
          )}
          <Carousel
            interval={null}
            indicators={false}
            indicatorLabels={false}
            wrap={false}
          >
            {scratchcardData.map((item, index) => (
              <Carousel.Item key={index}>
                {item?.IsScrached ? (
                  <div
                    className="text-center"
                    style={{ height: 346, width: 285 }}
                  >
                    <Image
                      src={item?.ScrachValueImage}
                      alt=""
                      className="mt-3"
                    />
                    <p className="mt-1 mb-0">You Win </p>
                    <p
                      className="mt-1 mb-0 fs-20 text-bold"
                      style={{ color: "#DC7832" }}
                    >
                      {item?.value}
                    </p>
                    <p className="mt-1 mb-0 fs-20">{item?.GoalScratchName}</p>
                    <hr />
                    <span className="fs-15 text-grey p-2">
                      {item?.goalscratchcardid?.goalscratchdescription}
                    </span>
                  </div>
                ) : (
                  <ScratchCard
                    width={285}
                    height={346}
                    image={scratchImage}
                    finishPercent={80}
                    onComplete={async () => {
                      const [error, result] = await asyncWrap(
                        axios.post("/updatescratchcard", {
                          userscratchcardId: item.UserScrachcardID,
                          userId: user.userId,
                          isScrached: 1,
                        })
                      );
                      if (!result) {
                        Swal.fire(error.response.data?.data?.message);
                      }
                    }}
                  >
                    <div className="text-center">
                      <Image
                        src={item?.ScrachValueImage}
                        alt=""
                        className="mt-3"
                        style={{ width: "80%" }}
                      />
                      <p className="mt-1 mb-0">You Win </p>
                      <p
                        className="mt-1 mb-0 fs-20 text-bold"
                        style={{ color: "#DC7832" }}
                      >
                        {item?.value}
                      </p>
                      <p className="mt-1 mb-0 fs-20">{item?.GoalScratchName}</p>
                      <hr />
                      <span className="fs-15 text-grey p-2">
                        {item?.Description}
                      </span>
                    </div>
                  </ScratchCard>
                )}
              </Carousel.Item>
            ))}
          </Carousel>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ScrachCardModal;
